export const removeItemsAtIndexes = (
  arr: any[],
  indexes: number | number[]
) => {
  if (!arr) {
    throw new Error("Attempted to remove items from a non existent array");
  }
  if (!indexes && indexes !== 0) {
    throw new Error(
      "Did not pass any indexes to remove to removeItemsAtIndexes()"
    );
  }

  if (!Array.isArray(indexes)) {
    indexes = [indexes];
  }
  return indexes.forEach((rm: number, i: number) => arr.splice(rm - i, 1));
};

export const removeFields = (
  obj: Record<string, any>,
  fieldsToRemove: string[]
) => {
  if (!obj) {
    throw new Error("Attempted to remove fields from a non existent object");
  }
  if (!fieldsToRemove) {
    throw new Error("Did not pass any fields to remove to removeFields()");
  }
  for (const field of fieldsToRemove) {
    delete obj[field];
  }
};
