import PropTypes from "prop-types";
import React from "react";
//import dynamic from 'next/dynamic';
import useTranslation from "next-translate/useTranslation";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import InputLoader from "components/Loading/InputLoader";
import { useFullUser } from "../../auth/useUser";

//const Table = dynamic(() => import('components/Table/Table.js'));

export default function MeasurementPreferenceToggle(props) {
  const {
    handleUpdateUser: deferredHandleUpdateUser,
    athlete,
    labelSmall,
  } = props;

  const [user, { isUpdating, handleUpdateUser }] = useFullUser();
  const athleteToUse = athlete || user;

  const {
    communicationPreferences: { measurementPreference },
  } = athleteToUse;
  const [loadingState, setLoadingState] = React.useState();

  const { t } = useTranslation();
  const update = deferredHandleUpdateUser || handleUpdateUser;

  React.useEffect(() => {
    if (!isUpdating && loadingState) {
      setLoadingState(false);
    }
  }, [isUpdating, loadingState]);

  const getLabel = (value) => {
    if (!loadingState) {
      return value;
    } else {
      return measurementPreference === value ? (
        <InputLoader size={16} color="gray" />
      ) : (
        value
      );
    }
  };
  const toggleSx = { minWidth: 33 };
  return (
    <>
      <FormControl variant="standard">
        <FormLabel
          sx={{
            mb: 1,
            fontSize: labelSmall ? "0.75rem" : "unset",
          }}
        >
          {t("dashboard:measurementPreference")}
        </FormLabel>
        <ToggleButtonGroup
          value={measurementPreference}
          exclusive
          disabled={isUpdating}
          onChange={(event) =>
            update({
              event,
              name: "measurementPreference",
              paths: ["communicationPreferences", "measurementPreference"],
              type: "athlete",
              setLoadingState,
            })
          }
          aria-label="Measurement preference"
          size="small"
        >
          <ToggleButton value="mi" sx={toggleSx} aria-label="Miles">
            {getLabel("mi")}
          </ToggleButton>
          <ToggleButton value="km" sx={toggleSx} aria-label="Kilometers">
            {getLabel("km")}
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
    </>
  );
}

MeasurementPreferenceToggle.propTypes = {
  measurementPreference: PropTypes.oneOf(["mi", "km"]),
};
