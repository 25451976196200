import React from "react";
import Image from "components/Image/CustomImage";

import { makeStyles } from "tss-react/mui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWordpress } from "@fortawesome/free-brands-svg-icons/faWordpress";

const useStyles = makeStyles()((theme) => ({
  poweredByStravaBadge: {
    paddingBottom: "3px",
    opacity: "0.4",
    [theme.breakpoints.down("lg")]: {
      marginTop: "20px",
      marginLeft: "10px",
    },
  },
  poweredByWordpressBadge: {
    [theme.breakpoints.down("lg")]: {
      paddingTop: "20px",
      marginLeft: "15px",
      fontSize: "40px",
    },
  },
}));

export default function PoweredByBadge(props) {
  const { classes } = useStyles();

  const { provider } = props;
  const providers = {
    strava: (
      <Image
        className={classes.poweredByStravaBadge}
        width={100}
        height={43}
        src={"/images/logos/strava/api_logo_pwrdBy_strava_stack_light.svg"}
        alt="Powered by Strava logo"
      />
    ),
    wordpress: (
      <span className={classes.poweredByWordpressBadge}>
        <FontAwesomeIcon icon={faWordpress} />
      </span>
    ),
  };
  const Badge = providers[provider];

  // Allow for issues when the badge does not display
  return Badge || <div />;
}
