import React from "react";

import styles from "components/Loading/AddLoadingDotsToText.module.css";

export default function AddLoadingDotsToText(props) {
  const { children } = props;

  // TODO: Wasn't working using JSS in MuiV4, so using CSS to achieve. To revisit once Material UI upgraded to V5

  return (
    <>
      {children}
      <span className={styles.addLoadingDots}></span>
    </>
  );
}
