import React from "react";

import Link from "next/link";
import Image from "components/Image/CustomImage";

import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { useRenders, scrollToTop } from "modules/utils";

import Button from "components/CustomButtons/Button.js";

import {
  whiteColor,
  hoverShadow,
} from "../../assets/jss/nextjs-material-kit-pro";

import { websiteLogoPath } from "../../settings/global-website-config";
// TODO: Link is currently set to 'noLang' so will not be localised correctly if localisation used

// @mui/icons-material
const logoCenterPosition = "calc(50% - 53px)";
const logoLeftPosition = 70;
const useStyles = makeStyles()((theme) => ({
  websiteTitle: {
    //  minWidth: '100px',
    // fontWeight: '600',
    //  color: 'inherit',
    position: "relative",
    padding: "0px", // was 0.9375rem
    fontSize: "18px",
    lineHeight: "20px",
    textTransform: "capitalize",
    textDecoration: "none",
    margin: "10px 5px 10px 0px", // was 10px 16px 5px 0px etc
    display: "inline-flex",
    "& a": {
      color: whiteColor,
      "&:hover": {
        ...hoverShadow,
      },
    },
  },
  centreOnMobile: {
    [theme.breakpoints.between("xs", "sm")]: {
      position: "absolute",
      left: logoCenterPosition,
      right: logoCenterPosition,
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      left: logoLeftPosition,

      //  marginRight: 0,
    },
  },
}));

const LogoButton = (props) => {
  const { logoHeight, disabled, dark, centreOnMobile } = props;

  const websiteLogoSvg = !dark
    ? "/images/logos/grly_wh_noshoe.svg"
    : "/images/logos/geerly_text_logo_gr@4x.png";

  const { classes, cx } = useStyles();
  useRenders("logoButton");
  return (
    <Link href="/" prefetch={false} passHref legacyBehavior>
      <Button
        className={cx({
          [classes.websiteTitle]: true,
          [classes.centreOnMobile]: centreOnMobile,
        })}
        transparent
        link
        disabled={disabled}
        disableRipple
        fontWeight="600"
        component="a"
        onClick={() => scrollToTop()}
      >
        <Image
          style={{ objectFit: "contain" }}
          src={websiteLogoSvg}
          alt="Home"
          onError={(e) => {
            if (e.target.src !== websiteLogoPath) {
              e.target.src = websiteLogoPath;
            }
          }}
          height={logoHeight}
          width={logoHeight * 2.5}
          noPlaceholder
        />
      </Button>
    </Link>
  );
};

LogoButton.propTypes = {
  disabled: PropTypes.any,
  logoHeight: PropTypes.number,
  dark: PropTypes.bool,
};

LogoButton.defaultProps = { logoHeight: 24 };

export default LogoButton;
