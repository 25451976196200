import React from "react";
import parse from "html-react-parser";
import { makeStyles } from "tss-react/mui";
import Link from "next/link";
import { ROOT_URL, SITE_URL } from "settings/global-urls";
import ProductEmbed from "components/Embeds/ProductEmbed";
import { FeaturedImage } from "components/Image/FeaturedImage";
import { hexToRgb } from "@mui/material";

import {
  captionText,
  imgFluid,
  blackColor,
} from "../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  captionText,
  imgFluid,
  imgRounded: {
    borderRadius: `${theme.shape.borderRadius} !important`,
  },
  imgRaised: {
    boxShadow:
      "0 5px 15px -8px rgba(" +
      hexToRgb(blackColor) +
      ", 0.24), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
  },
}));

const RenderFeaturedImage = ({
  domNode,
  imgClassesToUse,
  classes,
  content,
}) => {
  const img = domNode.children[0].attribs;
  img.sourceUrl = img.src;

  return (
    <>
      <FeaturedImage
        featuredImage={img}
        fallbackAltText={content.title}
        imgClass={imgClassesToUse}
      />
      <div className={classes.captionText}>
        {domNode.children[1].children[0]?.data}
      </div>
    </>
  );
};

export default function CustomParser(props) {
  const { content, imgClass, contentClass, removePTags, ...rest } = props;
  const { classes, cx } = useStyles();

  // send it back if there is no content
  if (!content) return <div />;

  const imgClassesToUse =
    imgClass || cx(classes.imgRaised, classes.imgRounded, classes.imgFluid);

  // config which pulls out images and captions and applies the house style to them:
  const options = {
    replace: (domNode) => {
      if (!domNode.attribs) return;

      if (
        domNode.name === "div" &&
        domNode.attribs.id?.indexOf("attachment") !== -1 &&
        domNode.children &&
        domNode.children[0]?.name === "img"
      ) {
        return (
          <RenderFeaturedImage
            domNode={domNode}
            imgClassesToUse={imgClassesToUse}
            classes={classes}
            content={content}
          />
        );
      }

      if (domNode.name === "a") {
        // change internal links from <a> to <link>
        let path = domNode.attribs.href;

        if (!path) {
          // bail if the path is empty and just return the text
          return domNode.children[0]?.data;
        }
        // return if not internal
        if (path.includes(ROOT_URL)) {
          // remove the domain

          path = path.split(ROOT_URL)[1];
        } else if (path.includes("http")) {
          return domNode;
        }
        // Didn't include the path or http = must be a relative URL

        return (
          <Link href={path} legacyBehavior>
            {domNode.children[0]?.data}
          </Link>
        );
      }

      if (domNode.attribs.class === "wp-caption-text") {
        return (
          <div className={classes.captionText}>{domNode.children[0]?.data}</div>
        );
      }

      if (domNode.name === "p" && removePTags) {
        return React.createElement("div", {}, domNode.children[0]?.data);
      }

      if (domNode.name === "productembed") {
        // Replace <customComponent> with your React component

        const rawData = domNode.attribs["data-json"];
        if (!rawData) {
          return "";
        }
        const props = JSON.parse(rawData);

        return <ProductEmbed {...props} {...rest} />;
      }
    },
  };

  return (
    <div className={contentClass}>{content && parse(content, options)}</div>
  );
}
