import React from "react";

import Toolbar from "@mui/material/Toolbar";

import { makeStyles } from "tss-react/mui";

import {
  websiteName,
  year,
  i18Internals,
} from "settings/global-website-config.js";
import LogoButton from "components/CustomButtons/LogoButton.js";

import I18nProvider from "next-translate/I18nProvider";
import setNamespaces from "modules/setNamespaces.js";

import footerMenu from "staticContent/footer-menu.json";

import useTranslation from "next-translate/useTranslation";
import { defaultLocale } from "i18n";
import { hexToRgb } from "@mui/material";

import { container, grayColor } from "../../assets/jss/nextjs-material-kit-pro";
import { FooterLinks } from "./FooterLinks";

const useStyles = makeStyles()((theme) => ({
  //...homeStyle,

  bottomStrip: {
    backgroundColor: grayColor[2],
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    paddingLeft: "57.5px",
    paddingRight: "57.5px",
    textAlign: "center",
    width: "100%", // vw was causing issues on very small size
    color: grayColor[1],
  },

  // Lowercase is important!

  // eslint-disable-next-line tss-unused-classes/unused-classes
  addBottomPaddingtall: {
    [theme.breakpoints.down("md")]: { paddingBottom: "70px" },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  addBottomPaddingshort: {
    [theme.breakpoints.down("md")]: { paddingBottom: "40px" },
  },
  bottomStripText: { fontSize: "14px", color: grayColor[7] },
  footer: {
    paddingTop: "0.9375rem",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    zIndex: "100",
    position: "relative",
    "& ul": {
      marginBottom: "0",
      padding: 0,
      listStyle: "none",
    },
  },
  dark: {
    background: `radial-gradient(ellipse at center,${grayColor[4]} 0,${grayColor[5]} 100%)`,
    backgroundSize: "550% 450%",
    zIndex: 1,
    color: theme.palette.common.white,
    "& p": {
      color: grayColor[0],
    },
    "& i": {
      color: theme.palette.common.white,
    },
    "& a": {
      color: theme.palette.common.white,
      opacity: "0.75",

      "&:focus, &:hover": {
        opacity: 1,
        color: theme.palette.common.white,
      },
    },
    "& hr": {
      borderColor: `rgba(${hexToRgb(theme.palette.common.white)},0.2)`,
    },
    "& $btnTwitter, & $btnFacebook, & $btnInstagram, & $btnStrava": {
      color: theme.palette.common.white,
    },
  },

  container: {
    ...container,
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    width: "100%",
    paddingBottom: "15px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
}));

function FooterContent({ bottomStripSize }) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const bottomStripClasses = bottomStripSize
    ? `${classes.bottomStrip} ${classes[`addBottomPadding${bottomStripSize}`]}`
    : classes.bottomStrip;
  return (
    <div className={bottomStripClasses}>
      <div className={classes.bottomStripText}>
        &copy; {t("nav:madeBy", { year, websiteName })}
      </div>
      <div className={classes.bottomStripText}>{t("nav:affiliateNotice")}</div>
    </div>
  );
}

export default function Footer(props) {
  const { isMaintenanceMode, bottomStripSize } = props;
  const { classes, cx } = useStyles();
  const { lang } = useTranslation();

  const supportedNamespaces = ["nav", "common"];
  const namespaces = setNamespaces(lang || defaultLocale, supportedNamespaces);

  const footerClasses = cx({
    [classes.footer]: true,
    [classes.dark]: true,
  });

  //const cookiePreferencesTitle = namespaces.nav.cookiePreferences;
  return (
    <>
      <I18nProvider
        lang={lang}
        internals={i18Internals}
        namespaces={namespaces}
      >
        <footer className={footerClasses}>
          <Toolbar className={classes.container}>
            <div className="left">
              <LogoButton
                logoHeight={40}
                titleFontSize="18px"
                disabled={isMaintenanceMode}
              />
            </div>
            <FooterLinks
              menu={footerMenu}
              isMaintenanceMode={isMaintenanceMode}
            />
          </Toolbar>
          <FooterContent bottomStripSize={bottomStripSize} />
        </footer>
      </I18nProvider>
    </>
  );
}

Footer.propTypes = {};
