import React from "react";

import dynamic from "next/dynamic";

import { makeStyles } from "tss-react/mui";
import Button from "components/CustomButtons/Button";

import { textCenter } from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()({ textCenter });

const PopUp = dynamic(() => import("components/PopUpMessage/PopUp.js"));

const ButtonWithConfirmationAlert = (props) => {
  const { classes } = useStyles();
  const { children, message, onAlertAccept, alertButtonText, ...rest } = props;

  const [showMessage, setShowMessage] = React.useState(false);
  return (
    <>
      <PopUp
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        content={<h5 className={classes.textCenter}>{message}</h5>}
        alertButtonText={alertButtonText}
        showLoader
        onAlertAccept={onAlertAccept}
        showCancelButton
        small
      />
      <Button {...rest} onClick={() => setShowMessage(true)}>
        {children}
      </Button>
    </>
  );
};

if (process.env.NODE_ENV !== "production") {
  ButtonWithConfirmationAlert.displayName = "ButtonWithConfirmationAlert";
}

export default ButtonWithConfirmationAlert;
