import React from "react";
import { gql } from "@apollo/client";
import AddLoadingDotsToText from "components/Loading/AddLoadingDotsToText.js";

import CustomLink from "components/Links/CustomLink";
//import Link from 'next/link';

import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";

import Divider from "@mui/material/Divider";
import { AffiliateLink } from "components/Links/AffiliateLink.js";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import MoreButton from "components/CustomButtons/MoreButton";
import CustomCarousel, {
  getSizesFromResponsiveSettings,
} from "components/Feedblocks/CustomCarousel.js";

import {
  FeaturedImage,
  FEATUREDIMAGEFRAGMENT,
} from "components/Image/FeaturedImage";

import useDeals from "modules/products/useDeals";

import {
  textButton,
  sectionTitleBlock,
  innerTitle,
} from "../../assets/jss/nextjs-material-kit-pro";
import {
  ANY_COLOUR_NAME,
  DEFAULT_RESPONSIVE_SETTINGS,
} from "../../settings/global-website-config";
import { getSizeDeals } from "../../modules/products/useSizeAndSex";

const useStyles = makeStyles()((theme) => ({
  textButton: { ...textButton, /*  minHeight: '70px', */ marginBottom: "10px" },
  marginBottom: { marginBottom: theme.spacing(4) },
  sectionTitleBlock: {
    ...sectionTitleBlock,
    marginBottom: "0px",
  },
  verticalCenter: {
    alignItems: "center",
  },
  innerTitle,
  titleAndPrice: {
    display: "block",
    margin: theme.spacing(0.5),
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export function FeedBlock(props) {
  const { classes, cx } = useStyles();
  const {
    feedItems,
    feedTitle,
    moreButtonText,
    moreButtonHref,
    showShadow,
    loading,
    noPrefetch,
    noThumbnail,
    showDivider,
    fallbackImage,
    responsiveSettings,
    type,
    showDeals,
    state,
    dealsLocale,
    disableScroll,
    displayHorizontally,
    hideScrollButtonsWhenNotNeeded,
    noMargin,
    dummyPrice,
    sortImages,
    supportedChoiceTypes,
    ...rest // Incs width and height overrides
  } = props;

  const { nodes, pageInfo } = feedItems;
  const showMoreButton = pageInfo?.hasNextPage;

  const dummyItems = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];

  let itemsToUse = nodes || dummyItems;

  if (sortImages) {
    itemsToUse = itemsToUse.sort((a, b) => {
      const aHasImage = a?.featuredImage?.node?.sourceUrl;
      const bHasImage = b?.featuredImage?.node?.sourceUrl;

      if (aHasImage && !bHasImage) {
        return -1;
      }

      if (!aHasImage && bHasImage) {
        return 1;
      }
      return 0;
    });
  }

  const dealsState = useDeals(showDeals ? nodes : null, state, dealsLocale, {
    isVisible: showDeals,
    liteOnly: true,
    noSizeOrSex: supportedChoiceTypes?.includes("none"),
  });

  const extraFeaturedImageProps = {};
  if (noPrefetch) {
    // We  do it this way around to prevent a console warning about prefetch=true being deprecated
    extraFeaturedImageProps.prefetch = false;
  }

  const sizes = React.useMemo(
    () => getSizesFromResponsiveSettings(responsiveSettings),
    [responsiveSettings]
  );

  return (
    <div
      className={cx({
        [classes.sectionTitleBlock]: !noMargin,
        [classes.marginBottom]: !showMoreButton && !noMargin,
      })}
    >
      {showDivider && !loading && <Divider variant="middle" />}
      {feedTitle && (
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={10}>
            <h2 className={classes.innerTitle}>{feedTitle}</h2>
          </GridItem>
        </GridContainer>
      )}
      <CustomCarousel
        loading={loading}
        responsiveSettings={responsiveSettings}
        disableScroll={disableScroll}
        hideScrollButtonsWhenNotNeeded={hideScrollButtonsWhenNotNeeded}
      >
        {itemsToUse.map((item) => {
          const deals = dealsState?.sexDeals?.[item.databaseId]?.deals;

          const dealObj = getSizeDeals(state, deals?.colours?.[ANY_COLOUR_NAME])
            ?.allDeals?.[0];

          const price =
            dummyPrice ||
            (dealsState.loading ? (
              <AddLoadingDotsToText>Loading</AddLoadingDotsToText>
            ) : !deals || deals.noDeals ? (
              "No deals"
            ) : (
              dealObj?.displayPrice || "No deals in size"
            ));

          return (
            <Card
              key={[feedTitle || type || item.type, item.uri || item.id].join(
                "-"
              )}
              blog
              plain
              displayHorizontally={displayHorizontally}
              fullWidth
              fullHeight
            >
              <CardHeader image noShadow={!showShadow} loading={loading} button>
                <FeaturedImage
                  featuredImage={item.featuredImage || fallbackImage}
                  fallbackAltText={item.title}
                  href={item.uri}
                  isThumbnail={!noThumbnail}
                  loading={loading}
                  reviews={item.productReviews}
                  productAthletes={item.productAthletes}
                  {...extraFeaturedImageProps}
                  ariaLabel={item?.title}
                  type={item.type || type}
                  sizes={sizes}
                  {...rest}
                />
              </CardHeader>
              {!loading && item.uri && (
                <div className={classes.titleAndPrice}>
                  <CustomLink
                    href={item.uri}
                    prefetch={false}
                    color="secondary"
                    className={classes.textButton}
                    classes={{ root: classes.verticalCenter }}
                  >
                    {item.title}
                  </CustomLink>
                  {showDeals && (
                    <div>
                      <div>{price}</div>
                      {(dummyPrice || dealObj?.url) && (
                        <AffiliateLink
                          text="Get deal"
                          deal={dealObj || { url: "#" }}
                          product={item}
                          from={"feedBlock"}
                          currency={dealsState.currency}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}
            </Card>
          );
        })}
      </CustomCarousel>
      <MoreButton
        show={showMoreButton}
        uri={moreButtonHref}
        text={moreButtonText}
        loading={loading}
      />
    </div>
  );
}

// NB This was previously pulling the thumbnail - experimenting with using the full size image and letting next optimise it
export const FEEDBLOCKFRAGMENT = gql`
  fragment FeedBlockFragment on NodeWithFeaturedImage {
    ...ItemBasicsFragment
    ...FeaturedImageFragment
  }
  ${FEATUREDIMAGEFRAGMENT}
`;

FeedBlock.defaultProps = {
  responsiveSettings: DEFAULT_RESPONSIVE_SETTINGS,
};

FeedBlock.propTypes = {
  // items: PropTypes.array || PropTypes.bool,
  feedType: PropTypes.string,
  feedTitle: PropTypes.string,
  moreButtonText: PropTypes.string,
  sport: PropTypes.string,
  category: PropTypes.string,
  currentItemId: PropTypes.string,
  // screenSizes: PropTypes.object,
  noPrefetch: PropTypes.bool,
};
