import Avatar from "@mui/material/Avatar";
import { makeStyles } from "tss-react/mui";

import { boxShadow } from "../../assets/jss/nextjs-material-kit-pro";
// core components
import { useRenders } from "../../modules/utils";

const useStyles = makeStyles()({
  avatarImg: {
    border: "1px solid white",
    //  marginTop: -40,

    borderRadius: "50%",
    ...boxShadow,
  },
});

const ProfileAvatar = (props) => {
  const { user, width, height, sx, ...rest } = props;
  useRenders("profileHeader");
  const { classes } = useStyles();

  const { featuredImage, athleteDetails, nicename } = user;

  return (
    <Avatar
      variant="rounded"
      src={
        featuredImage?.node.sourceUrl ||
        athleteDetails?.profileImages?.profile ||
        user?.profile ||
        user?.avatar?.url ||
        "#"
      }
      alt={nicename || athleteDetails?.fullName || ""}
      className={classes.avatarImg}
      {...rest}
      sx={{ width, height, ...sx }}
    />
  );
};

export default ProfileAvatar;

ProfileAvatar.defaultProps = {
  width: 80,
  height: 80,
  sx: {},
};
