import {
  supportedLangsAndLocales,
  fallbackLocale,
} from "../../settings/global-website-config.js";

export default function getDealsLocale(args: {
  lang?: string;
  country: string;
  isFallbackCountry: boolean;
  noFallback: boolean;
}): { dealsLocale: string; isFallbackLocale: boolean } {
  const { country, isFallbackCountry } = args;

  let { lang } = args;

  // Country might be gb, us, fr, or en-gb, en-us etc

  const isFallbackLocale =
    isFallbackCountry ||
    !(
      country &&
      supportedLangsAndLocales.some((l) => {
        if (l.countries.includes(country)) {
          if (!lang) {
            lang = l.lang;
            return true;
          } else if (l.lang === lang) {
            return true;
          }
        }
      })
    );

  const dealsLocale = !isFallbackLocale
    ? lang && lang !== country
      ? [lang, country].join("-")
      : country
    : fallbackLocale;

  return { dealsLocale, isFallbackLocale };
}
