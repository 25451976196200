/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputLoader from "components/Loading/InputLoader.js";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  loadingOverlayAbsolutePosition: {
    position: "absolute",
    right: theme.spacing(1.5),
    display: "flex",
  },
  fixedMinWidth: {
    minWidth: "253px",
    [theme.breakpoints.up("xs")]: { minWidth: "299px" }, // Accounts for some having start vs end adornments and inputLoader being swapped in for endAdornment
  },
}));

const CustomAutoComplete = (props) => {
  // NB on Settings page email ISN'T required as there is no sumbit button so this prop is not passed
  const {
    label,
    fullWidth,
    onChange,
    customClasses,
    error,
    loading,
    fixedMinWidth,
    ...rest
  } = props;

  const [loadingState, setLoadingState] = React.useState(false);

  React.useEffect(() => {
    if (!loading && loadingState) {
      setLoadingState(false);
    }
  }, [loading, loadingState]);

  const { classes } = useStyles();

  const allClasses = customClasses ? { ...customClasses } : {};

  if (fixedMinWidth) {
    allClasses.inputRoot = classes.fixedMinWidth;
  }
  return (
    <Autocomplete
      onChange={(e, v) => onChange(e, v, setLoadingState)}
      loading={loadingState}
      classes={allClasses}
      disabled={loading}
      isOptionEqualToValue={
        props.isOptionEqualToValue ||
        ((option, value) => option.name === value.name)
      }
      getOptionLabel={(option) => props.getOptionLabel || option?.name || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          label={label}
          variant="outlined"
          fullWidth={fullWidth}
          inputProps={{ ...params.inputProps, autoComplete: "new-password" }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingState ? (
                  <span className={classes.loadingOverlayAbsolutePosition}>
                    <InputLoader />
                  </span>
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
};

CustomAutoComplete.propTypes = {
  fullWidth: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  isOptionEqualToValue: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
};
CustomAutoComplete.defaultProps = {
  autoHighlight: true,
};
export default CustomAutoComplete;
