import dynamic from "next/dynamic";

import PropTypes from "prop-types";
import React from "react";
import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";

import { makeStyles } from "tss-react/mui";

import {
  drawerPaper,
  boxShadow,
} from "../../assets/jss/nextjs-material-kit-pro";

const SwipeableDrawer = dynamic(() => import("@mui/material/SwipeableDrawer"));

const useStyles = makeStyles()((theme) => ({
  drawerPaper,
  boxShadow,
  paperDrawer: { position: "relative" },
  /*   filterBarDrawer: {
    zIndex: 16,
    height: '100%', //'calc(100% - 50px)',
    // overflowY: 'visible', // might need to be AUTO to allow  scrolling
    visibility: 'visible !important',

    //position: 'relative',
    top: 56,
    // borderTopLeftRadius: 8,
    // borderTopRightRadius: 8,
    //right: 0,
    //left: 0,
  }, */
  swipeableDrawerRoot: {
    //  height: `calc(100% - ${50}px)`,
    overflow: "visible", //  needed when drawer is closed
  },
  swipeableDrawerRootClosed: {
    position: "absolute",
    bottom: "-161px",
  },
  swipeableDrawerRootOpen: {
    overflow: "auto",
    // borderRadius: `0px 0px ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  swipeableDrawerBoxClosed: {
    // Only below breakpoint or it will show when supposed to be hidden
    [theme.breakpoints.down("md")]: {
      // position: 'absolute', // breaks it ?
      bottom: "-161px", //SWIPEABLE_DRAWER_BLEEDING,
    },
  },
  swipeableDrawerBox: {
    visibility: "visible",
    right: 0,
    left: 0,
  },
}));

export default function CustomDrawer(props) {
  const { children, handleDrawerToggle, swipeable, drawerVersion, ...rest } =
    props;

  const { open } = props;

  const { classes, cx } = useStyles();

  const drawerClasses = cx({
    [classes.drawerPaper]: !swipeable,
    [classes.boxShadow]: !swipeable,
    [classes[`${drawerVersion}Drawer`]]: !swipeable && drawerVersion,
    [classes.swipeableDrawerRoot]: swipeable,
    [classes.swipeableDrawerRootOpen]: swipeable,
    [classes.swipeableDrawerRootClosed]: swipeable && !open,
  });

  if (!swipeable) {
    return (
      <Drawer
        classes={{
          paper: drawerClasses,
        }}
        {...rest}
      >
        {children}
      </Drawer>
    );
  }

  const boxClasses = cx({
    [classes.swipeableDrawerBox]: true,
    [classes.swipeableDrawerBoxClosed]: !open,
  });

  /* 
  const hasScrolledToTop = () => {
    if (drawerRef.current) {
      const { scrollTop } = drawerRef.current;
      console.log('scollTop', scrollTop, drawerRef.current);
      return scrollTop === 0;
    }
  };

  const [scrollState, setScrollState] = React.useState(hasScrolledToTop());

  React.useEffect(() => {
    // Is overflowing

    const checkScrolled = hasScrolledToTop();

    if (checkScrolled !== scrollState) {
      setScrollState(checkScrolled);
    }
  }, );

  console.log('Has scrolled to top', scrollState, 'ref', drawerRef); */

  return (
    <SwipeableDrawer
      classes={{
        paper: classes.paperDrawer, // drawerClasses,
        root: drawerClasses,
      }}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
        disablePortal: true,
      }}
      onOpen={handleDrawerToggle}
      onClose={handleDrawerToggle}
      {...rest}
    >
      <Box onScroll={() => console.log("Scrolling!")} className={boxClasses}>
        {children}
      </Box>
    </SwipeableDrawer>
  );
}

CustomDrawer.propTypes = {
  anchor: PropTypes.oneOf(["bottom", "left", "top", "right"]).isRequired,
  drawerVersion: PropTypes.oneOf(["header", "filterBar", "searchBar"])
    .isRequired,
  swipeable: PropTypes.bool,
};
