import React from "react";
import { makeStyles } from "tss-react/mui";
import useTranslation from "next-translate/useTranslation";
import Image from "components/Image/CustomImage";

import parse from "html-react-parser";

const useStyles = makeStyles()((theme) => ({
  errorBody: {
    textAlign: "center",
    width: "100%",
    "& h1, & h2, & h4": {
      color: theme.palette.common.white,
      zIndex: 10,
    },
  },
  backgroundImage: { zIndex: -1 },
  title: {
    // paddingTop: '60px',
    [theme.breakpoints.down("lg")]: {
      fontSize: "5.7em",
    },
    [theme.breakpoints.down("xl")]: {},
    fontSize: "10.7em",
    letterSpacing: "14px !important",
    fontWeight: "700",
  },
  subTitle: {
    fontSize: "2.25rem",
    marginTop: "0",
    marginBottom: "8px",
    fontWeight: "400",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.5em",
    },
  },

  errorComponentContainer: {
    minHeight: "100vh",
    height: "auto",
    display: "flex",
    padding: "0",
    border: "0",
    margin: theme.spacing(0, 1),
    // marginTop: '-60px', // hides the top bar
    marginBottom: "-111px", // pulls foooter into view
    [theme.breakpoints.down("xs")]: {
      marginBottom: "-80px",
    },
    alignItems: "center",
    overflowY: "hidden",
  },
}));

export default function ErrorComponent(props) {
  let { statusCode, errorMessage } = props;

  // If we want to use a random image:
  //  const TOTAL_PICS = 3;
  //  const picNumber = Math.round(Math.random() * (TOTAL_PICS - 1));

  const image = require(`public/images/404/1.gif`);

  const { t } = useTranslation();

  const { classes } = useStyles();
  return (
    <div className={classes.errorComponentContainer}>
      <div className={classes.errorBody}>
        <h1 className={classes.title}>{statusCode || "404"}</h1>
        <h2 className={classes.subTitle}>
          {parse(
            statusCode === 500
              ? t("errors:serverErrorMessage")
              : t("errors:pageNotFoundMessage")
          )}
        </h2>
        <Image
          priority={true}
          fill
          style={{ objectFit: "cover" }}
          src={image}
          alt="Error gif"
          className={classes.backgroundImage}
        />
        <h4>{errorMessage || parse(t("errors:navErrorMessage"))}</h4>
      </div>
    </div>
  );
}
