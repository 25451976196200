import React from "react";
import Link from "next/link";
import dayjs from "dayjs";
import parse from "html-react-parser";

import VisibilitySensor from "react-visibility-sensor";

import CustomParser from "modules/CustomParser";

import { makeStyles } from "tss-react/mui";
import useTranslation from "next-translate/useTranslation";
import Table from "components/Table/Table";

import LinkBadge from "components/LinkBadge/LinkBadge";
import TooltipAndIcon from "components/Tooltip/TooltipAndIcon";

import LinearGauge from "components/CustomLinearProgress/LinearGauge";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import { MONTH_YEAR_FORMAT } from "../../settings/global-website-config";
import { useRenders } from "../../modules/utils";
import { textCenter } from "../../assets/jss/nextjs-material-kit-pro";
// core components
const useStyles = makeStyles()({ textCenter });

const ProductSpecifications = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  useRenders("ProductSpefications");

  const { specifications, characteristics, family, type, verifiedColours } =
    props;

  let hasPercentiles = false;
  const charData = [];

  // Add family  to the front of the chars array:

  if (family && family.term) {
    charData.push([
      t("product:family"),
      <Link prefetch={false} key="char-1" href={family.uri} passHref>
        {family.term}
      </Link>,
    ]);
  }
  // add other characteristics (model, released etc):

  // Add Category specific characteristics to the array:
  // TODO #LANG - Need to move the char.taxonomy to be a lookup if we go multi language;

  const getName = (ch) => {
    const { name } = ch.node;
    if (!ch.uri) {
      return name;
    }
    const count = ch.termCount - 1;

    if (!count) {
      return name;
    }
    return name + " (+" + count + ")";
  };

  if (characteristics?.edges) {
    for (const char of characteristics.edges) {
      const charItem = [
        <>
          {char.title}
          <TooltipAndIcon
            title={char.content && parse(char.content)} // Pretty sure this should now be getApprovedContent(char.termEditorialContent.summary) /? ACTUALY - no dont think it is
            outlined
          />
        </>,
        char.terms.edges.map((ch) => (
          <LinkBadge
            name={getName(ch)}
            href={ch.uri}
            key={ch.uri}
            disabled={!ch.uri}
          />
        )),
      ];
      if (char.taxonomy === "Brand") {
        charData.unshift(charItem); // Place Brand at the top of the list
      } else charData.push(charItem);
    }
  }
  if (verifiedColours?.nodes?.length) {
    charData.push([
      t("product:colorways"),
      verifiedColours.nodes.map((colour) => colour.name).join(", "),
    ]);
  }
  // Create the specfications array:
  const specData = [];

  const [isVisible, setIsVisible] = React.useState(false);
  if (specifications?.edges) {
    for (const attributeType of specifications.edges) {
      if (attributeType.attributeType.slug !== "specification") {
        specData.push([
          {
            colSpan: 3,
            content: <h6>{attributeType.attributeType.name}</h6>,
            isSubheading: true,
          },
        ]);
      }

      for (const spec of attributeType.specifications) {
        if (!hasPercentiles && spec.percentile) {
          hasPercentiles = true;
        }
        specData.push([
          <>
            {spec.specification.title}{" "}
            <TooltipAndIcon
              title={spec.content.tooltip && parse(spec.content.tooltip)} // Pretty sure this should now be getApprovedContent(char.termEditorialContent.summary) /? ACTUALY - no dont think it is
              outlined
            />
          </>,
          <CustomParser
            content={
              spec.specification.title === "Released"
                ? dayjs(spec.content.value).format(MONTH_YEAR_FORMAT)
                : spec.content.value
            }
            key={spec.specification.title}
          />,

          spec.percentile && (
            <LinearGauge
              key={`${spec.specification.title}-progress`}
              variant="buffer"
              colour={spec.percentile.colour}
              value={isVisible ? spec.percentile.value : 5}
            />
          ),
        ]);
      }
    }
  }

  const tableHead = [t("product:specificationsOnly")];

  if (hasPercentiles) {
    tableHead.push("", t("product:vsAverage"));
  }
  if (!specifications && !characteristics) {
    return;
  }
  return (
    <>
      <VisibilitySensor
        partialVisibility
        onChange={(is) => is !== isVisible && setIsVisible(is)}
        offset={{ top: 60 }}
      >
        <GridContainer>
          {charData.length > 0 && (
            <GridItem md={type === "family" || !specData.length ? 12 : 6}>
              <Table
                striped
                hover
                shortCells
                tableHead={[t("product:characteristics")]}
                tableArray={charData}
                customCellClasses={[]}
                customClassesForCells={[]}
                tableName="characteristics"
              />
            </GridItem>
          )}

          {specData.length > 0 && (
            <GridItem md={charData.length ? 6 : 12}>
              <Table
                size="large"
                striped
                hover
                shortCells
                widthOverride
                tableHead={tableHead}
                tableArray={specData}
                customCellClasses={[classes.textCenter]}
                customClassesForCells={[1]}
                customHeadCellClasses={[classes.textCenter]}
                customHeadClassesForCells={[2]}
                tableName="specifications"
              />
            </GridItem>
          )}
        </GridContainer>
      </VisibilitySensor>
    </>
  );
};

export default React.memo(ProductSpecifications);
