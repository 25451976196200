import React from "react";

import useTranslation from "next-translate/useTranslation";
import WhatItIsSection from "./WhatItIsSection";

const LoginTextSection = (props) => {
  const { type, location } = props;

  const { t } = useTranslation();
  if (type === "login") {
    return null;
  }
  const loginContentKey = location === "parkrunSignup" ? "parkrunSignup" : type;

  const plusYoullGetKey =
    location === "parkrunSignup" ? "plusYoullGetOptional" : "plusYoullGet";

  if (type == "signup") {
    return;
  }
  return (
    <div>
      <>
        <p>{t(`nav:loginContent.${loginContentKey}`)}</p>
        {type !== "sync" && <p>{t(`nav:${plusYoullGetKey}`)}</p>}
      </>
      {type !== "signup" && <WhatItIsSection />}
    </div>
  );
};

export default LoginTextSection;
