import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";

import CircularProgress from "@mui/material/CircularProgress";
import CustomFade from "components/Transition/CustomFade.js";

import {
  progressContainer,
  whiteColor,
} from "assets/jss/nextjs-material-kit-pro.js";

const useStyles = makeStyles()({
  progressContainer,
  addMarginTop: { marginTop: "135px" },
  loadingColor: { color: whiteColor },
});

export default function ProgressWithFade(props) {
  const { classes, cx } = useStyles();

  const { inProp, addMarginTop, size, loadingColor, children } = props;

  const progressContainerStyles = cx({
    [classes.progressContainer]: true,
    [classes.addMarginTop]: addMarginTop,
  });
  const progressStyles = cx({
    // [classes.progress]: true,
    [classes.loadingColor]: loadingColor,
  });

  return (
    <>
      <CustomFade inProp={inProp}>
        <div className={progressContainerStyles}>
          <CircularProgress
            className={progressStyles}
            size={size}
            color="secondary"
          />
        </div>
      </CustomFade>
      <CustomFade inProp={!inProp}>{children}</CustomFade>
    </>
  );
}

ProgressWithFade.propTypes = {
  // index of the default active collapse
  size: PropTypes.number,
  addMarginTop: PropTypes.bool,
  loadingColor: PropTypes.bool,
  inProp: PropTypes.bool,
};
ProgressWithFade.defaultProps = { size: 40 };
