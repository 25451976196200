import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

import { hexToRgb } from "@mui/material";

import {
  whiteColor,
  blackColor,
  cardShadow,
  fullWidth,
  fullHeight,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  card: {
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: theme.shape.borderRadius,
    background: whiteColor,
    boxShadow: cardShadow,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    // some jss/css to make the cards look a bit better on Internet Explorer
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "inline-block !important",
    },
  },
  fullWidth,
  fullHeight,
  cardPlain: {
    background: "transparent",
    boxShadow: "none",
  },
  cardProfile: {
    marginTop: "20px", // Split 30px margin top to have some padding for when the profile is clicked on e.g in productAthlete
    paddingTop: "10px",

    textAlign: "center",
  },
  minWidth: { minWidth: "100px" },
  padSides: { paddingLeft: "10px", paddingRight: "10px" },
  noMargin: {
    margin: "0px",
  },
  cardBlog: {
    margin: "0px", // was 60px
  },
  centerText: { textAlign: "center", justifyContent: "center" }, // Added justifyContent: center for brands where no image, only tex
  cardRaised: {
    boxShadow: `0 16px 38px -12px rgba(${hexToRgb(
      blackColor
    )}, 0.56), 0 4px 25px 0px rgba(${hexToRgb(
      blackColor
    )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2)`,
  },
  displayHorizontally: { flexDirection: "inherit" },
  cardBackground: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    textAlign: "center",
    color: whiteColor,
    "& h3": {
      color: `${whiteColor} !important`,
    },
    "& p": {
      color: `rgba(${hexToRgb(whiteColor)},1)`, // was 0.7 !important
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""',
      backgroundColor: `rgba(${hexToRgb(blackColor)}, 0.56)`,
      borderRadius: theme.shape.borderRadius, // C
    },
    "& small": {
      color: `rgba(${hexToRgb(whiteColor)}, 0.7) !important`,
    },
  },
  cardProduct: {
    [theme.breakpoints.down("lg")]: { marginTop: "20px" },
  },
}));

export default function Card(props) {
  const {
    className,
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    color,
    product,
    testimonial,
    displayHorizontally,
    disableCardStyle,
    center,
    noMargin,
    padSides,
    fullWidth,
    fullHeight,
    minWidth,
    ...rest
  } = props;
  const { classes, cx } = useStyles();
  const cardClasses = cx({
    [classes.card]: !disableCardStyle,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile || testimonial,
    [classes.cardBlog]: blog,
    [classes.centerText]: center,
    [classes.noMargin]: noMargin,
    [classes.padSides]: padSides,
    [classes.fullWidth]: fullWidth,
    [classes.fullHeight]: fullHeight,
    [classes.cardRaised]: raised,
    [classes.cardBackground]: background,
    [classes.displayHorizontally]: displayHorizontally,
    [classes[color]]: color,
    [classes.cardProduct]: product,
    [classes.minWidth]: minWidth,
    [className]: className !== undefined,
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  blog: PropTypes.bool,
  raised: PropTypes.bool,
  background: PropTypes.bool,
  pricing: PropTypes.bool,
  testimonial: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "strava",
    "darkCustom",
  ]),
  product: PropTypes.bool,
  children: PropTypes.node,
};
