import React from "react";

import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import { makeStyles } from "tss-react/mui";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import {
  whiteColor,
  button,
  buttonSvg,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  buttonSvg,
  button,
  plainText: {
    margin: 0, //theme.spacing(0, 1, 0, 1),
    padding: 0,
    fontSize: "inherit",
  },
  whiteHover: { "&:focus,&:hover": { color: "white" } },

  simple: {
    "&,&:focus,&:hover": {
      color: whiteColor,
      background: "transparent",
      boxShadow: "none",
    },
    /*  '&$primary': {
      '&,&:focus,&:hover,&:visited': {
        color: primaryColor,
      },
    }, */
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.3", // was 0.65. Changed for family products scrolling on product page
    pointerEvents: "none",
  },

  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    color: theme.palette.primary.main,
    "&,&:hover,&:focus": {
      backgroundColor: "unset",
    },
  },
  justIcon: {
    paddingLeft: "12px", // was 12
    paddingRight: "12px", // was
    fontSize: "20px",
    height: "41px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
  },
  fileButton: {
    // display: "inline-block"
  },

  transparentText: { color: "#0000 !important" },
  loadingOverlay: {
    position: "absolute",
    //   color: whiteColor,
  },
  loadingOverlayWhite: {
    color: whiteColor,
  },
}));

const RegularButton = React.forwardRef((props, ref) => {
  const {
    color: incomingColor,
    round,
    children,
    disabled,
    simple,
    simpleCustom,
    plainText,
    block,
    link,
    justIcon,
    fileButton,
    className,
    whiteHover,
    icon,
    transparent,
    svg,
    showLoader,
    loading,
    size,
    ...rest
  } = props;
  const { classes, cx } = useStyles();
  //useRenders('button');
  // Hacks to smooth migration to closer alignment with material UI models
  const color =
    incomingColor === "strava"
      ? "secondary"
      : incomingColor === "darkCustom"
      ? "primary"
      : incomingColor;

  const variant = props.variant || link ? "text" : null;

  const btnClasses = cx({
    [classes.transparentText]: showLoader && loading,
    [classes.button]: true,
    [classes.buttonSvg]: svg,
    [classes.transparent]: transparent,
    [classes.whiteHover]: whiteHover,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.simpleCustom]: simpleCustom,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [classes.fileButton]: fileButton,
    [classes.plainText]: plainText,
    [className]: className,
  });
  const buttonProps = {
    variant,
    color,
    ref,
    size,
    className: btnClasses,
    ...rest,
  };

  const circularProgressProps = {};

  if (props.variant === "text" && showLoader) {
    circularProgressProps.color = "secondary";
  }
  const inner = (
    <>
      {showLoader && loading && (
        <CircularProgress
          size={size === "small" ? 18 : 24}
          className={cx({
            [classes.loadingOverlay]: true,
            [classes.loadingOverlayWhite]: props.variant !== "text",
          })}
          {...circularProgressProps}
        />
      )}
      {children}
    </>
  );
  return !icon ? (
    <Button {...buttonProps}>{inner}</Button>
  ) : (
    <IconButton {...buttonProps} size="large">
      {inner}
    </IconButton>
  );
});

//RegularButton.defaultProps = { svg: true };

RegularButton.defaultProps = { variant: "contained" };

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "gray",
    "error",
    "warning",
    "white",
    "info",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  simple: PropTypes.bool,
  simpleCustom: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  fileButton: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  svg: PropTypes.bool,
};

if (process.env.NODE_ENV !== "production") {
  RegularButton.displayName = "RegularButton";
}

export default RegularButton;
