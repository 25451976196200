import dayjs from "dayjs";
import { addLocationDetailsToName } from "tagging/tagFactory";
import Avatar from "@mui/material/Avatar";
import { makeStyles } from "tss-react/mui";

const stravaStyles = (theme) => ({
  stravaPaperContainer: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    //  maxWidth: "260px",
  },
  stravaTopLine: { display: "inline-flex" },
  stravaUserAndDate: {
    marginLeft: theme.spacing(1),
    fontSize: "0.75rem",
    lineHeight: "0.95rem",
    marginTop: "auto",
    marginBottom: "auto",
  },
  stravaUserName: {
    fontWeight: 500,
  },
  stravaDate: { color: "gray" },
  stravaName: {
    fontWeight: 500,
    fontSize: "1.1rem",
    margin: theme.spacing(1, 0),
  },
  stravaDescription: {
    fontSize: "0.9rem",
    fontWeight: 200,
    whiteSpace: "pre-line",
  },
});

export const getMockupDate = () =>
  dayjs()
    .add(-1, "day")
    .set("hour", 9)
    .set("minute", 55)
    .format(STRAVA_DATE_FORMAT);

const useStyles = makeStyles()(stravaStyles);
const STRAVA_DATE_FORMAT = "MMMM D, YYYY [at] h:mm A";
const StravaMockupScreen = (props) => {
  const { classes } = useStyles();
  const { user, name, description } = props;

  const date = getMockupDate();
  const dateString = user.city
    ? date +
      " - " +
      addLocationDetailsToName(null, [
        user.city,
        user.state,
        // user.country,
      ])
    : date;

  return (
    <>
      <div className={classes.stravaPaperContainer}>
        <div>
          <div className={classes.stravaTopLine}>
            <Avatar
              src={user?.profile || user?.athleteDetails.profileImages?.profile}
              alt="Profile picture"
            />
            <div className={classes.stravaUserAndDate}>
              <div className={classes.stravaUserName}>
                {user.displayName || user.athleteDetails.fullName}
              </div>
              <div className={classes.stravaDate}>{dateString}</div>
            </div>
          </div>
        </div>
        <div className={classes.stravaName}>{name || "Morning run"}</div>
        <div className={classes.stravaDescription}>{description || ""}</div>
      </div>
    </>
  );
};

export default StravaMockupScreen;
