import Image from "next/image";
import React, { useState } from "react";
import { PLACEHOLDER_IMAGE_PATH } from "../../settings/global-website-config";

// TODO: Incorporate this into other images using fallbacks (shouldn't it be all of them? )
const ImageWithFallback: React.FC<{
  src: string;
  fallbackSrc: string;
  alt?: string;
  width: number;
  height: number;
  noFallback?: boolean;
}> = ({ src, fallbackSrc, alt = "", width, height, noFallback, ...rest }) => {
  const [imgSrc, setImgSrc] = useState<string | null>(src);

  const fallbackImage = fallbackSrc || PLACEHOLDER_IMAGE_PATH;
  return !imgSrc ? null : (
    <Image
      src={imgSrc}
      alt={alt}
      width={width}
      height={height}
      onError={(e) => {
        // @ts-ignore
        if (e.target.src !== fallbackImage) {
          setImgSrc(noFallback ? null : fallbackImage);
        }
      }}
      {...rest}
    />
  );
};

export default ImageWithFallback;
