import React, { useState } from "react";
import useSWR from "swr";
import useSSR from "use-ssr";

import fetcher from "utils/fetcher.js";

import { stringify } from "querystring";

import { ROOT_URL } from "../../settings/global-urls";
import {
  ANY_SEX_NAME,
  ANY_SIZE_NAME,
  NO_DEALS_NAME,
} from "../../settings/global-website-config";
import { getSizeDeals } from "./useSizeAndSex";

export const getDealsApiUrl = ({
  isVisible,
  sizeAndSexState,
  locale,
  ids,
  isBrowser,
  liteOnly,
  absoluteUrl,
  noSizeOrSex,
}) => {
  if (
    //   !products.length ||
    !isVisible ||
    //  !sizeAndSexState.mounted ||
    !sizeAndSexState.sex ||
    !ids.length ||
    !isBrowser
  ) {
    return null;
  }
  let { sex } = sizeAndSexState;
  if (noSizeOrSex) {
    sex = ANY_SEX_NAME;
  }
  const params = {
    ids,
    locale,
    sex,
  };
  if (liteOnly) {
    params.liteOnly = true;
  }
  const parsedParams = stringify(params);

  const requestString = `/api/deals?${parsedParams}`;

  return absoluteUrl ? [ROOT_URL, requestString].join("") : requestString;
};

export default function useDeals(
  products,
  sizeAndSexState = {},
  locale,
  options,
  mergeDealsState = {}
) {
  // MergeDealsState allows us to merge in the same product from another useDeals hook to avoid fetching twice

  if (!Array.isArray(products)) {
    products = [products];
  }

  products = products.filter(Boolean);

  const { isVisible, liteOnly, noSizeOrSex } = options;

  const createCurrentDeals = React.useCallback(
    (dealProducts) => {
      const returnObject = mergeDealsState.deals
        ? { ...mergeDealsState.deals }
        : {};

      for (const databaseId of Object.keys(dealProducts)) {
        const { deals, lastUpdated } = dealProducts[databaseId];

        if (!sizeAndSexState || !sizeAndSexState.sex) {
          returnObject[databaseId] = { deals: null, sexDeals: null };
          continue;
        }

        returnObject[databaseId] = {};

        if (deals[NO_DEALS_NAME]) {
          // This is a bit backward, slightly reworking the incoming object, but would require quite a bit of refactoring  to simplify here, and would mean using a larger object. Probably only worth refactoring if we get rid of sexDeals altogether
          returnObject[databaseId][NO_DEALS_NAME] = true;
          //  continue;
        }
        let { sex, size, colour } = sizeAndSexState;
        if (noSizeOrSex) {
          if (sex) {
            sex = ANY_SEX_NAME;
          }
          if (size) {
            size = ANY_SIZE_NAME;
          }
        }
        if (!size && sex && colour) {
          // Size hasn't been set yet, so show the range of prices instead

          returnObject[databaseId] = {
            ...returnObject[databaseId],
            highPrice: deals.highPrice || null,
            lowPrice: deals.lowPrice || null,
            noSizeSet: true,
          };

          if (lastUpdated) {
            returnObject[databaseId].lastUpdated = lastUpdated;
          }
          continue;
        }

        if (sex && size && colour) {
          const sizeDeals = getSizeDeals(
            { sex, size, colour },
            deals?.colours?.[colour]
          );
          if (sizeDeals) {
            returnObject[databaseId] = {
              ...returnObject[databaseId],
              ...sizeDeals,
            };
          }

          if (lastUpdated && returnObject[databaseId]) {
            returnObject[databaseId].lastUpdated = lastUpdated;
          }
          continue; // Not that this does anything
        }
      }

      returnObject.currency = deals.currency || "gbp"; // As we are querying for the same region every time, by definition the currency is the same. GBP Placeholder only

      return returnObject;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mergeDealsState.deals, sizeAndSexState]
  );

  const loadingObject = {
    loading: true,
    deals: {},
    sexDeals: {},
  };
  const { isBrowser } = useSSR();

  const [deals, setDeals] = useState(loadingObject);

  const ids = products
    .filter((p) => !mergeDealsState.deals?.[p.databaseId])
    .map((p) => p.databaseId || p.node.databaseId)
    .join(",");

  const requestString = getDealsApiUrl({
    isVisible,
    sizeAndSexState,
    ids,
    isBrowser,
    locale,
    liteOnly,
    noSizeOrSex,
  });
  const { data, error } = useSWR(requestString, fetcher, {
    refreshInterval: 15 * 60 * 1000, // Automatically refetches data after 15 mins
    revalidateOnFocus: false, // Don't need to recheck every time the user comes back to the tab...
  });
  if (parseInt(process.env.NEXT_PUBLIC_SHOW_RAW_DEALS)) {
    console.log(
      "[useDeals] | Showing Data as NEXT_PUBLIC_SHOW_RAW_DEALS is set",
      {
        data,
        requestString,
        options,
        sex: sizeAndSexState.sex,
        size: sizeAndSexState.size,
      }
    );
  }

  React.useEffect(() => {
    if (data) {
      /*  setDeals({
        deals: createCurrentDeals(data),
        sexDeals: data,
        loading: false,
      }); */
    } else {
      setDeals(loadingObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    console.error("Error", error);
  }

  React.useEffect(() => {
    if (data) {
      // Don't fire until we have deals:
      setDeals({ deals: createCurrentDeals(data), sexDeals: data });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeAndSexState, data]);

  if (!isBrowser) {
    // console.log('[useDeals] | Bailing - still on server');
    return loadingObject;
  }
  if (products.some((p) => !p.databaseId && !p.node?.databaseId)) {
    console.log("Missing databaseIds, leaving", products);
    return;
  }

  return deals;
}
