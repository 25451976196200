//import fetch from 'node-fetch';
import staticDbLoader from "apollo/staticDbLoader";
import { gql } from "@apollo/client";
import { capitalizer, decodeGlobalId } from "@geerly/shared";

async function purgeCdn({ uri, type, queryName, slug, id }) {
  if (type === "basicPage") {
    type = "page";
  }
  if (parseInt(process.env.NEXT_PUBLIC_BYPASS_CDN)) {
    console.log(
      `[CDN] | Not purging ${type} with uri ${uri} because CDN is bypassed (and the error therefore is not related to the CDN)`
    );
    return;
  }

  // unless it's a general query with a queryName, first we need to get the ID from the uri

  // NB queryName approach is not ideal, as it invalidates all contentNodes in the query. But currently there is no way to target the specific query with particular variables, so its the only safe way to purge. The alternative is to bypass the cache in these instances, if we can be confident the issue only impacts a small number of queries, and that bypassing fixes the issue
  let query;
  if (!queryName) {
    // Bit roundabout but CDN has since added ability to purge via different field keys, and this is the safest/quickest way to catch these
    const isSlug = slug || (uri && !uri.includes("/"));

    if (slug || isSlug) {
      const slugToUse = slug || uri;
      query = `mutation { purge${capitalizer(type)}(slug: "${slugToUse}") }`;
      console.log(
        `[CDN] | Purging ${type} with slugToUse ${slugToUse}  | Query:`,
        query
      );
    } else {
      if (!id) {
        const ID_QUERY = gql`
          query GetContentNodeId($uri: ID!) {
            contentNode(id: $uri, idType: URI) {
              id
              slug
            }
          }
        `;

        const variables = { uri };
        const { data } = await staticDbLoader(ID_QUERY, variables);

        if (!data) {
          console.log(
            `[CDN] | Not purging ${type} with uri ${uri} because the server did not return any data`
          );
          return;
        }

        const { contentNode } = data;

        ({ id } = contentNode);

        if (!id) {
          console.log(
            `[CDN] | Not purging ${type} with uri ${uri} because the server did not return ID`
          );
          return;
        }
      }
      query = `mutation { purge${capitalizer(type)}(id: "${id}") }`;
      console.log(
        `[CDN] | Purging ${type} with uri ${uri} and ${id} and databaseId ${decodeGlobalId(
          id
        )} | Query:`,
        query
      );
    }
    // TODO: We should be able to tell the cache to recognise SLUG as a key to purge by, and skip the step of fetching the ID. Slugs are unique by product type so should be OK?
  } else {
    query = `mutation { _purgeQuery(queries: [${queryName}]) }`;
    console.log(`[CDN] | Purging query: ${queryName}`);
  }

  await fetch("https://admin.graphcdn.io/geerly", {
    method: "POST", // Always POST purge mutations
    headers: {
      "Content-Type": "application/json", // and specify the Content-Type
      "graphcdn-token": process.env.CDN_PURGE_TOKEN,
    },
    body: JSON.stringify({
      query,
    }),
  })
    .then((response) => response.json())
    .then((response) =>
      console.log("[CDN] | Response from purge request:", response)
    )
    .catch((error) => {
      console.log("[CDN] | Caught error attempting to purge CDN");
      console.error(error);
    });

  console.log("[CDN] | Exiting purgeCdn");

  return;
}

export default purgeCdn;
