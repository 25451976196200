import { useState, useEffect, useCallback } from "react";

export const useResize = (myRef, enable) => {
  const [height, setHeight] = useState(100);

  const handleResize = () => {
    if (myRef.current && myRef.current.offsetHeight > 100 && enable) {
      setHeight(myRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    if (myRef.current) {
      const observer = new ResizeObserver(handleResize).observe(myRef.current);
      const savedRef = myRef.current;
      return () => {
        if (observer && savedRef && observer.current) {
          observer.unobserve(savedRef);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef]);

  return height;
};

export const useContainerDimensions = (ref, loading, widthOverride) => {
  const getDimensions = useCallback(() => {
    if (loading && widthOverride) {
      return { width: widthOverride };
    }
    return {
      width: ref.current.offsetWidth,
      height: ref.current.offsetHeight,
    };
  }, [loading, ref, widthOverride]);

  const [dimensions, setDimensions] = useState({ width: 0 });

  useEffect(() => {
    /* const handleResize = () => {
      if (ref.current) {
        setDimensions(getDimensions());
      }
    }; */

    if (ref.current) {
      setDimensions(getDimensions());
    }

    //return () => {
    //   window.removeEventListener('resize', handleResize);
    // };
  }, [
    ref,
    ref?.current?.offsetWidth,
    ref?.current?.offsetHeight,
    getDimensions,
  ]);

  return dimensions;
};
