const shallowArrayMatch = (arr1, arr2) => {
  if (!Array.isArray(arr1)) {
    arr1 = [arr1];
  }
  if (!Array.isArray(arr2)) {
    arr2 = [arr2];
  }

  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays
  arr1 = arr1.slice().sort();
  arr2 = arr2.slice().sort();

  // Check if each element in arr1 is equal to the corresponding element in arr2
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // If we made it this far, the arrays match
  return true;
};

export default shallowArrayMatch;
