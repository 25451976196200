import React from "react";

import { makeStyles } from "tss-react/mui";
import {
  pageContainer,
  main,
  fullWidthContainer,
} from "assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()({
  pageContainer,
  main,
  fullWidthContainer,
});

const ContainedLayout = ({ children, bypass, fullWidth }) => {
  const { classes } = useStyles();

  if (bypass) {
    return <div>{children}</div>;
  }

  if (fullWidth) {
    return <div className={classes.fullWidthContainer}>{children}</div>;
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.main}>{children}</div>
    </div>
  );
};

export default ContainedLayout;
