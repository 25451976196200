import moment from "moment";
import { numberToEmoji } from "@geerly/shared";
import { getShortenedLink } from "../settings/global-urls";
import { getConvertedDistance } from "../modules/utils";

import { GENDER_DEFAULT } from "../settings/global-website-config";

const poweredBy = "Powered by";
const siteNameText = "geerly 👟";
export const poweredByText = [poweredBy, siteNameText].join(" ");
const statsBy = "Stats by";
export const statsByText = ["📊", statsBy, siteNameText].join(" ");

const getPersonalPoweredByText = (user, noBase) => {
  if (!user || user.athleteDetails.isRestricted) {
    return poweredByText;
  }
  const userLink = getShortenedLink(user);

  return noBase ? userLink : [poweredBy, userLink].join(" ");
};
export const hasOtherAppTags = (str, ignoreOwnTag) => {
  const appTagNames = [
    "myWindsock",
    "summitbag",
    "enduraw",
    "myTF.run",
    "tenzing",
    "activityfix",
    "strautomator",
    "Wandrer.earth",
    "-- from COROS",
    "Bandok.com",
    "8020training.app",
    "my-progress.app",
    "activitybot.cloud",
    "airawarelabs",
  ];
  if (!ignoreOwnTag) {
    appTagNames.push("geerly.com");
  }
  for (const tagName of appTagNames) {
    if (str.includes(tagName)) {
      return true;
    }
  }
};

export const combineDescriptions = (
  existing,
  toAdd,
  toRemove,
  noExtraLinebreak,
  ignoreOwnTag
) => {
  if (!existing) {
    return toAdd;
  }
  if (toRemove) {
    existing = existing.replace(toRemove, "");
  }
  if (!toAdd) {
    return existing;
  }
  const linebreak = noExtraLinebreak
    ? `
`
    : `

`;
  // Add two line breaks so it doesn't cramp any user written stuff

  if (!hasOtherAppTags(existing, ignoreOwnTag)) {
    return `${existing}${linebreak}${toAdd}`;
  } else {
    return `${toAdd}${linebreak}${existing}`;
  }
};
export const getExampleProduct = (user = {}) => {
  const { athleteProducts } = user;

  return (
    athleteProducts?.[0]?.product || {
      title: "Asics GT 2000 10",
      category: { node: { categoryDetails: { singleName: "shoes" } } },
    }
  );
};

export const getTagNewGearText = (product, user, plainText = false) => {
  const string = `🚨 New ${product.category.node.categoryDetails.singleName} added → ${product.title}`;
  const personalPoweredByText = poweredByText; //getPersonalPoweredByText(user);

  return plainText ? (
    string + " | " + personalPoweredByText
  ) : (
    <>
      {string}
      <br />
      {personalPoweredByText}
    </>
  );
};

export const getTargetByCoreSportType = (coreSportType, period, user) => {
  if (process.env.DANGEROUS_TEST_PROGRESS_TAGGING) {
    return 1000;
  }
  const {
    yearTargetRides,
    yearTargetRuns,
    yearTargetSwims,
    monthTargetRuns,
    monthTargetRides,
    monthTargetSwims,
  } = user.taggingPreferences || {};
  switch (coreSportType) {
    case "running":
      return !period
        ? yearTargetRuns || monthTargetRides
        : period === "year"
        ? yearTargetRuns
        : monthTargetRuns;

    case "swimming":
      return !period
        ? yearTargetSwims || monthTargetSwims
        : period === "year"
        ? yearTargetSwims
        : monthTargetSwims;
    case "cycling":
      return !period
        ? yearTargetRides || monthTargetRides
        : period === "year"
        ? yearTargetRides
        : monthTargetRides;
    default:
      return null;
  }
};

const getActivityTypeEmoji = (type) =>
  type.toLowerCase().includes("run")
    ? "🏃"
    : type.toLowerCase().includes("swim")
    ? "🏊"
    : "🚴";
export const getTagProgressText = (user, type, toDate, date, addBranding) => {
  const { year, month, week } = toDate;
  const { measurementPreference } = user.communicationPreferences;
  const currentYear = moment(date).format("YYYY");
  const currentMonthName = moment(date).format("MMM");
  const currentWeekNumber = moment(date).isoWeek(); // ISO week of the year

  const activityEmoji = getActivityTypeEmoji(type);
  const colon = "→";
  const separator = " • ";

  const measurementPreferenceToUse =
    type === "swimming" ? "km" : measurementPreference;

  const highResolution = type === "swimming";

  let yearEntry = `${currentYear}${colon}${getConvertedDistance(
    year.distance,
    measurementPreferenceToUse,
    true,
    highResolution
  )}`;

  if (year.elevationGain && type !== "swimming") {
    yearEntry += ` ⤴🏔️ ${getConvertedDistance(
      year.elevationGain,
      measurementPreferenceToUse,
      true,
      true
    )}`;
  }

  const parts = [
    `${activityEmoji} ${yearEntry}`,
    `${currentMonthName}${colon}${getConvertedDistance(
      month.distance,
      measurementPreferenceToUse,
      true,
      highResolution
    )}`,
    `Wk${currentWeekNumber}${colon}${getConvertedDistance(
      week.distance,
      measurementPreferenceToUse,
      true,
      highResolution
    )}`,
  ].join(separator);

  // Join the parts using the defined separator
  return addBranding
    ? [parts, statsByText].join(`
`)
    : parts;
};

export const getTagTargetText = (
  user,
  type,
  toDate,
  date,
  target,
  period = "year",
  shouldIncludeStatsText
) => {
  const { year, month } = toDate;
  const { measurementPreference } = user.communicationPreferences;
  const hasProgressTag = user.taggingPreferences.tagYearProgress[type];
  console.log({ hasProgressTag, user, type });
  const oppositePeriodTarget = getTargetByCoreSportType(
    type,
    period === "year" ? "month" : "year",
    user
  );
  const hasBothPeriodTags = !!oppositePeriodTarget;

  const convertedProgress = getConvertedDistance(
    period === "year" ? year.distance : month.distance,
    measurementPreference,
    false
  );

  const currentYear = moment(date).format("YYYY");
  const currentMonth = moment(date).format("MMMM");
  const activityEmoji = getActivityTypeEmoji(type);

  const percentage = (convertedProgress / target) * 100;
  const roundedPercentage = Math.round(percentage);

  // Calculate the expected progress based on the current date
  const startOfPeriod =
    period === "year"
      ? moment(`${currentYear}-01-01`)
      : moment(date).startOf("month");
  const endOfPeriod =
    period === "year"
      ? moment(`${currentYear}-12-31`)
      : moment(date).endOf("month");
  const daysElapsed = moment(date).diff(startOfPeriod, "days");
  const totalDays = endOfPeriod.diff(startOfPeriod, "days");
  const expectedPercentage = (daysElapsed / totalDays) * 100;

  // Define the range for "on track"
  const onTrackRange = 5; // 5% range
  const lowerBound = expectedPercentage - onTrackRange;
  const upperBound = expectedPercentage + onTrackRange;

  // Determine the progress status
  let progressEmoji = "🟩";
  let statusText = "✔️";
  if (roundedPercentage >= 100) {
    statusText = "";
    progressEmoji = "🟦"; // Use the "ahead" color for exceeded target
  } else if (roundedPercentage > upperBound) {
    progressEmoji = "🟦";
    statusText = "↑";
  } else if (roundedPercentage < lowerBound) {
    progressEmoji = "🟧";
    statusText = "↓";
  }

  // Determine the number of emoji units to show in the graph
  const emojiCount = Math.ceil(percentage / 10); // Each emoji represents 10%

  // Create the progress bar using emojis
  const progressBar = progressEmoji.repeat(emojiCount > 10 ? 10 : emojiCount); // Cap at 10 emojis for 100% but allow overflow
  const overflowBar = emojiCount < 10 ? "⬜".repeat(10 - emojiCount) : ""; // Use a different emoji if above 100%

  /* const shouldIncludeStatsText =
    period === "month" || !getTargetByCoreSportType(type, "month", user); */
  const statsByTextToUse = shouldIncludeStatsText
    ? `
${statsByText}`
    : "";

  const showDate =
    !hasBothPeriodTags && (!hasProgressTag || period === "month"); // month does not appear below year in line above so ok to show actual month if it's only one in use

  const shortenDate = hasBothPeriodTags; // because otherwise they are different lengths and it looks messy

  const celebration = roundedPercentage >= 100 ? "🎉" : "";
  // Construct the output string
  return `🎯 ${
    !hasProgressTag && !hasBothPeriodTags ? activityEmoji + " " : ""
  }${
    showDate
      ? period === "year"
        ? currentYear
        : currentMonth
      : shortenDate
      ? period === "year"
        ? "Y "
        : "M"
      : period === "year"
      ? "Year"
      : "Month"
  } ${progressBar}${overflowBar} ${roundedPercentage}% of ${(
    target || 0
  ).toLocaleString()} ${measurementPreference} ${celebration}${
    statusText ? "[" + statusText + "]" : ""
  }${statsByTextToUse}`;
};

export const addLocationDetailsToName = (str, details) => {
  if (typeof details === "string") {
    details = [details];
  }
  for (const detail of details) {
    if (detail) {
      if (str) {
        str = str + ", " + detail;
      } else {
        str = detail;
      }
    }
  }
  return str;
};

export const getFullUserProfileName = (user, locationOnly, verified) => {
  if (!user) {
    return;
  }
  const {
    athleteDetails: { fullName, city, state, country, reviewerChannel },
  } = user;
  let athleteNameWithCity = addLocationDetailsToName(
    locationOnly ? "" : fullName,
    [city?.name, state?.name, country?.name]
  );
  if (locationOnly) {
    // return athleteNameWithCity;
  }

  if (verified && reviewerChannel && reviewerChannel.title !== fullName) {
    athleteNameWithCity = `${athleteNameWithCity} (@${reviewerChannel.title})`;
  }

  return athleteNameWithCity;
  /* return !verified ? (
    athleteNameWithCity
  ) : (
    <span>
      {athleteNameWithCity}
      <VerifiedIcon color="secondary" fontSize="small" sx={{ ml: 1 }} />
    </span>
  ); */
};
export const getReviewText = (product, user) => {
  // TODO: Should pull rating from review and review type, use 📋 for written reviews
  return (
    <>
      {`▶️ ${product.title} review: ${getShortenedLink(user)}`}
      <br />
      ⭐⭐⭐⭐
    </>
  );
};

export const getExampleParkrunResults = (user) => {
  const sex = user.athleteCharacteristics?.sex?.slug || GENDER_DEFAULT;

  const gender = sex === "mens" ? "Male" : "Female";
  const detailedResult = {
    time: "00:22:45",
    gender,
    genderPosition: 21,
    ageGrading: 61,
    position: 35,
    raceTypeCount: 34,
    pb: true,
    firstTimer: false,
  };

  const eventDetails = {
    title: "Gunnersbury",
    eventNumber: 230,
    numberOfRunners: 549,
    maleCount: 299,
    femaleCount: 250,
  };
  const raceType = { name: "parkrun" };

  const originalName = "Morning run";
  const originalDescription = "";

  return getParkrunResultsText({
    detailedResult,
    eventDetails,
    raceType,
    originalName,
    originalDescription,
  });
};

export const getParkrunResultsText = ({
  detailedResult,
  eventDetails,
  raceType,
  originalName,
  originalDescription,
  hidePosition,
}) => {
  const {
    time,
    gender,
    genderPosition,
    ageGrading,
    position,
    raceTypeCount,
    pb,
    firstTimer,
  } = detailedResult;

  if (gender !== "Male" && gender !== "Female") {
    console.log("No gender found for result:", detailedResult);
  }
  const useNewActivityName =
    !originalName || originalName.toLowerCase() === "morning run"; // TODO: Add options for common translations of 'morning run'

  let activityName = `${eventDetails.title} ${raceType.name} #${eventDetails.eventNumber}`;

  if (firstTimer) {
    activityName += " | 🍃 course debut";
  }

  let parkrunNumberEntry = `my #${numberToEmoji(raceTypeCount)}`;

  const milestones = [25, 50, 100, 250, 500, 750, 1000];

  if (milestones.includes(raceTypeCount)) {
    parkrunNumberEntry += ` 🎉`;
  }
  activityName = `${activityName} (${parkrunNumberEntry})`;

  const name = useNewActivityName ? activityName : originalName; //"Gunnersbury parkrun 230 | 00:22:45 | parkrun #34 | PB 🚨",

  let timeEntry = `Official time: ${time}`;

  if (pb) {
    timeEntry += " | Course PB 🚨";
  }
  let positionEntry = `Overall position: ${position}/${eventDetails.numberOfRunners}`;

  const genderCount = !gender
    ? null
    : gender === "Male"
    ? eventDetails.maleCount
    : eventDetails.femaleCount;

  let genderEntry = !gender
    ? ""
    : `Gender position: ${genderPosition}/${genderCount}`;

  const MEDALS = { 1: "🥇", 2: "🥈", 3: "🥉" };

  if (position in MEDALS) {
    positionEntry += ` ${MEDALS[position]}`;
  }

  if (gender) {
    const genderEmoji = !gender ? null : gender === "Male" ? "🚹" : "🚺";

    if (genderPosition in MEDALS) {
      genderEntry += ` ${MEDALS[genderPosition]}`;
    }
    genderEntry = `
    ${genderEmoji} ${genderEntry}`;
  }

  const ageGradeEntry = !ageGrading
    ? ""
    : `
    🎯 Age grade: ${ageGrading}%`;

  const descriptionHeader = originalDescription?.includes(statsByText)
    ? ""
    : `${statsByText}
`;

  if (originalDescription?.includes(statsByText)) {
    const msg =
      "Description already contains geerly tag | Not removing it though";

    console.log(msg);
  }

  let description = `${descriptionHeader}${
    hidePosition ? "" : "    "
  }🕒 ${timeEntry}`;

  if (!hidePosition) {
    description = `${description}
    🏁 ${positionEntry}${genderEntry}${ageGradeEntry}`;
  }
  if (originalDescription?.includes(description)) {
    const msg =
      "Description already contains parkrun results | Not adding again";

    console.warn(msg);

    return { name, description: originalDescription };
  }

  if (!useNewActivityName) {
    description = `${activityName}
${description}`;
  }

  if (originalDescription) {
    description = combineDescriptions(originalDescription, description);

    /*  if (!hasOtherAppTags(originalDescription)) {
      description = `${originalDescription}
      
${description}`;
    } else {
      description = `${description}

${originalDescription}`;
    } */
  }

  return {
    name,
    description,
  };
};
