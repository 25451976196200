import React from "react";

import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button";
import { makeStyles } from "tss-react/mui";

import { supportedSocialChannels } from "settings/social-links-config";

import SocialIcon from "components/SocialLinks/SocialIcon";

const useStyles = makeStyles()({
  socialIcons: {
    display: "flex",
    "& a": {
      padding: "0px",
      margin: "0px",
      width: "100%",
      borderRadius: "0px 0px 6px 6px",
    },
  },
});

const getSocialLink = (channel, username, addUrl) => {
  if (!addUrl || username.startsWith("http")) {
    // Username is actually full channel already
    return username;
  }

  const parts = ["https://"];
  switch (channel) {
    case "twitter":
      parts.push("twitter.com/");
      break;
    case "facebook":
      parts.push("facebook.com/");
      break;
    case "instagram":
      parts.push("instagram.com/");
      break;
    case "linkedIn":
      parts.push("linkedin.com/in/");
      break;
    case "strava":
      parts.push("strava.com/athletes/");
      break;
    case "spotify":
      parts.push("https://open.spotify.com/user/");
      break;
    case "youtube":
      parts.push("youtube.com/channel/");
      break;
    case "tiktok":
      parts.push("tiktok.com/@");
      break;
  }
  parts.push(username);
  return parts.join("");
};

export function SocialLinks(props) {
  const { entity, button } = props;
  const { classes } = useStyles();

  if (!entity) return <div />;

  const sameAsLinks = [];

  const socialIcons = supportedSocialChannels.map((channel) => {
    channel = channel.name;

    if (entity[channel]) {
      sameAsLinks.push(entity[channel]);
      return (
        <Button
          key={`${entity.id || channel}-${entity[channel]}`}
          justIcon
          simple={!button}
          fullWidth={button}
          href={getSocialLink(channel, entity[channel], true)}
          target="_blank"
        >
          <SocialIcon channel={channel} size="sm" />
        </Button>
      );
    }
  });

  return (
    <>
      {/*    <SocialProfileJsonLd
        type="Person"
        name={entity.name}
        url={ROOT_URL}
        sameAs={sameAsLinks}
      /> */}
      <div className={classes.socialIcons}>{socialIcons}</div>
    </>
  );
}

SocialLinks.defaultProps = {
  button: false,
};
SocialLinks.propTypes = {
  entity: PropTypes.object.isRequired,
  button: PropTypes.bool,
};
