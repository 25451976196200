/* eslint-disable consistent-return */
import React from "react";
import CustomParser from "modules/CustomParser";
//import Source from "components/ProductContent/Source";
import Box from "@mui/material/Box";
// @mui/material components

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

export function prosConsComponent(prosCons, title, t) {
  if (!prosCons) return;
  const types = ["pros", "cons"];
  const count = (prosCons.pros?.length || 0) + (prosCons.cons?.length || 0);

  return types.some((type) => prosCons[type])
    ? {
        title: t("product:prosCons", {
          title,
          count,
        }),
        content: (
          <GridContainer>
            {" "}
            {types.map(
              (type) =>
                prosCons[type] && (
                  <GridItem xxs={12} xs={6} key={type}>
                    <h4>
                      {t(`product:${type}`, { count: prosCons[type].length })}
                    </h4>

                    {prosCons[type].map((pc) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                          "& p": {
                            marginBottom: "0px",
                            marginRight: "3px",
                          },
                          "& svg": {
                            marginRight: "8px",
                            display: "block",
                          },
                        }}
                        key={`pc-${title}-${pc.content}`}
                      >
                        {type === "pros" ? (
                          <CheckCircleIcon color="success" fontSize="small" />
                        ) : (
                          <CancelIcon color="error" fontSize="small" />
                        )}
                        <CustomParser content={pc.content} />
                        {/* pc.source && <Source source={pc.source} t={t} /> */}
                      </Box>
                    ))}
                  </GridItem>
                )
            )}
          </GridContainer>
        ),
        count,
      }
    : null;
}
