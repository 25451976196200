import {
  ROOT_URL,
  websiteName,
  FALLBACK_OG_IMAGE,
} from "settings/global-website-config";

import { twitterHandle } from "settings/social-links-config.js";

// TODO: Add other social links

export const DefaultOgImage = {
  url: FALLBACK_OG_IMAGE,
  width: 1536,
  height: 1024,
  alt: `${websiteName} OG image`,
  type: "image/jpg",
};

export const DefaultSeoConfig = {
  openGraph: {
    type: "website",
    locale: "en",
    url: ROOT_URL,
    site_name: websiteName,
    images: [DefaultOgImage],
  },
  twitter: {
    handle: twitterHandle,
    site: "@site",
    cardType: "summary_large_image",
  },
};
