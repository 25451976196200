import { modalUrls } from "settings/global-website-config.js";

export default function checkIfModal(uri, parseQuery = false) {
  if (uri === "/") {
    return false;
  }
  const uriToTest = parseQuery ? uri.replace("/?msg=", "") : uri;

  return modalUrls.some((u) => u.includes(uriToTest));
}
