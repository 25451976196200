const fetcher = (url) =>
  fetch(url)
    .then(async (res) => {
      if (process.env.SHOW_FETCHED_URL) {
        console.log("[Fetcher] | fetching URL", url);
      }
      if (!res.ok) {
        const error = new Error(
          "[Fetcher] | An error occurred while fetching the data."
        );
        // Attach extra info to the error object.
        error.info = await res.json();
        error.status = res.status;
        throw error;
      }
      return res.json();
    })
    .then((data) => {
      return data;
    });
/*   .catch((error) => {
      console.error("[Fetcher] | Caught error:", error);
    }); */

export default fetcher;
