import Link from "next/link";
import SizeAndSexDropdowns from "components/PriceComparison/SizeAndSexDropdowns.js";

import { FeaturedImage } from "components/Image/FeaturedImage.js";
import { Box, Card, CardMedia, CardHeader, CardContent } from "@mui/material";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { prosConsComponent } from "components/ProductContent/ProsCons";
import useTranslation from "next-translate/useTranslation";
import ProductSpecifications from "components/Specifications/ProductSpecifications";

import { makeStyles } from "tss-react/mui";
import { PriceComparisonModule } from "../PriceComparison/PriceComparisonModule";
import useDeals from "../../modules/products/useDeals";

const useStyles = makeStyles()((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  headerRow: { display: "flex", width: "100%", alignItems: "center" },
  cardMedia: { width: 92, height: 92, padding: theme.spacing(1) },
  dealsBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: theme.spacing(2),
    //width: "100%",
  },
}));

const ProductEmbed = (props) => {
  // Parse JSON string\

  const { classes } = useStyles();
  const {
    title,
    href,
    sourceUrl,
    databaseId,
    state,
    dealsState: mergeDealsState,
    handleChoice,
    categorySlug,
    dealsLocale,
    categoriesSizeProps,
    prosCons,
    specifications,
    detail,
  } = props;

  const { t } = useTranslation();
  const categorySizeProps = categoriesSizeProps[categorySlug];

  let sizeCharts, sizeLocaleOptions, supportedChoiceTypes;
  if (categorySizeProps) {
    ({ sizeCharts, sizeLocaleOptions, supportedChoiceTypes } =
      categorySizeProps);
  }
  const dealsState = useDeals(
    { databaseId },
    state,
    dealsLocale,
    {
      isVisible: true,
      liteOnly: true,
      noSizeOrSex: supportedChoiceTypes.includes("none"),
    },
    mergeDealsState
  );
  console.log("props HERE", {
    props,
    dealsState,
    categorySizeProps,
    mergeDealsState,
  });

  if (!categorySizeProps) {
    console.log("Unsupported category; skipping product embed");
    return null;
  }
  const gridSizes = { md: 6 };

  const prosConsElements =
    detail &&
    (prosCons?.pros?.length || prosCons?.cons?.length) &&
    prosConsComponent(prosCons, title, t);
  return (
    <>
      <Card className={classes.card}>
        <GridContainer className={classes.headerRow}>
          <GridItem {...gridSizes} className={classes.headerRow}>
            <CardMedia className={classes.cardMedia}>
              <FeaturedImage
                href={href}
                title={title}
                featuredImage={{ node: { sourceUrl } }}
                isThumbnail
              />
            </CardMedia>
            <CardHeader title={<Link href={href}>{title}</Link>}></CardHeader>
          </GridItem>
          <GridItem {...gridSizes}>
            <Box className={classes.dealsBox}>
              <h6>Best deal today:</h6>
              <PriceComparisonModule
                dealsState={dealsState}
                product={{
                  databaseId: databaseId,
                  title,
                  __typename: "Product",
                }}
                type={"product"}
                onlyDeal
                isVisible
                productsList
                comparisonBlock
                modelId={databaseId}
                alertProps={{ icon: false }}
                state={state}
              />
            </Box>
          </GridItem>
          <GridItem md={12} sx={{ mx: 1 }}>
            <SizeAndSexDropdowns
              sizeLocaleOptions={sizeLocaleOptions}
              handleChoice={handleChoice}
              sexDeals={dealsState?.sexDeals?.[databaseId]?.deals}
              state={state}
              supportedChoiceTypes={supportedChoiceTypes}
              // sizeSelectRef={sizeSelectRef}
            />
          </GridItem>
        </GridContainer>
        {detail && (prosConsElements || specifications) && (
          <CardContent>
            {prosConsElements && prosConsElements?.content}
            {specifications?.edges.length && (
              <ProductSpecifications
                specifications={specifications}
                title={title}
              />
            )}
          </CardContent>
        )}
      </Card>
    </>
  );
};

export default ProductEmbed;
