import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { useContainerDimensions } from "modules/useContainerDimensions";
// import { makeStyles } from '@mui/material/styles';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import CustomFade from "components/Transition/CustomFade.js";

const useStyles = makeStyles()({
  displayFlex: {
    "& span": {
      display: "flex",
      justifyContent: "center",
    },
  },
  displayBlock: {
    display: "block",
  },
});

export default function SkeletonWithFade(props) {
  const {
    inProp,
    height,
    width,
    children,
    count,
    circle,
    square,
    landscape,
    ref,
    displayFlexWhenLoading,
    sx,
  } = props;
  const { classes } = useStyles();
  let { marginTop, marginBottom, marginLeft, marginRight, textAlign } = props;

  // obfuscate any unrequested args so they don't go to the browser as undefined
  marginTop = marginTop ? `margin-top: ${marginTop};` : "";
  marginBottom = marginBottom ? `margin-bottom: ${marginBottom};` : "";
  marginLeft = marginLeft ? `margin-left: ${marginLeft};` : "";
  marginRight = marginRight ? `margin-right: ${marginRight};` : "";
  textAlign = textAlign ? `text-align: ${textAlign}` : "";

  const displayClasses = displayFlexWhenLoading
    ? classes.displayFlex
    : classes.displayBlock;

  const skeletonRef = React.useRef();

  const { width: actualWidth } = useContainerDimensions(skeletonRef);

  const heightToUse =
    square || circle
      ? width || actualWidth
      : landscape
      ? width || actualWidth > height
        ? height
        : width || actualWidth
      : height;

  return (
    <>
      <CustomFade inProp={inProp}>
        <span
          ref={skeletonRef}
          className={"skeletonContainer " + displayClasses}
        >
          <Skeleton
            height={heightToUse}
            width={width}
            count={count}
            circle={circle}
            ref={ref}
            sx={sx}
          />
          <style jsx>{`
            .skeletonContainer {
              ${marginTop}
              ${marginBottom}
              ${marginLeft}
              ${marginRight}
              ${textAlign}
            }
          `}</style>
        </span>
      </CustomFade>
      <CustomFade inProp={!inProp}>{children}</CustomFade>
    </>
  );
}

SkeletonWithFade.propTypes = {
  inProp: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  circle: PropTypes.bool,
  count: PropTypes.number,
  displayFlexWhenLoading: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string,
  textAlign: PropTypes.string,
  ref: PropTypes.oneOfType([
    // TODO: Move this toa reuseable fun
    // From https://stackoverflow.com/questions/48007326/what-is-the-correct-proptype-for-a-ref-in-react
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({
      current: PropTypes.instanceOf(
        typeof Element === "undefined" ? function () {} : Element
      ),
    }),
  ]),
};
