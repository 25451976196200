import React from "react";
import Link from "next/link";
import Router from "next/router";
import { useScreenSizes } from "components/Providers/ScreenSizesProvider";
import { useSWRConfig } from "swr";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssistantIcon from "@mui/icons-material/Assistant";
import cookie from "react-cookies";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import { makeStyles } from "tss-react/mui";

import useTranslation from "next-translate/useTranslation";
import Avatar from "@mui/material/Avatar";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import PoweredByBadge from "components/PoweredByBadge/PoweredByBadge.js";

import { useAuth } from "auth/useUser.js";
import { sleep } from "@geerly/shared";
import {
  USER_API_PATH_FULL,
  USER_API_PATH,
  AUTHENTICATED_API_PATH,
  SETTINGS_PATH,
  SESSION_COOKIE_NAME,
} from "../../settings/global-website-config";

import {
  dropdownLink,
  navLink,
} from "../../assets/jss/nextjs-material-kit-pro";
import BetaBadgeWrapper from "../Badge/BetaBadgeWrapper";
import { getAthleteUri } from "../../modules/utils";

const useStyles = makeStyles()((theme) => ({
  dropdownLink,
  navLink,
  poweredBySection: {
    // [theme.breakpoints.down('lg')]: {
    width: "100%",
    //  order: 0,
    display: "flex",
    paddingLeft: "12px",
    //},
  },
  loggedInBar: {
    // width: '100vw',
    // borderLeft: '0.1em solid rgba(255,255,255, 0.5);', // divider
    // padding: '0.5em',
    width: "100%",
    // order: 4,
    paddingLeft: "4px",
    marginLeft: "auto",
    // paddingRight: '20px',
    display: "inline-flex",
    alignItems: "center",
    // ...noWrap,
    // ...zeroPadding,
    justifyContent: "flex-end",
    // backgroundColor: stravaColor,
    // marginTop: '-25px',
    [theme.breakpoints.down("lg")]: {
      // marginTop: '30px',
      // display: 'flex',
      // flexWrap: 'wrap',
      // justifyContent: 'unset',
      // position: 'absolute',
      // right: 0,
      // top: '10px',
      // borderRadius: '3px 0px 0px 3px'
    },
    borderRadius: "0px",
    height: "30px",
    position: "relative",
    // width: '100%'
    // '& svg': { padding: '0px' }
  },
  userNavSection: {
    "& label": { borderRadius: "50%" },
    display: "flex",
    /* [theme.breakpoints.down('md')]: {
      marginTop: '10px',
      order: 1,
      width: '100%',
    }, */
  },
  userDropdownArea: {
    display: "inline-flex",
    alignItems: "center",
    "& a": {
      ...navLink,
      marginLeft: "10px",
      /* [theme.breakpoints.down('md')]: {
        color: blackColor,
        order: 3,
      }, */
    },
  },
  img: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    borderColor: theme.palette.common.white,
    border: "1px",
    borderStyle: "solid",
    /* [theme.breakpoints.down('md')]: {
      borderColor: stravaColor,
    }, */
  },
  imageDropdownButton: {
    [theme.breakpoints.down("md")]: {
      // top: '0',
      margin: "0px",
    },
    width: "100%",
    padding: "0px",
    // borderRadius: '50%',
    margin: "5px 15px",
  },
}));
export async function handleLogout(
  Router,
  mutate,
  setLoggingOut,
  redirectTo = "?msg=signedout",
  reason = "signedout"
) {
  console.log("[Auth] | ======= Logging out from userNav =======");

  setLoggingOut(true);

  try {
    await fetch([AUTHENTICATED_API_PATH, "/logout?reason=", reason].join(""));

    // Remove the cookie as the old one can get included in the revalidate path otherwise
    cookie.remove(SESSION_COOKIE_NAME, { path: AUTHENTICATED_API_PATH });
    // Clear the cache without re-fetching

    await mutate(USER_API_PATH);
    await mutate(USER_API_PATH_FULL);
  } catch (error) {
    console.error("Error during logout:", error);
    // Handle the error appropriately
  } finally {
    setLoggingOut(false);
  }

  console.log("[Auth] | ======= Logged out =======");

  const redirectBase = Router.asPath.includes("/dashboard")
    ? "/"
    : Router.asPath;
  const finalRedirectUrl = new URL(
    redirectBase + redirectTo,
    window.location.href
  ).toString();
  Router.push(finalRedirectUrl);
}

export default function UserNav() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { mutate } = useSWRConfig();

  const [user, { loading, loggingOut, setLoggingOut }] = useAuth();
  const { isMdScreen } = useScreenSizes();

  const slug = user?.slug;
  const prefetch = false;
  const dropDownList =
    loading || loggingOut
      ? []
      : [
          <Link
            prefetch={prefetch}
            key={`ddl-1`}
            href={`/dashboard`}
            className={classes.dropdownLink}
          >
            <ListItemIcon>
              <DashboardIcon fontSize="small" />
            </ListItemIcon>
            {t("nav:dashboard")}
          </Link>,
          <Link
            key={`ddl-2`}
            href={getAthleteUri(slug)}
            className={classes.dropdownLink}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            {t("nav:profile")}
          </Link>,

          { divider: true },
          <Link
            prefetch={prefetch}
            key={`ddl-4`}
            href={SETTINGS_PATH}
            className={classes.dropdownLink}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t("nav:settings")}
          </Link>,
          <a
            key={`ddl-5`}
            className={classes.dropdownLink}
            onClick={(e) => {
              e.preventDefault();
              handleLogout(Router, mutate, setLoggingOut);
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {t("nav:signOut")}
          </a>,
          user.provider && { divider: true },
          {
            disabled: true,
            content: (
              <div key={"ddl-6"} className={`${classes.poweredBySection}`}>
                <PoweredByBadge provider={user.provider} isMdScreen={true} />
              </div>
            ),
          },
        ];

  // TODO: Images currently unoptimized as cant whitelist images coming from Strava. Should be available soon as feature:
  //https://github.com/vercel/next.js/issues/27925

  const isLoading = loading || loggingOut; /* || !user; */

  return user || isLoading ? (
    <>
      <div id="user-nav" className={classes.loggedInBar}>
        <div className={classes.userNavSection}>
          <CustomDropdown
            noLiPadding
            caret={!isMdScreen && !isLoading}
            buttonText={
              <div className={classes.userDropdownArea}>
                {!isLoading && (
                  <Avatar
                    variant="rounded"
                    src={user?.profile}
                    alt={user?.displayName + " profile picture"}
                    className={classes.img}
                  />
                )}
                {!isMdScreen && (
                  <a className={classes.navLink}>{user && user.firstname}</a>
                )}
              </div>
            }
            buttonProps={{
              className: `${classes.navLink} ${classes.imageDropdownButton}`,
              transparent: true,
              showLoader: true,
              loading: isLoading,
            }}
            dropdownList={dropDownList}
          />
        </div>
      </div>
    </>
  ) : (
    <div />
  );
}
