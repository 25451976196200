import React from "react";

import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import { makeStyles } from "tss-react/mui";

import { stravaColor } from "assets/jss/nextjs-material-kit-pro.js";

const useStyles = makeStyles()({
  loadingCircularOverlay: {
    // color: stravaColor,
    zIndex: 1,
  },
});

const InputLoader = (props) => {
  const { classes } = useStyles();

  // Hacks to smooth migration to closer alignment with material UI models

  return (
    <CircularProgress {...props} className={classes.loadingCircularOverlay} />
  );
};

InputLoader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
InputLoader.defaultProps = {
  size: 18,
  color: "secondary",
};

if (process.env.NODE_ENV !== "production") {
  InputLoader.displayName = "InputLoader";
}

export default InputLoader;
