import React from "react";

import CustomLink from "components/Links/CustomLink";

import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  linkButton: {
    whiteSpace: "pre-line",
    textAlign: "left",
    fontSize: "14px",
    verticalAlign: "text-top",
    padding: "0px 4px", // was 10px 0px
    margin: "0px 0px 0px -4px",
    display: "unset", //Had to add here after mui upgrate to v5 and removed <span> from button // Was initinally BLock but makes them full width  // TO break up lists of specialVersions onto newlines, could impact other uses though
  },
});

export default function ArrowButton(props) {
  const { classes } = useStyles();
  const { text, hideArrow, icon, prefetch, ...rest } = props;

  const linkProps = { ...rest };

  // Because we get warnings for passing prefetch=true...
  if (prefetch === false) {
    linkProps.prefetch = false;
  }
  return (
    <CustomLink
      type="button"
      {...linkProps}
      classes={{ root: classes.linkButton }}
      startIcon={icon}
      endIcon={!hideArrow && <ArrowRightRoundedIcon />}
      variant="link"
      color="primary"
    >
      {text}
    </CustomLink>
  );
}

ArrowButton.propTypes = {};
