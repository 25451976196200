import React from "react";

import Button from "components/CustomButtons/Button";

import { useScreenSizes } from "components/Providers/ScreenSizesProvider";

import FilterListIcon from "@mui/icons-material/FilterList";

import { makeStyles } from "tss-react/mui";

import {
  selectFormControl,
  rotate180,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  selectFormControl,
  filtersIcon: {
    transition: "transform 0.25s linear",
  },
  rotate180,
  selectStyledButton: {
    display: "inline-flex",
    marginRight: "10px !important",
    justifyContent: "space-around",
    padding: "7px",
    marginTop: theme.spacing(1), // evil hack to line up with size * sex buttons
    fontSize: "0.9rem",
    "&:focus": { backgroundColor: theme.palette.secondary.main },
  },
  whiteColor: { color: theme.palette.common.white },
}));

export default function SelectorDropdown(props) {
  const { classes, cx } = useStyles();

  const { mobileOpen } = useScreenSizes();

  const { openLabel, closeLabel, handleDrawerToggle, ...rest } = props;

  const iconClasses = cx({
    [classes.filtersIcon]: true,
    [classes.rotate180]: mobileOpen,
  });
  return (
    <Button
      {...rest}
      color="secondary"
      fullWidth
      onClick={(e) => {
        console.log("clicked", e);
        handleDrawerToggle();
        e.preventDefault();
      }}
      classes={{
        root: `${classes.selectFormControl} ${classes.selectStyledButton} ${classes.whiteColor}`,
      }}
      endIcon={<FilterListIcon className={iconClasses} />}
    >
      {mobileOpen ? closeLabel || openLabel : openLabel}
    </Button>
  );
}
