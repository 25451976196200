import React from "react";
import CustomLink from "components/Links/CustomLink";

import { makeStyles } from "tss-react/mui";

import {
  textCenter,
  wrapText,
  mlAuto,
  mrAuto,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()({
  textCenter,
  wrapText,
  moreButton: {
    marginTop: "30px",
    marginBottom: "30px",
    ...mlAuto,
    ...mrAuto,
    textAlign: "center",
  },
});

const MoreButton = (props) => {
  const { classes, cx } = useStyles();

  const { show, uri, text, loading, centerContent, noPrefetch, noFollow } =
    props;

  if (!show) return <div />;

  const buttonStyle = cx({
    [classes.moreButton]: true,
    [classes.textCenter]: centerContent,
  });

  const linkProps = { href: uri };
  if (noPrefetch) {
    linkProps.prefetch = false;
  }

  const buttonProps = {
    component: "a",
    color: "secondary",
  };
  if (noFollow) {
    buttonProps.rel = "nofollow";
  }
  return (
    show &&
    !loading && (
      <div className={buttonStyle}>
        <CustomLink
          type="button"
          {...linkProps}
          {...buttonProps}
          classes={{
            root: classes.wrapText,
          }}
          legacyBehavior
        >
          {text}
        </CustomLink>
      </div>
    )
  );
};

export default MoreButton;
