module.exports = {
  locales: ["en"],
  defaultLocale: "en",
  logBuild: false, // False makes logs more succinct
  logger: (field) => {
    const SHOULD_LOG = false;
    if (SHOULD_LOG) {
      console.log("[i18n] | Missing i18n field:", field);
    }
  },
  pages: {
    "*": ["common", "errors", "nav", "menus"],
    "/": ["home", "feeds"],
    "/[dynamicPage]": ["home", "feeds", "landingPages"],
    "/categories": ["products", "feeds"],
    "/categories/[...category]": ["products", "product", "feeds"],
    "/model/[product]": ["product", "feeds"],
    "/family/[family]": ["product", "feeds"],
    "/guide/[guide]": ["feeds", "product"],
    "/post/[post]": ["feeds"],
    "/guides/[[...guides]]": ["feeds"],
    "/posts/[[...posts]]": ["feeds"],
    "/athlete/[athlete]": ["athlete", "feeds", "product"],
    "/dashboard": ["athlete", "dashboard", "feeds", "product"],
    "/chat": ["feeds"],
    // '/404': ['common', 'errors', 'nav'],
  },
};
