import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import {
  grayscale,
  cardHeaderImage,
  cardHeaderImageButton,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  cardHeaderImage,
  cardHeaderImageButton,
  cardHeader: {
    borderRadius: "3px 3px 0px 0px", // was just 3px, changed for size picker
    padding: "1rem 15px",
    marginLeft: "15px",
    marginRight: "15px",
    //marginTop: '-5px', // was -30, changed to -5px for size picker, now to 0 for login
    border: "0",
    marginBottom: "0",
  },
  cardHeaderPlain: {
    marginLeft: "0px",
    marginRight: "0px",
  },
  cardHeaderPlainImage: {
    margin: "0px", // was 25px top
  },
  noShadow: {
    "& img": {
      boxShadow: "none !important",
    },
  },
  grayscaleImg: {
    "& img": grayscale,
  },
  grid: {
    display: "grid",
  },
  noSideMargins: {
    margin: "15px 0px", // was 0px
  },
  cardHeaderContact: {
    margin: theme.spacing(0, 2),
    marginTop: "-20px",
  },
  cardHeaderSignup: {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "-40px",
    padding: "20px 0",
    width: "100%",
    marginBottom: "15px",
  },
}));

export default function CardHeader(props) {
  const {
    className,
    children,
    color,
    plain,
    image,
    contact,
    signup,
    noShadow,
    noSideMargins,
    grid,
    minHeight,
    loading,
    button,
    grayscale,
    ...rest
  } = props;
  const { classes, cx } = useStyles();
  const cardHeaderClasses = cx({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderImage]: image,
    [classes.cardHeaderPlainImage]: plain && image,

    [classes.cardHeaderImageButton]: button && !noShadow,
    [classes[`minHeight${minHeight}`]]: minHeight,
    [classes.cardHeaderContact]: contact,
    [classes.cardHeaderSignup]: signup,
    [classes.noShadow]: loading || noShadow,
    [classes.noSideMargins]: noSideMargins,
    [classes.grid]: grid,
    [classes.grayscaleImg]: grayscale,
    [className]: className !== undefined,
  });

  const boxSx = {};
  if (color) {
    boxSx.backgroundColor = `${color}.main`;
  }
  return (
    <Box className={cardHeaderClasses} sx={boxSx} {...rest}>
      {children}
    </Box>
  );
}

CardHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "info",
    "primary",
    "secondary",
  ]),
  contact: PropTypes.bool,
  grid: PropTypes.bool,
  image: PropTypes.bool,
  loading: PropTypes.bool,
  minHeight: PropTypes.oneOf([100, 200]),
  noShadow: PropTypes.bool,
  noSideMargins: PropTypes.any,
  plain: PropTypes.bool,
  signup: PropTypes.bool,
};
