const { useMemo } = require("react");

//const localForage = require('localforage');

const { ApolloClient, InMemoryCache } = require("@apollo/client");

/* const {
  CachePersistor,
  LocalStorageWrapper,
  LocalForageWrapper
} = require('apollo3-cache-persist'); */

const { apolloLink } = require("./apolloLink.js");

const typePolicies = require("./typePolicies.js");

const possibleTypes = require("./possibleTypes.json");

let apolloClient;
function createApolloClient(
  initialState,
  accessToken,
  refreshToken,
  expiresAt,
  useBasicAuth
) {
  const link = apolloLink(accessToken, refreshToken, expiresAt, useBasicAuth);

  // const fragmentMatcher = new IntrospectionFragmentMatcher({
  //   introspectionQueryResultData
  //  });
  const cache = new InMemoryCache({ possibleTypes, typePolicies });

  /*
  if (process.browser) {
    const persistor = new CachePersistor({
      cache,
      //  storage: new LocalStorageWrapper(window.localStorage),

      storage: new LocalForageWrapper(localForage),
      debug: true
    });

    //const size = persistor.getSize();
    //console.log('Persisted cache size: ', size);
    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    //persistor.restore();
  }
*/

  const fetchPolicy = !process.env.NEXT_PUBLIC_FETCH_POLICY
    ? "cache-first"
    : process.env.NEXT_PUBLIC_FETCH_POLICY;

  const defaultOptions = {
    query: {
      fetchPolicy,
    },
  };

  return new ApolloClient({
    // ssrMode: true, // poss not needed
    link,
    cache,
    defaultOptions,
    //  rehydrate the cache using the initial data passed from the server:
    //  .restore(initialState || {})
  });
}
const cachedClients = {};
function initializeApollo(
  initialState = null,
  accessToken,
  refreshToken,
  expiresAt,
  useBasicAuth
) {
  if (null == apolloClient) {
    apolloClient = createApolloClient(
      initialState,
      accessToken,
      refreshToken,
      expiresAt,
      useBasicAuth
    );
    if (useBasicAuth) {
      cachedClients.withAuth = apolloClient;
    } else {
      cachedClients.noAuth = apolloClient;
    }
  } else {
    if (useBasicAuth) {
      if (cachedClients.withAuth) {
        apolloClient = cachedClients.withAuth;
      } else {
        apolloClient = createApolloClient(
          initialState,
          accessToken,
          refreshToken,
          expiresAt,
          useBasicAuth
        );
        cachedClients.withAuth = apolloClient;
      }
    } else {
      if (cachedClients.noAuth) {
        apolloClient = cachedClients.noAuth;
      } else {
        apolloClient = createApolloClient(
          initialState,
          accessToken,
          refreshToken,
          expiresAt,
          useBasicAuth
        );
        cachedClients.noAuth = apolloClient;
      }
    }
  }

  const _apolloClient = apolloClient;

  //const _apolloClient = apolloClient ?? createApolloClient(accessToken, refreshToken, expiresAt);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

exports.useApollo = function useApollo(initialState) {
  return initializeApollo(initialState);
};
exports.initializeApollo = initializeApollo;
