import React from "react";
import dayjs from "dayjs";
import EmailIcon from "@mui/icons-material/Email";
import dynamic from "next/dynamic";
import Link from "next/link";
import Image from "components/Image/CustomImage";
import VerifiedIcon from "@mui/icons-material/Verified";
import CustomParser from "modules/CustomParser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { faStrava } from "@fortawesome/free-brands-svg-icons/faStrava";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import {
  Alert,
  Button,
  CardMedia,
  CardContent,
  CardActions,
  Card,
  Box,
} from "@mui/material";
import { getFullUserProfileName } from "tagging/tagFactory";
import FollowButton from "components/CustomButtons/FollowButton.js";
import { SocialLinks } from "components/SocialLinks/SocialLinks.js";
import LanguageIcon from "@mui/icons-material/Language";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { FeaturedImage } from "components/Image/FeaturedImage";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";

import SkeletonWithFade from "../../components/Loading/SkeletonWithFade";
import { getShortenedLink } from "../../settings/global-urls";
// core components
import { useRenders } from "../../modules/utils";
import ProfileAvatar from "../../components/Avatar/ProfileAvatar";

import { textMutedColor } from "../../assets/jss/nextjs-material-kit-pro";
import StyledChip from "../../components/Tag/StyledChip";
import { ResendEmailButton } from "./SettingsSection";

const AthleteStats = dynamic(() =>
  import("pages-sections/dashboard/AthleteStats.js")
);
const ContentHighlights = dynamic(() =>
  import("pages-sections/dashboard/ContentHighlights.js")
);

//const dayjs = dynamic(() => import("dayjs"));

const getPrettyUrl = (url) => url?.replace("https://", "");

const ProfileHeader = (props) => {
  const { user, loading, isLoggedInDashboard, demoOnly } = props;

  useRenders("profileHeader");
  const { t } = useTranslation();
  let slug,
    provider,
    description,
    profileImages,
    fullName,
    socialLinks,
    website,
    donate,
    integrationProfileUrl,
    isRestricted,
    isSynched,
    accountAuthorised,
    communicationPreferences,
    athleteType,
    emailVerified,
    email,
    playlist,
    sponsors;

  if (user) {
    ({
      slug,
      provider,
      integration: { integrationProfileUrl, accountAuthorised, isSynched },
      communicationPreferences,
      athleteDetails: {
        athleteType,
        playlist,
        isRestricted,
        description,
        profileImages,
        fullName,
        socialLinks,
        website,
        donate,
        sponsors,
      },
    } = user);

    if (communicationPreferences) {
      ({ email, emailVerified } = communicationPreferences);
    }
  }

  const coverImage = profileImages?.coverImage;

  const athleteName = fullName;
  const verified = athleteType && athleteType.slug !== "athlete";

  const athleteLocationName = getFullUserProfileName(user, true, verified);
  const placeholderPath = "/images/heros/cover_image_placeholder.png";

  const coverImageHeight = isLoggedInDashboard ? "80px" : "140px";

  const avatarStyles = /* isLoggedInDashboard
    ? */ { mr: "auto", ml: 2, mt: "-40px" };
  /*  : { ml: 2 }; */

  const sizes = isLoggedInDashboard ? "(max-width: 900px) 90vw,33vw" : "100vw";

  const emailNotVerified = email && !emailVerified;

  const processedReviews = React.useMemo(() => {
    if (user?.athleteDetails.athleteType.slug !== "reviewer") {
      return null;
    }

    const allReviews = user.athleteProducts?.edges
      .map((e) => e.reviews?.publicReviews)
      .flat()
      .filter(
        (r) =>
          !!r &&
          (r.reviewDetails.videoReview?.url ||
            r.reviewDetails.writtenReview?.pageUrl)
      );
    const latestReview =
      allReviews?.length &&
      allReviews.reduce((prev, current) =>
        dayjs(prev.date).isAfter(dayjs(current.date)) ? prev : current
      );

    const videoReviews = allReviews?.filter(
      (r) => r.reviewDetails.videoReview?.url
    );
    const mostViewedReview =
      videoReviews?.length &&
      videoReviews.reduce((prev, current) =>
        prev.reviewDetails.videoReview.viewCount >
        current.reviewDetails.videoReview.viewCount
          ? prev
          : current
      );

    const isSameVideo =
      latestReview?.id === mostViewedReview?.id ||
      (latestReview.videoReview &&
        latestReview.videoReview.url === mostViewedReview.videoReview.url);

    return { isSameVideo, latestReview, mostViewedReview, allReviews };
  }, [user]);

  // Make main box full width if there is a playlist and two videos
  const mainBoxProps =
    playlist && processedReviews.latestReview && !processedReviews?.isSameVideo // 3 boxes. Make top line be full width on all screens
      ? {}
      : processedReviews?.latestReview &&
        (!processedReviews?.isSameVideo || playlist) // 2 boxes. Make top line be full width on medium screens
      ? { md: true }
      : { xs: true }; // 1 or fewer boxes. Only need to be full width on xs screens

  return (
    <Card
      sx={{
        "& h6": { color: textMutedColor, mb: 2 },
        "& h3 > span": { display: "flex", alignItems: "center" },
      }}
      id="profile-box"
    >
      <SkeletonWithFade
        inProp={loading}
        height={coverImageHeight}
        marginTop="-10px"
      >
        <CardMedia
          sx={{
            position: "relative",
            width: "100%",
            height: coverImageHeight,
            filter:
              "brightness(103%) contrast(114%) grayscale(0%) hue-rotate(0deg) invert(0%) opacity(100%) saturate(122%) sepia(0%)",
          }}
        >
          <Image
            fill
            priority
            alt={fullName || "Loading"}
            src={coverImage?.sourceUrl || placeholderPath}
            style={{ objectFit: "cover" }}
            sizes={sizes}
          />
        </CardMedia>
      </SkeletonWithFade>
      <SkeletonWithFade
        inProp={loading}
        circle
        width="80px"
        marginTop="-40px"
        //   textAlign={isLoggedInDashboard ? "" : ""}
        marginLeft={"16px"}
      >
        {user && <ProfileAvatar user={user} sx={avatarStyles} />}
      </SkeletonWithFade>
      {!isLoggedInDashboard && !loading && (
        <Box
          sx={{
            justifyContent: "end",
            display: "flex",
            pr: 1,
            mt: "-37px",
            flexWrap: "wrap",
            maxWidth: "calc(100% - 94px)", // Measured to prevent overlapping profile pic
            marginLeft: "auto",
          }}
        >
          <SocialLinks entity={socialLinks} />
          <Box sx={{ mt: 0.35 }}>
            <FollowButton athlete={user} demoOnly={demoOnly} />
          </Box>
        </Box>
      )}
      <CardContent sx={{ minHeight: "100px" }}>
        {user &&
          isLoggedInDashboard &&
          // Eventually should be dynamic based on provider
          provider &&
          accountAuthorised &&
          isSynched && (
            <StyledChip
              label={
                <>
                  Synced{" "}
                  <TaskAltIcon
                    sx={{ fontSize: "1rem", verticalAlign: "middle" }}
                  />
                </>
              }
              color="secondary"
              href={integrationProfileUrl}
              target="_blank"
              sx={{
                position: "absolute",
                top: 16,
                "& .MuiChip-icon": { fontSize: "0.7rem" },
              }}
              icon={<FontAwesomeIcon icon={faStrava} />}
            />
          )}
        <GridContainer spacing={1}>
          <GridItem {...mainBoxProps}>
            {user && (
              <>
                <h3>
                  {athleteName}
                  {verified && (
                    <VerifiedIcon
                      color="secondary"
                      fontSize="small"
                      sx={{ ml: 1 }}
                    />
                  )}
                </h3>
                {athleteLocationName && <h6>{athleteLocationName}</h6>}
                {!isRestricted && (
                  <h6>
                    {isLoggedInDashboard ? (
                      <Link id="profile-link" href={user.uri}>
                        {getShortenedLink(user)}
                      </Link>
                    ) : (
                      `@${slug}`
                    )}
                  </h6>
                )}
                {isLoggedInDashboard && <AthleteStats user={user} />}
                {isLoggedInDashboard && emailNotVerified && (
                  <Alert sx={{ mt: 2 }} severity="warning" icon={<EmailIcon />}>
                    <Trans
                      i18nKey="dashboard:settings.checkForVerificationEmail"
                      values={{ email }}
                      components={{ strong: <strong /> }}
                    />
                    <ResendEmailButton
                      variant="text"
                      color="primary"
                      sx={{ p: 0.5 }}
                      email={email}
                    />
                  </Alert>
                )}
              </>
            )}
            {!isLoggedInDashboard && (
              <>
                <CustomParser content={description} />
                {sponsors && (
                  <>
                    <h4>Sponsors</h4>
                    <Box sx={{ display: "inline-flex" }}>
                      {sponsors.map((sponsor) => (
                        <FeaturedImage
                          key={sponsor.uri}
                          featuredImage={sponsor.featuredImage}
                          fallbackAltText={`${sponsor?.name} logo`}
                          //href={sponsor.uri} // todo: this link doesn't work -> needs to be contextualised around category etc
                          isThumbnail
                          widthOverride="60"
                          ariaLabel={sponsor?.name}
                        />
                      ))}
                    </Box>
                  </>
                )}
              </>
            )}
          </GridItem>
          {!isLoggedInDashboard &&
            (playlist ||
              user?.athleteDetails.athleteType.slug === "reviewer") && (
              <ContentHighlights
                user={user}
                processedReviews={processedReviews}
              />
            )}
        </GridContainer>
      </CardContent>
      {!isLoggedInDashboard && (website || donate) && (
        <CardActions>
          {website && (
            <>
              <Button
                variant="text"
                color="gray"
                startIcon={<LanguageIcon />}
                sx={{
                  "& > a": {
                    color: "gray",
                  },
                }}
              >
                <a target="_blank" href={website} rel="noreferrer">
                  {getPrettyUrl(website)}
                </a>
              </Button>
            </>
          )}
          {donate && (
            <Button
              variant="text"
              color="gray"
              startIcon={<VolunteerActivismIcon />}
            >
              <a target="_blank" href={donate} rel="noreferrer">
                {getPrettyUrl(donate)}
              </a>
            </Button>
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default ProfileHeader;
