import NextLink from "next/link";
import MuiLink from "@mui/material/Link";
import MuiButton from "@mui/material/Button";

export default function CustomLink({
  type,
  href,
  prefetch,
  children,
  // eslint-disable-next-line no-unused-vars
  legacyBehavior, // Including here to prevent being passed down in ...rest // TODO should be rationalised
  ...rest
}) {
  const PREFETCH_LINKS = !parseInt(
    process.env.NEXT_PUBLIC_PREVENT_PREFETCH_LINKS_BY_DEFAULT
  );

  const linkProps = {
    // Allow overrides
    href,
    passHref: true,
    legacyBehavior: true,
  };

  if (!PREFETCH_LINKS && !prefetch) {
    linkProps.prefetch = false;
  }

  if (type === "link" || !type) {
    return (
      <NextLink {...linkProps}>
        <MuiLink {...rest}>{children}</MuiLink>
      </NextLink>
    );
  } else if (type === "button") {
    return (
      <NextLink {...linkProps} legacyBehavior>
        <MuiButton {...rest}>{children}</MuiButton>
      </NextLink>
    );
  }
}
