import React from "react";
import { useRouter } from "next/router";
import { usePopUp } from "components/Providers/PopUpProvider";
import StravaLogin from "components/Login/StravaLogin.js";
import { useFullUser } from "auth/useUser";
import { deleteQueryFromAsPath } from "modules/utils";

function LoggedInFeatureButton(props) {
  const {
    Component,
    handleClick,
    type,
    queryParams = {},
    redirectToPage,
    ...rest
  } = props;
  const router = useRouter();
  const [user] = useFullUser();
  const [_popUpProps, setPopUpProps] = usePopUp();

  const customHandleClick = () => {
    if (!user) {
      setPopUpProps({
        contentKey: "featureNotLive",
      });
    } else {
      handleClick();
    }
  };

  if (redirectToPage) {
    queryParams.redirectTo = deleteQueryFromAsPath(router.asPath);
  }

  return user ||
    parseInt(process.env.NEXT_PUBLIC_REDIRECT_STRAVA_LOGIN) ||
    props.disabled ||
    props.readOnly ? (
    <Component {...rest} handleClick={customHandleClick} />
  ) : (
    <StravaLogin
      location={type}
      type={type}
      Component={Component}
      queryParams={queryParams}
    />
  );
}

export default LoggedInFeatureButton;
