import React from "react";

import { makeStyles } from "tss-react/mui";
import LinearProgress from "@mui/material/LinearProgress";

import {
  grayColor,
  displayFlex,
  textMuted,
} from "../../assets/jss/nextjs-material-kit-pro";

const nonHighlightBarColor = grayColor[6];
const borderRadius = "2px";

const useStyles = makeStyles()((theme) => ({
  displayFlex,
  textMuted,
  root: {
    height: 4,
  },
  thickBar: {
    // height: 16,
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 2%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 1%)",
  },
  disabled: { opacity: 0.15 },
  overrideDashed: {
    backgroundImage: "unset",
    background: nonHighlightBarColor,
    animation: "unset",
  },
  barHighlight: { backgroundColor: theme.palette.primary.main },
  barUnHighlight: { backgroundColor: nonHighlightBarColor },
  linearGaugeContainer: {
    position: "relative",
    //  display: 'flex',
    alignItems: "center",
  },
  linearGaugeLabel: {
    minWidth: "62px",
    fontSize: "0.9rem",
    paddingLeft: theme.spacing(1),
  },
  exceedsThreshold: { backgroundColor: theme.palette.secondary.main },
  verticalCentreLine: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Positions in the centre
    borderLeft: `3px solid ${nonHighlightBarColor}`,
    borderRadius,
    height: "10px",
  },
}));

export default function LinearGauge(props) {
  const {
    value,
    label,
    variant,
    thickBar,
    startIcon,
    endIcon,
    customClasses,
    disabled,
    exceedsThreshold,
    ...rest
  } = props;
  const { classes, cx } = useStyles();

  const showBuffer = variant === "buffer";

  const containerClasses = cx({
    [classes.linearGaugeContainer]: true,
    [classes.displayFlex]: !showBuffer,
  });

  const rootClasses = cx({
    [classes.thickBar]: thickBar,
    [classes.root]: !thickBar,
    [classes.disabled]: disabled,
    [classes.exceedsThreshold]: exceedsThreshold && variant === "determinate",
  });

  return (
    <div className={containerClasses}>
      {showBuffer && <div className={classes.verticalCentreLine} />}
      {startIcon}
      <LinearProgress
        variant={variant}
        {...rest}
        value={showBuffer ? value - 5 : value}
        valueBuffer={showBuffer ? value + 5 : null}
        classes={{
          root: rootClasses,
          dashed: classes.overrideDashed,
          barColorPrimary: showBuffer ? classes.barUnHighlight : "",
          colorPrimary: showBuffer ? classes.barHighlight : "",
          ...customClasses,
        }}
      />
      {endIcon}
      {label && (
        <div className={classes.linearGaugeLabel + " " + classes.textMuted}>
          {label}
        </div>
      )}
    </div>
  );
}

LinearGauge.defaultProps = { variant: "buffer" };
LinearGauge.propTypes = {};
