import React from "react";

import getDealsLocale from "modules/products/getDealsLocale";
import { useFullUser } from "auth/useUser";
import useIpCountry from "../../modules/products/useIpCountry";

const DealsLocaleContext = React.createContext({});

export const DealsLocaleProvider = (ctx) => {
  const { lang, children } = ctx;
  const [user] = useFullUser();
  const userCountry = user?.athleteDetails?.country?.slug;

  const { country, ipCountry, isFallbackCountry } = useIpCountry(userCountry);

  const { dealsLocale, isFallbackLocale } = getDealsLocale({
    lang,
    country: country?.toLowerCase(),
    isFallbackCountry,
  });
  const LOG_DEALS = false;
  if (LOG_DEALS) {
    console.log(
      "[dealsLocaleProvider] | dealsLocale:",
      dealsLocale,
      "| isFallbackLocale:",
      isFallbackLocale,
      "| user country:",
      country,
      "| ip country:",
      ipCountry,
      "| isFallbackCountry:",
      isFallbackCountry,
      "| userCountry:",
      userCountry,
      "| lang:",
      lang
    );
  }
  return (
    <DealsLocaleContext.Provider
      value={{
        dealsLocale,
        ipCountry,
        isFallbackLocale,
        lang,
      }}
    >
      {children}
    </DealsLocaleContext.Provider>
  );
};

export const useDealsLocale = () => {
  return React.useContext(DealsLocaleContext);
};

export default DealsLocaleContext;
