import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import CustomParser from "modules/CustomParser";

import { makeStyles } from "tss-react/mui";
import { Avatar, Grid, Badge, Box } from "@mui/material";
//import { green } from '@mui/material/colors';
//import { alpha } from '@mui/material/styles';
import LinearProgress from "@mui/material/LinearProgress";
import { FeedBlock } from "components/Feedblocks/FeedBlock";
import { displayFlex } from "assets/jss/nextjs-material-kit-pro";

const BOT_AVATAR_PATH = "apple-touch-icon.png"; //"images/logos/f_g_bg.png"; // in case we wanted to anthromorphise: "images/404/avatars/ai/ai_avatar_2.jpeg";

const useStyles = makeStyles()(({ breakpoints, palette, spacing }) => {
  const radius = spacing(2.5);
  const rightBgColor = palette.primary.light;
  // if you want the same as facebook messenger, use this color '#09f'
  return {
    msg: {
      padding: spacing(1, 2),
      borderRadius: 4,
      whiteSpace: "pre-line",
      marginBottom: 6,
      minHeight: "40px",
      "& p": {
        margin: "unset",
      }, // display: 'flex', //'inline-block', // may need to be inline-block for text content // 'flex' breaks Feedblock layoutv
      wordBreak: "break-word",
      // fontFamily:
      // eslint-disable-next-line max-len
      //  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      maxWidth: "30vw",
      [breakpoints.down("xl")]: { maxWidth: "40vw" },

      [breakpoints.down("lg")]: { maxWidth: "50vw" },

      [breakpoints.down("md")]: { maxWidth: "40vw" },

      [breakpoints.down("sm")]: { maxWidth: "80vw" },
    },
    fitContent: {
      width: "fit-content", //'100%',
      [breakpoints.down("sm")]: { maxWidth: "90%" },
      maxWidth: "80%",
    },
    fixedWidth: {
      width: "300px",
      [breakpoints.down("xs")]: { width: "200px" },
    },
    displayFlex,
    // eslint-disable-next-line tss-unused-classes/unused-classes
    leftRow: {
      textAlign: "left",
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    left: {
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      backgroundColor: palette.grey[100],
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    leftFirst: {
      borderTopLeftRadius: radius,
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    leftLast: {
      borderBottomLeftRadius: radius,
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    rightRow: {
      textAlign: "-webkit-right", // 'right' is not working as expected
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    right: {
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
      backgroundColor: rightBgColor,
      color: palette.common.white,
      marginLeft: "auto",
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    rightFirst: {
      borderTopRightRadius: radius,
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    rightLast: {
      borderBottomRightRadius: radius,
    },
  };
});

const useAvatarStyles = makeStyles()(({ spacing }) => {
  const size = spacing(5);

  // if you want the same as facebook messenger, use this color '#09f'
  return {
    avatar: {
      width: size,
      height: size,
    },
    /* onlineBadge: {
      backgroundColor: green[500],
      boxShadow: `0 0 0 ${alpha(green[500], 0.5)}`,
      animation: `$pulsing-success 1500ms ${transitions.easing.easeOut} infinite`,
    }, */
  };
});
const CONTAINER_SPACING = 2;
const ChatAvatar = ({ src }) => {
  const { classes } = useAvatarStyles();
  return (
    <Avatar className={classes.avatar} src={src}>
      <Image src={src} fill alt={"Avatar"} sizes="40px" />
    </Avatar>
  );
};

const BotAvatar = ({ isError }) => {
  return (
    <Badge
      color={!isError ? "success" : "error"}
      variant="dot"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      //  classes={{ badge: classes.onlineBadge }}
    >
      <ChatAvatar src={BOT_AVATAR_PATH} />
    </Badge>
  );
};

const renderContent = ({
  content,
  sizeAndSexProps,
  dealsLocale,
  dummyOnly,
}) => {
  const { products, error } = content;

  if (error) {
    return (
      <Box sx={{ color: "error.main", display: "flex", alignItems: "center" }}>
        <ErrorOutlineIcon fontSize="small" sx={{ mr: 0.5 }} />
        {"I'm sorry, something's gone wrong"}
      </Box>
    );
  }
  const { state } = sizeAndSexProps;
  if (products) {
    return (
      <FeedBlock
        feedItems={{
          nodes: products.map((product) => {
            if (!product.featuredImage) {
              product.featuredImage = { node: { sourceUrl: product.image } };
              delete product.image;
            }
            return product;
          }),
        }}
        dummyPrice={dummyOnly ? "£65.99" : null}
        feedType="product"
        noMargin
        openInNewTab
        widthOverride={60}
        heightOverride={40}
        //  noThumbnail
        state={state}
        dealsLocale={dealsLocale}
        displayHorizontally
        showDeals
        hideScrollButtonsWhenNotNeeded
        responsiveSettings={
          !dummyOnly
            ? {
                superLargeDesktop: 2, //3, //4, //6,
                desktop: 2, //3, //4, //5,
                tablet: 1, ///3,
                mobileLarge: 1, // 2, // 3,
                mobile: 1, //2
              }
            : { mobile: 1 }
        }
      />
    );
  }
};

const stripDataFromMessage = (message) => {
  const jsonStart = message.indexOf("{");
  if (jsonStart === -1) {
    return message;
  } else {
    return message.substring(0, jsonStart);
  }
};

const MessageBox = (props) => {
  const {
    side,
    index,
    totalMessages,
    content,
    renderAsIs,
    displayFlex,
    ...rest
  } = props;
  const { classes, cx } = useStyles();

  const attachClass = () => {
    if (index === 0) {
      return classes[`${side}First`];
    }
    if (index === totalMessages) {
      return classes[`${side}Last`];
    }
    return "";
  };
  const contentToUse = renderAsIs ? (
    content
  ) : typeof content === "string" ? (
    <CustomParser content={stripDataFromMessage(content)} />
  ) : (
    renderContent({ content, ...rest })
  );

  return (
    <div className={classes[`${side}Row`]}>
      <Box
        align={"left"}
        className={cx({
          [classes.msg]: true,
          [classes.fitContent]: typeof content === "string",
          [classes.fixedWidth]: typeof content !== "string" && props.dummyOnly,
          [classes.displayFlex]: displayFlex,
          [classes[side]]: true,
          [attachClass()]: true,
        })}
      >
        {contentToUse}
      </Box>
    </div>
  );
};
export const LoadingChatLine = () => {
  return (
    <Grid container spacing={CONTAINER_SPACING} justify="flex-start">
      <Grid item>
        <BotAvatar />
      </Grid>
      <Grid item>
        <MessageBox
          side="left"
          index={0}
          renderAsIs
          displayFlex
          content={
            <Box sx={{ width: "75px", my: "auto" }}>
              <LinearProgress
                sx={{ borderRadius: 6, height: 10, opacity: 0.5 }}
              />
            </Box>
          }
          totalMessages={0}
        />
      </Grid>
    </Grid>
  );
};

const ChatMessages = (props) => {
  const { avatar, messages, side, ...rest } = props;
  return (
    <Grid
      container
      spacing={CONTAINER_SPACING}
      justify={side === "right" ? "flex-end" : "flex-start"}
    >
      {side === "left" && (
        <Grid item>
          <BotAvatar isError={messages[0]?.error} />
        </Grid>
      )}
      <Grid item xxs>
        {messages.map((msg, i) => (
          <MessageBox
            key={msg + i}
            content={msg}
            side={side}
            totalMessages={messages.length - 1}
            index={i}
            {...rest}
          />
        ))}
      </Grid>
      {side === "right" && (
        <Grid item>
          <ChatAvatar src={avatar} />
        </Grid>
      )}
    </Grid>
  );
};

ChatMessages.propTypes = {
  avatar: PropTypes.string,
  messages: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ),
  side: PropTypes.oneOf(["left", "right"]),
};
ChatMessages.defaultProps = {
  avatar: "",
  messages: [],
  side: "left",
};

export default ChatMessages;
