import React from "react";
import Image from "components/Image/CustomImage";

import { makeStyles } from "tss-react/mui";
import useTranslation from "next-translate/useTranslation";
import AddLoadingDotsToText from "components/Loading/AddLoadingDotsToText.js";
import CustomFade from "components/Transition/CustomFade.js";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import OrDivider from "components/Login/OrDivider.js";
// @mui/icons-material

import Close from "@mui/icons-material/Close";

// core components
import Button from "components/CustomButtons/Button.js";
import ButtonBase from "@mui/material/ButtonBase";

import CardHeader from "components/Card/CardHeader.js";

import ProgressWithFade from "components/Loading/ProgressWithFade.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWordpress } from "@fortawesome/free-brands-svg-icons/faWordpress";
import modalStyle from "assets/jss/nextjs-material-kit-pro/modalStyle.js";

import {
  SHOW_WP_LOGIN,
  AUTHENTICATED_API_PATH,
} from "settings/global-website-config";

import LoginTextSection from "./LoginTextSection";

import { addPossibleQueryParams } from "../../modules/utils";

import { textCenter } from "../../assets/jss/nextjs-material-kit-pro";
import { getProviderLoginUrl } from "../../auth/utils";

const useStyles = makeStyles()((theme, props, classes) => ({
  ...modalStyle(theme, props, classes),
  modalLogin: {
    maxWidth: "360px",
    // minHeight: '300px',
    overflowY: "visible",
    width: "100%",
    // not working atm
    [`& .${classes.modalCloseButton}`]: {
      color: theme.palette.common.white,
      // top: "-10px",
      // right: "10px",
      textShadow: "none",
      zIndex: 999,
    },
    /*  [`& .${classes.modalHeader}`]: {
        borderBottom: 'none',
        paddingTop: '24px',
        position: 'absolute',
        // paddingRight: "24px",
        paddingBottom: '0',
        // paddingLeft: "24px"
      }, */
    /* [`& .${classes.modalBody}`]: {
        paddingBottom: '0',
        paddingTop: '0',
      },
      [`& .${classes.modalFooter}`]: {
        paddingBottom: '0',
        paddingTop: '0',
      }, */
  },
  stravaLoginSection: {
    // margin: '20px 0px 10px 0px',
    ...textCenter,
  },
  stravaLoginButton: { justifyContent: "center" },
  wordpressButton: {
    margin: "10px 0px 10px 0px",
    ...textCenter,
    fontSize: "40px",
    color: theme.palette.secondary.main,
    "& a": {
      borderRadius: "50%",
    },
    // height: '1000px'
  },
  /* 
  // seems to be unneeded...
  label: {
    color: `rgba(${hexToRgb(blackColor)}, 0.26)`,
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    transition: '0.3s ease all',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingLeft: '0'
  },
  */

  //labelWithIcon: { display: 'contents' },

  cardTitleWhite: {
    marginBottom: "0px !important",
    color: `${theme.palette.common.white}  !important`,
  },
  cardLoginHeader: {
    // position: 'absolute',
    display: "flex",
    verticalAlign: "middle",
    padding: "1rem",
    width: "100%",
    justifyContent: "center", // marginBottom: '15px'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
if (process.env.NODE_ENV !== "production") {
  Transition.displayName = "Transition";
}

export default function LoginSection(props) {
  const {
    Transition,
    loginModal,
    setLoginModal,
    type,
    location,
    queryParams = {},
  } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);

  const { classes } = useStyles({ test: true });

  if (location === "parkrunSignup") {
    queryParams.referrer = "parkrun";
  }
  const loginUrl = getProviderLoginUrl("strava", queryParams);

  const handleClose = () => setLoginModal(false);
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalLogin,
      }}
      open={Boolean(loginModal)}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      TransitionProps={{ onClose: handleClose }}
      aria-labelledby="login-modal-slide-title"
      aria-describedby="login-modal-slide-description"
    >
      <CardHeader
        loading={loading}
        plain
        color="secondary"
        className={classes.cardLoginHeader}
      >
        <h5 className={classes.cardTitleWhite}>
          <CustomFade inProp={!loading}>
            {t(`nav:loginTitle.${type}`)}
          </CustomFade>
          <CustomFade inProp={loading}>
            <AddLoadingDotsToText>{t("nav:loggingYouIn")}</AddLoadingDotsToText>
          </CustomFade>
        </h5>

        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={() => setLoginModal(false)}
        >
          {" "}
          <Close className={classes.modalClose} />
        </Button>
      </CardHeader>

      <DialogContent className={classes.stravaLoginSection}>
        <LoginTextSection type={loginModal} location={location} />
        <DialogActions className={classes.stravaLoginButton}>
          <ProgressWithFade inProp={loading} size={50}>
            <ButtonBase
              href={loginUrl}
              onClick={async () => {
                //   router.push(loginUrl);
                setLoading(true);
              }}
            >
              <Image
                src="/images/logos/strava/btn_strava_connectwith_orange.svg"
                onError={(e) => {
                  if (
                    e.target.src !==
                    "/images/logos/strava/btn_strava_connectwith_orange.png"
                  ) {
                    e.target.src =
                      "/images/logos/strava/btn_strava_connectwith_orange.png";
                  }
                }}
                alt="Connect with Strava"
                height={50}
                width={200}
              />
            </ButtonBase>
          </ProgressWithFade>
        </DialogActions>
      </DialogContent>

      {SHOW_WP_LOGIN && (
        <>
          <OrDivider />
          <CardHeader
            loading={loading}
            plain
            className={classes.wordpressButton}
          >
            <ProgressWithFade inProp={loading} size={50}>
              <ButtonBase
                href={"/api/auth/wordpress/login"}
                onClick={() => setLoading(true)}
              >
                <FontAwesomeIcon icon={faWordpress} />
              </ButtonBase>
            </ProgressWithFade>
          </CardHeader>
        </>
      )}
      {/*
              <OrDivider />
              <Tooltip
                id="tooltip-social-links-coming-soon"
                title="Coming soon..."
                classes={{ tooltip: classes.tooltip }}
              >
                <div className={classes.socialLine}>
                  <Button justIcon link className={classes.socialLineButton}>
                    <i className="fab fa-facebook-square" />
                  </Button>
                  <Button justIcon link className={classes.socialLineButton}>
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button justIcon link className={classes.socialLineButton}>
                    <i className="fab fa-google-plus-g" />
                  </Button>
                </div>
              </Tooltip>
              */}
    </Dialog>
  );
}
