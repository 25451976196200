import React from "react";

import Hidden from "@mui/material/Hidden";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { makeStyles } from "tss-react/mui";
import { ACTIONS } from "settings/global-website-config";

import { loggerUrl } from "settings/global-urls";

import { logEvent } from "components/TrackingProvider/TrackingProvider";

import { shouldTrack } from "modules/utils";

const useStyles = makeStyles()((theme) => ({
  affiliateLink: {
    color: "unset",
  },
  externalLinkIcon: {
    verticalAlign: "middle",
    opacity: "50%",
    fontSize: "18px",
    marginLeft: theme.spacing(0.5),
  },
}));

export const handleAffiliateClick = (deal, product, from, currency) => {
  const action = ACTIONS.affiliateClick;
  const { price, displayPrice, store, storeName } = deal;

  if (shouldTrack) {
    logEvent("affiliateLink", "click", from, { price, currency });

    let loggerString = `${loggerUrl}${action}&productId=${
      product.databaseId
    }&price=${price || displayPrice}&from=${from}`;
    if (store) {
      loggerString += `&storeId=${store.databaseId}&regionalStoreId=${store.regionalStoreId}`;
    } else if (storeName) {
      loggerString += `&storeName=${storeName}`;
    }

    console.log("Fetching loggerString", loggerString);

    fetch(loggerString, {
      method: "POST",
    });
  } else {
    console.log(
      "[Affiliate click] | Did not track link as shouldTrack is false"
    );
  }
};

export const AffiliateLink = (props) => {
  const { classes } = useStyles();

  const { deal, text, hideIcon, from, product, currency } = props;

  if (!deal) return <div />;

  const { url } = deal;
  //useRenders('affiliateLink');

  return (
    // Allow noreferrer here as using it risks account closure by Amazon
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={classes.affiliateLink}
      href={url}
      target="_blank"
      rel="sponsored noopener"
      onClick={() => handleAffiliateClick(deal, product, from, currency)}
    >
      {text}
      {!hideIcon && (
        <Hidden smDown>
          <OpenInNewIcon
            className={classes.externalLinkIcon}
            fontSize="small"
          />
        </Hidden>
      )}
    </a>
  );
};
