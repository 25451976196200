import React from "react";

import useTranslation from "next-translate/useTranslation";

import ArrowButton from "components/CustomButtons/ArrowButton";

import LinkBadge from "components/LinkBadge/LinkBadge";

import { REVIEWS_ID } from "settings/global-website-config";

import { makeStyles } from "tss-react/mui";

import Info from "components/Typography/Info";

import {
  reviewScoreBadge,
  textCenter,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  highest: {
    color: theme.palette.primary.main,
    backgroundColor: "#33FF33",
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  high: {
    backgroundColor: "#31B931",
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  medium: {
    color: theme.palette.primary.main,
    backgroundColor: "#E5E03E",
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  mediumLow: {
    color: theme.palette.primary.main,
    backgroundColor: "#E5A83E",
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  low: {
    color: theme.palette.primary.main,
    backgroundColor: "#E5613E",
  },
  reviewScoreBadge,
  noMarginTop: { marginTop: "0px" },
  badgeContainer: {
    display: "flex",
    // marginTop: '10px', // added for comparioson block
    alignItems: "center",
    justifyContent: "center", // For dashboardTable- does it effect other pages?
    textAlign: "left",
    lineHeight: "1.34rem",
    "& h6": { marginTop: "auto", marginBottom: "auto" },
  },
  reviewInfo: {
    display: "inline-flex",
    alignItems: "center",
    "& a": { textAlign: "center", display: "inline-flex" },
  },

  commentary: {
    margin: "0px 10px",
    textAlign: "center",
  },
  commentaryNoLeftPadding: {
    margin: "0px 10px 0px 0px",
  },

  textCenter,
  inlineFlex: { display: "inline-flex" },
}));

function ExpertReviewScoreBadge(props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const {
    type,
    reviews,
    showScore,
    loading,
    href,
    textCenter,
    hideArrow,
    hideDetails,
    noMarginTop,
    customClasses,
    isSingleScore,
  } = props;

  if (!reviews) return <div />;
  const { reviewsScore, reviewsCommentary, reviewsCount } = reviews;

  const reviewsLinkText = t("feeds:seeReviews", { count: reviewsCount });
  const reviewsHref =
    type !== "family" && href ? `${href}#${REVIEWS_ID}` : null;

  const badgeContainerClasses = cx({
    [classes.badgeContainer]: !noMarginTop,
    [classes.textCenter]: textCenter,
    [classes.inlineFlex]: textCenter,
    [customClasses]: customClasses,
  });

  const reviewsColour =
    reviewsScore > 90
      ? "highest"
      : reviewsScore > 80
      ? "high"
      : reviewsScore > 70
      ? "medium"
      : "low";

  const reviewScoreBadgeClasses = cx({
    [classes.reviewScoreBadge]: true,
    [classes.noMarginTop]: true,
    [classes[reviewsColour]]: reviewsColour,
  });
  return reviewsScore || loading || isSingleScore ? (
    <>
      <div className={badgeContainerClasses}>
        {reviewsScore > 0 && showScore && (
          <LinkBadge
            name={`${reviewsScore}%`}
            customClasses={reviewScoreBadgeClasses}
            href={reviewsHref}
          />
        )}
        {reviewsCommentary && reviewsHref && !hideDetails && (
          <div className={classes.reviewInfo}>
            <Info>
              <h6
                className={
                  !noMarginTop
                    ? classes.commentary
                    : classes.commentaryNoLeftPadding
                }
              >
                {t(`feeds:commentary.${reviewsCommentary}`)}
              </h6>
            </Info>
            <ArrowButton
              href={reviewsHref}
              text={reviewsLinkText}
              hideArrow={hideArrow}
            />
          </div>
        )}
      </div>
    </>
  ) : (
    <div />
  );
}

ExpertReviewScoreBadge.propTypes = {};

const Memoized = React.memo(ExpertReviewScoreBadge);

export default Memoized;
