import React from "react";

import { getRegionFromIP } from "../regionUtils";

export default function useIpCountry(userCountry?: string) {
  const [localeDetails, setLocaleDetails] = React.useState({
    country: userCountry,
    isFallbackCountry: false,
  });

  React.useEffect(() => {
    const fetchGeoLocation = async () => {
      try {
        if (!userCountry) {
          const { ipCountry, isFallbackCountry, error } =
            await getRegionFromIP();
          if (error) {
            console.error("Failed to fetch geo location");
          } else {
            setLocaleDetails((prevLocaleDetails) => ({
              ...prevLocaleDetails,
              country: ipCountry,
              ipCountry,
              isFallbackCountry:
                isFallbackCountry || prevLocaleDetails.isFallbackCountry,
            }));
          }
        } else {
          setLocaleDetails((prevLocaleDetails) => ({
            ...prevLocaleDetails,
            country: userCountry,
            isFallbackCountry: false,
          }));
        }
      } catch (error) {
        console.error("An error occurred while fetching geo location:", error);
      }
    };

    fetchGeoLocation();
  }, [userCountry]);

  return localeDetails;
}
