import PropTypes from "prop-types";
import React from "react";

import { makeStyles } from "tss-react/mui";

import Tooltip from "@mui/material/Tooltip";

import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { tooltipIcon } from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()({ tooltipIcon });

export default function TooltipAndIcon(props) {
  const {
    title,
    customIconClasses,
    outlined,
    enterDelay,
    leaveTouchDelay,
    ...rest
  } = props;

  const { classes } = useStyles();

  const iconClassesToUse = customIconClasses
    ? `${classes.tooltipIcon} ${customIconClasses} `
    : classes.tooltipIcon;

  const iconProps = {
    fontSize: "small",
    classes: {
      root: iconClassesToUse,
    },
    //  onClick: () => console.log('CLICKED', 'open:', open) || setOpen(!open),
  };

  return (
    title && (
      <Tooltip
        id={`${title}-tooltip`}
        title={title}
        enterDelay={enterDelay}
        enterTouchDelay={0}
        leaveTouchDelay={leaveTouchDelay}
        arrow
        {...rest}
      >
        {!outlined ? (
          <HelpIcon {...iconProps} />
        ) : (
          <HelpOutlineIcon {...iconProps} />
        )}
      </Tooltip>
    )
  );
}

TooltipAndIcon.defaultProps = {
  enterTouchDelay: 0,
  leaveTouchDelay: 3000,
};

TooltipAndIcon.propTypes = {
  customClasses: PropTypes.any,
  customIconClasses: PropTypes.any,
  enterDelay: PropTypes.number,
  enterTouchDelay: PropTypes.number,
  outlined: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
