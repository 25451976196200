import React from "react";

import Link from "next/link";

import SocialIcon from "components/SocialLinks/SocialIcon.js";

import { makeStyles } from "tss-react/mui";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

// core components
import Button from "components/CustomButtons/Button.js";

import { supportedSocialChannels } from "../../settings/social-links-config";

const useStyles = makeStyles()({
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  footerLink: {
    display: "inline-block",
    padding: "15px",
    width: "auto",
    zIndex: 100,
    "& a": { fontSize: "14px", "&hover": { color: "unset" } },
  },
  pullCenter: {
    display: "flex",
  },
  rightLinks: {
    "& ul": {
      marginBottom: 0,
      padding: 0,
      listStyle: "none",
      "& li": {
        display: "inline-block",
      },
    },
    "& img": {
      height: "20px",
    },
    "& i": {
      fontSize: "20px",
    },
  },
});
export function FooterLinks(props) {
  const { menu, isMaintenanceMode } = props;

  const { classes } = useStyles();

  return (
    <>
      <div className={classes.pullCenter}>
        <List className={classes.list}>
          {!isMaintenanceMode &&
            menu.map((menuItem) => (
              <ListItem key={menuItem.id} className={classes.footerLink}>
                <Link prefetch={false} href={menuItem.path}>
                  {menuItem.label}
                </Link>
              </ListItem>
            ))}
        </List>
      </div>
      <div id="socials" className={classes.rightLinks}>
        <ul>
          {supportedSocialChannels.map(
            (channel) =>
              channel.url && (
                <li key={channel.url}>
                  <Button
                    href={channel.url}
                    target="_blank"
                    rel="noopener"
                    aria-label={channel.name}
                    justIcon
                    simple
                  >
                    <SocialIcon channel={channel.name} white />
                  </Button>
                </li>
              )
          )}
        </ul>
      </div>
    </>
  );
}

FooterLinks.defaultProps = {};

FooterLinks.propTypes = {};
