import React from "react";

import PropTypes from "prop-types";
import useTranslation from "next-translate/useTranslation";
import CustomSelect from "components/Select/CustomSelect.js";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import { makeStyles } from "tss-react/mui";

import { Chip, Tabs, Tab } from "@mui/material";

import { useRenders } from "modules/utils";

import { SUPPORTED_SIZE_LOCALES } from "../../../settings/global-website-config";

import {
  selectMenuItem,
  selectMenuItemSelected,
  grayColor,
  displayNone,
  hexToRgb,
} from "../../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  sizeButtonsSelectContainer: {
    position: "absolute", // new
    width: "290px",
  },

  gridMenuItem: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "1px",
    minWidth: "50px",
    width: "68px",
  },
  selectMenuItem,
  selectMenuItemSelected,
  disabled: {
    color: grayColor[11],
    "&:hover": {
      background: grayColor[6],
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.common.white}`,
    },
  },
  disabledSelected: {
    color: grayColor[0],
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: `${grayColor[16]} !important`,
    },
  },
  localeSizeBar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,

    "& root": {
      //   paddingTop: '0px',
    },
    borderRadius: theme.shape.borderRadius,
  },

  displayNone,

  tabsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
  sizeSelectLabel: {
    color: theme.palette.common.white,
    padding: "14px",
    top: "-8px",
    position: "absolute",
  },

  customTabsRoot: {
    minHeight: "unset",
    marginBottom: "5px",
  },
  customTabSelected: {
    backgroundColor: "rgba(" + hexToRgb(theme.palette.common.white) + ", 0.3)",
    transition: "background-color .4s",
  },
  customTabRoot: {
    width: "35px",
    minWidth: "35px",
    borderRadius: theme.shape.borderRadius,
    opacity: "1",

    minHeight: "unset",

    lineHeight: "24px",
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgb(theme.palette.common.white) + ", 0.2)",
      transition: "background-color 0.4s",
    },
  },
  sizeLocaleChip: {
    marginRight: theme.spacing(1),
    height: "unset",
    borderRadius: theme.shape.borderRadius,
  },
}));

export const getUnitSize = (options, size, sizeLocale) => {
  if (!size || !options) {
    return; // !loading ? t('feeds:noSizeSelected') : ' ';
  }
  if (!Array.isArray(size)) {
    size = [size];
  }
  let currentSizes = options.filter((option) =>
    size.includes(option.value.toString())
  );
  if (!currentSizes.length) {
    // if the current size isn't found in the available sizes, just take the first available size

    const highestSize = Math.max(...size.map(Number));
    currentSizes =
      highestSize <= 106 ? [options[0]] : [options[options.length - 1]];
  }
  return currentSizes.map((s) => s[sizeLocale]).join(", "); //.units.find((u) => u.unit.title === sizeLocale)?.value;
};

const SizeTabs = (props) => {
  const {
    handleChoice,
    selections,
    sizeSelectRef,
    loading,
    options,
    enableAllOptions,
    sexDeals,
    state,
    currentSelection,
    disabled,
    sizeLocaleOptions,
    isUpdatingError,
    ...rest
  } = props;

  useRenders("sizeTabs");
  const { t } = useTranslation();
  // get current size selection:

  const { sizeLocale } = state;

  const [value, setValue] = React.useState(
    (sizeLocaleOptions || SUPPORTED_SIZE_LOCALES).indexOf(sizeLocale)
  );

  React.useEffect(() => {
    const currentIndex = (sizeLocaleOptions || SUPPORTED_SIZE_LOCALES).indexOf(
      sizeLocale
    );

    if (currentIndex !== value) {
      setValue(currentIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeLocale]);
  const [openState, setOpenState] = React.useState(false);

  const isAvailable = (value) =>
    enableAllOptions ||
    !currentSelection ||
    sexDeals?.colours?.[state.colour]?.[value]?.allDeals;

  const handleChange = (event, v, type) => {
    const updateValue =
      type === "sizeLocale" ? sizeLocaleOptions[v] : v || event.target.value;

    const updatedSelections = { ...selections, [type]: updateValue };

    if (type === "sizeLocale") {
      setValue(v);
      handleChoice(null, updatedSelections);
    } else {
      if (v) {
        console.log("v", v, "handlingChoice", updatedSelections);
        // Prevent clicking when the item is disabled
        handleChoice(null, updatedSelections);
        //  setOpenState(false);
      }
    }
  };

  const openSizeField = (target) => {
    // don't close if they are clicking in the sizeLocale tab along the top:
    if (
      !target.id.includes("sizesGrid") &&
      !target.id.includes("localeSizeBar") &&
      !target.id.includes("localeSizeButton") &&
      !sizeLocaleOptions.includes(target.innerHTML)
    ) {
      setOpenState(!openState);
    }
  };
  const { classes, cx } = useStyles();

  const tabsContainer = cx({
    [classes.tabsContainer]: true,
  });

  const renderSelection = () => {
    const unitSize = getUnitSize(options, currentSelection, sizeLocale);

    if (!unitSize) {
      return; // !loading ? t('feeds:noSizeSelected') : ' ';
    }

    return `${unitSize} (${sizeLocale})`;
  };
  const optionsToUse = options || [{ value: 1, units: [] }];

  return (
    <CustomSelect
      id="size-select"
      key="size-select"
      fullWidth
      error={isUpdatingError === "size"}
      multiple
      disabled={disabled}
      inputProps={{
        ref: sizeSelectRef,
      }}
      label={t("feeds:selectSize")}
      //handleChoice={handleChoice}
      loading={loading}
      renderValue={props.renderValue || renderSelection}
      currentSelection={currentSelection}
      onClick={(event) =>
        !loading && !disabled && !openState && openSizeField(event.target)
      }
      type="Size"
      selectProps={{
        onClose: () => setOpenState(false),
        startAdornment: state.size?.length ? (
          <Chip label={sizeLocale} classes={{ root: classes.sizeLocaleChip }} />
        ) : null,
      }}
      menuProps={{
        open: openState,
        classes: { paper: classes.sizeButtonsSelectContainer },
        MenuListProps: {
          subheader: (
            <ListSubheader
              disableGutters
              id="localeSizeBar"
              className={classes.localeSizeBar}
            >
              <label className={classes.sizeSelectLabel}>
                {t("feeds:selectSize")}
              </label>
              <Tabs
                classes={{
                  root: classes.customTabsRoot,
                  flexContainer: tabsContainer,
                  indicator: classes.displayNone,
                }}
                value={value}
                onChange={(e, v) => handleChange(e, v, "sizeLocale")}
                textColor="inherit"
              >
                {sizeLocaleOptions?.map((tab, index) => (
                  <Tab
                    key={`${tab}-tab`}
                    classes={{
                      root: classes.customTabRoot,
                      selected: classes.customTabSelected,
                      //   wrapper: classes.customTabWrapper,
                    }}
                    label={tab}
                    index={index}
                    id="localeSizeButton"
                  />
                ))}
              </Tabs>
            </ListSubheader>
          ),
        },
      }}
      {...rest}
    >
      {optionsToUse?.map((option) => {
        const available = isAvailable(option.value);

        return (
          <MenuItem
            onClick={(event) => {
              console.log(
                "event",
                event,
                "option.value",
                option.value,
                "currentSelection",
                currentSelection
              ) ||
                handleChange(
                  event,
                  event.target.getAttribute("data-value"),
                  "size"
                );
            }}
            key={`${option.value}-size-select`}
            classes={{
              root: `${classes.gridMenuItem} ${classes.selectMenuItem} ${
                !available ? classes.disabled : ""
              }`,
              selected: available
                ? classes.selectMenuItemSelected
                : classes.disabledSelected,
            }}
            value={option.value}
            //selected={option.value == currentSelection}
            id={`localeSelectButton-${option.value}`}
          >
            {option[sizeLocale]}
          </MenuItem>
        );
      })}
    </CustomSelect>
  );
};

SizeTabs.propTypes = {
  title: PropTypes.string,
  sizeLocaleOptions: PropTypes.arrayOf(PropTypes.string),
};
if (process.env.NODE_ENV !== "production") {
  SizeTabs.displayName = "SizeTabs";
}
export default SizeTabs;
