import React from "react";
import CustomLink from "components/Links/CustomLink";

import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Trans from "next-translate/Trans";
import Scroll from "react-scroll";
import Box from "@mui/material/Box";
import { makeStyles } from "tss-react/mui";

import useTranslation from "next-translate/useTranslation";
import CircularProgress from "@mui/material/CircularProgress";
// core components

import BuyButton from "components/CustomButtons/BuyButton.js";

import SizeAndSexDropdowns from "components/PriceComparison/SizeAndSexDropdowns.js";
import { AffiliateLink } from "components/Links/AffiliateLink.js";
import AddLoadingDotsToText from "components/Loading/AddLoadingDotsToText.js";

import { getPluralType, useRenders } from "modules/utils";

import {
  NO_DEALS_NAME,
  ANY_COLOUR_NAME,
  GENDER_DEFAULT,
} from "settings/global-website-config";

import { focusSizeSelect } from "./DealsList";

import CustomAlert from "../CustomAlert/CustomAlert";
import NoSizeSetBlock from "./NoSizeSetBlock";

import {
  setSizeButton,
  fullWidth,
} from "../../assets/jss/nextjs-material-kit-pro";

const DealsSection = dynamic(
  () => import("components/PriceComparison/DealsSection"),
  { ssr: false }
);

const AlternativeDeals = dynamic(
  () =>
    import("components/PriceComparison/DealsSection").then(
      (mod) => mod.AlternativeDeals
    ),
  { ssr: false }
);

const useStyles = makeStyles()((theme) => ({
  setSizeButton,
  availableSizesButton: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  findBestDealsHeader: {
    marginTop: "0px",
    fontSize: "1.2rem",
    fontWeight: 400,
  },
  fullWidth,
  pricesFixed: {
    zIndex: 200,
    background: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      top: "80px",
      right: "50px", // Prevent overlapping with wide elements down the page
      maxWidth: "200px",
      // transition: 'all 150ms ease-in-out',
      "& .mainPrice": {
        display: "none",
      },
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "250px",
      right: "unset",
    },

    // The below prevents the bestDealHeader overflowing the screen when we reach teh bottom, when there are a lot of alternativeDeals. But it looks horrible before you get there. Not sure there is a way to fix this without using JS to watch for the end of scroll, which seems unecessary. Disabled for now
    // 117 (footer) + 60 (header) = 177
    // + 114 for select = 291
    // + 20 (gap before header) = 311
    // '& #best-deal-header': { maxHeight: 'calc(100vh - 311px)', overflow: 'auto' },

    "& #best-deal": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const BestDealBadge = () => {
  const { t } = useTranslation();
  return (
    <CustomAlert
      sx={{
        px: 1,
        py: 0.5,
        "& .MuiAlert-message,& .MuiAlert-icon": { p: 0 },
        "& .MuiAlert-icon": { mr: 0.5 },
      }}
      severity="success"
    >
      {t("athlete:bestDeal")}
    </CustomAlert>
  );
};

export const PriceRange = (props) => {
  const { t } = useTranslation();

  const { deals } = props;
  const { lowPrice, highPrice } = deals;

  if (!lowPrice && !highPrice) {
    return;
  }

  if (!lowPrice || !highPrice || lowPrice === highPrice) {
    // No price range - just one price
    return t("feeds:from", { price: lowPrice || highPrice });
  }

  return `${deals.lowPrice} - ${deals.highPrice}`;
};

export const SetAnotherSizeButton = ({ sizeSelectRef, onClick, noSizeSet }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <span
      className={classes.setSizeButton}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        focusSizeSelect(sizeSelectRef);
      }}
    >
      {noSizeSet ? t("product:noSizeSet") : t("product:selectAnotherSize")}
    </span>
  );
};

export const NoDealsInThisSizeMessage = (props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    sexDeals,
    state,
    compact,
    sizeSelectRef,
    hasColourFilter,
    focusColors,
    alertProps,
  } = props;

  const colour = state?.colour;

  const getSizeInLocale = (s) =>
    state?.sizeCharts[state.sex || GENDER_DEFAULT]?.find((f) => f.value == s)?.[
      state.sizeLocale
    ];
  //      ?.units?.find((u) => u.unit.title === state.sizeLocale).value;

  const availableSizes =
    sexDeals?.colours?.[colour] &&
    Object.keys(sexDeals.colours[colour])
      .filter((s) => s !== "name" && sexDeals.colours[colour][s].allDeals)
      .map((s) => getSizeInLocale(s))
      .filter(Boolean);
  // NB it's possible to have items that are listed as supported for the sex but in a size which is not on the sex sizechart - under this logic, it will be filtered out
  // TODO: these deals should be filtered out on the server by the engine (or the size charts system overhauled to allow this)

  const isAvailableInSize = availableSizes?.length > 0;

  const title = hasColourFilter
    ? isAvailableInSize
      ? "noDealsInSizeAndColorMessage"
      : "noDealsInColorMessage"
    : "noDealsInSizeMessage";
  // Previously had 10px marginBottom, but if needed this should be added by the calling component
  return (
    <CustomAlert
      title={t(`product:${title}`)}
      severity="warning"
      {...alertProps}
    >
      {!compact ? (
        isAvailableInSize ? (
          <span>
            {t("product:availableSizes")}{" "}
            <span
              className={classes.availableSizesButton}
              onClick={() => sizeSelectRef?.current.focus()}
            >
              {availableSizes.join(", ")}
            </span>
            {` (${state.sizeLocale})`}
          </span>
        ) : (
          hasColourFilter && (
            <Trans
              i18nKey={"product:trySearchingForADifferentColour"}
              components={[
                // eslint-disable-next-line react/jsx-key
                <span
                  className={classes.setSizeButton}
                  onClick={focusColors}
                />,
              ]}
            />
          )
        )
      ) : (
        <SetAnotherSizeButton sizeSelectRef={sizeSelectRef} />
      )}
    </CustomAlert>
  );
};

export const NoDealsInAnySizeMessage = (props) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { locale: lang } = router;

  const { product, type, title, compact, alertProps, children } = props;

  if (!product.brand) {
    //console.log('No  BRAND???', product);
  }
  return (
    <CustomAlert title={title} severity="warning" {...alertProps}>
      {children}
      {!compact && product.brand && (
        <span>
          {" "}
          {t("product:checkOutMore")}{" "}
          {product.family && (
            <>
              <CustomLink href={product.family.node.uri}>
                {product.family.node.title}
              </CustomLink>{" "}
              {t("product:familyOr")}{" "}
            </>
          )}
          <CustomLink href={product.brand?.uri}>
            {product.brand.node.name}
          </CustomLink>{" "}
          {getPluralType(type, lang)}
          {"."}
        </span>
      )}
    </CustomAlert>
  );
};

const PriceComparisonModulePreMemo = (props) => {
  const { classes } = useStyles();

  const {
    handleChoice,
    state,
    dealsState,
    showDropdowns,
    sizeLocaleOptions,
    loading,
    isVisible,
    compact,
    supportedChoiceTypes,
    product,
    sizeSelectRef,
    comparisonBlock,
    type,
    lang,
    productsList,
    setColorsFocused,
    showAlternativeDeals,
    alertProps,
    modelId,
    ...rest
  } = props;

  useRenders("priceComparisonModule");

  const { t } = useTranslation();

  if (!product) {
    return <div />;
  }

  const supportsShoes =
    props.supportsShoes || supportedChoiceTypes?.includes("shoe-size");

  const hasColourFilter = state && state.colour !== ANY_COLOUR_NAME;

  const deals =
    dealsState && product ? { ...dealsState.deals[product.databaseId] } : null;

  let bestDeal = false;
  if (modelId && deals?.allDeals && !loading) {
    if (deals.allDeals[0]?.model?.id === modelId) {
      bestDeal = true;
    }
    deals.allDeals = [
      ...(deals.allDeals?.filter((d) => !d.model || d.model?.id === modelId) ||
        []),
    ];
  }

  if (parseInt(process.env.NEXT_PUBLIC_SHOW_DEALS)) {
    console.log("[LogVerbose] deals", deals, "dealsState", dealsState);
  }

  if (!deals) {
    // Temp only!
    // return;
  }

  let deal;
  const sexDeals = dealsState?.sexDeals?.[product.databaseId]?.deals;
  const noSizeSet = supportsShoes && !state?.size?.length;

  const currency = dealsState?.deals.currency;

  const focusColors = (e) => {
    e.preventDefault();
    setColorsFocused(true);
    Scroll.scroller.scrollTo("color-selector", {
      smooth: true,
      animation: "easeInOutQuint",
      offset: -80,
    });
  };

  const noDealsMessage = compact ? (
    <NoDealsInAnySizeMessage
      product={product}
      type={type}
      lang={lang}
      compact={productsList || !isVisible}
      alertProps={alertProps}
    >
      {supportsShoes
        ? t("product:noDealsInSizeMessageCompact")
        : t("product:noDealsMessageCompact")}
    </NoDealsInAnySizeMessage>
  ) : supportsShoes ? (
    <NoDealsInThisSizeMessage
      compact={productsList || !isVisible}
      hasColourFilter={hasColourFilter}
      sexDeals={sexDeals}
      state={state}
      sizeSelectRef={sizeSelectRef}
      focusColors={focusColors}
      alertProps={alertProps}
    />
  ) : (
    t("product:noDealsMessage")
  );

  const noDealsAtAllMessage = (
    <NoDealsInAnySizeMessage
      product={product}
      type={type}
      lang={lang}
      compact={productsList || !isVisible || comparisonBlock}
      alertProps={alertProps}
    >
      {supportsShoes
        ? compact || comparisonBlock
          ? t("product:noDealsInAnySizeMessageCompact")
          : t("product:noDealsInAnySizeMessage")
        : t("product:noDealsMessage")}
    </NoDealsInAnySizeMessage>
  );

  if (!loading && comparisonBlock) {
    if (dealsState?.loading) {
      return <CircularProgress size={21} />;
    }
    if (noSizeSet) {
      return (
        <NoSizeSetBlock
          smallText
          deals={deals}
          sizeSelectRef={sizeSelectRef}
          productsList={productsList}
          alertProps={alertProps}
          hideSetSizeMessage={!showAlternativeDeals}
        />
      );
    }
    deal = deals?.allDeals?.[0] || null;
    const from = "comparisonBlock";
    const hasAlternativeDeals =
      deals &&
      (deals.familyProductDeals?.length || deals.specialVersionDeals?.length);

    return (
      <>
        {dealsState?.loading ? (
          <AddLoadingDotsToText>
            {t(`product:lookingForDealsMessage`)}
          </AddLoadingDotsToText>
        ) : !deal && (comparisonBlock || !deals || deals.noDeals) ? (
          showAlternativeDeals && hasAlternativeDeals ? (
            ""
          ) : deals?.[NO_DEALS_NAME] ? (
            noDealsAtAllMessage
          ) : (
            noDealsMessage
          )
        ) : deal ? (
          <span>
            <BuyButton
              bold
              svg
              deal={deal}
              product={product}
              from={from}
              currency={currency}
            >
              <AffiliateLink
                text={deal.displayPrice}
                deal={deal}
                product={product}
                from={from}
                currency={currency}
              />
            </BuyButton>

            <Box sx={{ mb: 1 }}>
              {t("product:fromStore", { store: deal.storeName })}
            </Box>
          </span>
        ) : (
          <div />
        )}
        {showAlternativeDeals && (
          <AlternativeDeals
            productsList={productsList}
            isVisible={isVisible}
            product={product}
            deals={deals}
            alertProps={alertProps}
          />
        )}
        {bestDeal && <BestDealBadge />}
      </>
    );
  }
  const supportsSex = supportedChoiceTypes?.includes("sex");

  return (
    <div className={!isVisible ? classes.pricesFixed : classes.fullWidth}>
      {showDropdowns && !loading && (
        <>
          <h2 className={classes.findBestDealsHeader}>
            {`${
              t("product:findBestDeals", { title: product.title }) +
              (supportsSex ? " " + t("product:dealsSex") : "")
            }`}
          </h2>
          <SizeAndSexDropdowns
            sizeLocaleOptions={sizeLocaleOptions}
            handleChoice={handleChoice}
            sexDeals={sexDeals}
            state={state}
            loading={loading}
            supportedChoiceTypes={supportedChoiceTypes}
            sizeSelectRef={sizeSelectRef}
          />
        </>
      )}

      <DealsSection
        {...rest}
        focusColors={focusColors}
        dealsState={dealsState}
        deal={deal}
        deals={deals}
        lang={lang}
        noDealsMessage={noDealsMessage}
        compact={compact}
        comparisonBlock={comparisonBlock}
        sizeSelectRef={sizeSelectRef}
        product={product}
        noDealsAtAllMessage={noDealsAtAllMessage}
        loading={loading}
        noSizeSet={noSizeSet}
        isVisible={isVisible}
        type={type}
        colour={state?.colour}
        productsList={productsList}
        currency={currency}
        alertProps={alertProps}
        showAlternativeDeals={showAlternativeDeals}
      />
    </div>
  );
};

PriceComparisonModulePreMemo.defaultProps = {
  loading: false,
};

export const PriceComparisonModule = React.memo(PriceComparisonModulePreMemo);
