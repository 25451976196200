/* eslint-disable no-nested-ternary */
import React from "react";
import Link from "next/link";
import AutocompleteDropdown from "components/PriceComparison/Dropdowns/AutocompleteDropdown.js";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { useSWRConfig } from "swr";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { handleLogout } from "components/Login/UserNav";

import MeasurementPreferenceToggle from "components/Toggle/MeasurementPreferenceToggle.js";
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Alert,
  Box,
} from "@mui/material";

import { usePopUp } from "components/Providers/PopUpProvider";

import CountrySelector from "components/CustomDropdown/CountrySelector.js";
import Router from "next/router";
import { useFullUser } from "auth/useUser.js";
import {
  useRenders,
  checkScope,
  getConvertedDistanceAlertThreshold,
  getDistanceAlertParams,
} from "modules/utils";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonIcon from "@mui/icons-material/Person";

import Button from "components/CustomButtons/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Collapse from "@mui/material/Collapse";

import CachedIcon from "@mui/icons-material/Cached";
// core components

import ButtonWithConfirmationAlert from "components/CustomButtons/ButtonWithConfirmationAlert";

import SizeAndSexDropdowns from "components/PriceComparison/SizeAndSexDropdowns.js";

import {
  WRITE_SCOPE,
  AUTHENTICATED_API_PATH,
} from "settings/global-website-config";
import SwitchGroup from "components/Switch/SwitchGroup";
import CustomInput from "components/Inputs/CustomInput";

import { makeStyles } from "tss-react/mui";

import { getShortenedLink } from "../../settings/global-urls";

import {
  helperText,
  textMuted,
  dashboardLabel,
  pulseBackground,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  helperText,
  pulseBackground,
  dashboardLabel,
  marginTop: { marginTop: theme.spacing(1) },
  syncErrorMessage: { color: theme.palette.secondary.main, minHeight: "40px" },
  settingsBlock: {
    margin: theme.spacing(2, 0),
  },
  settingsEntry: {
    marginBottom: theme.spacing(2),
    "& input": {
      [theme.breakpoints.up("xs")]: {
        minWidth: "200px !important",
        //  maxWidth: '100vw',
      },
    },
  },
  itemDetailsBlock: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "max-width 0.3s ease-in-out",
    "& h4": { margin: 0 },

    "& h3": { fontSize: "1.1rem" },
  },

  thresholdBox: {
    display: "flex",
    alignItems: "center",
  },
  currentBestPriceBox: { marginLeft: theme.spacing(1), fontSize: "0.75rem" },
  textMuted,
}));

export const NotLiveYetMessage = () => {
  const { classes } = useStyles();

  return (
    <p className={classes.helperText + " " + classes.marginTop}>
      <em>
        {
          "This feature isn't actually ready yet! But as you have enabled it, it will automatically become live for you when it is 🏃"
        }
      </em>
    </p>
  );
};

function SettingsBlock(props) {
  const { title, children } = props;

  const { classes } = useStyles();

  return (
    <>
      <Divider />
      <div className={classes.settingsBlock}>
        <h3>{title}</h3>
        {children}
      </div>
    </>
  );
}
export function SettingsEntry(props) {
  const { children } = props;

  const { classes } = useStyles();

  return <div className={classes.settingsEntry}>{children}</div>;
}

const SettingsComponent = (props) => {
  const { isUpdating, isUpdatingError, handleUpdateUser, options, ...rest } =
    props;
  const { classes } = useStyles();

  return (
    <SwitchGroup
      helperTextClasses={classes.helperText}
      customHandleChange={handleUpdateUser}
      type="athlete"
      isUpdatingError={isUpdatingError}
      controlled
      labelClasses={classes.dashboardLabel}
      isUpdating={isUpdating}
      disabled={isUpdating}
      options={options}
      {...rest}
    />
  );
};

export const areAlertsEnabled = (userObject) =>
  userObject.communicationPreferences.distanceAlerts ||
  userObject.communicationPreferences.newModelAlerts ||
  userObject.communicationPreferences.priceAlerts;

export const PriceAlertInputs = (props) => {
  const {
    label,
    isUpdating,
    controlled,
    value,
    disabled,
    controlledLoadingState,
    useCustomOnBlurOnChange,
    onBlur,
    localeCurrency,
    familyPriceAlerts,
    onCheckboxChange,
    helperText,
    thresholds,
    fixedMinWidth,
    showCurrentPrice,
  } = props;

  const [loadingState, setLoadingState] = React.useState(false);
  React.useEffect(() => {
    if (!isUpdating && loadingState) {
      setLoadingState(null);
    }
  }, [isUpdating, loadingState]);

  const { cx, classes } = useStyles();
  const { t } = useTranslation();

  const otherProps = {};

  if (controlled) {
    otherProps.value = value || "";
  } else {
    otherProps.defaultValue = value;
  }

  const bestDeal = thresholds?.bestDeal;

  return (
    <>
      <div className={classes.thresholdBox}>
        <CustomInput
          label={label}
          disabled={disabled}
          isUpdating={isUpdating}
          controlledLoadingState={controlledLoadingState}
          useCustomOnBlurOnChange={useCustomOnBlurOnChange}
          onBlur={onBlur}
          type="number"
          min="0"
          fixedMinWidth={fixedMinWidth}
          startAdornment={localeCurrency}
          {...otherProps}
        />

        {showCurrentPrice && value && !controlledLoadingState && (
          <div className={classes.currentBestPriceBox}>
            {bestDeal ? (
              <>
                <div className={classes.helperText}>
                  {t("product:currentBestPrice")}
                </div>
                <div className={classes.textMuted}>
                  <>
                    {bestDeal.displayPrice}
                    {bestDeal.isFamilyProduct && (
                      <>
                        {" ("}
                        <Link href={bestDeal.uri}>
                          {bestDeal.noBrandTitle || bestDeal.title}
                        </Link>
                        {")"}
                      </>
                    )}
                  </>
                </div>
              </>
            ) : (
              t("product:unavailable")
            )}
          </div>
        )}
      </div>
      <FormHelperText sx={{ mb: 2 }} className={classes.helperText}>
        {helperText}
      </FormHelperText>

      <div>
        <FormControlLabel
          classes={{
            label: cx({
              [classes.dashboardLabel]: true,
              [classes.zeroMargin]: true,
            }),
          }}
          sx={{ ml: 0 }}
          control={
            <>
              <Checkbox
                checked={familyPriceAlerts || false}
                disabled={isUpdating}
                onChange={(event) => {
                  onCheckboxChange(event, setLoadingState);
                }}
                classes={{
                  root: cx({
                    [classes.pulseBackground]:
                      loadingState === "familyPriceAlerts",
                  }),
                }}
              />
            </>
          }
          label={t("product:priceAlertsAllFamily")}
        />
        <FormHelperText sx={{ mb: 2 }} classes={{ root: classes.helperText }}>
          {t("product:priceAlertsAllFamilyHelperText")}
        </FormHelperText>
      </div>
    </>
  );
};

export const getDistanceAlertThresholdOptions = (params) => {
  const {
    measurementPreference,
    pathsPrepender = [],
    distanceAlerts,
    distanceAlertThreshold,
    isUpdating,
    isUpdatingError,
    handleUpdateUser,
    t,
    disabled,
    otherProps = {},
    customOnChange,
    type,
    placeholderValue,
  } = params;
  const values = [];
  const { max, min, step } = getDistanceAlertParams(measurementPreference);
  for (let i = min; i <= max; i += step) {
    values.push(i);
  }

  const name = "distanceAlertThreshold";

  return {
    name: "distanceAlerts",
    paths: pathsPrepender.concat("distanceAlerts"),
    label: t("product:distanceAlerts"),
    isSelected: distanceAlerts,
    helperText: t("product:distanceAlertsHelperText"),
    ...otherProps,
    followOnInput: (
      <CustomInput
        fixedMinWidth
        label={t("product:threshold")}
        select
        value={getConvertedDistanceAlertThreshold(
          distanceAlertThreshold,
          measurementPreference
        )}
        disabled={isUpdating || disabled}
        name={name}
        isUpdating={isUpdating}
        isUpdatingError={isUpdatingError}
        onChange={(event, setLoadingState) => {
          event.target.value = getConvertedDistanceAlertThreshold(
            event.target.value,
            measurementPreference,
            true
          );

          return customOnChange
            ? customOnChange(event, setLoadingState)
            : handleUpdateUser({
                event,
                paths: pathsPrepender.concat(name),
                type,
                setLoadingState,
                placeholderValue,
                name,
                ...otherProps,
              });
        }}
      >
        {values.map((opt) => (
          <MenuItem key={`threshold-${opt}`} value={opt}>
            {`${opt} ${measurementPreference}`}
          </MenuItem>
        ))}
      </CustomInput>
    ),
  };
};

export const AlertSettings = (props) => {
  const {
    athlete,
    isUpdating,
    isUpdatingError,
    handleUpdateUser,
    localeCurrency,
  } = props;
  const { t } = useTranslation();

  const {
    communicationPreferences: {
      distanceAlertThreshold,
      distanceAlerts,
      priceAlerts,
      familyPriceAlerts,
      priceAlertThresholdReduction,
      newModelAlerts,
      measurementPreference,
    },
  } = athlete;

  return (
    <SettingsComponent
      {...props}
      preventSelecting={!athlete.communicationPreferences.email}
      isPreventedPopUpKey="email"
      options={[
        getDistanceAlertThresholdOptions({
          measurementPreference,
          pathsPrepender: ["communicationPreferences"],
          distanceAlerts,
          distanceAlertThreshold,
          isUpdating,
          isUpdatingError,
          handleUpdateUser,
          t,
          type: "athlete",
        }),
        {
          name: "priceAlerts",
          paths: ["communicationPreferences", "priceAlerts"],
          label: t("product:priceAlerts"),
          isSelected: priceAlerts,
          helperText: t("product:priceAlertsHelperText"),
          disabled: false,
          followOnInput: (
            <PriceAlertInputs
              fixedMinWidth
              familyPriceAlerts={familyPriceAlerts}
              localeCurrency={localeCurrency}
              label={t("dashboard:priceAlertThresholdReduction")}
              disabled={isUpdating}
              value={priceAlertThresholdReduction}
              name="priceAlertThresholdReduction"
              isUpdating={isUpdating}
              isUpdatingError={isUpdatingError}
              helperText={t("dashboard:priceAlertThresholdReductionHelperText")}
              onBlur={(event, setLoadingState) =>
                handleUpdateUser({
                  event,
                  paths: [
                    "communicationPreferences",
                    "priceAlertThresholdReduction",
                  ],
                  type: "athlete",
                  setLoadingState,
                })
              }
              onCheckboxChange={(event, setLoadingState) =>
                handleUpdateUser({
                  //  ...updateSettingsProps,
                  type: "athlete",
                  event: {
                    ...event,
                    target: { ...event.target, type: "checkbox" },
                  },
                  setLoadingState,
                  paths: ["communicationPreferences", "familyPriceAlerts"],
                })
              }
            />
          ),
        },
        /* {
          name: 'newModelAlerts',
          paths: ['communicationPreferences', 'newModelAlerts'],
          label: t('dashboard:newModelAlerts'),
          isSelected: newModelAlerts,
          helperText: t('dashboard:newModelAlertsHelperText'),
          disabled: false,
          notLiveYet: true,
        },*/
      ]}
    />
  );
};

{
  /* <CustomInput
   type="number"
   min="0"
   startAdornment={localeCurrency}
   label={t('product:priceAlertThresholdReduction')}
   disabled={isUpdating}
   value={priceAlertThresholdReduction}
   name="priceAlertThresholdReduction"
   isUpdating={isUpdating}
   isUpdatingError={isUpdatingError}
   helperText={t('dashboard:priceAlertThresholdReductionHelperText')}
   onBlur={(event, setLoadingState) =>
     handleUpdateUser({
       event,
       paths: ['communicationPreferences', 'priceAlertThresholdReduction'],
       type: 'athlete',
       setLoadingState,
     })
   }
 />; */
}

export const TaggingSettings = (props) => {
  const {
    athlete,
    isUpdating,
    isUpdatingError,
    handleUpdateUser,
    showErrorIfFollowUpBlank,
  } = props;
  const { t } = useTranslation();

  const {
    taggingPreferences: {
      hideShortRuns,
      shortRunThreshold,
      tagNewGear,
      parkrunStatsSync,
      parkrunId,
      hidePosition,
      tagYearProgress,
      tagYearTargets,
      yearTargetRuns,
      yearTargetRides,
      yearTargetSwims,
      monthTargetRuns,
      monthTargetRides,
      monthTargetSwims,
    },
    communicationPreferences: { measurementPreference, sendParkrunResults },
  } = athlete;
  delete tagYearProgress.__typename;
  return (
    <SettingsComponent
      {...props}
      shouldValidate
      preventSelecting={!checkScope(athlete, WRITE_SCOPE)}
      isPreventedPopUpKey={WRITE_SCOPE}
      options={[
        {
          name: "parkrunStatsSync",
          paths: ["taggingPreferences", "parkrunStatsSync"],
          label: t("dashboard:parkrunStatsSync"),
          isSelected: parkrunStatsSync,
          helperText: t("dashboard:parkrunStatsSyncHelperText"),
          showExample: true,
          followOnInput: (
            <>
              <CustomInput
                fixedMinWidth
                label={t("dashboard:parkrunId")}
                startAdornment={"A"}
                endAdornment={<AccountBoxIcon fontSize="small" />}
                isUpdatingError={isUpdatingError}
                disabled={isUpdating}
                defaultValue={parkrunId}
                validateOnBlur
                min={1}
                max={99999999999999}
                type="number"
                invalidMsg={t("dashboard:parkrunIdInvalid")}
                name="parkrunId"
                error={showErrorIfFollowUpBlank && !parkrunId}
                required
                validateFn={(v) => v.length < 15}
                isUpdating={isUpdating}
                onBlur={(event, setLoadingState) =>
                  handleUpdateUser({
                    event,
                    paths: ["taggingPreferences", "parkrunId"],
                    type: "athlete",
                    setLoadingState,
                    //  forceInt: true,
                  })
                }
              />
              <SettingsComponent
                {...props}
                shouldValidate
                preventSelecting={!checkScope(athlete, WRITE_SCOPE)}
                isPreventedPopUpKey={WRITE_SCOPE}
                options={[
                  {
                    name: "hidePosition",
                    paths: ["taggingPreferences", "hidePosition"],
                    label: t("dashboard:hidePosition"),
                    isSelected: hidePosition,
                    helperText: t("dashboard:hidePositionHelperText"),
                    notLiveYet: false,
                  },
                ]}
              />
              {/* <SettingsComponent
                {...props}
                shouldValidate
                preventSelecting={!checkScope(athlete, WRITE_SCOPE)}
                isPreventedPopUpKey={WRITE_SCOPE}
                options={[
                  {
                    name: "sendParkrunResults",
                    paths: ["communicationPreferences", "sendParkrunResults"],
                    label: t("dashboard:sendParkrunResults"),
                    isSelected: sendParkrunResults,
                    helperText: t("dashboard:sendParkrunResultsHelperText"),
                    notLiveYet: true,
                  },
                ]}
              /> */}
            </>
          ),
        },
        {
          name: "tagYearProgress",
          paths: ["taggingPreferences", "tagYearProgress"],
          label: t("dashboard:tagYearProgress"),
          isSelected: tagYearProgress,
          helperText: t("dashboard:tagYearProgressHelperText"),
          showExample: true,
          notLiveYet: false,
          checkboxGroup: [
            { label: "Runs", value: "running" },
            { label: "Rides", value: "cycling" },
            { label: "Swims", value: "swimming" },
          ],
          customHandleChange: (event, _value, setLoadingState) => {
            handleUpdateUser({
              paths: ["taggingPreferences", "tagYearProgress"],
              type: "athlete",
              setLoadingState,
              event,
            });
          },
        },

        {
          name: "tagYearTargets",
          paths: ["taggingPreferences", "tagYearTargets"],
          isSelected: tagYearTargets,
          label: t("dashboard:tagYearTargets"),
          helperText: t("dashboard:tagYearTargetsHelperText"),
          showExample: true,
          notLiveYet: false,
          followOnInput: (
            <>
              <FormHelperText sx={{ mb: 2 }}>
                {t("dashboard:tagYearTargetsFollowOnHelperText")}
              </FormHelperText>
              <CustomInput
                fixedMinWidth
                label={t("dashboard:targetBySportAndPeriod", {
                  sport: "runs",
                  period: "Annual",
                })}
                endAdornment={measurementPreference}
                isUpdatingError={isUpdatingError}
                disabled={isUpdating}
                defaultValue={yearTargetRuns}
                validateOnBlur
                min={1}
                max={100000}
                type="number"
                name="yearTargetRuns"
                isUpdating={isUpdating}
                onBlur={(event, setLoadingState) => {
                  console.log("Onblur", event);
                  handleUpdateUser({
                    event,
                    paths: ["taggingPreferences", "yearTargetRuns"],
                    type: "athlete",
                    setLoadingState,
                    //  forceInt: true,
                  });
                }}
              />
              <CustomInput
                fixedMinWidth
                label={t("dashboard:targetBySportAndPeriod", {
                  sport: "rides",
                  period: "Annual",
                })}
                endAdornment={measurementPreference}
                isUpdatingError={isUpdatingError}
                disabled={isUpdating}
                defaultValue={yearTargetRides}
                validateOnBlur
                min={1}
                max={100000}
                type="number"
                name="yearTargetRides"
                isUpdating={isUpdating}
                onBlur={(event, setLoadingState) =>
                  handleUpdateUser({
                    event,
                    paths: ["taggingPreferences", "yearTargetRides"],
                    type: "athlete",
                    setLoadingState,
                    //  forceInt: true,
                  })
                }
              />
              <CustomInput
                fixedMinWidth
                label={t("dashboard:targetBySportAndPeriod", {
                  sport: "swims",
                  period: "Annual",
                })}
                endAdornment={measurementPreference}
                isUpdatingError={isUpdatingError}
                disabled={isUpdating}
                defaultValue={yearTargetSwims}
                validateOnBlur
                min={1}
                max={100000}
                type="number"
                name="yearTargetSwims"
                isUpdating={isUpdating}
                onBlur={(event, setLoadingState) =>
                  handleUpdateUser({
                    event,
                    paths: ["taggingPreferences", "yearTargetSwims"],
                    type: "athlete",
                    setLoadingState,
                    //  forceInt: true,
                  })
                }
              />
              <CustomInput
                fixedMinWidth
                label={t("dashboard:targetBySportAndPeriod", {
                  sport: "runs",
                  period: "Monthly",
                })}
                endAdornment={measurementPreference}
                isUpdatingError={isUpdatingError}
                disabled={isUpdating}
                defaultValue={monthTargetRuns}
                validateOnBlur
                min={1}
                max={100000}
                type="number"
                name="monthTargetRuns"
                isUpdating={isUpdating}
                onBlur={(event, setLoadingState) =>
                  handleUpdateUser({
                    event,
                    paths: ["taggingPreferences", "monthTargetRuns"],
                    type: "athlete",
                    setLoadingState,
                    //  forceInt: true,
                  })
                }
              />
              <CustomInput
                fixedMinWidth
                label={t("dashboard:targetBySportAndPeriod", {
                  sport: "rides",
                  period: "Monthly",
                })}
                endAdornment={measurementPreference}
                isUpdatingError={isUpdatingError}
                disabled={isUpdating}
                defaultValue={monthTargetRides}
                validateOnBlur
                min={1}
                max={100000}
                type="number"
                name="monthTargetRides"
                isUpdating={isUpdating}
                onBlur={(event, setLoadingState) =>
                  handleUpdateUser({
                    event,
                    paths: ["taggingPreferences", "monthTargetRides"],
                    type: "athlete",
                    setLoadingState,
                    //  forceInt: true,
                  })
                }
              />
              <CustomInput
                fixedMinWidth
                label={t("dashboard:targetBySportAndPeriod", {
                  sport: "swims",
                  period: "Monthly",
                })}
                endAdornment={measurementPreference}
                isUpdatingError={isUpdatingError}
                disabled={isUpdating}
                defaultValue={monthTargetSwims}
                validateOnBlur
                min={1}
                max={100000}
                type="number"
                name="monthTargetSwims"
                isUpdating={isUpdating}
                onBlur={(event, setLoadingState) =>
                  handleUpdateUser({
                    event,
                    paths: ["taggingPreferences", "monthTargetSwims"],
                    type: "athlete",
                    setLoadingState,
                    //  forceInt: true,
                  })
                }
              />
            </>
          ),
        },
        {
          name: "tagNewGear",
          paths: ["taggingPreferences", "tagNewGear"],
          label: t("dashboard:tagNewGear"),
          isSelected: tagNewGear,
          helperText: t("dashboard:tagNewGearHelperText"),
          showExample: true,
        },

        /*{
          name: 'tagReviews',
          paths: ['taggingPreferences', 'tagReviews'],
          label: t('dashboard:tagReviews'),
          isSelected: tagReviews,
          helperText: t('dashboard:tagReviewsHelperText', {
            reviewTaggingFrequency: REVIEW_TAGGING_FREQUENCY,
          }),
          notLiveYet: true,
          showExample: true,
        },*/
        /*
        // Disabled: Strava does not have the capability to update workout type (Jan 2023)
        {
          name: 'tagLongRuns',
          paths: ['taggingPreferences', 'tagLongRuns'],
          label: t('dashboard:tagLongRuns'),
          isSelected: tagLongRuns,
          helperText: t('dashboard:tagLongRunsHelperText'),
          notLiveYet: true,
          followOnInput: (
            <CustomInput
              label={t('dashboard:longRunThreshold')}
              type="number"
              min="0"
              endAdornment={measurementPreference}
              isUpdatingError={isUpdatingError}
              disabled={isUpdating}
              defaultValue={longRunThreshold}
              name="longRunThreshold"
              error={showErrorIfFollowUpBlank && !longRunThreshold}
              required
              isUpdating={isUpdating}
              onBlur={(event, setLoadingState) =>
                handleUpdateUser({
                  event,
                  paths: ['taggingPreferences', 'longRunThreshold'],
                  type: 'athlete',
                  setLoadingState,
                })
              }
            />
          ),
        },*/
        {
          name: "hideShortRuns",
          paths: ["taggingPreferences", "hideShortRuns"],
          label: t("dashboard:hideShortRuns"),
          isSelected: hideShortRuns,
          helperText: t("dashboard:hideShortRunsHelperText"),
          followOnInput: (
            <CustomInput
              fixedMinWidth
              label={t("dashboard:shortRunThreshold")}
              type="number"
              min="1"
              endAdornment={measurementPreference}
              isUpdatingError={isUpdatingError}
              disabled={isUpdating}
              defaultValue={shortRunThreshold}
              name="shortRunThreshold"
              error={showErrorIfFollowUpBlank && !shortRunThreshold}
              required
              isUpdating={isUpdating}
              onBlur={(event, setLoadingState) =>
                handleUpdateUser({
                  event,
                  paths: ["taggingPreferences", "shortRunThreshold"],
                  type: "athlete",
                  setLoadingState,
                })
              }
            />
          ),
        },
        /*{
          name: 'tagCommutes',
          paths: ['taggingPreferences', 'tagCommutes'],
          label: t('dashboard:tagCommutes'),
          isSelected: tagCommutes,
          helperText: t('dashboard:tagCommutes'),
          notLiveYet: true,
        }, */
      ]}
    />
  );
};

export function NotificationSettings(props) {
  // NB on Settings page email ISN'T required as there is no sumbit button so this prop is not passed
  const {
    alertsEnabled,
    hideHelperText,
    athlete,
    showVerificationStatus,
    ...rest
  } = props;
  const { t } = useTranslation();

  const {
    athleteDetails: { isRestricted },
    communicationPreferences: { email, emailVerified, emailCommunications },
  } = athlete;

  const [emailValue, setEmailValue] = React.useState(email);

  React.useEffect(() => {
    if (email && email !== emailValue) {
      setEmailValue(email);
    }
    // Do not include emailValue in deps as it prevents being able to update email
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <>
      <EmailInput
        required={alertsEnabled}
        athlete={athlete}
        emailValue={emailValue}
        setEmailValue={setEmailValue}
        hideHelperText={hideHelperText}
        emailVerified={emailVerified}
        showVerificationStatus={showVerificationStatus}
        {...rest}
      />
      <SettingsComponent
        {...props}
        athlete={athlete}
        options={[
          {
            name: "emailCommunications",
            paths: ["communicationPreferences", "emailCommunications"],
            label: t("dashboard:emailCommunications"),
            isSelected: emailCommunications,
            helperText: t("dashboard:emailCommunicationsHelperText"),
          },
          /*  {
            name: "pushNotifications",
            paths: ["communicationPreferences", "pushNotifications"],
            label: t("dashboard:pushNotifications"),
            isSelected: pushNotifications,
            helperText: t("dashboard:pushNotificationsHelperText"),
            notLiveYet: true,
          }, */
          {
            name: "isRestricted",
            paths: ["athleteDetails", "isRestricted"],
            label: t("athlete:isRestricted"),
            isSelected: isRestricted,
            helperText: (
              <>
                {`${t("dashboard:isRestrictedHelperText")} `}
                <a href={athlete.uri} target="_blank" rel="noreferrer">
                  {getShortenedLink(athlete)}
                </a>
              </>
            ),
          },
        ]}
      />
    </>
  );
}

export function YourRunningSettings(props) {
  const {
    athlete,
    runningCharacteristics,
    handleUpdateUser,
    isUpdating,
    // isUpdatingError,
  } = props;

  const { pronationTypes, archTypes, strikeTypes } = runningCharacteristics;

  const {
    athleteCharacteristics: { pronationType, archType, strikeType },
  } = athlete;

  const { t } = useTranslation();

  const handleChoice = (event, value, setLoadingState, type) => {
    // Function that updates the user
    // In this case we are updating the athleteDetails
    console.log("handling choice", { event, value });
    handleUpdateUser({
      event: { value, target: event.target },
      shortcutValue: value
        ? {
            id: value.databaseId,
            slug: value.slug,
            name: value.name,
          }
        : { none: true, name: "" },
      name: type,
      paths: ["athleteCharacteristics", type],
      type: "athlete",
      setLoadingState,
    });
  };

  const commonProps = {
    disabled: isUpdating,
    loading: isUpdating,
    fixedMinWidth: true,
    noLabelPrepender: true,
    handleChoice,
    isOptionEqualToValue: (option, value) =>
      option.databaseId == value?.databaseId,
  };
  return (
    <>
      <AutocompleteDropdown
        {...commonProps}
        label={t("dashboard:pronationType")}
        currentSelection={pronationType}
        options={pronationTypes}
        type={"pronationType"}
      />
      <AutocompleteDropdown
        {...commonProps}
        label={t("dashboard:strikeType")}
        currentSelection={strikeType}
        options={strikeTypes}
        type={"strikeType"}
      />
      <AutocompleteDropdown
        {...commonProps}
        label={t("dashboard:archType")}
        currentSelection={archType}
        options={archTypes}
        type={"archType"}
      />
    </>
  );
}

export const handleResendEmailRequest = async (email, setPopUpProps) => {
  setPopUpProps({
    content: (
      <Box>
        <Box sx={{ "& svg": { mr: 1 }, display: "inline-flex" }}>
          <MarkEmailReadIcon size="large" />
          <Trans i18nKey="dashboard:settings.emailSent" />
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Trans
          i18nKey="dashboard:settings.checkForVerificationEmail"
          values={{ email }}
          components={{ strong: <strong /> }}
        />
      </Box>
    ),
  });
  await fetch(AUTHENTICATED_API_PATH + "/updateUser", {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // and specify the Content-Type
    },
    body: JSON.stringify({
      verify: true,
      email,
    }),
  });
};

export const ResendEmailButton = (props) => {
  const { email, ...rest } = props;
  const [_popupProps, setPopUpProps] = usePopUp();
  const { t } = useTranslation();
  return (
    <Button
      {...rest}
      onClick={() => handleResendEmailRequest(email, setPopUpProps)}
    >
      {t("dashboard:settings.resendVerificationEmail")}
    </Button>
  );
};

export const EmailInput = (props) => {
  const {
    isUpdating,
    isUpdatingError,
    handleUpdateUser,
    hideHelperText,
    noBlur,
    preventLockingEmail,
    customOnChange,
    emailValue,
    setEmailValue,
    emailVerified,
    showVerificationStatus,
    ...rest
  } = props;

  const { t } = useTranslation();
  const { classes } = useStyles();

  const [emailLocked, setEmailLocked] = React.useState(showVerificationStatus);

  const handleChange = (event, setLoadingState) => {
    if (!noBlur) {
      props.athlete.communicationPreferences.emailVerified = false;
      handleUpdateUser({
        event,
        paths: ["communicationPreferences", "email"],
        type: "athlete",
        setLoadingState,
        additionalVars: { emailVerified: false },
      });
    }
    if (!preventLockingEmail && showVerificationStatus && !emailLocked) {
      setEmailLocked(true);
    }
  };

  const buttonProps = {
    variant: "text",
    color: "primary",
    sx: { ml: 1 /*  fontSize: '0.75rem'  */ },
  };

  return (
    <>
      <CustomInput
        fixedMinWidth
        validateOnBlur
        {...rest}
        label={t("product:emailAddress")}
        type="email"
        endAdornment={<EmailIcon fontSize="small" />}
        disabled={isUpdating || (showVerificationStatus && emailLocked)}
        value={emailValue || ""}
        onChange={(e) => {
          if (customOnChange) {
            customOnChange(e);
          } else {
            setEmailValue(e.target.value);
          }
        }}
        isUpdating={isUpdating}
        isUpdatingError={isUpdatingError}
        name="email"
        onBlur={handleChange}
      />
      {!hideHelperText && (
        <div className={classes.helperText}>
          {t("dashboard:settings.needEmailAddress")}
        </div>
      )}
      {showVerificationStatus && (
        <Box sx={{ display: "flex" }} className={classes.helperText}>
          {emailValue && (
            <Alert
              sx={{
                py: 0,
                px: 1,
                ".MuiAlert-message": {
                  padding: 0,
                  alignItems: "center",
                  display: "flex",
                },
                ".MuiAlert-icon": {
                  padding: 0,
                  alignItems: "center",
                  marginRight: 0.5,
                  display: "flex",
                },
              }}
              icon={<EmailIcon />}
              severity={emailVerified ? "success" : "warning"}
            >
              <Trans
                i18nKey={
                  emailVerified
                    ? "dashboard:settings.emailVerified"
                    : "dashboard:settings.emailNotVerified"
                }
              />
              {emailValue && !emailVerified && (
                <ResendEmailButton
                  {...buttonProps}
                  disabled={!emailLocked || isUpdating}
                  email={emailValue}
                />
              )}
            </Alert>
          )}
          <Button {...buttonProps} onClick={() => setEmailLocked(!emailLocked)}>
            {emailLocked
              ? emailValue
                ? t("dashboard:settings.changeEmail")
                : t("dashboard:settings.setEmail")
              : t("dashboard:settings.save")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default function SettingsSection(props) {
  useRenders("settingsSection");
  const {
    athlete,
    loading,
    sizeAndSexProps,
    provider,
    isSyncing,
    errorMessage,
    runSync,
    isFallbackLocale,
    localeCurrency,
  } = props;

  const { classes } = useStyles();

  const { mutate } = useSWRConfig();

  const { t } = useTranslation();

  const [_, { handleUpdateUser, isUpdating, isUpdatingError, setLoggingOut }] =
    useFullUser();

  const {
    slug,
    athleteDetails: { firstName, lastName },
  } = athlete;

  const DISABLE_NAME_INPUT_FIELDS = true;

  return (
    <div className={classes.itemDetailsBlock}>
      <SettingsBlock title="Sync">
        {!loading && (
          <SettingsEntry>
            <Button
              color="secondary"
              size="small"
              onClick={() => runSync()}
              disabled={Boolean(loading || isSyncing)}
              showLoader
              loading={isSyncing}
              startIcon={<CachedIcon />}
            >
              {t("dashboard:settings.resync")}
            </Button>

            <div className={classes.helperText + " " + classes.marginTop}>
              {t("dashboard:syncGearHelperText", {
                provider,
              })}
            </div>
            <Collapse in={Boolean(errorMessage)}>
              <div className={classes.syncErrorMessage}>{errorMessage}</div>
            </Collapse>
          </SettingsEntry>
        )}
      </SettingsBlock>
      <SettingsBlock title={t("dashboard:general")}>
        <SettingsEntry>
          <NotificationSettings
            isUpdating={isUpdating}
            athlete={athlete}
            isUpdatingError={isUpdatingError}
            handleUpdateUser={handleUpdateUser}
            //  hideHelperText
            showVerificationStatus
          />
        </SettingsEntry>
        <SettingsEntry>
          <CountrySelector
            fixedMinWidth
            isFallbackLocale={isFallbackLocale}
            isUpdating={isUpdating}
            athlete={athlete}
            isUpdatingError={isUpdatingError}
            handleUpdateUser={handleUpdateUser}
          />
        </SettingsEntry>

        <SettingsEntry>
          <CustomInput
            fixedMinWidth
            label={t("dashboard:firstName")}
            type="text"
            endAdornment={<PersonIcon fontSize="small" />}
            disabled={isUpdating}
            defaultValue={firstName}
            isUpdating={isUpdating}
            isUpdatingError={isUpdatingError}
            name="firstName"
            validateOnBlur
            onBlur={(event, setLoadingState) =>
              handleUpdateUser({
                event,
                paths: ["athleteDetails", "firstName"],
                type: "athlete",
                setLoadingState,
              })
            }
          />
        </SettingsEntry>
        <SettingsEntry>
          <CustomInput
            fixedMinWidth
            label={t("dashboard:lastName")}
            type="text"
            endAdornment={<PersonIcon fontSize="small" />}
            disabled={isUpdating}
            defaultValue={lastName}
            isUpdating={isUpdating}
            isUpdatingError={isUpdatingError}
            name="lastName"
            validateOnBlur
            onBlur={(event, setLoadingState) =>
              handleUpdateUser({
                event,
                paths: ["athleteDetails", "lastName"],
                type: "athlete",
                setLoadingState,
              })
            }
          />
        </SettingsEntry>
        <SettingsEntry>
          <CustomInput
            fixedMinWidth
            label={t("dashboard:username")}
            type="text"
            endAdornment={<PersonIcon fontSize="small" />}
            disabled={DISABLE_NAME_INPUT_FIELDS || isUpdating}
            defaultValue={slug}
            isUpdating={isUpdating}
            isUpdatingError={isUpdatingError}
            name="slug"
            validateOnBlur
            onBlur={(event, setLoadingState) =>
              handleUpdateUser({
                event,
                paths: [],
                type: "athlete",
                setLoadingState,
              })
            }
          />
        </SettingsEntry>
        <div className={classes.helperText}>
          <Trans
            i18nKey="dashboard:disabledFields"
            components={{
              link: (
                <a href="/contact" target="_blank" rel="noopener noreferrer" />
              ),
            }}
          />
        </div>
      </SettingsBlock>
      <SettingsBlock title={t("dashboard:shoeSizing")}>
        <SettingsEntry>
          <SizeAndSexDropdowns
            {...sizeAndSexProps}
            showBrandDropdown
            from={"settings"}
            brandHelperText={
              <div className={classes.helperText}>
                {t("feeds:brandHelperText")}
              </div>
            }
          />
        </SettingsEntry>
        <SettingsEntry>
          <MeasurementPreferenceToggle />
        </SettingsEntry>
      </SettingsBlock>
      {/* <SettingsBlock title={t('dashboard:yourRunning')}>
        <p className={classes.helperText}>{t('dashboard:yourRunningIntro')}</p>
        <SettingsEntry>
          <YourRunningSettings
            athlete={athlete}
            runningCharacteristics={runningCharacteristics}
            handleUpdateUser={handleUpdateUser}
            isUpdating={isUpdating}
            isUpdatingError={isUpdatingError}
          />
        </SettingsEntry>
      </SettingsBlock> */}
      <SettingsBlock title={t("dashboard:alerts")}>
        <SettingsEntry>
          <AlertSettings
            isUpdating={isUpdating}
            isUpdatingError={isUpdatingError}
            handleUpdateUser={handleUpdateUser}
            athlete={athlete}
            shouldValidate={true}
            localeCurrency={localeCurrency}
          />
        </SettingsEntry>
      </SettingsBlock>
      <SettingsBlock title={t("dashboard:tagging")}>
        <SettingsEntry>
          <TaggingSettings
            isUpdating={isUpdating}
            isUpdatingError={isUpdatingError}
            handleUpdateUser={handleUpdateUser}
            athlete={athlete}
            showErrorIfFollowUpBlank
          />
        </SettingsEntry>
      </SettingsBlock>
      <SettingsBlock title={t("dashboard:dangerZone")}>
        <SettingsEntry>
          <ButtonWithConfirmationAlert
            color="secondary"
            endIcon={<DeleteForeverIcon />}
            message={t("dashboard:settings.deleteAreYouSure")}
            alertButtonText={t("dashboard:settings.deleteConfirm")}
            onAlertAccept={async () => {
              // open modal for confirmation
              localStorage?.removeItem("hasOnboarded");

              const res = await fetch(AUTHENTICATED_API_PATH + "/updateUser", {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json", // and specify the Content-Type
                },
              });
              if (res.ok) {
                console.log("Successfully deleted user");
                handleLogout(
                  Router,
                  mutate,
                  setLoggingOut,
                  "?msg=accountdeleted",
                  "accountdeleted"
                );

                //. Redirect to homepage
              } else {
                console.error("issue deleting user");
              }

              // Should logout here
              return true;
            }}
          >
            {t("dashboard:settings.deleteAccount")}
          </ButtonWithConfirmationAlert>
          <div className={classes.marginTop}>
            <Trans
              i18nKey="dashboard:settings.deleteHelperText"
              components={{ p: <p className={classes.helperText} /> }}
            />
          </div>
        </SettingsEntry>
      </SettingsBlock>
    </div>
  );
}
