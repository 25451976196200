import React, { useState } from "react";

import cookie from "react-cookies";

import PropTypes from "prop-types";

import { dedupeArrayByKey } from "@geerly/shared";

import { useFullUser } from "auth/useUser.js";

import {
  GENDER_DEFAULT,
  ANY_SEX_NAME,
  ANY_COLOUR_NAME,
  ANY_SIZE_NAME,
  cookieDuration,
  MAX_STORES_TO_LIST,
  SIZE_DEFAULT,
 SIZE_LOCALE_DEFAULT } from "settings/global-website-config";

import fetchSizeCharts from "../../modules/fetchSizeCharts";

import shallowArrayMatch from "../../utils/shallowArrayMatch";

const sortDeals = (deals, key = "storeName", maxPrice) => {
  if (!deals) {
    return;
  }

  let dealsToUse = deals.flat();
  if (maxPrice) {
    dealsToUse = dealsToUse.filter((deal) => deal.price <= maxPrice);
  }

  dealsToUse = dealsToUse.sort((a, b) => {
    if (!a.price && b.price) {
      return 1;
    }
    if (a.price && !b.price) {
      return -1;
    }
    if (a.price === b.price) {
      if (a.lowStock !== b.lowStock) {
        return a.lowStock ? 1 : -1;
      }
    }
    return a.price > b.price ? 1 : -1;
  });

  const deduped = dedupeArrayByKey(dealsToUse, key);
  if (deduped.length > MAX_STORES_TO_LIST) {
    deduped.length = MAX_STORES_TO_LIST;
  }
  return deduped;
};

export const getSizeDeals = (state, deals) => {
  if (!state?.size || !deals) {
    return;
  }

  const sizesDeals = {
    allDeals: [],
    baseModelDeal: null,
    familyProductDeals: [],
  };
  let sizes = state.size;
  if (!Array.isArray(sizes)) {
    sizes = [sizes];
  }
  for (const size of sizes) {
    const sizeDeals = deals[size];
    if (sizeDeals) {
      const { allDeals, baseModelDeal, familyProductDeals } = sizeDeals;
      if (allDeals) {
        sizesDeals.allDeals.push(allDeals);
      }
      if (baseModelDeal) {
        sizesDeals.baseModelDeal = baseModelDeal;
      }
      if (familyProductDeals) {
        sizesDeals.familyProductDeals.push(familyProductDeals);
      }
    }
  }
  if (sizesDeals.allDeals.length) {
    sizesDeals.allDeals = sortDeals(sizesDeals.allDeals);
  } else {
    delete sizesDeals.allDeals;
  }

  if (sizesDeals.familyProductDeals.length) {
    sizesDeals.familyProductDeals = sortDeals(
      sizesDeals.familyProductDeals,
      "title",
      sizesDeals.allDeals?.[0].price
    );
  } else {
    delete sizesDeals.familyProductDeals;
  }
  return sizesDeals;
};

export const getShoeSizing = (state) => {
  const { size } = state;

  const sizing = {
    sex: state.sex,
    size: Array.isArray(size) ? size.join(",") : size || null,
    sizeLocale: { title: state.sizeLocale },
    brand: state.brand,
  };

  return sizing;
};
export const processShoeSizingForServer = (shoeSizing) => {
  shoeSizing.sex = { name: shoeSizing.sex };
  shoeSizing.sizeLocale = { name: shoeSizing.sizeLocale.title };
  shoeSizing.brand = shoeSizing.brand?.databaseId;
};

const sizeChecker = (sizes, size, sex) => {
  const currentSizes = Array.isArray(size)
    ? size.sort().map((s) => parseInt(s))
    : [parseInt(size)];
  if (sex === "womens") {
    const highestSize = sizes[sizes.length - 1].value;

    const highestAvailableSizes = currentSizes.filter(
      (size) => size <= highestSize
    );

    if (highestAvailableSizes.length) {
      return highestAvailableSizes;
    }

    const highestCurrentSize = currentSizes[currentSizes.length - 1];

    return [
      highestSize < highestCurrentSize ? highestSize : highestCurrentSize,
    ];
  } else {
    const lowestSize = sizes[0].value;

    const lowestAvailableSizes = currentSizes.filter(
      (size) => size >= lowestSize
    );

    if (lowestAvailableSizes.length) {
      return lowestAvailableSizes;
    }
    const lowestCurrentSize = currentSizes[0];

    return [lowestSize >= lowestCurrentSize ? lowestSize : lowestCurrentSize];
  }
};

export default function useSizeAndSex(
  dealsLocale,
  sizeCharts,
  handleLoaded,
  sizeLocaleOptions,
  supportedChoiceTypes,
  category,
  loading,
  allowUnsupportedChoiceTypes,
  allowUserBrandToDefineSizeChart
) {
  const timeout = React.useRef();
  const [user, userProps] = useFullUser();

  const cookieArgs = { path: "/" };

  const mutate = userProps?.mutate;
  const sendChangeToApi = userProps?.sendChangeToApi;

  const shoeSizes = user?.communicationPreferences?.shoeSizing;
  dealsLocale = dealsLocale?.toLowerCase();
  const userSex = user?.athleteCharacteristics?.sex?.slug;
  const userSexToUse = shoeSizes?.sex?.slug || userSex;

  const sex =
    supportedChoiceTypes?.includes("sex") || allowUnsupportedChoiceTypes
      ? /*  parseInt(process.env.NEXT_PUBLIC_FORGET_SIZE_AND_SEX)
        ? GENDER_DEFAULT
        : */ userSexToUse || cookie.load("gender") || GENDER_DEFAULT
      : ANY_SEX_NAME; // Was ANY_SEX_NAME, but changed to prevent out-of-range warnings beforoe supportedTypes was initialised. Will poss cause issues with Watches etc,

  const colour = ANY_COLOUR_NAME;

  const savedSize = shoeSizes?.size && shoeSizes.size.toString().split(",");
  let sizeLocale =
    shoeSizes?.sizeLocale?.title ||
    cookie.load("sizeLocale") ||
    SIZE_LOCALE_DEFAULT;

  const getSizeFromCookie = () => {
    const s = cookie.load("size");
    if (typeof s === "string") {
      return s.split(",");
    }
    return s;
  };

  const size =
    !supportedChoiceTypes ||
    allowUnsupportedChoiceTypes ||
    supportedChoiceTypes?.includes("shoe-size")
      ? /*  parseInt(process.env.NEXT_PUBLIC_FORGET_SIZE_AND_SEX)
        ? ''
        : */ savedSize || getSizeFromCookie() || [SIZE_DEFAULT]
      : [ANY_SIZE_NAME];

  // update state if any of them have changed:

  if (
    (sizeLocale &&
      sizeLocaleOptions &&
      !sizeLocaleOptions.includes(sizeLocale)) ||
    (!sizeLocale && sizeLocaleOptions)
  ) {
    // Move the user to a 'safe' size locale if there are missing units
    sizeLocale = sizeLocaleOptions[0];
  }

  let brand = shoeSizes?.brand;

  if (!brand) {
    brand = cookie.load("brand");
    if (brand === "null") {
      brand = null;
    }
  }
  const [state, setState] = useState({
    sex,
    size,
    colour,
    sizeLocale, //: !pageLoading && defaultSizeLocaleFinder(dealsLocale),
    sizeCharts,
    brand,
  });

  if (!brand) {
    brand = state.brand;
  }

  //const sizeChartId = data?.brand.databaseId;
  const [mounted, hasMounted] = React.useState();

  const updateSizingOnServer = ({
    newState,
    choice,
    type,
    noDelay = false,
  }) => {
    if (!user || user.provider === "wordpress") {
      return;
    }
    // Prevent sending more than one request every 2 seconds
    //Clear the previous timeout.
    clearTimeout(timeout.current);

    // If there is no search term, do not make API call

    timeout.current = setTimeout(
      () => {
        const shoeSizing = getShoeSizing(state);

        if (type) {
          if (typeof type === "string") {
            if (type === "Size") {
              choice = choice.join(",");
            }
            shoeSizing[type.toLowerCase()] = choice;
          } else {
            for (const key of Object.keys(type)) {
              let val = type[key];
              if (key === "size") {
                val = val.join(",");
              }
              // Do not lowercase!!
              if (key === "sizeLocale") {
                val = { title: val };
              }
              shoeSizing[key] = val;
            }
          }
        }
        const optimisticData = {
          user: {
            ...user,
            communicationPreferences: {
              ...user.communicationPreferences,
              shoeSizing: { ...shoeSizing }, // makes a copy
            },
          },
        };

        processShoeSizingForServer(shoeSizing);
        // Refactor for server :(

        const variables = {
          shoeSizing,
        };

        if (type === "Brand") {
          variables.shoeSizing[type.toLowerCase()] =
            choice?.databaseId || choice || 0;
        }
        const payload = {
          variables,
          type: "athlete",
          operation: "update",
          preventLoadingState: true,
        };
        const options = {
          optimisticData,
          rollbackOnError: true,
          revalidate: !noDelay, // Causes an unwanted re-render if it happens during onboarding
        };

        mutate(sendChangeToApi(optimisticData, payload, "all"), options); //

        const stateToUse = newState || state;
        setState({
          ...stateToUse,
          updateEnqueued: false,
        });
      },
      noDelay ? 0 : 5000
    );
  };

  async function updateSizeCharts() {
    if (!brand || !category || !allowUserBrandToDefineSizeChart) {
      return;
    }

    const newSizeCharts = await fetchSizeCharts(category, brand);

    if (newSizeCharts?.length) {
      setState({
        ...state,
        sizeCharts: newSizeCharts,
      });
    }
  }

  React.useEffect(() => {
    if (!mounted) {
      hasMounted(true);
    }
  }, [mounted]);

  const handleUserSizing = async () => {
    if (state.updateEnqueued) {
      return;
    }
    const newState = { ...state };
    let hasChanged = false;

    if (userSexToUse && userSexToUse !== state.sex) {
      hasChanged = true;
      newState.sex = userSexToUse;
      cookie.save("gender", userSexToUse, cookieArgs);
    }

    if (shoeSizes) {
      if (savedSize && !shallowArrayMatch(savedSize, state.size)) {
        newState.size = savedSize;
        cookie.save("size", shoeSizes.size, cookieArgs);
        hasChanged = true;
      }
      if (
        shoeSizes.sizeLocale &&
        shoeSizes.sizeLocale.title !== state.sizeLocale
      ) {
        newState.sizeLocale = shoeSizes.sizeLocale.title;

        cookie.save("sizeLocale", shoeSizes.sizeLocale.title, cookieArgs);
        hasChanged = true;
      }
      if (
        shoeSizes.brand?.databaseId &&
        shoeSizes.brand?.databaseId !== state.brand?.databaseId
      ) {
        console.log("Re-polling fetchSizeCharts");
        if (category && allowUserBrandToDefineSizeChart) {
          const newSizeCharts = await fetchSizeCharts(
            category,
            shoeSizes.brand
          );
          newState.sizeCharts = newSizeCharts;
        }

        newState.brand = shoeSizes.brand;
        cookie.save("brand", shoeSizes.brand, cookieArgs);
      } else {
        if (!state.sizeCharts && shoeSizes.brand) {
          if (category && allowUserBrandToDefineSizeChart) {
            const newSizeCharts =
              sizeCharts || (await fetchSizeCharts(category, shoeSizes.brand));
            newState.sizeCharts = newSizeCharts;
          }
        }
      }
    }

    if (hasChanged) {
      setState(newState);
    }
  };
  React.useEffect(() => {
    if (mounted && user && !loading) {
      handleUserSizing();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.athleteCharacteristics?.sex]);

  React.useEffect(() => {
    // once the page has loaded on the browser, update options (once) if we have them saved in cookies:
    //
    if (brand?.databaseId && !loading && mounted) {
      updateSizeCharts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.brand, mounted]);

  React.useEffect(() => {
    //if (!pageTransition) {
    /*  if (brand?.databaseId && !sizeChartId) {
      updateData({ variables: { databaseId: brand.databaseId } });
    } */

    if (
      !loading &&
      mounted &&
      (sex !== state.sex ||
        (sex !== ANY_SEX_NAME &&
          supportedChoiceTypes &&
          !allowUnsupportedChoiceTypes &&
          !supportedChoiceTypes.includes("sex")) ||
        !shallowArrayMatch(size, state.size) ||
        (size !== ANY_SIZE_NAME &&
          supportedChoiceTypes &&
          !allowUnsupportedChoiceTypes &&
          !supportedChoiceTypes.includes("shoe-size")) ||
        colour !== state.colour ||
        sizeLocale !== state.sizeLocale ||
        (state.brand && brand.id !== state.brand.id) ||
        (sizeCharts && !state.sizeCharts))
    ) {
      const newState = {
        sex:
          supportedChoiceTypes &&
          !allowUnsupportedChoiceTypes &&
          !supportedChoiceTypes?.includes("sex")
            ? ANY_SEX_NAME
            : sex || state.sex,
        size:
          supportedChoiceTypes &&
          !allowUnsupportedChoiceTypes &&
          !supportedChoiceTypes.includes("shoe-size")
            ? ANY_SIZE_NAME
            : size || state.size,
        colour: colour || state.colour,
        sizeLocale: sizeLocale || state.sizeLocale,
        sizeCharts: state.sizeCharts || sizeCharts,
        colours: state.colours,
        brand: brand || state.brand,
        mounted: true,
      };

      setState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    supportedChoiceTypes,
    // pageTransition,
    /*  brand,
    colour,
    data?.brand.sizeCharts,
    sex,
    size,
    sizeChartId,

    sizeCharts,
    sizeLocale,
    state.brand,
    state.colour,
    state.colours,
    state.sex, */
    // State.size prevents updates happening
    // state.size,
    // state.sizeCharts,
    // state.sizeLocale,
    // supportedChoiceTypes,
    //updateData,
  ]);

  return {
    updateSizingOnServer,
    state,
    handleChoice: (choice = null, type) => {
      let newState = {
        ...state,
      };

      if (choice) {
        cookieArgs.maxAge = cookieDuration;
      }

      if (handleLoaded) {
        // Disabled as this only appears to reset the extraBarContent which isn't actually particularly relevant if you're just changing shoe size
        // handleLoaded();
      }

      if (type === "Sex") {
        cookie.save("gender", choice, cookieArgs);

        const closestSize = sizeChecker(
          state.sizeCharts[choice],
          state.size,
          choice
        );
        cookie.save("size", closestSize, cookieArgs);
        state.size = closestSize; // update immediately for the server update

        newState.sex = choice;
        newState.size = closestSize;
      } else if (type === "Colour") {
        // Not sure it makes sense to store color in cookie, leaving in for now but will prob remove

        newState.colour = choice;
      } else if (type === "Brand") {
        if (choice) {
          cookie.save(
            "brand",
            JSON.stringify({
              databaseId: choice.databaseId,
              name: choice.name,
              slug: choice.slug,
            }),
            cookieArgs
          );
        } else {
          cookie.remove("brand", cookieArgs);
        }

        /*    if (
          !choice ||
          (choice.databaseId && sizeChartId !== choice.databaseId)
        ) {
          updateData({ variables: { databaseId: choice?.databaseId } });
        } */

        newState.brand = choice;
      } else if (typeof type === "object") {
        // It's size and regions combined into an object

        const typeKeys = Object.keys(type);

        for (const typeKey of typeKeys) {
          if (typeKey === "size") {
            const newValue = type[typeKey].toString();

            let currentState = state.size || [];
            if (typeof currentState === "string") {
              currentState = [currentState];
            }
            currentState = currentState
              .filter(Boolean)
              .map((s) => s.toString());
            if (currentState.includes(newValue)) {
              currentState = currentState.filter((item) => item !== newValue);
            } else {
              currentState.push(newValue);
            }

            type[typeKey] = currentState;
          }
          if (type[typeKey]) {
            cookie.save(typeKey, type[typeKey], cookieArgs);
          }
        }
        newState = {
          ...newState,
          ...type,
        };
      } else {
        // Disabled as this throws an error even though functionality works correctly: presumably it's not actually an error
        //  console.error("don't recognise type:", type);
      }
      if (type !== "Colour") {
        console.log("Enqueing to update on server", choice, type);

        updateSizingOnServer({ newState, choice, type });
        newState.updateEnqueued = true;
      }
      setState(newState);
    },
  };
}

useSizeAndSex.propTypes = {
  dealsLocale: PropTypes.string.isRequired,
};
