import React from "react";

import Link from "next/link";

import Chip from "@mui/material/Chip";

import { styledChipStyle } from "components/Tag/StyledChip.js";

import { makeStyles } from "tss-react/mui";
import { whiteHover } from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  ...styledChipStyle(theme),
  whiteHover,
  unsetWhitespace: {
    whiteSpace: "unset",
  },
}));

export default function LinkBadge(props) {
  const { name, href, color, customClasses, noAnchor, notClickable, ...rest } =
    props;
  const { classes, cx } = useStyles();

  const styledChipClasses = cx({
    [classes.styledChip]: true,
    [classes.whiteHover]: href,
    [customClasses]: customClasses,
  });
  const chipProps = {
    component: noAnchor || !href ? "" : "a",
    label: name,
    clickable: Boolean(!notClickable && href),
    disabled: notClickable,
    classes: { root: styledChipClasses, label: classes.unsetWhitespace },
    color: color || "primary",
    size: "small",
    ...rest,
  };

  return href ? (
    <Link prefetch={false} href={href} passHref legacyBehavior>
      <Chip {...chipProps} />
    </Link>
  ) : (
    <Chip {...chipProps} />
  );
}
