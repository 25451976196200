import { useHasMounted } from "../../modules/utils";

// Wrapper to prevent components mounting until the client has hydrated and we get client-only props which impact the UI. Prevents server rendered HTML mismatch

function ClientOnly({ children, bypass, ...delegated }) {
  const hasMounted = useHasMounted();

  if (bypass) {
    return children;
  }
  if (!hasMounted) {
    return null;
  }

  return <div {...delegated}>{children}</div>;
}

export default ClientOnly;
