import React from "react";

import { NextSeo } from "next-seo";

//import { JSONLD, Generic } from "react-structured-data";
import { gql } from "@apollo/client";

// To be implimented
// import generateSocialImage from 'modules/generateSocialImage';

import {
  addPageNumberToTitle,
  useRenders,
  addPossibleQueryParams,
} from "modules/utils";

import PropTypes from "prop-types";

import {
  year,
  seoTitleAppender,
  OG_IMAGE_DIMENSIONS,
} from "settings/global-website-config";

import { ROOT_URL } from "settings/global-urls";
import productsTitleOrganiser from "modules/products/productsTitleOrganiser";

export function PageSeo(props) {
  const {
    seo,
    count,
    isProduct,
    ogContent,
    isCustomQuery,
    t,
    fallbackMetaDesc,
    page,
    pageTitle,
    profileImage,
  } = props;
  // prevent indexing of fallback pages
  useRenders("seo");

  if (!seo) {
    return <div />;
  }

  if (parseInt(process.env.NEXT_PUBLIC_SHOW_SEO_OBJECT)) {
    console.log("[SEO]", seo);
  }

  // check if we included title & metadesc from the CMS, otherwise pull them from the translation json
  const type = seo.itemType?.toLowerCase();

  const title = isProduct
    ? addPageNumberToTitle(
        productsTitleOrganiser(
          seo.titleContent,
          count || 0,
          isCustomQuery,
          t,
          null
        ) + seoTitleAppender,
        page
      )
    : seo.title ||
      (t &&
        type &&
        t(`common:title.${type}`, {
          content: seo.titleContent,
          count,
          date: year,
        }) + seoTitleAppender);

  const metaDesc =
    seo.metaDesc ||
    (t &&
      type &&
      t(`common:metaDesc.${type}`, {
        content: seo.titleContent,
        count,
      })) ||
    fallbackMetaDesc;

  const fullPath = ROOT_URL + seo.path;

  const baseImageUrl = [ROOT_URL, "api", "og"].join("/");
  const ogTitle =
    ogContent?.title || pageTitle || seo.titleContent || seo.title;

  const ogImages = { ...OG_IMAGE_DIMENSIONS };

  const ogImageParams = {
    title: ogTitle,
  };

  if (seo?.featuredImage?.node.sourceUrl) {
    ogImageParams.url = seo.featuredImage.node.sourceUrl;
  }
  if (profileImage) {
    ogImageParams.profileImageUrl = profileImage;
  }

  if (ogContent?.subtitles?.length) {
    // Not suitable for use with og:image
    ogImageParams.subtitles = ogContent.subtitles
      .map((b) => b.subtitle)
      .join(",");

    if (ogContent.subtitles.some((b) => b.bullet)) {
      ogImageParams.bullets = ogContent.subtitles
        .map((b) => b.bullet || "")
        .join(",");
    }
  }

  const imageUrl = addPossibleQueryParams(baseImageUrl, ogImageParams);

  ogImages.url = imageUrl;

  if (parseInt(process.env.NEXT_PUBLIC_SHOW_OG_IMAGE_URL)) {
    console.log(
      "[Seo] |SEO (disable NEXT_PUBLIC_SHOW_OG_IMAGE_URL to hide):",
      seo,
      "ogImages:",
      ogImages
    );
  }

  //         noindex={seo.metaRobotsNoindex === '1' || isCustomQuery ? true : ''}

  // hacky workaround to prevent this markup appearing on Post pages, as it's included within the existing BlogJsonLd for those pages. To be replaced:

  // TODO: Pretty sure this isn't working properly where SEO isn't present on immediate page launch. To do with seo not being present on first render, it doesn't then update on page source.

  return (
    <>
      <NextSeo
        title={title}
        description={metaDesc}
        canonical={fullPath}
        noindex={seo.metaRobotsNoindex !== "index" || isCustomQuery}
        nofollow={seo && seo.metaRobotsNofollow !== "follow"}
        openGraph={{
          url: fullPath,
          title,
          description: metaDesc,
          images: [ogImages],
        }}
      />
      {/*  {seo.itemType !== "post" && seo.itemType !== "guide" && (
        <JSONLD dangerouslyExposeHtml>
          <Generic
            type="webPage"
            jsonldtype="WebPage"
            schema={{ name: title, description: seo.metaDesc }}
          />
        </JSONLD>
      )} */}
    </>
  );
}

PageSeo.propTypes = {
  seo: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const SEOINNERFRAGMENT = gql`
  fragment SeoInnerFragment on PostTypeSEO {
    title
    metaDesc
    metaRobotsNoindex
    metaRobotsNofollow
  }
`;

export const SEOFRAGMENT = gql`
  fragment SeoFragment on UniformResourceIdentifiable {
    ... on NodeWithFeaturedImage {
      featuredImage {
        node {
          __typename
          id
          altText
          sourceUrl(size: LARGE)
          mediaDetails {
            height
            width
          }
        }
      }
    }
    ... on Family {
      # Not included on nodeWithFeaturedImage as pulled from children
      featuredImage {
        node {
          __typename
          id
          altText
          sourceUrl(size: LARGE)
          mediaDetails {
            height
            width
          }
        }
      }
    }
    ... on ContentNode {
      __typename
      seo {
        ...SeoInnerFragment
      }
    }
    ... on Category {
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
      }
    }
  }
  ${SEOINNERFRAGMENT}
`;
