import React from "react";

import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import Divider from "@mui/material/Divider";

import Button from "components/CustomButtons/Button";
import { hexToRgb } from "@mui/material";

import { grayColor, rotate180 } from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  multiDropdownItem: { overflow: "visible", padding: 0 },

  manager: {
    /* '& > div > button:first-of-type > span:first-of-type, & > div > a:first-of-type > span:first-of-type':
      {
        width: '100%',
      }, */
    // marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      //   marginLeft: theme.spacing(1),
    },
  },

  target: {
    "& > button:first-of-type > span:first-of-type, & > a:first-of-type > span:first-of-type":
      {
        display: "inline-block",
      },
    "& $caret": {
      marginLeft: "0px",
    },
  },
  menuList: {
    padding: theme.spacing(0.5, 0),
  },
  dropdownItem: {
    // ...defaultFont,
    fontSize: "14px",
    padding: "10px 20px",
    margin: theme.spacing(0, 0.5),
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    // fontWeight: '400',
    height: "100%",
    //  color: grayColor[8],
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  hoverColors: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      "& svg": { color: theme.palette.common.white },
    },
  },

  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: `rgba(${hexToRgb(theme.palette.common.black)}, 0.12)`,
    height: "1px",
    overflow: "hidden",
  },
  buttonIcon: {
    width: "20px",
    height: "20px",
  },
  caret: {
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "4px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },
  rotate180,
  /* caretActive: {
    transform: 'rotate(180deg)',
  },
  caretDropup: {
    transform: 'rotate(180deg)',
  }, */
  dropdownHeader: {
    display: "block",
    padding: "0.1875rem 1.25rem",
    fontSize: "0.75rem",
    lineHeight: "1.428571",
    color: grayColor[10],
    whiteSpace: "nowrap",
    fontWeight: "inherit",
    marginTop: "10px",
    minHeight: "24px",
    "&:hover,&:focus": {
      backgroundColor: "transparent",
      cursor: "auto",
    },
  },
  noLiPadding: {
    padding: "0",
  },
}));

export default function CustomDropdown(props) {
  const {
    buttonText,
    buttonIcon,
    dropdownList,
    buttonProps,
    dropup,
    dropdownHeader,
    caret,
    noLiPadding,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (anchorEl?.contains && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (event) => {
    if (anchorEl?.contains && anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };
  const handleCloseMenu = (param) => {
    setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(param);
    }
  };

  const { classes, cx } = useStyles();

  const caretClasses = cx({
    [classes.caret]: true,
    [classes.rotate180]:
      (dropup && !anchorEl) || (Boolean(anchorEl) && !dropup),
    // [classes.rotate180]:,
  });
  const dropdownItem = cx({
    [classes.dropdownItem]: true,
    [classes.hoverColors]: true,
    [classes.noLiPadding]: noLiPadding,
  });

  return (
    <div className={classes.manager}>
      <div className={buttonText !== undefined ? "" : classes.target}>
        <Button
          aria-label="Notifications"
          aria-owns={anchorEl ? "menu-list" : null}
          aria-haspopup="true"
          disableRipple
          {...buttonProps}
          onClick={handleClick}
        >
          {buttonIcon !== undefined ? (
            <props.buttonIcon className={classes.buttonIcon} />
          ) : null}
          <span>{buttonText !== undefined ? buttonText : null}</span>
          {caret ? <b className={caretClasses} /> : null}
        </Button>
      </div>
      <Menu
        role="menu"
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        classes={{ list: classes.menuList }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          // Gives the arrow pointing towards the button:
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {dropdownHeader !== undefined ? (
          <MenuItem
            onClick={() => handleCloseMenu(dropdownHeader)}
            className={classes.dropdownHeader}
          >
            {dropdownHeader}
          </MenuItem>
        ) : null}
        {dropdownList.map((prop, key) => {
          if (prop) {
            // TODO: Should be removed
            if (prop.divider) {
              return (
                <Divider
                  key={key}
                  onClick={() => handleCloseMenu("divider")}
                  className={classes.dropdownDividerItem}
                />
              );
            } else if (
              prop.props !== undefined &&
              prop.props["data-ref"] === "multi"
            ) {
              return (
                <MenuItem
                  key={key}
                  className={`${dropdownItem} ${classes.multiDropdownItem}`}
                >
                  {prop}
                </MenuItem>
              );
            } else if (prop.disabled) {
              return prop.content;
            }
            return (
              <MenuItem
                key={key}
                onClick={() => handleCloseMenu(prop)}
                className={dropdownItem}
              >
                {prop}
              </MenuItem>
            );
          }
        })}
      </Menu>
      {/* <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={dropPlacement}
        className={cx({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
          [classes.popperNav]: Boolean(anchorEl) && navDropdown,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            classes={{
              root: dropup
                ? classes.dropUpTransformOrigin
                : classes.noDropUpTransformOrigin,
            }}
          >
            <Paper className={classes.dropdown}>
              {innerDropDown ? (
                dropDownMenu
              ) : (
                <ClickAwayListener onClickAway={handleClose}>
                  {dropDownMenu}
                </ClickAwayListener>
              )}
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </div>
  );
}

CustomDropdown.defaultProps = {
  dropup: false,
};

CustomDropdown.propTypes = {
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.object,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  caret: PropTypes.bool,
  dropPlacement: PropTypes.oneOf([
    "bottom",
    "top",
    "right",
    "left",
    "bottom-start",
    "bottom-end",
    "top-start",
    "top-end",
    "right-start",
    "right-end",
    "left-start",
    "left-end",
  ]),
  noLiPadding: PropTypes.bool,
  innerDropDown: PropTypes.bool,
  navDropdown: PropTypes.bool,
  // This is a function that returns the clicked menu item
  onClick: PropTypes.func,
};
