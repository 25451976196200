//import Badge from './Badge';
import StyledChip from "components/Tag/StyledChip";
import { Box } from "@mui/material";

const BetaBadgeWrapper = (props) => {
  const { children } = props;

  return (
    <Box sx={{ "& div >": { mt: -1 } }}>
      {children}
      <StyledChip basicStyling notClickable color="secondary" label="BETA" />
    </Box>
  );
};

export default BetaBadgeWrapper;
