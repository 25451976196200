import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import parse from "html-react-parser";
//import Button from '@mui/material/Button';

//import { getMockupDate } from '../../strava/StravaMockupScreen';

export default function EmailMockup(props) {
  const { user } = props;
  const product = user.athleteProducts.edges[0].product;
  //  const date = getMockupDate();

  const price = "£64.99";
  return (
    <Box sx={{ "& a": { textDecoration: "underline" } }}>
      <Box>
        <strong>Subject: </strong>
        {`📈 Your ${product.title} needs replacing. ${price} is today's best price`}
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ mb: 2 }}>
        <p>{`You've run 406 miles in your ${product.title}.`}</p>
        <p>
          {`The best price to replace them in Size 9 today is `}
          <strong>{price}</strong>
          {" from "}
          <a href="#">Sportsshoes.com</a>.
        </p>
        {/*  <Button>{'Get deal'}</Button> */}
      </Box>
      <Box>
        <p>
          <em>⚠️ Cheaper model available:</em>
        </p>
        <p>
          {"The previous model (GT 2000 9) is currently available cheaper at "}
          <strong>£59.99</strong>
          {" from "}
          <a href="#">Wiggle.com</a>.
        </p>
      </Box>
    </Box>
  );
}

export function EmailRecommendationMockup(props) {
  const { user } = props;
  const productObject = user.athleteProducts.edges[3];

  const { product, recommendedProducts } = productObject;

  console.log(recommendedProducts);
  const price = "$74.99";
  return (
    <Box
      sx={{
        maxHeight: "220px",
        overflow: "hidden",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "50px", // Adjust the height of the gradient as needed
          background:
            "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
        },
      }}
    >
      <Box>
        <strong>Subject: </strong>
        {`📉 Price alert! ${product.title} is now ${price} at Holabird Sports in US size 10`}
      </Box>
      <Divider sx={{ my: 1 }} />

      <Box>
        <p>
          <em>Looking for a change? 3 comparable products</em>
        </p>
        {/*  <p>
          {`If you're looking to try something similar to the ${product.title}, check out these alternatives, available in your size today:`}
        </p> */}
        <table>
          {recommendedProducts.map((rp) => (
            <tr key={rp.title}>
              <td>
                <Box>
                  <a href="">{rp.title}</a>
                </Box>
                <Box sx={{ "& a": { textDecoration: "underline" } }}>
                  <strong>$59.99</strong> from <a href="#">Lovell Sports</a>
                </Box>
              </td>
              <td>{parse(rp.content)}</td>
            </tr>
          ))}
        </table>
      </Box>
    </Box>
  );
}
