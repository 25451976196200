import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Divider from "@mui/material/Divider";
import useTranslation from "next-translate/useTranslation";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  dividerContainer: {
    display: "flex",
    alignItems: "center",
  },
  dividerItem: {
    textAlign: "center",
    "& h4": { margin: "0px" },
  },
});

export default function OrDivider() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <GridContainer className={classes.dividerContainer}>
      <GridItem xxs={5} sm={5} md={5} className={classes.dividerItem}>
        <Divider />
      </GridItem>
      <GridItem xxs={2} sm={2} md={2} className={classes.dividerItem}>
        <h4>{t("nav:or")}</h4>
      </GridItem>
      <GridItem xxs={5} sm={5} md={5} className={classes.dividerItem}>
        <Divider />
      </GridItem>
    </GridContainer>
  );
}
