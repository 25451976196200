// TODO: Move this into WP Options table

const twitterHandle = "@geerlyhq";

const supportedSocialChannels = [
  { name: "strava", url: "https://strava.com/clubs/geerly" },
  { name: "twitter", url: "https://twitter.com/geerlyhq" },
  { name: "instagram", url: "https://instagram.com/geerlyhq" },
  { name: "facebook", url: "https://facebook.com/geerlyhq" },
  {
    name: "spotify",
    url: "https://open.spotify.com/user/31abjzve5dxi5xx7gkffrivho4fy",
  },
];

module.exports = {
  supportedSocialChannels,
  twitterHandle,
};
