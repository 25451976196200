/* eslint-disable no-param-reassign */
import clonedeep from "lodash.clonedeep";

const { initializeApollo } = require("./apolloClient.js");

const PropTypes = require("prop-types");

function staticDbLoader(
  query,
  variables,
  options,
  accessToken,
  refreshToken,
  expiresAt
) {
  const useBasicAuth = options?.useBasicAuth;

  const apolloClient = initializeApollo(
    null,
    accessToken,
    refreshToken,
    expiresAt,
    useBasicAuth
  );
  if (parseInt(process.env.SHOW_GRAPHQL_QUERY)) {
    console.log("[CMS] | Showing query in dev mode", query);
  }

  if (parseInt(process.env.SHOW_GRAPHQL_VARIABLES)) {
    console.log(
      "[CMS] | Showing variables in dev mode",
      JSON.stringify(variables, null, 2),
      "| useBasicAuth:",
      useBasicAuth
    );
  }
  let args;

  if (options && options.type === "mutate") {
    args = { mutation: query, variables };
  } else args = { query, variables };

  let fetchedData;
  try {
    fetchedData = apolloClient[(options && options.type) || "query"](args).then(
      (result) => {
        let { data, errors } = result;

        if (errors) {
          console.error(
            `[Static DB] | GraphQl fetch error: ${JSON.stringify(errors)}`
          );
          return { error: errors[0] };
        }

        if (parseInt(process.env.SHOW_GRAPHQL_RESULT)) {
          console.log("[Static DB] | Showing graphql result in dev mode", data);
        }
        // Because this fn is only used in the build stage, we don't care about immutability. So we return a clone of the data to override Apollo's immutability
        data = clonedeep(data);
        return { data };
      }
    );
  } catch (error) {
    console.log(
      "[Static DB] | Caught getStaticData error | Query: ",
      query,
      " | Variables:",
      variables,
      " | Error:",
      error
    );
    return { error };
    // throw new Error('Error retrieving data from CMS');
  }

  return fetchedData;
}
staticDbLoader.propTypes = {
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  accessToken: PropTypes.string,
  refreshToken: PropTypes.string,
  expiresAt: PropTypes.number,
};

export default staticDbLoader;
