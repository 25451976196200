import React from "react";
import PropTypes from "prop-types";
//import { useSyncScroller } from 'utils/useScrollSync';

import { ScrollSync, ScrollSyncNode } from "scroll-sync-react";

import { makeStyles } from "tss-react/mui";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import FadeOverlay from "components/Transition/FadeOverlay.js";
import CustomFade from "components/Transition/CustomFade.js";
import ScrollToEndButton from "components/CustomButtons/ScrollToEndButton";

import { useRenders } from "modules/utils";

import {
  grayColor,
  verticalMiddle,
  defaultFont,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  hideColumn: {
    display: "none",
  },
  subheadingCell: {
    borderBottom: `1px solid ${grayColor[6]} !important`,
    borderTop: `1px solid ${grayColor[6]} !important`,
    padding: "4px 8px",
    height: "14px",
    maxHeight: 10,
    "& h6": { margin: 0 },
    backgroundColor: grayColor[2],
  },
  showDealsSwitchContainer: {
    padding: "0px", // Setting to 1px covers  very thin grey line underneath but causes issues with border
    position: "absolute !important",
    //  border: 'none',
    width: "100%",
    backgroundClip: "padding-box",
    borderBottom: "1px solid white",
    borderRadius: "6px 6px 0px 0px",
    fontWeight: "400",
    color: theme.palette.primary.main,
    //    backgroundColor: grayColor[2],
    //  verticalAlign: 'middle', Removed as it interferes with deal blocks. Add elsewhere if needed back
    //  position: 'sticky',
    zIndex: 15,
    // left: 0,
    // backgroundClip: 'padding-box',
  },
  verticalMiddle,
  dealsRow: {
    height: "135px", // Should really be minHeight but not working ?
  },
  tableScrollable: {
    overflowBehavior: "contain",
    display: "block",
    overflow: "auto",
    marginBottom: "-1px", // Prevents a thin line being visible under the scroll block. Should be necessary, should look for the root cause
    // paddingRight: '20px', // So that if you scroll to the end, the last item doesnt sit under the fade overlay
    borderRadius: "5px 5px 0px 0px",
  },
  hideScrollBar: {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": { display: "none" },
  },

  stickyHeader: {
    zIndex: 99,
    position: "sticky",
    top: "-50px", // was -65
    [theme.breakpoints.down("md")]: {
      //  top: '-20px',
    },
  },
  stickyButtonContainer: {
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 190, // 200 puts it above the deal bar on mobile
    pointerEvents: "none",
  },
  // fullWidthFooter: { position: 'absolute' }, // no pointer events to allow swipe scolling etc the underlying table
  footerCell: {
    textAlign: "center",
    backgroundColor: grayColor[2],
    borderTop: "1px solid white !important",
    borderBottom: "none",
    borderRadius: "0px 0px 5px 5px",
    paddingTop: "20px",
    "& h6": { position: "unset", marginBottom: "4px" },
  },
  fullWidthCell: {
    width: "100vw",
  },

  childColumn: {
    "& a": {
      fontSize: "14px",
      //   color: grayColor[4]
    },
  },
  shortCells: {
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    "& a": {
      margin: "4px 2px",
    },
  },
  tableFixed: {
    tableLayout: "fixed",
  },
  padding4px: {
    padding: "4px !important",
  },
  tableCellStandard: {
    padding: "20px 8px",
    //  borderTop: 'none',
    // borderBottom: '1px solid ' + grayColor[6],
  },
  minWidth150: { minWidth: "150px" },
  tableCell: {
    ...defaultFont,
    //   width: '100vw',
    //transition: 'all 0.3s ease-in-out', // Was included to allow cell to grow when deals shown but caused flickering when transitioning widths
    lineHeight: "1.5em",
    /// verticalAlign: 'middle',
    fontSize: "0.875rem",

    // position: 'relative', Prevents fixed left hand column if enabled
    color: grayColor[1],
    border: "none",
  },
  tableHeadCell: {
    fontSize: "1.063rem",
    // borderBottomWidth: '1px',
    color: grayColor[15],
    //borderTopWidth: '0',
    verticalAlign: "top",
    background: theme.palette.common.white,
    padding: "8px",
  },
  tableStripedRow: {
    backgroundColor: grayColor[16],
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: grayColor[23],
    },
  },
}));
function CustomTable(props) {
  const {
    hideFirstColumn,
    hover,
    colorsColls,
    coloredColls,
    shortCells,
    customCellClasses,
    customClassesForCells,
    customThinLineClassesForCells,
    striped,
    customHeadCellClasses,
    customHeadClassesForCells,
    uri,
    tableHead,
    tableArray,
    footerArray,
    scrollable,
    showDeals,
    isChildColumn,
    tableName,
    containerWidth,
  } = props;

  const { classes, cx } = useStyles();

  useRenders(`table: ${tableName}`);

  /*  console.log(
    'tableHead',
    tableHead,
    'tableArray',
    tableArray,
    'footerArray',
    footerArray,
    'relatedProducts',
    relatedProducts
  ); */

  const maxWidth = containerWidth; //

  const listInnerRef = React.useRef();

  const hasScrolledToEnd = () => {
    if (listInnerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = listInnerRef.current;

      return scrollLeft + clientWidth >= scrollWidth;
    }
  };

  const hasScrolled = () => {
    if (listInnerRef.current) {
      const { scrollLeft } = listInnerRef.current;

      return scrollLeft === 0;
    }
  };

  const [scrollState, setScrollState] = React.useState({
    scrolledToEnd: hasScrolledToEnd(),
    scrolled: hasScrolled(),
  });

  const { scrolledToEnd, notOverflowing, scrolled } = scrollState;

  // const [hasScrolled, setHasScrolled] = React.useState(hasScrolledToEnd());

  React.useEffect(() => {
    const isOverflowing = listInnerRef.current
      ? listInnerRef.current.clientWidth < listInnerRef.current.scrollWidth
      : false;

    if (!isOverflowing) {
      setScrollState({ notOverflowing: true });
    } else {
      // Is overflowing

      const checkScrolled = hasScrolledToEnd();
      const checkHasScrolled = hasScrolled();

      if (checkScrolled !== scrolledToEnd || checkHasScrolled !== scrolled) {
        setScrollState({
          ...scrollState,
          notOverflowing: false,
          scrolledToEnd: checkScrolled,
          scrolled: checkHasScrolled,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listInnerRef.current?.clientWidth, tableArray]);

  const onScroll = () => {
    if (!scrollable) {
      return;
    }
    const newState = { ...scrollState };
    let updateScrollState = false;
    if (hasScrolledToEnd()) {
      if (!scrolledToEnd) {
        newState.scrolledToEnd = true;
        updateScrollState = true;
      }
    } else {
      if (scrolledToEnd) {
        newState.scrolledToEnd = false;
        updateScrollState = true;
      }
    }

    if (hasScrolled()) {
      if (!scrolled) {
        newState.scrolled = true;
        updateScrollState = true;
      }
    } else {
      if (scrolled) {
        newState.scrolled = false;
        updateScrollState = true;
      }
    }

    if (updateScrollState) {
      setScrollState(newState);
    }
  };

  const tableClasses = cx({ [classes.tableFixed]: scrollable });

  return (
    <>
      {scrollable && !notOverflowing && (
        <>
          <FadeOverlay orientation="vertical" inProp={!scrolledToEnd} />
          <div
            id="scrollToEndButtonContainer"
            className={classes.stickyButtonContainer}
          >
            <CustomFade inProp={!scrolledToEnd}>
              <ScrollToEndButton
                direction="end"
                containerId="comparisonTableRow"
              />
            </CustomFade>
            <CustomFade inProp={!scrolled}>
              <ScrollToEndButton
                direction="start"
                containerId="comparisonTableRow"
              />
            </CustomFade>
          </div>
        </>
      )}
      <ScrollSync disabled={!scrollable}>
        <Table className={tableClasses}>
          {tableHead !== undefined ? (
            <TableHead
              id="comparisonTableHeader"
              className={classes.stickyHeader}
            >
              <ScrollSyncNode>
                <TableRow
                  id="comparisonTableRow"
                  className={cx({
                    setMaxWidth: scrollable,
                    [classes.tableRow]: true,
                    [classes.tableScrollable]: scrollable,
                    [classes.hideScrollBar]: scrollable,
                    // [classes.greyBorderBottom]: scrollable,
                  })}
                  onScroll={onScroll}
                  ref={listInnerRef}
                >
                  {tableHead.map((headerCell, index) => {
                    const tableCellClasses = `${classes.tableHeadCell} ${
                      classes.tableCell
                    } ${cx({
                      [customHeadCellClasses[
                        customHeadClassesForCells.indexOf(index)
                      ]]: customHeadClassesForCells.indexOf(index) !== -1,
                      // If we've assigned it a parentChildArray, add the appropriate class here
                      //  [classes[parentChildColumnVersion[index - 1]]]:
                      //     classes[parentChildColumnVersion[index - 1]],
                      [classes.childColumn]: isChildColumn[index - 1],
                      [classes.hideColumn]: hideFirstColumn && index === 0,
                      [classes.fullWidthCell]: true, // Needed for comparison blocks when only a couple products...does it break anything?
                      [classes.minWidth150]: scrollable,
                    })}`;
                    return (
                      <TableCell
                        className={tableCellClasses}
                        key={`header-${index}`}
                      >
                        {headerCell}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </ScrollSyncNode>
            </TableHead>
          ) : null}
          <ScrollSyncNode>
            <TableBody
              className={cx({
                setMaxWidth: scrollable,
                [classes.tableScrollable]: scrollable,
              })}
            >
              {tableArray?.map((rowCell, index) => {
                {
                  /* let rowColor = '';
                let rowColored = false;
                if (rowCell.color !== undefined) {
                  rowColor = rowCell.color;
                  rowColored = true;
                  rowCell = rowCell.data;
                } */
                }

                const tableRowClasses = cx({
                  [classes.tableRowHover]: hover,
                  // [classes[`${rowColor}Row`]]: rowColored,
                  [classes.tableStripedRow]: striped && index % 2 === 0,
                });

                const isDealsRow = rowCell[0] === "bestDeal";

                return (
                  <TableRow
                    key={`table-${uri}-row-${index}`}
                    hover={hover}
                    className={tableRowClasses}
                  >
                    {rowCell.map((prop, key) => {
                      const isShowDealsSwitch =
                        isDealsRow && key === 1 && !showDeals;

                      const tableCellClasses = cx({
                        [classes.showDealsSwitchContainer]: isShowDealsSwitch,
                        [classes.fullWidthCell]: !isShowDealsSwitch,
                        [classes.tableCellStandard]:
                          !isShowDealsSwitch && !prop?.isSubheading,
                        [classes.dealsRow]: isDealsRow && showDeals,
                        [classes.tableCell]: true,
                        [classes.minWidth150]: scrollable,
                        [classes.shortCells]: shortCells && !prop?.isSubheading,
                        [classes.padding4px]:
                          customThinLineClassesForCells.includes(index),
                        [classes.subheadingCell]: prop?.isSubheading,
                        [classes[colorsColls[coloredColls.indexOf(key)]]]:
                          coloredColls.includes(key),
                        [customCellClasses[customClassesForCells.indexOf(key)]]:
                          customClassesForCells.includes(key),
                        [classes.childColumn]: isChildColumn[key - 1],
                        [classes.hideColumn]: hideFirstColumn && key === 0,
                        //     [classes.verticalTop]: isDealsRow, // &&
                        [classes.verticalMiddle]: !isDealsRow, //prop === t
                      });

                      const tableCellProps = {
                        className: tableCellClasses,
                      };

                      if (prop?.colSpan) {
                        tableCellProps.colSpan = prop.colSpan;
                      }
                      return (
                        <TableCell key={`${index}-${key}`} {...tableCellProps}>
                          {prop?.content || prop}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </ScrollSyncNode>

          {footerArray && (
            <TableFooter id="comparison-footer">
              <TableRow
                className={`setFullWidth ${classes.fullWidthFooter} ${classes.tableRow}`}
              >
                <TableCell
                  className={`${classes.fullWidthCell} ${classes.footerCell}`}
                >
                  {footerArray[1]}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </ScrollSync>
      {maxWidth && (
        <style jsx>{`
          .setMaxWidth {
            max-width: ${maxWidth}px;
          }
          .setFullWidth {
            width: ${maxWidth}px !important;
          }
        `}</style>
      )}
    </>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  hover: false,
  colorsColls: [],
  coloredColls: [],
  striped: false,
  customCellClasses: [],
  customClassesForCells: [],
  customHeadCellClasses: [],
  customHeadClassesForCells: [],
  columnsToShow: {},
  parentChildColumnVersion: [],
  isChildColumn: [],
  customThinLineClassesForCells: [],
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
    "strava",
    "darkCustom",
  ]),
  tableHead: PropTypes.array, //Of(PropTypes.string) || PropTypes.arrayOf(PropTypes.object),
  // Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
  tableArray: PropTypes.array,
  hover: PropTypes.bool,
  //columnsToShow: PropTypes.array, // This is now dynamic based on uri keys so not sure we can use a proptype with it
  coloredColls: PropTypes.arrayOf(PropTypes.number),
  // Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
  colorsColls: PropTypes.array,
  customCellClasses: PropTypes.arrayOf(PropTypes.string),
  customClassesForCells: PropTypes.arrayOf(PropTypes.number),
  customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
  customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
  striped: PropTypes.bool,
  // this will cause some changes in font
  tableShopping: PropTypes.bool,
};
/* 
const areEqual = (prevProps, nextProps) => {
  const prevOpen = prevProps.columnsToShow[prevProps.uri];
  const nextOpen = nextProps.columnsToShow[nextProps.uri];
  const prevState = prevProps.state;
  const nextState = nextProps.state;

  const openSame = prevOpen?.sort().toString() == nextOpen?.sort().toString();

  const dealsSame =
    prevProps.dealsLoaded?.sort().toString() ==
    nextProps.dealsLoaded?.sort().toString();

  const override = false; // FALSE MEANS WILL ALWAYS RERENDER
  const stateSame =
    prevState?.sex === nextState?.sex &&
    prevState?.size === nextState?.size &&
    override;
  console.log(prevProps);
  console.log('Prev state', prevProps.state);
  console.log('Next state', nextProps.state);
  console.log(
    'dealsSame',
    dealsSame,
    'openSame',
    openSame,
    'stateSame',
    stateSame
  );
  return dealsSame && openSame && stateSame;
};

const CustomTableMemoized = React.memo(CustomTable, areEqual);

 */
// Memoization was added to prevent all tables re-rendering when one is paginated through. But it has been disabled as changes to size and sex state weren't properly evaluating - state was already updated when it came in as prevState. Leaving code here in case we need to revisit for performance reasons. Could do something where we extend dealsLoaded to capture value and compare that. But probably overkill as adding overhead

export default React.memo(CustomTable);
