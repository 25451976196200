import React from "react";
import CustomLink from "components/Links/CustomLink";

import StyledChip from "components/Tag/StyledChip";
import { useScreenSizes } from "components/Providers/ScreenSizesProvider";

import { makeStyles } from "tss-react/mui";

import Scroll from "react-scroll";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AddLoadingDotsToText from "components/Loading/AddLoadingDotsToText.js";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BuyButton from "components/CustomButtons/BuyButton.js";
import useTranslation from "next-translate/useTranslation";
import CircularProgress from "@mui/material/CircularProgress";
import { AffiliateLink } from "components/Links/AffiliateLink.js";
import { PriceRange } from "components/PriceComparison/PriceComparisonModule";
import CustomAlert from "../CustomAlert/CustomAlert";

import {
  zeroPadding,
  grayColor,
  displayFlex,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  zeroPadding,
  mainLinkText: {
    width: "max-content",
    //marginBottom: '0px',
    [theme.breakpoints.down("lg")]: {
      //   margin: '0px',
    },
  },
  mainLinkTextSmall: {
    marginBottom: "0px",
  },
  voucherCodeBlock: {
    display: "flex",
    margin: "0px",
  },
  lowStockContainer: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("lg")]: {
      //   order: 3,
    },
  },

  storeTitleContainer: {
    padding: "0px",
    // display: 'inline-flex', // had once been 'inline-flex' but already removed. FLEX allows it to be centered in bottomDealBar
    [theme.breakpoints.down("lg")]: {
      order: "0",
    },
  },
  displayFlex,
  mainPrice: {
    whiteSpace: "nowrap",
    textAlign: "right",
    marginTop: "0px",
    marginBottom: "0px",
    padding: "0px 10px 0px 0px",
    marginLeft: "auto",
    paddingRight: "0px",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  flexCenter: { justifyContent: "center" },
  rrp: {
    verticalAlign: "super",
    fontSize: "65%",
    "& h4": {
      fontSize: "12px",
    },
    textAlign: "right",
    margin: "0px",
    padding: "0px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "none",
    color: grayColor[0],
    textDecoration: "line-through",
    [theme.breakpoints.up("md")]: {
      display: "unset",
    },
    opacity: "0.6",
  },
  priceMeta: {
    zIndex: 1,
    display: "flex",
    padding: "0px",
    width: "100%",
    minHeight: "41px",
    flexWrap: "wrap",
    marginTop: theme.spacing(-1),
    "& h5": {
      fontSize: "14px",
      marginTop: "10px",
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      "& svg": { marginRight: "4px" },
      whiteSpace: "nowrap",
    },
  },
  delivery: {
    justifyContent: "center",
    color: grayColor[0],
    order: 3,
  },
  saving: {
    order: 2,
    // display: 'block',
    padding: "0px", // was 0px 20px 0px 0px
    color: theme.palette.secondary.main,
    fontWeight: "500",
    width: "100%",
    //order: 8, // was 2
    // flexWrap: 'wrap',
    marginTop: "-15px",
    "& h5": { fontSize: "14px", marginTop: "10px", marginBottom: "10px" },

    /* [theme.breakpoints.down('lg')]: {
      //   marginTop: '-10px',
      // order: 2,
      //  '& h5': { fontSize: '14px', marginTop: '10px', marginBottom: '10px' }
    }, */
  },
  flexJustifyLeft: { justifyContent: "flex-start" },
  marginLeft: { margin: theme.spacing(0, 0, 0, 2) },
  buttonItem: {
    paddingLeft: "0px",
    paddingRight: "0px",

    [theme.breakpoints.down("lg")]: {
      marginTop: "2px",
      marginBottom: "2px",
    },
  },
  circular: {
    color: theme.palette.common.white,
    "&svg": {
      display: "unset",
    },
  },
  mainDealListContainer: {
    alignItems: "center",
    display: "flex",
    // justifyContent: 'space-between',
    //  width: '100%',
    minHeight: "80px", // causing jump when deals
    // minHeight: '80px', // may need to reenable for wider screens
    "& h4": {
      fontSize: "18px",
      fontWeight: "400",
      // height: '30px'
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "unset",
    },
  },

  moreDealsListContainer: {
    alignItems: "center",
    alignContent: "start",
    display: "flex",
    "& h4": {
      fontSize: "16px",
    },
  },
  modelNameText: { fontSize: "14px", marginBottom: "5px" },
  responsiveDealTodayMessage: {
    color: grayColor[0],
  },
  lowStock: {
    color: theme.palette.secondary.main,
  },
  lowStockIcon: {
    fontSize: "14px",
    verticalAlign: "text-bottom",
  },
  lowestPriceEver: {
    color: theme.palette.secondary.main,
  },
}));

export const focusSizeSelect = (sizeSelectRef, productsList, isSmScreen) => {
  if (productsList && !isSmScreen) {
    Scroll.scroller.scrollTo("size-select", {
      //  smooth: true, // Smooth scrolling was causing issues, esp in Chrome, when scrolling from beneath the element when it has disappeared from viewport (would scroll from top of page). Decided best to not have smooth than one looking a bit broken
      //  animation: 'easeInOutQuint',
      offset: -80,
      //  containerId: 'page-body',
    });
  }
  sizeSelectRef.current.focus();
};

export const NoSizeSetMessage = (props) => {
  const { productsList, isSmScreen, sizeSelectRef, alertProps } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <CustomAlert {...alertProps} severity="warning">
      <span
        className={classes.setSizeButton}
        onClick={() => focusSizeSelect(sizeSelectRef, productsList, isSmScreen)}
      >
        {t("feeds:setSize")}
      </span>
      <span> {t("feeds:forPriceComparison")}</span>
    </CustomAlert>
  );
};
const AffiliateLinkWithProps = (linkProps) => {
  const { deal } = linkProps;
  if (deal.loading) return <span />;

  return <AffiliateLink {...linkProps} />;
};

export function DealsList(props) {
  const { classes, cx } = useStyles();

  const {
    loading,
    deal,
    bestDeal,
    type,
    onlyDeal,
    isVisible,
    product,
    sizeSelectRef,
    productsList,
    compact,
    noDealsMessage,
    noSizeSet,
    currency,
    bottomDealBar,
    alertProps,
  } = props;

  const { isXsScreen, isSmScreen, isMdScreen, isLgScreen } = useScreenSizes();

  const hideDealsTodayMsg = isXsScreen;

  const isFamily = type === "family";

  //const hidePricePoint = productsList ? 'md' : 'sm';

  const hidePrice = productsList ? isMdScreen : isSmScreen; ///useMediaQuery(theme.breakpoints.down(hidePricePoint));

  let displayPrice,
    displayDelivery,
    rrp,
    saving,
    lowStock,
    model,
    price,
    code,
    lowestPriceEver;

  if (deal && !deal.loading) {
    ({
      displayPrice,
      displayDelivery,
      rrp,
      saving,
      lowStock,
      model,
      lowestPriceEver,
      code,
      price,
    } = deal);
  }
  const { t } = useTranslation();

  if (!deal) {
    return <div />;
  }

  if (productsList) {
    rrp = null;
  }

  const affiliateLinkProps = { deal, product, currency };

  const savingClasses = cx({
    [classes.priceMeta]: true,
    [classes.saving]: true,
    // [classes.flexJustifyRight]: !productsList,
    [classes.flexJustifyLeft]: productsList,
    [classes.flexCenter]: !productsList,
    [classes.marginLeft]: !isVisible,
  });

  const voucherLabel = !onlyDeal
    ? t("product:voucher", {
        code: code?.toUpperCase(),
      })
    : t("product:voucherNoCode");

  const shouldShow = isVisible || isMdScreen;

  const showPriceMeta =
    !compact &&
    !productsList &&
    // !bottomDealBar && // Allow it to show in the bottom, though may need to refine which items can show
    isVisible &&
    (!bestDeal || (!isMdScreen && isVisible) || isMdScreen || isLgScreen); /* &&
  (!bestDeal || isMdScreen); */

  const from = "priceLink";
  return (
    <>
      <GridContainer
        className={
          bestDeal || onlyDeal
            ? classes.mainDealListContainer
            : classes.moreDealsListContainer
        }
      >
        {!loading && bestDeal && deal.noStock && !deal.noSizeSet ? (
          <GridItem className={classes.zeroPadding}>
            <h5>
              {deal.loading ? (
                <AddLoadingDotsToText>
                  {t(`product:lookingForDealsMessage`)}
                </AddLoadingDotsToText>
              ) : (
                t(`product:${noDealsMessage}`)
              )}
            </h5>
          </GridItem>
        ) : (
          <>
            {hidePrice && bottomDealBar && !hideDealsTodayMsg && (
              <>
                <GridItem xxs={2}>
                  <h6 className={classes.responsiveDealTodayMessage}>
                    {t("product:dealTodayHeaderText")}
                  </h6>{" "}
                  {lowestPriceEver && !compact && (
                    <h5 className={classes.lowestPriceEver}>
                      {t("product:lowestPriceEver")}
                    </h5>
                  )}
                </GridItem>
              </>
            )}
            <GridItem
              xxs={hideDealsTodayMsg || isVisible || bottomDealBar ? 8 : 6}
              xs={hideDealsTodayMsg || isVisible || bottomDealBar ? 8 : 6}
              sm={bottomDealBar ? 8 : 6} // was
              md={isVisible ? (productsList ? 8 : 6) : 12 /* was 4 */}
              lg={isVisible ? 6 : 7}
              className={cx({
                [classes.storeTitleContainer]: true,
                [classes.displayFlex]: !isFamily,
              })}
            >
              <h4
                className={cx({
                  [classes.mainLinkText]: true,
                  [classes.mainLinkTextSmall]: isFamily,
                })}
              >
                {!deal.noSizeSet ? (
                  !deal.noStock ? (
                    <AffiliateLinkWithProps
                      {...affiliateLinkProps}
                      text={deal && !deal.loading && deal.storeName}
                      from="storeNameLink"
                    />
                  ) : (
                    <span>{deal.storeName}</span>
                  )
                ) : (
                  <NoSizeSetMessage
                    sizeSelectRef={sizeSelectRef}
                    productsList={productsList}
                    isSmScreen={isSmScreen}
                    alertProps={alertProps}
                  />
                )}
              </h4>
              {/* <span className={classes.voucherCodeBlock}>
          {code && (
            <StyledChip label={voucherLabel} color="strava" notClickable />
          )}
        </span>*/}
              <span className={classes.voucherCodeBlock}>
                {code && (
                  <StyledChip
                    label={voucherLabel}
                    color="strava"
                    notClickable
                  />
                )}
              </span>
              <div className={classes.modelNameText}>
                {!compact && model && (
                  <CustomLink href={model.uri}>
                    {model.noBrandTitle || model.title}
                  </CustomLink>
                )}
              </div>
            </GridItem>
            {!hidePrice && (
              <GridItem
                xxs={12}
                sm={deal.noSizeSet ? 6 : 2}
                md={deal.noSizeSet ? 6 : 3}
                lg={
                  shouldShow ? (deal.noSizeSet ? 6 : !productsList ? 3 : 3) : 0
                }
                className={`${classes.mainPrice} mainPrice`}
              >
                <h4>
                  {" "}
                  {!deal.noSizeSet && price ? (
                    <AffiliateLinkWithProps
                      {...affiliateLinkProps}
                      text={!loading && !compact && displayPrice}
                      hideIcon
                      from={from}
                    />
                  ) : (
                    <PriceRange deals={deal} />
                  )}
                  {shouldShow &&
                    !loading &&
                    !compact &&
                    !productsList &&
                    !deal.noSizeSet &&
                    price &&
                    rrp !== displayPrice &&
                    rrp && <div className={`${classes.rrp} rrp`}>{rrp}</div>}
                </h4>
              </GridItem>
            )}{" "}
            {!loading && !deal.noSizeSet && (
              <GridItem
                xxs={4}
                sm={shouldShow && !bottomDealBar ? 6 : 2}
                md={shouldShow ? (productsList ? 4 : 3) : 12}
                lg={shouldShow ? 3 : 8}
                className={`${classes.buttonItem} buttonItem`}
              >
                <BuyButton
                  fullWidth
                  bold={bestDeal && !onlyDeal}
                  deal={deal}
                  product={product}
                  from={from}
                  currency={currency}
                >
                  {deal && deal.loading ? (
                    <CircularProgress size={21} className={classes.circular} />
                  ) : shouldShow &&
                    ((!hidePrice && isVisible) || deal.noStock) &&
                    !compact ? (
                    <AffiliateLinkWithProps
                      {...affiliateLinkProps}
                      text={
                        price
                          ? t("product:buyNow")
                          : noSizeSet
                          ? t("product:noSizeSet")
                          : deal.noStock
                          ? t("product:noStock")
                          : t("product:checkPrice")
                      }
                      from={from}
                      hideIcon={!price || (!noSizeSet && !deal.noStock)}
                    />
                  ) : (
                    <AffiliateLinkWithProps
                      {...affiliateLinkProps}
                      text={!loading && !compact && displayPrice}
                      from={from}
                    />
                  )}
                </BuyButton>
              </GridItem>
            )}
            {showPriceMeta && (
              <>
                <GridItem
                  xxs={hideDealsTodayMsg || isVisible ? 4 : 6}
                  sm={4}
                  md={6 /* was 4 */}
                  lg={6}
                  className={
                    classes.priceMeta +
                    " " +
                    classes.lowStockContainer +
                    " lowStock"
                  }
                >
                  {lowStock && !compact && price && (
                    <h5 className={classes.lowStock}>
                      {" "}
                      <ErrorOutlineIcon className={classes.lowStockIcon} />
                      {t("product:lowStock")}
                    </h5>
                  )}
                </GridItem>

                <GridItem
                  xxs={productsList ? 8 : 4}
                  md={productsList ? 8 : 3}
                  lg={productsList ? 8 : 3}
                  className={`${savingClasses} saving`}
                >
                  {saving && <h5>{t("product:saving", { saving })}</h5>}
                </GridItem>
                <GridItem
                  xxs={4}
                  sm={shouldShow && !bottomDealBar ? 4 : 2}
                  md={productsList ? 4 : shouldShow ? 3 : 12}
                  lg={shouldShow ? 3 : 8}
                  className={
                    classes.priceMeta + " " + classes.delivery + " delivery"
                  }
                >
                  {!compact && price && displayDelivery && (
                    <h5>
                      <LocalShippingIcon fontSize="small" /> {displayDelivery}
                    </h5>
                  )}
                </GridItem>
              </>
            )}
          </>
        )}
      </GridContainer>
    </>
  );
}
