import { year } from "settings/global-website-config";
/* eslint-disable no-nested-ternary */

export default function productsTitleOrganiser(
  parsedTitle,
  pageTotal,
  isCustomQuery,
  t,
  page
) {
  const title = !isCustomQuery
    ? t(`common:title.products`, {
        content: parsedTitle,
        count: pageTotal,
        date: year,
      })
    : t(`common:title.productsCustom`, {
        content: parsedTitle,
        count: pageTotal,
        date: year,
      });

  return !page || page === 1 ? title : `${title} ${t("common:page", { page })}`;
}
