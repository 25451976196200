/* eslint-disable jsx-a11y/anchor-is-valid */

import PropTypes from "prop-types";
import React from "react";

import Chip from "@mui/material/Chip";

import { makeStyles } from "tss-react/mui";

import {
  grayColor,
  postTags,
  whiteHover,
} from "../../assets/jss/nextjs-material-kit-pro";

export const styledChipStyle = (theme) => ({
  styledChip: {
    ...postTags,
    height: "auto",
    borderRadius: theme.shape.borderRadius,
    // color: darkCustomColor,
    // fontWeight: 400,
    zIndex: 14, // Any higher breaks comparisonBlock
    //  height: '100%',
    margin: theme.spacing(0, 0.5),
    //    height: '100%', Switched off for titleBadge, not clear what this was doing elsewhere
    minHeight: "24px",
    whiteSpace: "normal",
    "& span": { verticalAlign: "middle" },
  },
});

const useStyles = makeStyles()((theme) => ({
  ...styledChipStyle(theme),
  whiteHover,
  basicStyling: { /*  margin: 'unset', */ lineHeight: "1.5rem" },
  info: { backgroundColor: theme.palette.info.main },
  grayDisabled: { backgroundColor: grayColor[11] },
}));

const StyledChip = React.forwardRef((props, ref) => {
  const {
    color,
    info,
    grayDisabled,
    basicStyling,
    customClasses,
    noAnchor,
    notClickable,
    noHover,
    ...rest
  } = props;
  const { classes, cx } = useStyles();

  const styledChipClasses = cx({
    [classes.styledChip]: true,
    [classes.whiteHover]: !noHover,
    [classes.info]: info,
    [classes.grayDisabled]: grayDisabled,
    [classes.basicStyling]: basicStyling,
    [customClasses]: customClasses,
  });

  return (
    <Chip
      ref={ref}
      component={noAnchor ? "" : "a"}
      clickable={!notClickable}
      classes={{ root: styledChipClasses }}
      size="small"
      color={color}
      {...rest}
    />
  );
});
StyledChip.defaultProps = { noAnchor: true };
StyledChip.propTypes = {
  color: PropTypes.string,
  customClasses: PropTypes.any,
  noAnchor: PropTypes.bool,
  notClickable: PropTypes.bool,
};

StyledChip.displayName = "StyledChip";

export default StyledChip;
