import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

import { defaultFont } from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  defaultFontStyle: {
    ...defaultFont,
    fontSize: "14px",
  },

  infoText: {
    "&, & *": {
      color: theme.palette.info.main,
      display: "inline-block",
    },
  },
}));

export default function Info(props) {
  const { children } = props;
  const { classes } = useStyles();
  return (
    <div className={classes.defaultFontStyle + " " + classes.infoText}>
      {children}
    </div>
  );
}

Info.propTypes = {
  children: PropTypes.node,
};
