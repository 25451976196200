/* eslint-disable no-nested-ternary */
import React from "react";

import ShoesIcon from "components/CustomIcon/ShoesIcon.js";
import WatchesIcon from "components/CustomIcon/WatchesIcon.js";
import BootsIcon from "components/CustomIcon/BootsIcon.js";

import { camelToSlug } from "../../modules/utils";

const CategoryIcon = (props) => {
  const { slug } = props;
  if (!camelToSlug) {
    return <div />;
  }

  return slug == "shoes" ? (
    <ShoesIcon />
  ) : slug === "watches" ? (
    <WatchesIcon />
  ) : slug === "boots" ? (
    <BootsIcon />
  ) : (
    <div />
  );
};
export default CategoryIcon;
