import React, { useEffect } from "react";
import Link from "next/link";
import {
  CookieConsent,
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";

import { useRouter } from "next/router";
import { useFullUser } from "auth/useUser.js";
import { capitalizer } from "@geerly/shared";

import { shouldTrack, forceTrack } from "modules/utils";
import { defaultBorderRadius } from "../../assets/jss/nextjs-material-kit-pro";

const GA_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;
const PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

let ReactPixel;

const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID;
const HOTJAR_VERSION = 6;
const ENABLE_PIXEL = process.env.NEXT_PUBLIC_ENABLE_PIXEL;

export const logEvent = (
  category = "",
  action = "",
  label = "",
  value = ""
) => {
  if (category && action) {
    if (parseInt(process.env.NEXT_PUBLIC_CONSOLE_LOG_ANALYTICS_EVENTS)) {
      console.log(
        "[Analytics] | Logging event | Category:",
        category,
        "| Action:",
        action,
        "| Label:",
        label,
        "| Value:",
        value
      );
    }
    if (ReactGA) {
      ReactGA.event("affiliateClick", {
        category,
        action,
        label,
        value: value.price,
        currency: value.currency,
      });
    }

    if (ENABLE_PIXEL && ReactPixel) {
      ReactPixel.trackCustom(`${category}${capitalizer(action)}`, {
        value,
      });
    }
  }
};

export const logException = (description = "" /*  fatal = false */) => {
  if (description) {
    // This is causing issues - to be debugged if required
    //    ReactGA.exception({ description, fatal });
  }
};

export const TrackingProvider = ({ children }) => {
  const router = useRouter();
  const [user] = useFullUser(); // useAuth();

  const logPageView = (which, Client) => {
    if (which === "ga") {
      const page = window.location.pathname;

      Client.send({ hitType: "pageview", page });

      Client.set({ page });
    }
    if (which === "pixel") {
      Client.pageView(); // For tracking page view
    }
  };

  function logPageViews(which, Client) {
    logPageView(which, Client);
    router.events.on("routeChangeComplete", () => {
      logPageView(which, Client);
    });
  }

  /*  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_TAG_MANAGER_ID,
  }; */

  const handleAcceptCookie = React.useCallback(() => {
    console.log("[Cookies accepted]");
    if (shouldTrack) {
      if (forceTrack()) {
        console.log("FORCE TRACKING ENABLED");
      }
      if (HOTJAR_ID) {
        hotjar.initialize(HOTJAR_ID, HOTJAR_VERSION);

        if (user && user.id) {
          hotjar.identify("USER_ID", { userProperty: user.id });
        }
      }

      if (GA_ID) {
        ReactGA.initialize(GA_ID, {
          debug: false,
        });

        logPageViews("ga", ReactGA);
      }

      if (ENABLE_PIXEL && !window.FB_INITIALIZED) {
        // UNTESTED. To verify once Users fully set up

        const advancedPixelMatching = user?.communicationPreferences?.email
          ? { email: user.communicationPreferences.email }
          : {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

        import("react-facebook-pixel")
          .then((module) => module.default)
          .then((pxl) => {
            ReactPixel = pxl;
            ReactPixel.init(PIXEL_ID, advancedPixelMatching, {
              autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
              debug: false, // enable logs
            });
            //  ReactPixel.revokeConsent(); // NB we should be revoking consent and then regiving it when consent window acceptedeeds to be opted into. Should create a ConsentProvider HOC and then subscribe to it here. OR share ReactPixel as context to run ReactPixel.grantConsent when it's granted (prob easier as this is already a HoC)
            logPageViews("pixel", ReactPixel);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    ENABLE_PIXEL && ReactPixel.revokeConsent();
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, [handleAcceptCookie]);

  // TODO: We should enable the Decline button using enableDeclineButton, and add a component into the Privacy Policy allowing users to toggle their preferences, in the same way that Strava does: https://www.strava.com/legal/cookie_policy

  return (
    <>
      {children}
      <CookieConsent
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        style={{ zIndex: "10000" }}
        buttonStyle={{
          padding: "10px",
          borderRadius: defaultBorderRadius,
          fontSize: "18px",
        }}
      >
        {
          "This website uses cookies to enhance the user experience. Read our privacy policy "
        }
        <Link
          href="/privacy-policy"
          style={{ color: "#FFD42D" }}
          target="_blank"
          prefetch={false}
        >
          here
        </Link>
        {"."}
      </CookieConsent>
    </>
  );
};
