/* eslint-disable no-nested-ternary */
import React from "react";
import Collapse from "@mui/material/Collapse";

import useTranslation from "next-translate/useTranslation";

import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import CustomAutoComplete from "components/CustomDropdown/CustomAutoComplete.js";
import { countryToFlag } from "modules/utils";
import { makeStyles } from "tss-react/mui";
import CustomAlert from "components/CustomAlert/CustomAlert.js";

const useStyles = makeStyles()((theme) => ({
  marginRight: { marginRight: theme.spacing(1) },
}));

const getCountryList = () => {
  countries.registerLocale(enLocale);

  const countryObj = countries.getNames("en", { select: "official" });
  return Object.entries(countryObj).map(([slug, name]) => ({
    slug: slug.toLowerCase(),
    name,
  }));
};

const CountrySelector = (props) => {
  // NB on Settings page email ISN'T required as there is no sumbit button so this prop is not passed
  const {
    athlete,
    handleUpdateUser,
    isFallbackLocale,
    isUpdating,
    isUpdatingError,
    fixedMinWidth,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { classes } = useStyles();
  const {
    athleteDetails: { country },
  } = athlete;

  const options = React.useMemo(() => getCountryList(), []);

  if (country && country.slug && !country.name) {
    const countryObj = options.find((o) => o.slug === country.slug);
    if (countryObj) {
      athlete.athleteDetails.country.name = countryObj.name;
    }
  }

  return (
    <>
      <CustomAutoComplete
        id="country-select"
        options={options}
        error={isUpdatingError === "country"}
        loading={isUpdating}
        value={country || { name: "" }}
        disableClearable
        renderOption={(_props, option) => (
          <li {..._props}>
            <span className={classes.marginRight}>
              {countryToFlag(option.slug)}
            </span>

            {option.name}
          </li>
        )}
        fixedMinWidth={fixedMinWidth}
        label={t("dashboard:country")}
        onChange={(_event, value, setLoadingState) =>
          handleUpdateUser({
            event: {
              target: {
                value: { ...value, addNewTerms: false },
              },
            },
            setLoadingState,
            paths: ["athleteDetails", "country"],
            type: "athlete",
          })
        }
        {...rest}
      />

      <Collapse in={isFallbackLocale}>
        <CustomAlert severity="warning">
          {t("dashboard:unsupportedRegion")}
        </CustomAlert>
      </Collapse>
    </>
  );
};

export default CountrySelector;
