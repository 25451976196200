import React from "react";

import PropTypes from "prop-types";
import InputLoader from "components/Loading/InputLoader.js";
import { makeStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

// NB CHANGE!!
import { validateEmail } from "modules/utils";

import { whiteColor } from "assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  /* root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  }, */

  textFieldContainer: {
    position: "relative",
    margin: theme.spacing(1, 1, 1, 0),
    background: whiteColor,
    borderRadius: theme.shape.borderRadius,
  },
  fixedMinWidth: {
    minWidth: "253px",
    [theme.breakpoints.up("xs")]: { minWidth: "299px" }, // Accounts for some having start vs end adornments and inputLoader being swapped in for endAdornment
  },
  addBackgroundToLabel: {
    "& .MuiInputLabel-root": {
      background: theme.palette.common.white,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(-1.5),
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const CustomInput = (props) => {
  const {
    startAdornment,
    endAdornment,
    validateOnBlur,
    onBlur,
    isUpdating,
    isUpdatingError,
    min,
    max,
    onChange,
    errorMessage,
    useCustomOnBlurOnChange,
    forceInt,
    controlledLoadingState,
    helperText,
    fixedMinWidth,
    children,
    SelectProps,
    addBackgroundToLabel,
    validateFn,
    invalidMsg,
    ...rest
  } = props;

  const { classes, cx } = useStyles();
  const [errorText, setErrorText] = React.useState();
  const [loadingState, setLoadingState] = React.useState(null);

  const InputProps = { inputProps: {} };
  if (min || min === 0) {
    InputProps.inputProps.min = min;
  }
  if (max || max === 0) {
    InputProps.inputProps.max = max;
  }
  // InputProps.inputProps.inputMode = "numeric";
  //InputProps.inputProps.pattern = "[0-9]*";

  const additionalProps = {};
  if (startAdornment) {
    InputProps.startAdornment = (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    );
  }
  if (endAdornment || loadingState || controlledLoadingState) {
    InputProps.endAdornment = (
      <InputAdornment position="end">
        {((loadingState || !!controlledLoadingState) && <InputLoader />) ||
          endAdornment}
      </InputAdornment>
    );
  }

  if (props.select) {
    additionalProps.SelectProps = SelectProps || {};
    if (loadingState === props.name) {
      additionalProps.SelectProps.IconComponent = null;
    }
  }
  React.useEffect(() => {
    if (
      errorMessage &&
      isUpdatingError &&
      isUpdatingError &&
      isUpdatingError === props.name &&
      !errorText
    ) {
      setErrorText(errorMessage);
    }
  }, [errorMessage, errorText, isUpdatingError, props.name]);
  React.useEffect(() => {
    if (!isUpdating && loadingState) {
      setLoadingState(null);
    }
  }, [isUpdating, loadingState]);

  const customOnBlur = (event) => {
    let { value } = event.target;

    if (forceInt) {
      value = parseInt(value).toString();
      event.target.value = value;
    }

    if (validateOnBlur || props.required) {
      console.log("Validatein...");
      if (props.type === "email") {
        if (value && !validateEmail(value)) {
          setErrorText("Please enter a valid email address");
          return;
        }
      } else {
        if (validateFn) {
          if (value && !validateFn(value)) {
            setErrorText(invalidMsg || "Invalid value");
            return;
          }
        }
      }
      if (props.required) {
        if (!value) {
          console.log("REQUIRED");
          setErrorText("Required");
          return;
        }
      }
    }

    if (errorText) {
      setErrorText(null);
    }
    if (onBlur) {
      //console.log("EVENT", event);
      // Fire next FN
      //  setLoadingState(true);
      onBlur(event, setLoadingState);
    }
  };

  return (
    <TextField
      variant="standard"
      size="small"
      hiddenLabel
      className={cx({
        [classes.textFieldContainer]: true,
        [classes.fixedMinWidth]: fixedMinWidth,
      })}
      classes={{
        root: addBackgroundToLabel ? classes.addBackgroundToLabel : "",
      }}
      InputProps={InputProps}
      // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      {...rest}
      //   type={"text"}
      {...additionalProps}
      error={
        !!(
          errorText ||
          props.error ||
          (isUpdatingError && isUpdatingError === props.name)
        )
      }
      helperText={errorText || helperText}
      onBlur={customOnBlur}
      onKeyDown={(ev) => {
        if (ev.key === "Enter") {
          // Do code here
          customOnBlur(ev, setLoadingState);
        }
      }}
      onChange={(e, v) => {
        onChange
          ? onChange(e, setLoadingState)
          : useCustomOnBlurOnChange
          ? customOnBlur(e, setLoadingState)
          : // Workaround because firefox doesnt recognise focus when using number buttons
            e.target.focus && e.target.focus();
      }}
    >
      {children}
    </TextField>
  );
};

CustomInput.defaultProps = { margin: "dense", variant: "outlined" };
CustomInput.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,

  margin: PropTypes.oneOf(["none", "dense", "normal"]),
};

export default CustomInput;
