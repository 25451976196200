import PropTypes from "prop-types";
import React from "react";

import StyledChip from "components/Tag/StyledChip";

import { makeStyles } from "tss-react/mui";

// core components

const useStyles = makeStyles()({
  imageChipPosition: { margin: "0px" },
});

function ImageBadge(props) {
  const { classes } = useStyles();
  const { color, customClasses, ...rest } = props;

  //NB place ...rest at top of tree !
  return (
    <StyledChip
      {...rest}
      color={color}
      notClickable
      noAnchor
      customClasses={`${classes.imageChipPosition} ${customClasses || ""}`}
    />
  );
}

ImageBadge.propTypes = {
  color: PropTypes.string,
  customClasses: PropTypes.string,
};

ImageBadge.defaultProps = { color: "secondary" };

export default ImageBadge;
