import React from "react";

import PropTypes from "prop-types";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Button from "components/CustomButtons/Button";

import { makeStyles } from "tss-react/mui";

const fixedPositionFullWidth = "-30px";
const fixedPositionNarrowWidth = "-10px";

const useStyles = makeStyles()((theme) => ({
  roundArrowButton: {
    position: "absolute",
    top: "60px",
    zIndex: 105,
    padding: "7px",
    margin: 0,
    pointerEvents: "auto",
    "& svg": { margin: 0 },
    // [theme.breakpoints.down('md')]: { top: '125px' },
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  endButton: {
    right: fixedPositionFullWidth,
    [theme.breakpoints.down("sm")]: { right: fixedPositionNarrowWidth },
  }, // was -30 but encroaches on price module when scrolling down on medium screens
  // eslint-disable-next-line tss-unused-classes/unused-classes
  startButton: {
    left: fixedPositionFullWidth,
    [theme.breakpoints.down("sm")]: { left: fixedPositionNarrowWidth },
  }, // '125px' to miss the first column
}));

export default function ScrollToEndButton(props) {
  const { classes, cx } = useStyles();
  const { direction, containerId, scrollSize } = props;

  const Icon = direction === "start" ? ChevronLeftIcon : ChevronRightIcon;

  const buttonClasses = cx({
    [classes.roundArrowButton]: true,
    [classes[`${direction}Button`]]: true,
  });

  const scrollOptions = {
    smooth: true,
    animation: "easeInOutQuint",
    horizontal: true,
    containerId,
  };
  return (
    <Button
      round
      color="secondary"
      icon
      classes={{ root: buttonClasses }}
      edge="start"
      onClick={() => {
        import("react-scroll").then(
          (scroll) =>
            direction === "start"
              ? scroll.animateScroll.scrollMore(-scrollSize, scrollOptions)
              : scroll.animateScroll.scrollMore(scrollSize, scrollOptions)
          //  ? scroll.animateScroll.scrollToTop(scrollOptions)
          //  : scroll.animateScroll.scrollToBottom(scrollOptions)
        );
      }}
    >
      <Icon fontSize="large" />
    </Button>
  );
}

ScrollToEndButton.defaultProps = {
  scrollSize: 150,
};

ScrollToEndButton.propTypes = {
  containerId: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  scrollSize: PropTypes.number,
};
