import { TOKEN_REFRESH_WINDOW } from "settings/global-website-config.js";

const isTokenValid = function isTokenValid(token, expiresAt) {
  if (!token) return false;
  try {
    // Refresh 5 min before it's expired to avoid issues with a lag when server is under load
    if (Date.now() >= expiresAt - TOKEN_REFRESH_WINDOW) {
      console.log(
        "[Access token] | Token about to expire | Refresh now",
        token
      );
      return false;
    }

    return true;
  } catch {
    console.log("[Access token] | Caught - invalid token.");
    return false; // token is invalid
  }
};

export default isTokenValid;
