import React from "react";
import { createRoot } from "react-dom/client";

import Router from "next/router";

import dynamic from "next/dynamic";

import {
  PAGE_TRANSITION_ID,
  PAGE_TRANSITION_CONTAINER_ID,
} from "settings/global-website-config";

import { defaultLocale } from "i18n";

const PageChange = dynamic(() => import("components/PageChange/PageChange"));

export const shouldShowPageChange = (url, TheRouter) =>
  //    !url.includes('strava') &&
  //    !url.includes('beta') &&
  (TheRouter.isFallback ||
    parseInt(process.env.NEXT_PUBLIC_SKIP_PRERENDERING_PAGES)) &&
  (!TheRouter.asPath.includes("categories") || // Coming from another part of the app
    TheRouter.asPath.split("/").length < 5 || // Coming from a category page, but a root category page
    !url.includes("categories") || // Not going to a category page
    url.split("/").length < 5); // Going to a category page, but it's a root page

const usePageChange = (lang) => {
  React.useEffect(() => {
    if (parseInt(process.env.NEXT_PUBLIC_SHOW_PAGE_CHANGE)) {
      let root, container;
      Router.events.on("routeChangeStart", (url, { shallow }) => {
        if (shallow) {
          return;
        }
        console.log("[Page change] >==== PAGE CHANGE =====>", url);

        if (typeof window !== "undefined") {
          if (!container) {
            container = document.getElementById(PAGE_TRANSITION_CONTAINER_ID);
          }
          if (container && !root) {
            root = createRoot(container);
          }
        }
        if (shouldShowPageChange(url, Router) && root) {
          console.log(
            "[Page change] Showing skeleton page || Path",
            Router.asPath,
            "| url:",
            url
          );
          // check if it's an internal change (a filtered refresh of the same page) and doesn't need a pageTransition
          document.body.classList.add(PAGE_TRANSITION_ID);
          root.render(<PageChange path={url} lang={lang || defaultLocale} />);
        }
      });

      Router.events.on("routeChangeComplete", () => {
        console.log(
          "[Page change] |=========== PAGE CHANGE COMPLETE ===========|"
        );

        if (root && container) {
          root.unmount();
          root = null;
          document.body.classList.remove(PAGE_TRANSITION_ID);
        }
      });

      Router.events.on("routeChangeError", (err) => {
        console.log("[Page Change] | ROUTE CHANGE ERROR | Error:", err);
        if (root) {
          root.unmount();
          root = null;
        }
      });
    }
  }, [lang]);
};

export default usePageChange;
