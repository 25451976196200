import React from "react";

import useTranslation from "next-translate/useTranslation";
import CustomSelect from "components/Select/CustomSelect.js";
import { useRenders } from "modules/utils";

export default function SexesDropdown(props) {
  const { t } = useTranslation();

  const { label, options, handleChoice, currentSelection, from, ...rest } =
    props;

  useRenders("sexesDropdown");

  const sexOptions = [
    { name: "Men's", value: "mens" },
    { name: "Women's", value: "womens" },
  ];

  const dropdownOptions = options ? options : sexOptions;

  return (
    <CustomSelect
      fullWidth
      label={t("feeds:selectSelector", { label })}
      options={dropdownOptions.map((option) => ({
        key: `${option.value}-${from}`,
        value: option.value,
        name: option.name,
      }))}
      handleChoice={handleChoice}
      currentSelection={currentSelection}
      type="Sex"
      {...rest}
    />
  );
}
