import React from "react";
import Carousel from "react-multi-carousel";
import { useScreenSizes } from "components/Providers/ScreenSizesProvider";
import { useRenders } from "modules/utils";

import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

import DirectionButton from "components/CustomButtons/DirectionButton";

import {
  mlAuto,
  mrAuto,
  grayColor,
} from "../../assets/jss/nextjs-material-kit-pro";
import { DEFAULT_RESPONSIVE_SETTINGS } from "../../settings/global-website-config";

const useStyles = makeStyles()((theme) => ({
  carouselArrowMargins: {
    margin: "0px 40px",
    [theme.breakpoints.down("lg")]: {
      margin: "0px 15px",
      // Small screens get issues with overflows when there isn't enough margin
      // margin: '0px 60px',
    },
    [theme.breakpoints.down("md")]: {
      margin: "0px 40px",
      // Small screens get issues with overflows when there isn't enough margin
      // margin: '0px 60px',
    },
  },
  carouselContainer: {
    position: "relative",
    display: "inherit",

    "& .react-multi-carousel-list": {
      borderRadius: "3px",
      maxWidth: "100%",
      width: "100%",
      height: "auto",
      overflow: "hidden",
      // textAlign: 'center', // Breaks written text. What was this for?
      minHeight: "100px",
      ...mlAuto,
      ...mrAuto,
    },
    "& .react-multi-carousel-dot > button": {
      background: grayColor[11],
      border: "none",
    },
    "& .react-multi-carousel-dot--active > button": {
      background: theme.palette.secondary.main,
    },
  },
}));

const useButtonStyles = makeStyles()({
  carouselButtonGroup: {
    position: "absolute",
    top: "50%",
    bottom: "50%", // Not sure why this isn't 50% but that doesn't seem to work...
    transform: "translateY(-50%)",
    width: "100%",
    // margin: 'auto',
    height: "100%",
    padding: 0, //'0px 25px',
    display: "flex",
    alignItems: "center",
    pointerEvents: "none",
  },
});
const ButtonGroup = ({
  next,
  previous,
  goToSlide,
  loading,
  infinite,
  positionOutside,
  positionFromTop,
  disableScroll,
  hideScrollButtonsWhenNotNeeded,
  ...rest
}) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  const { classes } = useButtonStyles();

  React.useEffect(() => {
    if (currentSlide > totalItems - 1) {
      goToSlide(totalItems - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide, totalItems]);

  const shouldHide =
    hideScrollButtonsWhenNotNeeded &&
    (disableScroll || totalItems <= slidesToShow);

  if (shouldHide) {
    return null;
  }
  return (
    <div className={classes.carouselButtonGroup}>
      <DirectionButton
        positionAbsolutely
        disabled={disableScroll || loading || (!infinite && currentSlide === 0)}
        handleClick={previous}
        direction="previous"
        positionOutside={positionOutside}
        positionFromTop={positionFromTop}
      />
      <DirectionButton
        positionAbsolutely
        positionOutside={positionOutside}
        disabled={
          disableScroll ||
          loading ||
          (!infinite && currentSlide + slidesToShow >= totalItems)
        }
        handleClick={next}
        direction="next"
        positionFromTop={positionFromTop}
      />
    </div>
  );
};

const defaultResponsiveSettings = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
    //    partialVisibilityGutter: 40,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    //     partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 1,
    //     partialVisibilityGutter: 30,
  },
  mobileLarge: {
    breakpoint: { max: 600, min: 464 },
    items: 1,
    //     partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    //      partialVisibilityGutter: 30,
  },
};
export const getSizesFromResponsiveSettings = (responsiveSettings) => {
  const settingsToUse = responsiveSettings || defaultResponsiveSettings;
  const keys = Object.keys(settingsToUse).reverse();
  const { length } = keys;
  let sizes = [];
  for (const [index, key] of keys.entries()) {
    const numToShow = responsiveSettings
      ? settingsToUse[key]
      : settingsToUse[key].items;

    const value = Math.round(100 / numToShow) + "vw";
    if (index + 1 === length) {
      sizes.push(value);
      break;
    }
    const nextEntry = keys[index + 1];
    const nextValue = responsiveSettings
      ? settingsToUse[nextEntry]
      : settingsToUse[nextEntry].items;
    if (numToShow === nextValue) {
      // Prevent including same value twice
      continue;
    }
    const maxWidth = defaultResponsiveSettings[key].breakpoint.max;
    sizes.push(`(max-width: ${maxWidth}) ${value}`);
  }
  return sizes.join(",");
};

const CustomCarousel = (props) => {
  const { classes, cx } = useStyles();
  const {
    responsiveSettings,
    children,
    showDots,
    displayName,
    positionOutside,
    positionFromTop,
    loading,
    infinite,
    hideScrollButtonsWhenNotNeeded,
    disableScroll,
    ...rest
  } = props;

  useRenders("Carousel", displayName);

  const carouselRef = React.useRef();
  const { isSmScreen } = useScreenSizes();

  const [shouldUpdate, setShouldUpdate] = React.useState(true);

  // set shouldUpdate => true on initial render, triggering re-render
  // Hack to ensure we can inspect carouselRef.current to check state in case we can hide the arrowContainer
  React.useEffect(() => {
    if (hideScrollButtonsWhenNotNeeded && shouldUpdate) setShouldUpdate(false);
  }, [shouldUpdate, hideScrollButtonsWhenNotNeeded]);

  if (!children) {
    return <div />;
  }

  const responsive = { ...defaultResponsiveSettings };

  for (const key of Object.keys(responsiveSettings)) {
    if (responsiveSettings[key]) {
      //  Make sure to clone the sub object
      responsive[key] = { ...responsive[key] };
      responsive[key].items = responsiveSettings[key];
    }
  }
  const showArrows =
    !hideScrollButtonsWhenNotNeeded ||
    !carouselRef.current ||
    carouselRef.current.state.slidesToShow < children.length;
  const carouselClasses = cx({
    [classes.carouselContainer]: showArrows,
    [classes.carouselArrowMargins]: positionOutside && showArrows,
  });

  return (
    <div className={carouselClasses}>
      <Carousel
        responsive={responsive}
        ref={carouselRef}
        arrows={false}
        infinite={infinite}
        renderButtonGroupOutside={true}
        showDots={showDots && children.length > 1}
        customButtonGroup={
          <ButtonGroup
            hideScrollButtonsWhenNotNeeded={hideScrollButtonsWhenNotNeeded}
            loading={loading}
            infinite={infinite}
            positionOutside={positionOutside}
            positionFromTop={positionFromTop}
            disableScroll={disableScroll}
          />
        }
        keyBoardControl={true}
        transitionDuration={500}
        deviceType={isSmScreen ? "mobile" : null}
        {...rest}
      >
        {children}
      </Carousel>
    </div>
  );
};

CustomCarousel.defaultProps = {
  responsiveSettings: DEFAULT_RESPONSIVE_SETTINGS,
  positionOutside: true,
  // positionFromTop: 0,
};

CustomCarousel.propTypes = {
  responsiveSettings: PropTypes.shape({
    desktop: PropTypes.number,
    mobile: PropTypes.number,
    mobileLarge: PropTypes.number,
    superLargeDesktop: PropTypes.number,
    tablet: PropTypes.number,
  }),
};

export default CustomCarousel;
