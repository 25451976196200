import PropTypes from "prop-types";
import { Alert, AlertTitle } from "@mui/material";

const CustomAlert = (props) => {
  const { children, title, fullWidth, dense, sx, ...rest } = props;

  const alertSx = sx || {};
  if (fullWidth) {
    alertSx.width = "100%";
    alertSx["& .MuiAlert-message"] = { width: "100%" };
  }
  const titleSx = {};
  if (dense) {
    alertSx.py = 0.25;
    titleSx.mb = 0;
  }
  return (
    <Alert {...rest} sx={alertSx}>
      {title && <AlertTitle sx={titleSx}>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};

CustomAlert.defaultProps = { severity: "info", dense: true };

CustomAlert.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  severity: PropTypes.string,
};

export default CustomAlert;
