import { grayColor } from "assets/jss/nextjs-material-kit-pro.js";

const modalStyle = (theme, _props, classes) => {
  return {
    modalRoot: {
      zIndex: "1500 !important",
      overflow: "auto",
      display: "block",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        margin: "0px",
        // maxHeight: '100%'
      },
    },
    modal: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "510px",
        margin: "auto",
      },
      [theme.breakpoints.down("md")]: {
        margin: "auto",
      },
      maxHeight: "95%", //was 'unset',
      //overflow: "visible",

      margin: "0px",

      // marginTop: "130px !important"
    },
    modalHeader: {
      borderBottom: "none",
      paddingTop: "24px",
      paddingRight: "0px", // was 24px
      paddingBottom: "0",
      paddingLeft: "0px", // was 24px
      minHeight: "16.43px",
      "& root": {
        padding: "0px",
      },
    },
    modalTitle: {
      margin: "0",
      lineHeight: "1.5",
    },
    modalCloseWithBackgroundButton: { color: "inherit" },
    modalCloseButton: {
      "&, &:hover": {
        color: grayColor[0],
      },
      "&:hover": {
        opacity: "1",
      },
      minWidth: "unset",
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      cursor: "pointer",
      padding: "0.5rem", // was 1rem, changed for logged out msg
      marginLeft: "auto", // added for logged out msg

      // margin: "-1rem -1rem -1rem auto",
      backgroundColor: "transparent",
      border: "0",
      WebkitAppearance: "none",
      //  float: 'right',
      fontSize: "1.5rem",
      fontWeight: "500",
      lineHeight: "1",
      textShadow: `0 1px 0 ${theme.palette.common.white}`,
      opacity: ".5",
    },
    modalClose: {
      width: "16px",
      height: "16px",
    },
    modalBody: {
      padding: theme.spacing(2, 3),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1, 2),
      },
      //paddingTop: '24px', // was 24px
      // paddingRight: '24px',
      //  paddingBottom: '16px',
      //  paddingLeft: '24px',
      position: "relative",
      overflow: "auto",
    },
    modalFooter: {
      padding: theme.spacing(2, 3),
      textAlign: "right",
      //s  paddingTop: '0',
      margin: "0",
    },
    modalFooterCenter: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    instructionNoticeModal: {
      marginBottom: "25px",
    },
    imageNoticeModal: {
      maxWidth: "150px",
    },
    modalLarge: {
      [theme.breakpoints.up("sm")]: {
        width: "510px",
        //  maxWidth: '800px',
      },
      /* [theme.breakpoints.up('sm')]: {
        width: '510px',
        //  maxWidth: '800px',
      }, */
      [theme.breakpoints.down("sm")]: {
        //   minWidth: '510px',
        // maxWidth: '800px',
        width: "95vw",
      },
    },
    modalSmall: {
      [theme.breakpoints.up("sm")]: {
        width: "300px",
        margin: "auto",
      },
      margin: "0 auto",
    },
    closeButtonPadding: { paddingTop: theme.spacing(3) },
    modalSmallBody: {
      marginTop: "20px",
    },
    modalSmallFooterFirstButton: {
      margin: "0",
      paddingLeft: "16px",
      paddingRight: "16px",
      width: "auto",
    },
    modalSmallFooterSecondButton: {
      marginBottom: "0",
      marginLeft: "5px",
    },
    modalSignup: {
      maxWidth: "900px",
      width: "100%",
      "& $modalHeader": {
        paddingTop: "0",
      },
      "& $modalTitle": {
        textAlign: "center",
        width: "100%",
        marginTop: "0.625rem",
      },
      "& $modalBody": {
        paddingBottom: "0",
        paddingTop: "0",
      },
    },
    modalSignupCard: {
      padding: "40px 0",
      margin: "0",
    },
  };
};

export default modalStyle;
