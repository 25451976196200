import React from "react";

import Image from "components/Image/CustomImage";
import PropTypes from "prop-types";
import urlPropType from "url-prop-type";

import SkeletonWithFade from "components/Loading/SkeletonWithFade";
import { PLACEHOLDER_IMAGE_PATH } from "settings/global-website-config";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  thumbnailStyles: {
    objectFit: "cover",
    minWidth: "100%",
    height: "100%",
    aspectRatio: "1 / 1",
  },
  imageModule: { minWidth: "100%" },
  cover: { objectFit: "cover" },
  contain: { objectFit: "contain" },
});
export default function ImageModule(props) {
  const {
    featuredImage,
    fallbackAltText,
    imgClass,
    loading,
    noPlaceholder,
    width,
    height,
    priority,
    isThumbnail,
    cover,
    layout,
    unoptimized,
    alt,
    sizes,
  } = props;

  const { cx, classes } = useStyles();

  let src =
    !loading &&
    featuredImage &&
    ((featuredImage.node &&
      (featuredImage.node.sourceUrl || featuredImage.node.thumbnail)) ||
      featuredImage.sourceUrl ||
      featuredImage.thumbnail);

  //const objectFit =
  //   isThumbnail || cover ? 'cover' : !src ? 'contain' : 'contain'; // was previously 'fill' if !src
  src = src || PLACEHOLDER_IMAGE_PATH;

  if (noPlaceholder && !featuredImage) return <div />;

  const imgProps = {
    style: {},
    sizes,
    width,
    height,
    priority,
    layout,
    unoptimized,
  };

  if (layout) {
    imgProps.style.layout = layout;
  }

  const allClasses = cx({
    [imgClass]: !!imgClass,
    [classes.imageModule]: !isThumbnail,
    [classes.thumbnailStyles]: isThumbnail,
    [classes.cover]: !isThumbnail && cover,
    [classes.contain]: !isThumbnail && !cover,
  });

  return (
    <>
      <SkeletonWithFade
        inProp={loading}
        height={isThumbnail ? width : width * 0.5}
      />
      <Image
        {...imgProps}
        className={allClasses}
        src={src}
        alt={
          alt ||
          (featuredImage &&
            (featuredImage.altText || featuredImage.node?.altText)) ||
          fallbackAltText ||
          null
        }
      />
    </>
  );
}

// Previously I was using react-image as didn't want to use nextjs's image optimisation but the optimised images from WP instead. However, I'm trying the nextJs version. Check old commits for ref, though it was never fully working.

const featuredImagePropShape = {
  sourceUrl: urlPropType,
  original: urlPropType,
  thumbnail: urlPropType,
  altText: PropTypes.string.isRequired,
  caption: PropTypes.string,
  __typename: PropTypes.string,
};

ImageModule.propTypes = {
  as: PropTypes.string,
  cover: PropTypes.bool,
  fallbackAltText: PropTypes.string,
  featuredImage: PropTypes.oneOfType([
    PropTypes.shape(featuredImagePropShape),
    PropTypes.shape({
      node: PropTypes.shape(featuredImagePropShape),
      __typename: PropTypes.string,
    }),
    PropTypes.shape({
      node: PropTypes.shape({
        sourceUrl: urlPropType,
      }),
    }),
  ]),
  height: PropTypes.any,
  href: urlPropType,
  id: PropTypes.string,
  imgClass: PropTypes.any,
  isThumbnail: PropTypes.any,
  loading: PropTypes.any,
  noPlaceholder: PropTypes.any,
  priority: PropTypes.any,
  width: PropTypes.number,
};
