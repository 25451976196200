import React from "react";

import useTranslation from "next-translate/useTranslation";

import { useRenders } from "modules/utils";
import CustomAutoComplete from "components/CustomDropdown/CustomAutoComplete.js";

import { makeStyles } from "tss-react/mui";

import { selectFormControl } from "../../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  selectFormControl,
  padRight: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: { paddingRight: theme.spacing(0.5) },
  },
}));

export default function AutocompleteDropdown(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const {
    label,
    filter,
    handleChoice,
    currentSelection,
    type,
    noLabelPrepender,
    ...rest
  } = props;

  useRenders(`autoCompleteDropdown ${type}`);

  return (
    <CustomAutoComplete
      label={
        noLabelPrepender
          ? label
          : t(`feeds:${filter ? "filter" : "select"}Selector`, { label })
      }
      customClasses={{
        root: `${classes.selectFormControl} ${classes.padRight}`,
      }}
      onChange={(e, v, r) => handleChoice(e, v, r, type)}
      value={currentSelection || null}
      isOptionEqualToValue={(option, value) => option.id == value?.id}
      {...rest}
    />
  );
}
