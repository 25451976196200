import React from "react";
import CustomLink from "components/Links/CustomLink.js";

import PropTypes from "prop-types";
import { gql } from "@apollo/client";

import { useContainerDimensions } from "modules/useContainerDimensions";
import SkeletonWithFade from "components/Loading/SkeletonWithFade";

import ImageModule from "components/Image/ImageModule";
import ExpertReviewScoreBadge from "components/Badge/ExpertReviewScoreBadge";

import { makeStyles } from "tss-react/mui";
import ImageBadge from "components/Badge/ImageBadge.js";

import { textOverlay } from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  imageModule: { position: "relative" }, // Newly added and may mess with things
  reviewScoreBadge: {
    position: "absolute",

    zIndex: 10,
    // padding: '0px 15px',
    [theme.breakpoints.down("lg")]: {
      order: 1,
    },
  },
  absoluteWithPadding: { top: 10, left: 10 },
  absoluteNoPadding: { top: 10, left: 0 },
  textOverlay,
  familyBadge: { margin: "-5px 0px 2px 4px !important" },
}));

export const FeaturedImage = (props) => {
  const { classes, cx } = useStyles();

  const {
    href,
    loading,
    isThumbnail,
    widthOverride,
    heightOverride,
    maxHeight,
    prefetch,
    reviews,
    circle,
    noPlaceholder,
    noBadgePadding,
    displayFlexWhenLoading,
    ariaLabel,
    badge,
    type,
    textOverlay,
  } = props;

  const isFamily = type === "family";

  const imageRef = React.useRef();

  const { width, height: _realHeight } = useContainerDimensions(
    imageRef,
    loading
  );

  const height = isThumbnail ? width : width * 0.5;
  // removed   {/*sizes="100%" */''} from Img
  const widthToUse = widthOverride || width;
  let heightToUse = widthOverride
    ? heightOverride || isThumbnail
      ? widthToUse
      : widthToUse * 0.5
    : height;

  if (maxHeight && heightToUse > maxHeight) {
    heightToUse = maxHeight;
  }

  const SHOW_VERBOSE = false;

  if (SHOW_VERBOSE) {
    console.log("[Trace] | Showing featured image props");
    // console.log('FeaturedImage', props.featuredImage);
    console.log("width", width);
    // console.log('realHeight', _realHeight); // Not v relevant, doesnt' drive anything

    console.log("isthumbnail", isThumbnail);
    console.log("widthoverride", widthOverride);
    console.log("height over", heightOverride);

    console.log("widthToUse", widthToUse);
    console.log("heightToUse", heightToUse);

    console.log("Layout??", props?.layout);
  }

  const reviewScoreBadgeClasses = cx({
    [classes.reviewScoreBadge]: true,
    [classes.absoluteNoPadding]: noBadgePadding,
    [classes.absoluteWithPadding]: !noBadgePadding,
  });
  return (
    <div className={classes.imageModule} ref={imageRef}>
      <SkeletonWithFade
        inProp={loading && !noPlaceholder}
        height={heightToUse}
        width={widthToUse}
        circle={circle}
        displayFlexWhenLoading={displayFlexWhenLoading}
      >
        {href ? (
          <CustomLink
            href={href}
            prefetch={prefetch}
            aria-label={ariaLabel || ""}
          >
            <ImageModule {...props} height={heightToUse} width={widthToUse} />
          </CustomLink>
        ) : (
          <ImageModule {...props} height={heightToUse} width={widthToUse} />
        )}
      </SkeletonWithFade>
      <>
        {(reviews || badge || isFamily) && (
          <div className={reviewScoreBadgeClasses}>
            {reviews && (
              <ExpertReviewScoreBadge
                reviews={reviews}
                loading={loading}
                showScore
                noMarginTop
                hideHeader
                hideDetails
                href={href}
              />
            )}
            {badge}
            {!badge && !loading && isFamily && (
              <ImageBadge
                color="primary"
                label="Family"
                customClasses={classes.familyBadge}
                disabled
              />
            )}
          </div>
        )}
        {textOverlay && (
          <div className={classes.textOverlay}>{textOverlay}</div>
        )}
      </>
    </div>
  );
};

// export function FEATUREDIMAGECONTENT(size) {

// if (size === "large") {}

// Have removed srcSet experimentally as not needed when using next/legacy/image optimsation. If successful, these should be combined into one fragment with a variable for size
export const IMAGECONTENTTHUMBNAILFRAGMENT = gql`
  fragment ImageContentThumbnailFragment on MediaItem {
    id
    altText
    sourceUrl(size: THUMBNAIL)
  }
`;

export const IMAGECONTENTLARGEFRAGMENT = gql`
  fragment ImageContentLargeFragment on MediaItem {
    id
    altText
    sourceUrl(size: LARGE)
  }
`;

export const IMAGECONTENTLARGEANDTHUMBNAILFRAGMENT = gql`
  fragment ImageContentLargeAndThumbnailFragment on MediaItem {
    id
    altText
    original: sourceUrl(size: LARGE)
    thumbnail: sourceUrl(size: THUMBNAIL)
  }
`;

export const FEATUREDIMAGELARGEFRAGMENT = gql`
  fragment FeaturedImageLargeFragment on NodeWithFeaturedImage {
    featuredImage {
      node {
        id
        altText
        sourceUrl(size: LARGE)
      }
    }
  }
`;
export const FEATUREDIMAGEMEDIUMFRAGMENT = gql`
  fragment FeaturedImageMediumFragment on NodeWithFeaturedImage {
    featuredImage {
      node {
        id
        altText
        sourceUrl(size: MEDIUM)
      }
    }
  }
`;

export const FEATUREDIMAGEFRAGMENT = gql`
  fragment FeaturedImageFragment on NodeWithFeaturedImage {
    featuredImage {
      node {
        altText
        sourceUrl
        id
      }
    }
  }
`;

FeaturedImage.propTypes = {
  //featuredImage: PropTypes.object,
  //fallbackAltText: PropTypes.string,
  href: PropTypes.string,
  as: PropTypes.string,
  id: PropTypes.string,
};
