import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles()({
  grid: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    //    paddingRight: '15px',
    //   paddingLeft: '15px',
    /* flexBasis: "auto" */
  },
});

const GridItem = React.forwardRef((props, ref) => {
  const { children, className, xxs, ...rest } = props;

  const xxsPropToUse = xxs; //|| props.xs || props.sm || props.lg || props.xl;

  const xxsClass = `MuiGrid-grid-xxs-${xxsPropToUse}`;

  const { classes, cx } = useStyles();

  return (
    <Grid
      item
      {...rest}
      className={cx({
        [classes.grid]: true,
        [className]: className,
        [xxsClass]: xxs,
      })}
      ref={ref}
    >
      {children}
    </Grid>
  );
});

GridItem.defaultProps = {
  className: "",
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  xxs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};
if (process.env.NODE_ENV !== "production") {
  GridItem.displayName = "GridItem";
}

export default GridItem;
