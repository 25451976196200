import PropTypes from "prop-types";
import React, { useState } from "react";
import { useRouter } from "next/router";
import Image from "components/Image/CustomImage";
import useTranslation from "next-translate/useTranslation";

// Algolia parts:
import {
  InstantSearch,
  connectAutoComplete,
  Highlight,
  Index,
  Configure,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import ExpertReviewScoreBadge from "components/Badge/ExpertReviewScoreBadge";

// @mui/material components
import MuiAutocomplete from "@mui/material/Autocomplete";

import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { makeStyles } from "tss-react/mui";
import TextField from "@mui/material/TextField";
import { scrollToTop } from "modules/utils";

import SearchIcon from "@mui/icons-material/Search";
// core components

import { ALGOLIA_INDEXES } from "settings/global-website-config";

import {
  iconButtons,
  transitionFast,
} from "../../assets/jss/nextjs-material-kit-pro";
import { useFullUser } from "../../auth/useUser";
import UsageIcon from "../../pages-sections/dashboard/UsageIcon";

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
);

const HITS_TO_FETCH = 10;
const HITS_TO_SHOW = 20;
const asymBorderRadius = "0px 4px 4px 0px";

const useStyles = makeStyles()((theme) => ({
  /* searchContainer: {
    flexGrow: 6,
    // minWidth: '330px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
      //    order: '3',
      // margin: '-10px 0px 0px 0px',
    },
  }, */
  listbox: {
    maxHeight: "60vh",
  },
  popper: { zIndex: theme.zIndex.tooltip + 1 },
  option: {
    "& em": {
      fontWeight: 600,
      fontStyle: "normal",
      backgroundColor: "transparent",
    },
  },
  optionMain: { display: "flex", verticalAlign: "center" },
  searchOptionUsageBadge: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  marginLeft: { marginLeft: "8px" },
  searchBar: {
    padding: 0,
    paddingRight: "0px !important",
    margin: theme.spacing(1, 0),
    // margin: 0,
    height: "40px",

    // borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    zIndex: 4,
    // border: '1px solid #999',
    display: "flex", // this is important !
    [theme.breakpoints.down("md")]: {
      order: "3",
      // margin: '0px',
    },
  },
  /*  root: {
    flexGrow: 7,
    // padding: '10px 0px 10px 0px',
    //   position: 'relative',
    [theme.breakpoints.down('sm')]: {
      ///  paddingBottom: '15px',
    },
    '& em': {
      fontWeight: 500,
      fontStyle: 'normal',
      backgroundColor: 'transparent',
    },
  }, */

  searchBarTextField: {
    margin: 0,
  },

  inputBase: {
    "&:hover fieldset": {
      ...transitionFast,
      border: `2px solid ${theme.palette.secondary.main} !important`,
    },
    width: "100%",
    // borderRadius: '4px', // should be covere
    paddingRight: "0px !important", // causes text to sit over dismiss button :(
    // '& .MuiInputBase-input': { paddingRight: '30px !important' },
  },

  iconButton: {
    ...transitionFast,
    ...iconButtons,

    //marginBottom: -1,
    // zIndex: 2,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: asymBorderRadius,
    "&:hover": {
      zIndex: 0,
      backgroundColor: "unset",
      color: theme.palette.secondary.main,
      cursor: "auto",
    },
  },

  showCloseIcon: {
    ...iconButtons,
    // display: 'block',
    //   color: grayColor[0],
    //  backgroundColor: whiteColor,
    borderRadius: asymBorderRadius,
  },
}));

export const SearchIconButton = (props) => {
  const { customIconClasses, customButtonClasses, ...rest } = props;

  return (
    <IconButton
      id="searchIconButton"
      className={customButtonClasses}
      type="reset"
      aria-label="search"
      {...rest}
      size="large"
    >
      <SearchIcon
        id="searchIcon"
        className={customIconClasses}
        aria-label="search"
      />
    </IconButton>
  );
};

const Autocomplete = ({
  hits,
  currentRefinement,
  refine,
  customOnChange,
  placeholderKey,
  placeholderText,
  customFilter,
  customOptionDisabled,
  setValue,
  value,
  noPrefetch,
  id,
}) => {
  const [user] = useFullUser();

  if (!hits[0]?.index) {
    // Create a consistent API whether or not we have multiple indices
    hits = [{ hits }];
  }
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState(currentRefinement);
  const router = useRouter();

  const clearInputs = () => {
    setInputValue("");
    refine();
    if (value && setValue) {
      console.log("setting value");

      setValue(false);
    }
  };

  // Section title in dropdown. Currently don't want to show anything, could add something in future if required

  let processedHits = [];
  const totalHits = hits.reduce(
    (acc, element) => acc + element.hits?.length,
    0
  );

  const maxPossible = HITS_TO_FETCH * hits.length;
  let hitGroupHitsToShow = null;

  if (totalHits < HITS_TO_SHOW) {
    // do nothing
  } else if (totalHits === maxPossible) {
    hitGroupHitsToShow = Math.floor(HITS_TO_SHOW / hits.length);
    // limit to maxPossible/limit * length = i.e. halve
  } else {
    // e.g. maxPossible > total > HITS_TO_SHOW
    // limit to total / count each
    hitGroupHitsToShow = Math.floor(
      HITS_TO_SHOW / hits.length + (totalHits - HITS_TO_SHOW) / hits.length
    );
  }

  for (const hitGroup of hits) {
    if (hitGroupHitsToShow && hitGroup.hits?.length > hitGroupHitsToShow) {
      hitGroup.hits.length = hitGroupHitsToShow;
    }

    const processed = hitGroup.hits?.map((hit) => ({
      hit,
      index: hitGroup.index ? t(`nav:searchIndexNames.${hitGroup.index}`) : "",
    })); // allow for single index structures

    processedHits = processedHits.concat(processed);
  }

  return (
    <MuiAutocomplete
      id={id || "searchBar"}
      freeSolo
      clearOnBlur
      clearOnEscape
      autoHighlight
      includeInputInList
      filterOptions={(options) =>
        customFilter
          ? options.filter((option) => customFilter(option))
          : options
      }
      getOptionDisabled={(option) =>
        customOptionDisabled && !customOptionDisabled(option)
      }
      onHighlightChange={(_event, value) => {
        if (!noPrefetch && value?.hit) {
          router.prefetch(value.hit.href);
        }
      }}
      classes={{
        root: classes.searchBar,
        option: classes.option,
        listbox: classes.listbox,
        popper: classes.popper,
      }}
      options={!inputValue ? [] : processedHits}
      groupBy={(option) => option?.index}
      renderOption={(_props, option) => {
        return (
          <li
            key={(option.hit.href || option.hit.objectID) + "-" + option.index}
            {..._props}
          >
            <div className={classes.optionMain}>
              {option.hit.imageUrl && (
                <Image
                  width={30}
                  height={30}
                  src={option.hit.imageUrl}
                  alt={option.hit.title}
                  style={{ objectFit: "cover", borderRadius: "4px" }}
                />
              )}

              <Highlight
                className={classes.marginLeft}
                attribute="title"
                hit={option.hit}
                tagName="em"
              />
            </div>
            <div className={classes.searchOptionUsageBadge}>
              <UsageIcon title={option.hit.title} user={user} />
              {option.hit.reviewsScore && (
                <ExpertReviewScoreBadge
                  showScore
                  reviews={{ reviewsScore: option.hit.reviewsScore }}
                />
              )}
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.hit?.title || ""}
      onChange={(_event, newValue) => {
        // Fires when an option is selected

        if (newValue?.hit) {
          if (customOnChange) {
            customOnChange(newValue);
          } else {
            // Don't fire if we're reseting the value to ''
            clearInputs();
            scrollToTop();
            router.push(newValue.hit.href);
          }
        }
      }}
      onInputChange={(_event, newInputValue) => {
        // fires when text is input in the box
        setInputValue(newInputValue);
        refine(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          variant="outlined"
          aria-label={t("nav:searchBox")}
          placeholder={placeholderText || t(`nav:${placeholderKey}`)}
          classes={{
            root: classes.searchBarTextField,
          }}
          id="searchInput"
          inputProps={{
            ...params.inputProps,
            value: inputValue,
            "aria-label": t("nav:searchInput"),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            ...params.InputProps,
            classes: {
              root: classes.inputBase,
            },
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => {
                  clearInputs();
                }}
              >
                {!inputValue ? (
                  <SearchIconButton
                    customButtonClasses={classes.showCloseIcon}
                    customIconClasses={classes.iconButton}
                  />
                ) : (
                  params.InputProps.endAdornment
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

Autocomplete.defaultProps = { placeholderKey: "searchGear" };

const CustomAutocomplete = connectAutoComplete(Autocomplete);

export default function SearchBar(props) {
  const { location, ...rest } = props;
  return (
    <InstantSearch searchClient={searchClient} indexName="wpsy_product">
      <Configure
        attributesToRetrieve={["title", "href", "imageUrl", "reviewsScore"]}
        hitsPerPage={HITS_TO_FETCH}
      />
      <CustomAutocomplete {...rest} />
      {ALGOLIA_INDEXES.filter((index) =>
        index.locations.includes(location)
      )?.map((index) => (
        <Index key={index.name} indexName={`wpsy_${index.name}`} />
      ))}
    </InstantSearch>
  );
}

SearchBar.propTypes = {
  location: PropTypes.oneOf(ALGOLIA_INDEXES.map((i) => i.locations).flat()),
  setShowSearchBar: PropTypes.func,
  shouldShowSearchBar: PropTypes.bool,
};
