import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";

const UsageIcon = ({ title, user, ...rest }) => {
  const matchedProduct = user?.athleteProducts?.edges.find(
    (p) => p.product?.title === title
  );

  return (
    matchedProduct &&
    (matchedProduct.usageStatus === "using" ? (
      <CheckCircleIcon fontSize="small" {...rest} />
    ) : (
      <VisibilityIcon fontSize="small" {...rest} />
    ))
  );
};

export default UsageIcon;
