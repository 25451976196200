import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faStrava } from "@fortawesome/free-brands-svg-icons/faStrava";

import { faSpotify } from "@fortawesome/free-brands-svg-icons/faSpotify";

import {
  twitterColor,
  stravaColor,
  facebookColor,
  instagramColor,
  whiteColor,
} from "../../assets/jss/nextjs-material-kit-pro";
// Because we have to explicitly import the relevant icon I've not found a DRYer way to do this.
// We could use dynamic imports in case any of the icons aren't require but this feels like overoptimisation as most are used on the footer anyway.

const SocialIcon = (props) => {
  const { channel, white, size } = props;

  if (channel === "strava")
    return (
      <FontAwesomeIcon
        icon={faStrava}
        color={white ? whiteColor : stravaColor}
        size={size}
      />
    );
  if (channel === "twitter")
    return (
      <FontAwesomeIcon
        icon={faTwitter}
        color={white ? whiteColor : twitterColor}
        size={size}
      />
    );
  if (channel === "facebook")
    return (
      <FontAwesomeIcon
        icon={faFacebook}
        color={white ? whiteColor : facebookColor}
        size={size}
      />
    );
  if (channel === "instagram")
    return (
      <FontAwesomeIcon
        icon={faInstagram}
        color={white ? whiteColor : instagramColor}
        size={size}
      />
    );

  if (channel === "spotify")
    return (
      <FontAwesomeIcon
        icon={faSpotify}
        color={white ? whiteColor : stravaColor}
        size={size}
      />
    );

  return <div />;
};

export default SocialIcon;

SocialIcon.propTypes = {
  channel: PropTypes.oneOf([
    "twitter",
    "facebook",
    "instagram",
    "strava",
    "spotify",
  ]).isRequired,
};

SocialIcon.defaultProps = { size: "sm" };
