import React from "react";

import PropTypes from "prop-types";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { makeStyles } from "tss-react/mui";

import IconButton from "@mui/material/IconButton";
import useTranslation from "next-translate/useTranslation";

import { grayDisabled } from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  directionButton: {
    zIndex: "100",
    color: theme.palette.secondary.main,
    pointerEvents: "auto",
  },

  absolute: {
    position: "absolute",
  },
  // positionTop70: { top: '70px' },
  //  positionTop180: { top: '180px' },

  absolutePositionRight: {
    right: "0px",
  },
  absolutePositionOutsideRight: {
    right: "-45px", //was -45// was 16, added 30px to account for 30px padding
    [theme.breakpoints.down("lg")]: {
      right: "-50px", //was -45// was 16, added 30px to account for 30px padding
    },
    [theme.breakpoints.down("md")]: {
      //   right: '-10px', //was -45// was 16, added 30px to account for 30px padding
    },
  },
  absolutePositionLeft: {
    left: "0px",
  },
  absolutePositionOutsideLeft: {
    /* '@media (min-width: 1078px)': {
      left: '2px'
    }, */
    //  [theme.breakpoints.down('lg')]: {
    left: "-45px", // was 9 added 30
    //  },
    [theme.breakpoints.down("lg")]: {
      left: "-50px",
    },
    [theme.breakpoints.down("md")]: {
      // left: '-10px',
    },
  },
  grayDisabled,
}));

function DirectionButton(props) {
  const {
    direction,
    handleClick,
    disabled,
    handleClickProps,
    positionAbsolutely,
    positionOutside,
  } = props;
  const { t } = useTranslation();

  const { classes, cx } = useStyles();

  return (
    <IconButton
      color="secondary"
      classes={{
        root: cx({
          [classes.directionButton]: true,
          [classes.absolute]: positionAbsolutely,
          [classes.absolutePositionRight]:
            !positionOutside && positionAbsolutely && direction === "next",
          [classes.absolutePositionOutsideRight]:
            positionAbsolutely && positionOutside && direction === "next",
          [classes.absolutePositionLeft]:
            !positionOutside && positionAbsolutely && direction === "previous",
          [classes.absolutePositionOutsideLeft]:
            positionAbsolutely && positionOutside && direction === "previous",
          //   [classes.positionTop70]: positionAbsolutely && positionFromTop === 70,
          //   [classes.positionTop180]:
          //      positionAbsolutely && positionFromTop === 180,
          //    [classes.overrideDirectionButtonAlignment]: true,
        }),
        disabled: classes.grayDisabled,
      }}
      component="a"
      disabled={disabled}
      aria-disabled={disabled}
      aria-label={t(`nav:${direction}`)}
      onClick={() => handleClick(direction, handleClickProps)}
      size="large"
    >
      {direction === "next" ? (
        <ChevronRightIcon fontSize="large" />
      ) : (
        <ChevronLeftIcon fontSize="large" />
      )}
    </IconButton>
  );
}

DirectionButton.propTypes = {
  direction: PropTypes.oneOf(["next", "previous"]).isRequired,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  handleClickProps: PropTypes.func, // Doesnt look like this should be Required?
  positionAbsolutely: PropTypes.bool,
  positionFromTop: PropTypes.oneOf([70, 180]), //.isRequired,
  positionOutside: PropTypes.bool,
};

export default DirectionButton;
