import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ReviewsIcon from "@mui/icons-material/Reviews";

const IconCard = (props) => {
  const { Icon, content } = props;
  const iconProps = {
    fontSize: "small",
    color: "secondary",
  };

  return (
    <Card sx={{ p: 1, minWidth: "145px" }}>
      <Icon {...iconProps} />
      <CardContent
        sx={{
          display: "block",
          p: 0,
          "&:last-child": {
            pb: 0,
          },
        }}
      >
        {content}
      </CardContent>
    </Card>
  );
};

const WhatItIsSection = () => {
  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2 }}
        sx={{ my: 2 }}
      >
        <IconCard
          Icon={NotificationsNoneIcon}
          content={
            "The best price when your shoes need replacing or are on offer"
          }
        />
        <IconCard
          Icon={ReviewsIcon}
          content={"Personalised recommendations based on your data"}
        />
        <IconCard
          Icon={SettingsSuggestIcon}
          content={"Automated activity management and perfomance insights"}
        />
      </Stack>
      <p>...and even more features to help get the most out of your gear</p>
    </>
  );
};

export default WhatItIsSection;
