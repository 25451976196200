const globalStyles = (theme) => ({
  body: {
    "& h1": {
      margin: theme.spacing(1, 0),
      fontWeight: 800,
      fontSize: "3rem",
      lineHeight: "1.15em",
      letterSpacing: "-.025em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
    },
    "& h2": {
      margin: theme.spacing(1, 0),
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: "1.5em",
      letterSpacing: "-.025em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.3rem",
      },
    },
    "& h3": {
      margin: theme.spacing(0.75, 0),
      fontWeight: 700,
      fontSize: "1.4rem",
      lineHeight: "1.4em",
      letterSpacing: "-.0125em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    "& h4": {
      margin: theme.spacing(0.75, 0),
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: "1.5em",
      letterSpacing: "-.0125em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    "& h5": {
      margin: theme.spacing(0.5, 0),
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: "1.4em",
      letterSpacing: "-.0125em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    "& h6": {
      margin: theme.spacing(0.25, 0),
      fontWeight: 400,
      fontSize: "0.85rem", // was 0.75
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },
    "& p": {
      /// fontSize: '1rem',// This is the default size so no need to set
      margin: theme.spacing(0, 0, 1),
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    "& ul": {
      // margin: 0,
      listStyleType: "square",
      // padding: 0,
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    "& a": {
      textDecoration: "none",
      backgroundColor: "transparent",
      color: theme.palette.primary.light,
      transition: theme.transitions.create("color", {
        duration: theme.transitions.duration.short,
      }),
      "&:hover": {
        color: theme.palette.secondary.main,
        textDecoration: "none",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
    },
    /*  '& img': {
      transition: 'all 300ms',
    }, */
  },

  ".MuiGrid-direction-xxs-column": {
    flexDirection: "column",
  },

  ".MuiGrid-direction-xxs-column-reverse": {
    flexDirection: "column-reverse",
  },

  ".MuiGrid-direction-xxs-row-reverse": {
    flexDirection: "row-reverse",
  },

  ".MuiGrid-wrap-xxs-nowrap": {
    flexWrap: "nowrap",
  },

  ".MuiGrid-wrap-xxs-wrap-reverse": {
    flexWrap: "wrap-reverse",
  },

  ".MuiGrid-align-items-xxs-center": {
    alignItems: "center",
  },

  ".MuiGrid-align-items-xxs-flex-start": {
    alignItems: "flex-start",
  },

  ".MuiGrid-align-items-xxs-flex-end": {
    alignItems: "flex-end",
  },

  ".MuiGrid-align-items-xxs-baseline": {
    alignItems: "baseline",
  },

  ".MuiGrid-align-content-xxs-center": {
    alignContent: "center",
  },

  ".MuiGrid-align-content-xxs-flex-start": {
    alignContent: "flex-start",
  },

  ".MuiGrid-align-content-xxs-flex-end": {
    alignContent: "flex-end",
  },

  ".MuiGrid-align-content-xxs-space-between": {
    alignContent: "space-between",
  },

  ".MuiGrid-align-content-xxs-space-around": {
    alignContent: "space-around",
  },

  ".MuiGrid-justify-xxs-center": {
    justifyContent: "center",
  },

  ".MuiGrid-justify-xxs-flex-end": {
    justifyContent: "flex-end",
  },

  ".MuiGrid-justify-xxs-space-between": {
    justifyContent: "space-between",
  },

  ".MuiGrid-justify-xxs-space-around": {
    justifyContent: "space-around",
  },

  ".MuiGrid-justify-xxs-space-evenly": {
    justifyContent: "space-evenly",
  },

  ".MuiGrid-spacing-xxs-1": {
    width: "calc(100% + 8px)",
    margin: "-4px",
  },

  ".MuiGrid-spacing-xxs-1>.MuiGrid-item": {
    padding: "4px",
  },

  ".MuiGrid-spacing-xxs-2": {
    width: "calc(100% + 16px)",
    margin: "-8px",
  },

  ".MuiGrid-spacing-xxs-2>.MuiGrid-item": {
    padding: "8px",
  },

  ".MuiGrid-spacing-xxs-3": {
    width: "calc(100% + 24px)",
    margin: "-12px",
  },

  ".MuiGrid-spacing-xxs-3>.MuiGrid-item": {
    padding: "12px",
  },

  ".MuiGrid-spacing-xxs-4": {
    width: "calc(100% + 32px)",
    margin: "-16px",
  },

  ".MuiGrid-spacing-xxs-4>.MuiGrid-item": {
    padding: "16px",
  },

  ".MuiGrid-spacing-xxs-5": {
    width: "calc(100% + 40px)",
    margin: "-20px",
  },

  ".MuiGrid-spacing-xxs-5>.MuiGrid-item": {
    padding: "20px",
  },

  ".MuiGrid-spacing-xxs-6": {
    width: "calc(100% + 48px)",
    margin: "-24px",
  },

  ".MuiGrid-spacing-xxs-6>.MuiGrid-item": {
    padding: "24px",
  },

  ".MuiGrid-spacing-xxs-7": {
    width: "calc(100% + 56px)",
    margin: "-28px",
  },

  ".MuiGrid-spacing-xxs-7>.MuiGrid-item": {
    padding: "28px",
  },

  ".MuiGrid-spacing-xxs-8": {
    width: "calc(100% + 64px)",
    margin: "-32px",
  },

  ".MuiGrid-spacing-xxs-8>.MuiGrid-item": {
    padding: "32px",
  },

  ".MuiGrid-spacing-xxs-9": {
    width: "calc(100% + 72px)",
    margin: "-36px",
  },

  ".MuiGrid-spacing-xxs-9>.MuiGrid-item": {
    padding: "36px",
  },

  ".MuiGrid-spacing-xxs-10": {
    width: "calc(100% + 80px)",
    margin: "-40px",
  },

  ".MuiGrid-spacing-xxs-10>.MuiGrid-item": {
    padding: "40px",
  },

  ".MuiGrid-grid-xxs-auto": {
    flexGrow: "0",
    maxWidth: "none",
    flexBasis: "auto",
  },

  ".MuiGrid-grid-xxs-true": {
    flexGrow: "1",
    maxWidth: "100%",
    flexBasis: "0",
  },

  ".MuiGrid-grid-xxs-1": {
    flexGrow: "0",
    maxWidth: "8.333333%",
    flexBasis: "8.333333%",
  },

  ".MuiGrid-grid-xxs-2": {
    flexGrow: "0",
    maxWidth: "16.666667%",
    flexBasis: "16.666667%",
  },

  ".MuiGrid-grid-xxs-3": {
    flexGrow: "0",
    maxWidth: "25%",
    flexBasis: "25%",
  },

  ".MuiGrid-grid-xxs-4": {
    flexGrow: "0",
    maxWidth: "33.333333%",
    flexBasis: "33.333333%",
  },

  ".MuiGrid-grid-xxs-5": {
    flexGrow: "0",
    maxWidth: "41.666667%",
    flexBasis: "41.666667%",
  },

  ".MuiGrid-grid-xxs-6": {
    flexGrow: "0",
    maxWidth: "50%",
    flexBasis: "50%",
  },

  ".MuiGrid-grid-xxs-7": {
    flexGrow: "0",
    maxWidth: "58.333333%",
    flexBasis: "58.333333%",
  },

  ".MuiGrid-grid-xxs-8": {
    flexGrow: "0",
    maxWidth: "66.666667%",
    flexBasis: "66.666667%",
  },

  ".MuiGrid-grid-xxs-9": {
    flexGrow: "0",
    maxWidth: "75%",
    flexBasis: "75%",
  },

  ".MuiGrid-grid-xxs-10": {
    flexGrow: "0",
    maxWidth: "83.333333%",
    flexBasis: "83.333333%",
  },

  ".MuiGrid-grid-xxs-11": {
    flexGrow: "0",
    maxWidth: "91.666667%",
    flexBasis: "91.666667%",
  },

  ".MuiGrid-grid-xxs-12": {
    flexGrow: "0",
    maxWidth: "100%",
    flexBasis: "100%",
  },
  // },
});

export default globalStyles;
