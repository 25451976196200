
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
//  Core
import React from "react";

import Head from "next/head";
import { CacheProvider } from "@emotion/react";
import NextTopLoader from "nextjs-toploader";

import createEmotionCache from "assets/createEmotionCache";
import Router from "next/router";
import { ApolloProvider } from "@apollo/client";

import { useApollo } from "apollo/apolloClient";
import CssBaseline from "@mui/material/CssBaseline";
import { Inter } from "next/font/google";

import ErrorBoundary from "components/ErrorComponent/ErrorBoundary";
import MainLayout from "components/Layouts/MainLayout";
import ContainedLayout from "components/Layouts/ContainedLayout";
import { ScreenSizesProvider } from "components/Providers/ScreenSizesProvider";
//import PageChangeProvider from "components/Providers/PageChangeProvider";
import { FullUserProvider, AuthProvider } from "auth/useUser";
import { DealsLocaleProvider } from "components/Providers/DealsLocaleProvider";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { TrackingProvider } from "components/TrackingProvider/TrackingProvider";
//import runQuantcast from 'third-parties/quantcast';
// Seo:
import { defaultLocale } from "i18n";

import customTheme from "settings/theme.js";

// Core CSS styles
import "assets/scss/nextjs-material-kit-pro.scss";

//import 'react-image-gallery/styles/scss/image-gallery.scss';
//import 'public/fonts/style.css';
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css"; // Import the CSS
import { PopUpProvider } from "../components/Providers/PopUpProvider";
import { secondaryColor } from "../assets/jss/nextjs-material-kit-pro";

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

const defaultFont = Inter({ subsets: ["latin"] });

const theme = createTheme(...customTheme(defaultFont));

if (parseInt(process.env.NEXT_PUBLIC_SHOW_THEME || "")) {
  console.log("[Theme]:", theme);
}
const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) {
  // componentDidMount() {
  // TagManager.initialize({ gtmId: process.env.TAG_miscANAGER_ID });
  // initAnalytics();

  // force reload using dyanamic params when user presses 'back':

  if (!parseInt(process.env.NEXT_PUBLIC_PREVENT_HARD_HISTORY_REWRITE)) {
    if (typeof window !== "undefined") {
      Router.beforePopState(({ as }) => {
        // ** DISABLED - Almost all routes are dymamic in some way - only way to ensure accurate navigation is to use this on all routes (for now)
        // check if the as/url ends with a number (eg pagination) or is a products page...
        //if (!isNaN(as[as.length - 1]) || as.indexOf('products') !== -1) {

        // the below forces a hard refresh on browser "back" so that the correct pages are loaded with their queries from their urls
        window.location.href = as;
      });
    }
  }
  // const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    /* const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    } */
    // setMounted(true);
    /*  return () => {
      setMounted(false);
    }; */
  }, []);

  // if we go multi language this will become dynamic. NB this will also need to be passed to the PageChange component above somehow, or all text items (titles etc) will need to be hidden behind skeletons on page change.
  const lang = defaultLocale;
  const fullScreen = Component.fullScreen || pageProps.fullScreen;
  const fullWidth = Component.fullWidth || pageProps.fullWidth;

  const bottomStripSize =
    Component.bottomStripSize || pageProps.bottomStripSize;
  const isLandingPage = pageProps.isLandingPage;

  const apolloClient = useApollo();
  return (
    <>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
          </Head>
          <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {/*  <PageChangeProvider theme={theme} lang={lang}> */}
              <ScreenSizesProvider>
                <NextTopLoader showSpinner={false} color={secondaryColor} />

                <AuthProvider>
                  <TrackingProvider>
                    <FullUserProvider>
                      <DealsLocaleProvider lang={lang}>
                        <MainLayout
                          lang={lang}
                          fullScreen={fullScreen}
                          isLandingPage={isLandingPage}
                          bottomStripSize={bottomStripSize}
                        >
                          <ErrorBoundary lang={lang}>
                            <ContainedLayout
                              bypass={fullScreen}
                              fullWidth={fullWidth}
                            >
                              <PopUpProvider>
                                <Component lang={lang} {...pageProps} />
                              </PopUpProvider>
                            </ContainedLayout>
                          </ErrorBoundary>
                        </MainLayout>
                      </DealsLocaleProvider>
                    </FullUserProvider>
                  </TrackingProvider>
                </AuthProvider>
              </ScreenSizesProvider>
              {/* </PageChangeProvider> */}
            </ThemeProvider>
          </ApolloProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </>
  );
}

// Wraps all components in the tree with the data provider
const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  