const { CMS_URL } = require("../settings/global-urls.js");
const { expiresAtCalculator } = require("./expiresAtCalculator.js");

export const updateAccessToken = async function updateAccessToken(
  refreshToken
) {
  const url = `${CMS_URL}/oauth/token`;

  const data = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };

  const requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      Authorization: `Basic ${process.env.WORDPRESS_CREDS_ENCODED}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const updatedTokens = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    });
  // calculate expiry time before returning tokens object

  updatedTokens.expires_at =
    !updatedTokens.error && expiresAtCalculator(updatedTokens.expires_in);

  return updatedTokens;
};
