import dynamic from "next/dynamic";

import React, { useContext, createContext } from "react";
import Router from "next/router";
import { handleLogout } from "components/Login/UserNav";
import { WRITE_SCOPE } from "settings/global-website-config";
import { useFullUser } from "auth/useUser.js";
import { EmailInput } from "pages-sections/dashboard/SettingsSection.js";
import { validateEmail } from "../../modules/utils";

const PopUp = dynamic(() => import("components/PopUpMessage/PopUp.js"), {
  ssr: false,
});

const getPopUpContent = (popUpProps, userProps, user, value, setValue) => {
  const { contentKey, onAccept, mutate, ...rest } = popUpProps;
  const { setLoggingOut } = userProps;

  if (!contentKey) {
    // If no key...

    return { ...rest };
  }
  switch (contentKey) {
    case "featureNotLive": {
      return {
        content: (
          <div>
            {
              "This feature isn't publicly available just yet. But you can sign up to the Beta to be one of the first to try it out."
            }
          </div>
        ),
        alertButtonText: "Learn more",
        onAlertAccept: () => {
          Router.push("/beta");
        },
      };
    }
    case "email": {
      const { isUpdating, isUpdatingError, handleUpdateUser } = userProps;
      const customChange = (e) => {
        if (e.target?.value !== value) {
          if (!e.target.value) {
            setValue(false);
          } else {
            const isValid = validateEmail(e.target.value)
              ? e.target.value
              : false;
            setValue({ value: e.target.value, isValid });
          }
        }
      };
      return {
        content: (
          <>
            <div>Please confirm your email address to enable Alerts</div>
            <EmailInput
              customOnChange={customChange}
              required
              fullWidth
              hideHelperText
              isUpdating={isUpdating}
              isUpdatingError={isUpdatingError}
              handleUpdateUser={handleUpdateUser}
              athlete={user}
              noBlur
              validateOnBlur={false}
              emailValue={value?.value}
            />
          </>
        ),
        showCancelButton: true,
        alertButtonText: "Save email",
        alertButtonDisabled: !value?.isValid || isUpdatingError, //!user.communicationPreferences.email,
        onAlertAccept: () => {
          handleUpdateUser({
            event: { target: { value: value.value } },
            paths: ["communicationPreferences", "email"],
            type: "athlete",
            setLoadingState: userProps.setLoadingState,
          });

          onAccept();
        },
      };
    }
    case WRITE_SCOPE:
      return {
        content:
          "You haven't given us sufficient permissions for this feature. To enable it, log out now and accept all options when you log back in.",
        showCancelButton: true,
        alertButtonText: "OK, log me out now",
        onAlertAccept: () => {
          handleLogout(Router, mutate, setLoggingOut, "?login=true");
        },
      };

    case "default":
      return "NO CONTENT FOUND for KEY:", contentKey;
  }
};

// create  hook

const popUpContext = createContext([]);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function PopUpProvider({ children }) {
  const [popUpProps, setPopUpProps] = React.useState(false);
  const [user, userProps] = useFullUser();

  const [value, setValue] = React.useState(null);
  let fullProps;

  if (popUpProps) {
    fullProps = getPopUpContent(popUpProps, userProps, user, value, setValue);
  }
  /*  const fullProps = React.useMemo(() => {
    if (popUpProps) {
      return getPopUpContent(popUpProps, userProps, user, value, setValue, t);
    }
    return {};
  }, [value, popUpProps, userProps]); */

  return (
    <popUpContext.Provider value={[popUpProps, setPopUpProps, value, setValue]}>
      <PopUp
        showMessage={Boolean(popUpProps)}
        setShowMessage={setPopUpProps}
        small
        value={value}
        setValue={setValue}
        hideCloseButton={popUpProps?.hideCloseButton}
        {...fullProps}
      />
      {children}
    </popUpContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const usePopUp = () => {
  return useContext(popUpContext);
};
