import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

import Button from "components/CustomButtons/Button.js";

import { labelBold } from "../../assets/jss/nextjs-material-kit-pro";
import { handleAffiliateClick } from "../Links/AffiliateLink";

const useStyles = makeStyles()((theme) => ({
  labelBold,
  buyButton: {
    zIndex: 2,
    minHeight: 40,
    minWidth: "120px",
    padding: theme.spacing(0.5),
    "& a": { /*  padding: '10px', */ color: "unset" }, // Padding should be on the anchor tag to make the whole button clickable

    "&svg": {
      margin: "0px",
      display: "block !important",
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "80px",
    },
  },
}));

const BuyButton = (props) => {
  const { bold, deal, product, from, currency, children, ...rest } = props;

  const { classes, cx } = useStyles();
  return (
    <Button
      color="secondary"
      classes={{
        label: cx({ [classes.labelBold]: bold }),
        root: classes.buyButton,
      }}
      loading={deal && deal.loading}
      disabled={deal.noStock}
      component="a"
      target="_blank"
      rel="sponsored noopener"
      href={deal?.url}
      onClick={() => handleAffiliateClick(deal, product, from, currency)}
      {...rest}
    >
      {children}
    </Button>
  );
};

BuyButton.propTypes = {
  bold: PropTypes.any,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
};

export default BuyButton;
