import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  fadeOverlay: {
    transition: "opacity 0.3s ease",
    opacity: 1,
    position: "absolute",
    // transition: '3s ease all',
    display: "block",
    zIndex: 100,
  },
  hideOverlay: {
    opacity: 0,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  horizontal: {
    width: "100%",
    height: "50px",
    bottom: "0px",
    backgroundImage: `linear-gradient(to bottom, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 1)
    100%)`,
  },
  // eslint-disable-next-line tss-unused-classes/unused-classes
  vertical: {
    height: "100%",
    width: "20px",
    right: "-1px",
    top: 0,
    bottom: 0,
    backgroundImage: `linear-gradient(to right, 
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 1)
    100%)`,
  },
});

const FadeOverlay = (props) => {
  const { orientation, inProp } = props;
  const { classes, cx } = useStyles();

  const overlayClasses = cx({
    [classes.fadeOverlay]: true,
    [classes[orientation]]: orientation,
    [classes.hideOverlay]: !inProp,
  });

  return <div className={overlayClasses} />;
};
FadeOverlay.defaultProps = { inProp: true };

FadeOverlay.propTypes = {
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
};

export default FadeOverlay;
