import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import AddIcon from "@mui/icons-material/Add";
import { useFullUser } from "auth/useUser";
import { makeStyles } from "tss-react/mui";
import { useRenders } from "../../modules/utils";
import LoggedInFeatureButton from "./LoggedInFeatureButton";

const useStyles = makeStyles()({
  button: {
    "&:hover": {
      "& span": { display: "none" },
      "&:before": { content: '"Unfollow"' },
    },
  },
});

const ButtonComponent = (props) => {
  const { handleClick, isFollowing, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <Button
      color="secondary"
      sx={{ minWidth: "108px" }}
      startIcon={!isFollowing ? <AddIcon /> : null}
      onClick={handleClick}
      size="small"
      className={cx({ [classes.button]: isFollowing })}
      {...rest}
    >
      <span>{!isFollowing ? "Follow" : "Following"}</span>
    </Button>
  );
};
function FollowButton(props) {
  // Athlete is the public entity; user is the private
  const { athlete, demoOnly, ...rest } = props;

  const [user, { handleUpdateUser }] = useFullUser();

  useRenders("followButton");

  const isFollowing = user?.following?.nodes.some((f) => f.id === athlete.id);

  const handleClick = () => {
    if (!isFollowing) {
      if (!user.following) {
        user.following = { nodes: [] };
      }
    }
    handleUpdateUser({
      event: {
        target: {
          value: athlete,
        },
      },
      type: "athlete",
      isArray: true,
      name: "athleteId",
      shortcutValue: athlete.databaseId,
      shouldAdd: !isFollowing,
      target: user.following.nodes,
      operation: "follow",
      additionalVars: { loggedInUserId: user.databaseId },
    });
  };

  if (!demoOnly && user && user.databaseId === athlete.databaseId) {
    return <div />;
  }
  return (
    <LoggedInFeatureButton
      {...rest}
      handleClick={handleClick}
      isFollowing={isFollowing}
      type="followButton"
      queryParams={{ follow: athlete.databaseId }}
      redirectToPage
      Component={ButtonComponent}
    />
  );
}

FollowButton.propTypes = {
  athlete: PropTypes.object.isRequired,
  handleUserUpdate: PropTypes.func,
};

export default FollowButton;
