import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import useSSR from "use-ssr";

const MobileViewContext = React.createContext({
  mobileOpen: false,
  setMobileOpen: () => {},
  //  isSmScreen: false,
});

export const ScreenSizesProvider = ({ children }) => {
  const [mounted, hasMounted] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.startTransition(() => {
    if (!mounted) {
      hasMounted(true);
    }
  }, []);

  const theme = useTheme();

  const { isBrowser } = useSSR();

  let valueProps = {
    mobileOpen,
    setMobileOpen,
    sizes: {},
  };

  const isXssScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isXsScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMdScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isLgScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  // if (isBrowser) {
  const useWidth = () => {
    if (!isBrowser) return "sm";
    if (isXssScreen) return "xss";
    if (isXsScreen) return "xs";
    if (isSmScreen) return "sm";
    if (isMdScreen) return "md";
    if (isLgScreen) return "lg";
    if (isXlScreen) return "xl";
    return "xl";
  };
  const width = useWidth();

  // Having issues with themeProvider but could do something like this:
  /*  const isMobileView = width === 'xs';
  const isCollapsedMenu = width === 'sm';

  const isTabletView = width === 'sm';
  const isLaptopView = width === 'md';
  const isDesktopView = width === 'lg'; */

  // const isExtraSmallScreen = useMediaQuery('(max-width:550px)');

  // isMdScreen => isSmScreen => isMobileView
  //const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  // TO CHANGE: isSmScreen to isMdScreen (isCollapseMenu)
  // isSmScreen to isSmScreen (isMobileView)

  // const menuBreakpoint = 'sm'; // SHOW_STRAVA_LOGIN ? 'md' : 'sm'; // This was causing issues on product page with deals on largest view
  //isMdScreen;=> isMdScreen
  // const isCollapsedMenu = useMediaQuery(theme.breakpoints.down(menuBreakpoint));

  //isMdScreen
  //const isTabletView = useMediaQuery(theme.breakpoints.down('md'));
  const sizes = !isBrowser
    ? {}
    : {
        isXsScreen,
        isSmScreen,
        isMdScreen,
        isLgScreen,
        isXlScreen,
      };
  if (parseInt(process.env.NEXT_PUBLIC_SHOW_SCREEN_SIZES)) {
    console.log("***[Screen sizes] | Width:", width?.toUpperCase(), "***");
  }

  valueProps = {
    ...valueProps,
    ...sizes,
    width,
  };
  // }
  return (
    <MobileViewContext.Provider value={valueProps}>
      {children}
    </MobileViewContext.Provider>
  );
};

export const useScreenSizes = () => {
  return React.useContext(MobileViewContext);
};

export default MobileViewContext;
