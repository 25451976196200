import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { bootsSvgProps as svgProps } from "settings/iconPaths.js";

function Icon(props) {
  const { path, viewBox } = svgProps;
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d={path} />
    </SvgIcon>
  );
}

export default Icon;
