import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React from "react";
import Image from "components/Image/CustomImage";

import { useScreenSizes } from "components/Providers/ScreenSizesProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStrava } from "@fortawesome/free-brands-svg-icons/faStrava";
import { useAuth } from "auth/useUser.js";

import { makeStyles } from "tss-react/mui";
import Slide from "@mui/material/Slide";

import LoginSection from "components/Login/LoginSection";

import useTranslation from "next-translate/useTranslation";

// core components
import Button from "components/CustomButtons/Button";

import { hoverShadow } from "../../assets/jss/nextjs-material-kit-pro";

//import Tooltip from '@mui/material/Tooltip';
//import Backdrop from '@mui/material/Backdrop';
//import Fade from '@mui/material/Fade';

const Tooltip = dynamic(() => import("@mui/material/Tooltip"));
const Backdrop = dynamic(() => import("@mui/material/Backdrop"));
const Fade = dynamic(() => import("@mui/material/Fade"));

const useStyles = makeStyles()((theme) => ({
  syncGearButton: {
    display: "inline",
    whiteSpace: "noWrap",
    // width: '100%',
    padding: theme.spacing(0, 2),
    height: "40px", // was 39px
    // borderRadius: '0px',

    "& img": {
      marginBottom: theme.spacing(0.5),
    },
    margin: theme.spacing(0, 0, 0, 2),

    "& a": {
      marginRight: theme.spacing(0.5),
      color: theme.palette.common.white,
      alignItems: "center",

      "&:hover,&:focus": {
        ...hoverShadow,
        fontWeight: "400",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 1),
      height: "30px",
      lineHeight: "unset",
    },
  },
  labelWithImage: { display: "inline-flex" },
  loginButtonLogo: {
    minWidth: "80px",
    justifyContent: "center",
    verticalAlign: "middle",
  },
  betaTooltip: { fontSize: "1rem", padding: theme.spacing(2) },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
if (process.env.NODE_ENV !== "production") {
  Transition.displayName = "Transition";
}
export default function StravaLogin(props) {
  const { location, type, Component, ...rest } = props;

  const [loginModal, setLoginModal] = React.useState(false);
  const { width, isXsScreen } = useScreenSizes();

  const [user, _, hasBetaAccess] = useAuth(); // useAuth()

  const router = useRouter();

  React.useEffect(() => {
    if (router?.query?.login && !loginModal) {
      setLoginModal("login");
      router.push("/");
    }
  }, [loginModal, router]);

  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const STRAVA_POWERED_BY_WHITE_PATH =
    "/images/logos/strava/api_logo_pwrdBy_strava_stack_white";

  const shouldShowBetaTooltip =
    parseInt(process.env.NEXT_PUBLIC_IS_BETA) && location === "header"; // was sync?

  const showBetaTooltip =
    !loginModal && hasBetaAccess === "hasAccess" && location === "header";

  const REDIRECT_ONLY = parseInt(process.env.NEXT_PUBLIC_REDIRECT_STRAVA_LOGIN);

  const redirect = React.useCallback(() => router.push("/beta"), [router]);

  const Inner = React.useMemo(
    () =>
      Component ? (
        <Component
          handleClick={() => (REDIRECT_ONLY ? redirect() : setLoginModal(type))}
        />
      ) : (
        <Button
          size="small"
          classes={{
            root: cx({
              [classes.syncGearButton]: location !== "notloggedin",
              [classes.labelWithImage]: width === "xl" || location !== "header",
              //[classes.labelWithIcon]: width !== 'xl',
            }),
            endIcon: classes.loginButtonLogo,
          }}
          color="secondary"
          onClick={() => (REDIRECT_ONLY ? redirect() : setLoginModal(type))}
          endIcon={
            type !== "signup" &&
            (width !== "xl" && location === "header" ? (
              <span>
                <FontAwesomeIcon icon={faStrava} size="sm" />
              </span>
            ) : (
              <Image
                src={`${STRAVA_POWERED_BY_WHITE_PATH}.svg`}
                onError={(e) => {
                  if (e.target.src !== `${STRAVA_POWERED_BY_WHITE_PATH}.svg`) {
                    e.target.src = `${STRAVA_POWERED_BY_WHITE_PATH}.png`;
                  }
                }}
                alt="Connect with Strava"
                height={40}
                width={80}
                style={{ objectFit: "fill" }}
                noPlaceholder
              />
            ))
          }
        >
          {/*   <span className={classes.navLink}> */}
          {type === "sync" && isXsScreen
            ? t(`nav:loginTitle.syncShort`)
            : t(`nav:loginTitle.${type}Med`)}
          {/*    </span> */}
        </Button>
      ),
    [
      Component,
      cx,
      classes.syncGearButton,
      classes.labelWithImage,
      classes.loginButtonLogo,
      location,
      width,
      type,
      isXsScreen,
      t,
      REDIRECT_ONLY,
      redirect,
    ]
  );

  return (
    <div id={`sync-gear-buttons-${location}`}>
      {(!user || user.provider === "wordpress") &&
        (shouldShowBetaTooltip ? (
          <>
            <Backdrop open={showBetaTooltip} />
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              open={showBetaTooltip}
              title={t("nav:beta.loginTooltip")}
              placement="bottom-start"
              classes={{
                //  popper: classes.betaPopper,
                tooltip: classes.betaTooltip,
              }}
            >
              {Inner}
            </Tooltip>
          </>
        ) : (
          Inner
        ))}
      <LoginSection
        Transition={Transition}
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        type={type}
        location={location}
        {...rest}
      />
    </div>
  );
}

StravaLogin.propTypes = {
  location: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "sync",
    "login",
    "followButton",
    "reviewButton",
    "watchProduct",
  ]),
  user: PropTypes.any,
};

StravaLogin.defaultProps = {
  type: "sync",
};
