export default async function fetchSizeCharts(category, brand) {
  if (!category) {
    throw new Error("No category supplied to fetchSizeCharts");
  }
  const params = { category: category.slug };
  if (brand) {
    params.brand = brand.slug || brand;
  }

  const url = [`/api/sizeCharts`, new URLSearchParams(params)].join("?");
  const newSizeCharts = await fetch(url).then((response) => response.json());

  console.log(
    "[FetchSizeCharts] | Returning sizeCharts | Brand:",
    brand?.slug || "none",
    newSizeCharts
  );
  return newSizeCharts;
}
