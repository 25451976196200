import PropTypes from "prop-types";
import Transition from "react-transition-group/Transition";

import { fadeDuration } from "settings/global-website-config.js";

export default function CustomFade(props) {
  const { inProp, outOpacity = 0, children, duration, width } = props;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: outOpacity,
    zIndex: "unset", // This is newly added to place term tags above image dropshadow - watch out for issues
    width,
    // position: 'relative',
  };

  const outDisplay = outOpacity ? "inherit" : "none";

  const transitionStyles = {
    entering: { opacity: outOpacity, display: "relative" },
    entered: { opacity: 1, display: "inline" },
    exited: { opacity: outOpacity, display: outDisplay },
  };

  return (
    <Transition in={Boolean(inProp)} timeout={duration}>
      {(state) => (
        <span
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </span>
      )}
    </Transition>
  );
}

CustomFade.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  duration: PropTypes.number,
  inProp: PropTypes.any,
  outOpacity: PropTypes.number,
  width: PropTypes.string,
};

CustomFade.defaultProps = { width: "100%", duration: fadeDuration };
