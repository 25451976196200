import React from "react";

// If loading a variable font, you don't need to specify the font weight

// components/layouts/main
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import { DefaultSeo } from "next-seo";
import { DefaultSeoConfig } from "settings/global-seo.config";
import { makeStyles } from "tss-react/mui";
import { isMaintenanceMode } from "modules/utils";
import { PAGE_TRANSITION_CONTAINER_ID } from "settings/global-website-config";
import { bodyMinHeight } from "../../assets/jss/nextjs-material-kit-pro";
// Causes hydration error-> don't do!
// const Footer = dynamic(() => import('components/Footer/Footer'));

const useStyles = makeStyles()({
  // fullScreenContainer,
  // topStrip,
  // topStripLimitedSite,
  bodyMinHeight,
});

const MainLayout = ({
  fullScreen,
  isLandingPage,
  bottomStripSize,
  lang,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <DefaultSeo {...DefaultSeoConfig} />
      {!isMaintenanceMode && (
        <>
          <Header
            lang={lang}
            fullScreen={fullScreen}
            isLandingPage={isLandingPage}
          />
        </>
      )}
      <div id={PAGE_TRANSITION_CONTAINER_ID} />
      <div id="page-body" className={classes.bodyMinHeight}>
        {children}
      </div>
      <Footer
        lang={lang}
        isMaintenanceMode={isMaintenanceMode}
        bottomStripSize={bottomStripSize}
      />
    </>
  );
};

export default MainLayout;
