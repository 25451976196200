import { makeStyles } from "tss-react/mui";

import { PriceRange } from "components/PriceComparison/PriceComparisonModule";

import { NoSizeSetMessage } from "components/PriceComparison/DealsList";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles()({
  smallText: {
    "& p": {
      fontSize: "14px",
    },
  },
});

const NoSizeSetBlock = ({ smallText, deals, hideSetSizeMessage, ...rest }) => {
  const { classes, cx } = useStyles();

  return (
    <GridItem className={cx({ [classes.smallText]: smallText })}>
      <p>
        <PriceRange deals={deals} />
      </p>
      {!hideSetSizeMessage && <NoSizeSetMessage {...rest} />}
    </GridItem>
  );
};

export default NoSizeSetBlock;
