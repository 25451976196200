import checkIfModal from "modules/checkIfModal.js";

const checkIfIsExpandingHeader = (path) =>
  !!(
    path === "/" ||
    path === "/beta" ||
    path === "/strava" ||
    path === "/404" ||
    checkIfModal(path, true)
  );

export default checkIfIsExpandingHeader;
