//import Zoom from '@mui/material/Zoom';
//import Grow from '@mui/material/Grow';
import globalStyleOverrides from "settings/globalStyles.js";
//import { isWebKit154 } from 'modules/utils';

const {
  primaryColor,
  secondaryColor,
  textDefaultColor,
  grayColor,
  whiteColor,
  //defaultFont,
  defaultLetterSpacing,
  defaultBorderRadius,
} = require("assets/jss/nextjs-material-kit-pro");

/* const isWebKit154 =
  typeof navigator !== 'undefined' &&
  /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) &&
  /(os |version\/)15(.|_)[4-9]/i.test(navigator.userAgent);

// Should be able to remove this after upgrade to v5
// https://github.com/mui/material-ui/pull/32304#issuecomment-1122753563
const tempTrans = {
  TransitionComponent: isWebKit154 ? Zoom : Grow,
}; */

const colorTransition = `color 250ms cubic-bezier(0.4, 0, 0.2, 1) !important`;

const customTheme = (defaultFont) => {
  const theme = {
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor, //'#00796b'
      },
      gray: { main: grayColor[6], light: grayColor[0], dark: grayColor[10] },
      // white: { main: whiteColor },
      text: { primary: textDefaultColor },
    },
    breakpoints: {
      values: {
        xxs: 0,
        xs: 400,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        //    mobile: 414,
        //  tablet: 640,
      },
    },
    /* props: {
    MuiPopover: tempTrans,
    MuiTooltip: tempTrans,
  }, */
    shape: {
      borderRadius: defaultBorderRadius,
    },
    typography: {
      allVariants: {
        fontFamily: defaultFont?.style.fontFamily,
        letterSpacing: defaultLetterSpacing,
        //   color: textDefaultColor, // SShould be set inside Palette?
      },
      button: {
        textTransform: "none",
        fontWeight: 500,
      },
    },
  };

  return [
    theme,
    {
      components: {
        MuiCssBaseline: {
          styleOverrides: globalStyleOverrides,
        },
        MuiLink: {
          defaultProps: {
            underline: "none",
          },
        },
        MuiFormControl: {
          defaultProps: { margin: "dense" },
        },
        MuiAutocomplete: {
          defaultProps: { size: "small" },
          styleOverrides: { root: { borderRadius: defaultBorderRadius } },
        },
        MuiPaper: {
          defaultProps: { elevation: 24 },
        },
        MuiTooltip: {
          defaultProps: { arrow: true, placement: "top" },
          styleOverrides: {
            tooltip: {
              fontSize: "0.8em",
              textAlign: "center",
              lineHeight: "initial", // prevents layout shift after the tooltip pops up on longer content (3+ lines)
            },
          },
        },
        MuiAlert: {
          styleOverrides: { root: { display: "inline-flex" } },
        },
        MuiInputBase: {
          defaultProps: { size: "small" },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: { border: "1px solid rgba(0, 0, 0, 0.12)" }, // To be consistent with MuiPaper oultined variant
          },
        },
        MuiCard: { defaultProps: { variant: "outlined" } },
        MuiButton: {
          defaultProps: {
            variant: "contained",
            color: "secondary",
          },
          variants: [
            {
              props: { variant: "onBackground" },
              style: {
                "& a": {
                  color: whiteColor,
                },
                color: whiteColor,
                fontSize: "1.1rem",
                "&:hover": {
                  //  boxShadow: "none",
                  color: whiteColor,
                  "& a": {
                    color: whiteColor,
                  },
                },
                border: "3px solid white",
              },
            },
            {
              props: { variant: "link" },
              style: {
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            },
            {
              props: { variant: "link", color: "primary" },
              style: {
                color: primaryColor,
                "&:hover": {
                  color: secondaryColor,
                },
              },
            },
            {
              props: { variant: "link", color: "secondary" },
              style: {
                color: secondaryColor,
                "&:hover": {
                  color: primaryColor,
                },
              },
            },
            {
              props: { variant: "text", color: "gray" },
              style: {
                "& > a": {
                  color: grayColor[0],
                  "&:hover": {
                    color: grayColor[1], // Was 'unset' but makes the link disappear on hover (ie athlete profile page)
                  },
                },
                "&:hover": {
                  color: grayColor[1],
                },
              },
            },
            {
              props: { color: "gray" },
              style: {
                "&:hover": {
                  color: whiteColor,
                },
              },
            },
          ],
          styleOverrides: {
            text: ({ ownerState }) => ({
              transition: colorTransition,
              "&:hover": {
                color: `${
                  ownerState.color === "primary"
                    ? primaryColor
                    : ownerState.color === "secondary"
                    ? secondaryColor
                    : ownerState.color === "gray"
                    ? grayColor[0]
                    : "inherit"
                }`,
              },
            }),

            contained: {
              fontSize: "1rem",
              boxShadow: "unset",
              "&:hover,&:focus": {
                a: {
                  color: "unset",
                  "& svg": { color: "unset" },
                },
              },

              transition: `background-${colorTransition}`,
            },
            containedSecondary: {
              "&:hover,&:focus": {
                color: whiteColor,
              },
            },
            containedPrimary: {
              "&:hover,&:focus": {
                color: whiteColor,
              },
            },
          },
        },
      },
    },
  ];
};

export default customTheme;
