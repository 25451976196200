// TODO: MOve below items into WP Options

const isDevelopment = process.env.VERCEL_ENV === "development";
const isProduction = process.env.VERCEL_ENV === "production";

function getServerUrl(url) {
  if (!isDevelopment) {
    return url;
  }

  // NB: Not actually correct. Need to find a new way of finding the port
  const port = process.env.PORT || "3000";
  const serverUrl = `http://localhost:${port}`;
  return serverUrl;
}

const PRODUCTION_ROOT_URL = "https://www.geerly.com";
const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || PRODUCTION_ROOT_URL;
const ROOT_URL = getServerUrl(process.env.NEXT_PUBLIC_ROOT_URL) || SITE_URL;

const CMS_URL = process.env.CMS_URL || "https://cms.geerly.com";
const GRAPHQL_URL = parseInt(process.env.NEXT_PUBLIC_BYPASS_CDN)
  ? `${CMS_URL}/graphql`
  : "https://geerly.graphcdn.app";
const AUTH_URL = "/?oauth=token";
const ENGINE_URL = process.env.ENGINE_URL || "http://aux.engine.geerly.com";
const QSTASH_URL = "https://qstash.upstash.io/v1/publish/";
const API_PROCESSING_URL = ROOT_URL + "/api/incoming/strava/process";
const API_REPROCESSING_URL = ROOT_URL + "/api/incoming/strava/reprocess";

const loggerUrl = `${ROOT_URL}/api/logger?type=`;
const errorLoggerUrl = `${loggerUrl}error&path=`;
const SANDBOX_PAGE_URL = "test_sandbox";
const cloudinaryBaseUrl = `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/images/`;
//  'http://geerly-price-engine-aux-production.eba-pbg8i85i.us-east-1.elasticbeanstalk.com/';

const STRAVA_SETTINGS_URL = "https://www.strava.com/settings/gear";
const getShortenedLink = (node) => {
  const start = "geer.ly";
  switch (node.__typename) {
    case "Athlete":
      return `${start}/${node.slug}`;

    case "default":
      console.log("Invalid type passed to link shortener");
  }
};

module.exports = {
  getShortenedLink,
  CMS_URL,
  GRAPHQL_URL,
  AUTH_URL,
  ROOT_URL,
  ENGINE_URL,
  SITE_URL,
  QSTASH_URL,
  loggerUrl,
  API_PROCESSING_URL,
  API_REPROCESSING_URL,
  errorLoggerUrl,
  SANDBOX_PAGE_URL,
  cloudinaryBaseUrl,
  STRAVA_SETTINGS_URL,
  isDevelopment,
  isProduction,
  PRODUCTION_ROOT_URL,
};
