import React from "react";

import Link from "next/link";

import { makeStyles } from "tss-react/mui";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CategoryIcon from "components/CustomIcon/CategoryIcon.js";
import ListItemIcon from "@mui/material/ListItemIcon";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import mainMenuDev from "staticContent/main-menu-dev.json";
import mainMenu from "staticContent/main-menu.json";

import {
  collapseMenuWidthThreshold,
  mainMenuVersion,
} from "settings/global-website-config";

import {
  navLink,
  dropdownLink,
  mlAuto,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  list: {
    // order: 2,
    [theme.breakpoints.up(collapseMenuWidthThreshold)]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down(collapseMenuWidthThreshold)]: {
      display: "block",
      order: "2",
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
    // order: '2'
  },
  listBlock: {
    [theme.breakpoints.down("lg")]: {
      display: "inline-flex",
      order: "2",
    },
  },

  menuItems: {
    display: "inline-flex", // 'inline-flex
    minHeight: "60px !important",
    alignItems: "center",
    [theme.breakpoints.down(collapseMenuWidthThreshold)]: {
      display: "inline-block",
    },
  },

  navLink: {
    ...navLink,

    [theme.breakpoints.down(collapseMenuWidthThreshold)]: {
      //  width: 'calc(100% - 30px)',
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      justifyContent: "flex-start",
      "& a": {
        color: theme.palette.common.black,
      },
    },
    "& svg, & img": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  dropdownLink,
  mlAuto,
}));

const menus = { mainMenu, mainMenuDev };

const menu = menus[mainMenuVersion];

export default function HeaderLinks(props) {
  const { handleDrawerToggle } = props;

  const { classes } = useStyles();

  return (
    <List
      className={`${classes.menuItems} ${classes.listBlock} ${classes.list} ${classes.mlAuto}`}
    >
      {menu.map((menuItem) => (
        <ListItem key={menuItem.id}>
          {menuItem.childItems.nodes.length ? (
            // if there are submenu options, create a dropdown:
            <CustomDropdown
              noLiPadding
              navDropdown
              caret
              buttonText={menuItem.label}
              buttonProps={{
                className: classes.navLink,
                transparent: true,
                disableRipple: true,
              }}
              dropdownList={menuItem.childItems.nodes.map((subMenuItem) => (
                <Link
                  prefetch={false}
                  key={subMenuItem.id}
                  href={subMenuItem.path}
                  onClick={() => handleDrawerToggle()}
                  className={classes.dropdownLink}
                >
                  <ListItemIcon>
                    <CategoryIcon
                      slug={subMenuItem.label.toLowerCase()}
                    ></CategoryIcon>
                  </ListItemIcon>
                  {subMenuItem.label}
                </Link>
              ))}
            />
          ) : (
            // otherwise, create a direct link:
            <Button
              className={classes.navLink}
              onClick={(e) => e.preventDefault()}
              transparent
              disableRipple
            >
              <Link
                prefetch={false}
                key={menuItem.id}
                href={menuItem.path}
                onClick={() => handleDrawerToggle()}
              >
                {menuItem.label}
              </Link>
            </Button>
          )}{" "}
        </ListItem>
      ))}
    </List>
  );
}

HeaderLinks.defaultProps = {};

HeaderLinks.propTypes = {};
