import React from "react";

import { PageSeo } from "seo-modules/PageSeo";

import ErrorComponent from "components/ErrorComponent/ErrorComponent";
import I18nProvider from "next-translate/I18nProvider";
import {
  i18Internals,
  PAGE_TRANSITION_CONTAINER_ID,
} from "settings/global-website-config";
import setNamespaces from "modules/setNamespaces";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, ...props };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here

    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      const { lang } = this.state;
      // You can render any custom fallback UI
      const supportedNamespaces = ["common", "errors", "nav"];
      const namespaces = setNamespaces(lang, supportedNamespaces);
      const seo = { metaRobotsNoindex: true, title: "Something went wrong" };

      // TODO: This isn't techincally a 500 error. Should there be no code at all?
      return (
        <>
          <div id={PAGE_TRANSITION_CONTAINER_ID} />
          <div id="page-body" />

          <I18nProvider
            lang={lang}
            namespaces={namespaces}
            internals={i18Internals}
          >
            {seo && <PageSeo seo={seo} pageTitle="Error" />}
            <ErrorComponent statusCode={this.state.error?.statusCode || 500} />
          </I18nProvider>
        </>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}
ErrorBoundary.fullScreen = true;
export default ErrorBoundary;
