import { useEffect, useState } from "react";

import { Grid, Box, Paper } from "@mui/material";
import ClientOnly from "components/ClientOnly/ClientOnly.js";


import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "tss-react/mui";
import Scroll from "react-scroll";
import { sleep } from "@geerly/shared";

import ChatMessages, { LoadingChatLine } from "./ChatMessages";
import {
  AI_NAME,
  AUTHENTICATED_API_PATH,
  fallbackLocale,
  localeCurrencies,
} from "../../settings/global-website-config";


const scrollOpts = { containerId: "chatBox", duration: 200 }; // default first message to display in UI (not necessary to define the prompt)

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: 0,

    // height: '100%',
    // ...bodyMinHeight,
  },
  minWidth: {
    minWidth: "600px", // Prevent shrinking before content
    [theme.breakpoints.down("lg")]: {
      minWidth: "373px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "calc(100vw - 80px)",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "calc(100vw - 32px)",
    },
  },
  chatBox: {
    flexGrow: 1,
    overflow: "auto",
    // ...bodyMinHeight,
    //  height: '200px',
    height: "calc(100vh - 280px)", // 50 + 15 + 24+40+16+75+15+21
    padding: theme.spacing(2),
  },
  dummyChatBox: { height: "unset" },
}));

const getInitialMessages = (greeting, conversationId) => {
  if (conversationId) {
    // TODO: Retrieve conversation from cache via api
    // For now...

    return [
      {
        role: "assistant",
        content: `${greeting} I'm really sorry, I can't find that conversation. Please start a new one.`,
      },
    ];
  }

  return [
    {
      role: "assistant",
      content: `${greeting} I’m ${AI_NAME}, your running gear oracle. I've already taken a look at your running stats and gear stack. Ask me anything!`,
    },
  ];
};

const InputMessage = ({ input, setInput, sendMessage, loading }) => {
  const handleSend = () => {
    if (!loading) {
      sendMessage(input);
      setInput("");
    }
  };
  const sx = { borderRadius: "20px" };

  if (loading) {
    sx.backgroundColor = "grey.200";
  }
  return (
    <Box sx={{ p: 2 }}>
      <OutlinedInput
        fullWidth
        sx={sx}
        id="chatInput"
        placeholder="Type here..."
        value={input}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSend();
          }
        }}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="send chatbot message"
              onClick={handleSend}
              edge="end"
            >
              <SendIcon size="small" color={"primary"} />
            </IconButton>
          </InputAdornment>
        }
      />
    </Box>
  );
};

export function Chat(props) {
  const {
    conversationId,
    dealsLocale,
    dummyMessages,
    dummyOnly,
    user,
    userProps = {},
    ...rest
  } = props;

  const { loading: userLoading } = userProps;
  const [messages, setMessages] = useState(dummyMessages || []);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const scroll = Scroll.animateScroll;

  const { classes, cx } = useStyles();

  useEffect(() => {
    if (user && !messages.length) {
      const initialMessages = getInitialMessages(
        user.firstname ? `Hi ${user.firstname}!` : `Hi!`,
        conversationId
      );

      setMessages(initialMessages);
    }
  }, [messages.length, user, conversationId]);

  // send message to API /api/chat endpoint
  const sendMessage = async (message) => {
    if (!message) {
      return;
    }
    setLoading(true);

    const newMessages = [...messages, { content: message, role: "user" }];
    setMessages(newMessages);
    const last10messages = newMessages.slice(-10);

    const currency =
      localeCurrencies[dealsLocale] || localeCurrencies[fallbackLocale];

    const response = await fetch(`${AUTHENTICATED_API_PATH}/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        messages: last10messages,
        userCharacteristics: {
          provider: user.provider,
          athleteProducts: user.athleteProducts.edges.map(
            (n) => n.product?.title
          ),
          birthday: user.athleteDetails.birthday,
          sex: user.athleteDetails.sex,
          athleteCharacteristics: user.athleteCharacteristics,
          athleteStats: user.athleteStats,
          country: user.country,
          currency,
          measurementPreference:
            user.communicationPreferences.measurementPreference,
        },
      }),
    });

    try {
      const resData = await response.json();

      if (!resData.content) {
        throw new Error(`Chat API did not return content: ${resData.message}`);
      }

      // strip out white spaces from the bot message
      const botNewMessage = resData.content?.trim();
      const { data } = resData;
      if (
        data.products &&
        !data.reshareProducts &&
        !parseInt(process.env.NEXT_PUBLIC_ALWAYS_RESHARE_PRODUCTS)
      ) {
        data.products = data.products.filter(
          (p) =>
            !messages.some((m) =>
              m.data?.products?.some((p2) => p2.uri === p.uri)
            )
        );
      }
      if (data.products && !data.products.length) {
        delete data.products;
      }
      setMessages([
        ...newMessages,
        { content: botNewMessage, data, role: "assistant" },
      ]);
    } catch (error) {
      console.log("error", error);
      setMessages([
        ...newMessages,
        {
          content: "Something went wrong",
          error: true,
          role: "assistant",
        },
      ]);
    }

    setLoading(false);
  };

  useEffect(() => {
    const scrollToBottomAsync = async () => {
      await sleep(10);
      scroll.scrollToBottom(scrollOpts);
    };

    scrollToBottomAsync();
  }, [messages, loading, scroll]);

  const gridSizes = {};
  if (!dummyOnly) {
    gridSizes.lg = 8;
  }
  return (
    <ClientOnly>
      <Grid container spacing={0}>
        <Grid item {...gridSizes}>
          <Paper
            variant="outlined"
            spacing={2}
            // classes={{ root: classes.zeroPadding }}
            className={cx({
              [classes.root]: true,
              [classes.minWidth]: !dummyOnly,
              //  [classes.zeroPadding]: dummyOnly,
            })}
          >
            <div
              className={cx({
                [classes.chatBox]: true,
                [classes.dummyChatBox]: dummyOnly,
              })}
              id="chatBox"
            >
              {messages.map(({ data, content, error, role }, index) => {
                const messages = error ? [{ error: true }] : [content];
                if (data && Object.keys(data).length) {
                  messages.push(data);
                }

                return (
                  <ChatMessages
                    key={index}
                    side={role === "assistant" ? "left" : "right"}
                    avatar={
                      (role !== "assistant" &&
                        (user?.profile ||
                          user?.athleteDetails?.profileImages?.profile)) ||
                      null
                    }
                    messages={messages}
                    dealsLocale={dealsLocale}
                    dummyOnly={dummyOnly}
                    {...rest}
                  />
                );
              })}

              {(loading || (userLoading && !user)) && <LoadingChatLine />}
            </div>

            {!dummyOnly &&
            messages.length &&
            (!conversationId || messages.length < 2) ? (
              <InputMessage
                input={input}
                setInput={setInput}
                sendMessage={sendMessage}
                scroll={scroll}
                loading={loading}
              />
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </ClientOnly>
  );
}

export default Chat;
