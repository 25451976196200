import PropTypes from "prop-types";
import React from "react";

import { makeStyles } from "tss-react/mui";

import FormControl from "@mui/material/FormControl";

import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";

import {
  disabledItem,
  selectMenuItem,
  selectMenuItemSelected,
  select,
  selectMenu,
} from "../../assets/jss/nextjs-material-kit-pro";

const useStyles = makeStyles()((theme) => ({
  listMenuItem: {
    padding: "10px 20px",
    paddingRight: "30px",
    display: "block",
  },
  disabled: {
    ...disabledItem,
    borderRadius: "3px",
  },

  borderOverride: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  select,
  selectMenu,
  selectMenuItem,
  selectMenuItemSelected,
  //inputMinHeight: { minHeight: '40px' },

  /* noListPadding: {
    '& > div > ul': {
      padding: '0', // was       padding: '5px 0', = > changed for sizes dropdown
    },
  },
  topListPadding: {
    '& > div > ul': {
      padding: '5px 0', // was       padding: '5px 0', = > changed for sizes dropdown
    },
  }, */

  /* selectMenuItemSelectedMultiple: {
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} + !important`,
      color: theme.palette.common.white,
      ...stravaBoxShadow,
      '&:after': {
        color: theme.palette.common.white,
      },
    },
    '&:after': {
      top: '16px',
      right: '12px',
      width: '12px',
      height: '5px',
      borderLeft: '2px solid currentColor',
      transform: 'rotate(-45deg)',
      opacity: '1',
      color: grayColor[1],
      position: 'absolute',
      content: "''",
      borderBottom: '2px solid currentColor',
      transition: 'opacity 90ms cubic-bezier(0,0,.2,.1)',
    },
  }, */

  /* selectPaper: {
    //boxSizing: 'borderBox', included but invalid
    borderRadius: theme.shape.borderRadius,
    padding: '0',
    minWidth: '100%',
    display: 'block',
    border: '0',
    boxShadow:
      '0 2px 5px 0 rgba(' + hexToRgb(theme.palette.common.black) + ', 0.26)',
    backgroundClip: 'padding-box',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: 'transparent',
    maxHeight: '266px',
  }, */
}));

export default function CustomSelect(props) {
  const { classes, cx } = useStyles();

  const {
    type,
    options,
    handleChoice,
    currentSelection,
    loading,
    formControlClasses,
    selectClasses,
    children,
    label,
    fullWidth,
    //preventMenuPadding,
    highlight,
    renderValue,
    menuProps,
    selectProps,
    customMenuClasses,
    inputProps,
    id,
    multiple,

    ...rest
  } = props;

  const menuClasses = cx({
    [classes.selectMenu]: true,

    [classes.customMenuClasses]: customMenuClasses,
  });

  if (!options && !children) {
    return <div />;
  }
  const MenuProps = {
    disableScrollLock: true,
    className: menuClasses,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    ...menuProps,
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      classes={formControlClasses}
      id={id || label}
    >
      <TextField
        label={label}
        select
        classes={{ root: cx({ [classes.borderOverride]: highlight }) }}
        variant="outlined"
        SelectProps={{
          renderValue,
          multiple,
          MenuProps,
          classes: {
            select: `${classes.select}`,
            disabled: classes.disabled,
            ...selectClasses,
          },
          ...selectProps,
        }}
        value={
          multiple &&
          (typeof currentSelection === "string" || !currentSelection)
            ? [currentSelection]
            : currentSelection
        }
        onChange={(event) => {
          event.stopPropagation();
          if (handleChoice) {
            handleChoice(event.target.value, type);
          }
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        disabled={loading}
        inputProps={inputProps}
        size="small"
        {...rest}
      >
        {options
          ? options.map((option) => (
              <MenuItem
                key={`${option.key || option.name}-${label}`}
                classes={{
                  root: cx({
                    [classes.selectMenuItem]: true,
                    [classes.listMenuItem]: true,
                  }),
                  selected: classes.selectMenuItemSelected,
                }}
                value={option.value || option.id || option.databaseId}
                name={option.name || option.value}
              >
                {option.name || option.value}
              </MenuItem>
            ))
          : children}
      </TextField>
    </FormControl>
  );
}

CustomSelect.defaultProps = {
  selectClasses: {},
  formControlClasses: {},
};

CustomSelect.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.object,
  ]),
  color: PropTypes.string,
  currentSelection: PropTypes.any,
  fullWidth: PropTypes.bool,
  handleChoice: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  label: PropTypes.string,
  loading: PropTypes.any,
  options: PropTypes.array,
  preventMenuPadding: PropTypes.any,
  formControlClasses: PropTypes.object,
  selectClasses: PropTypes.object,
  type: PropTypes.string,
};
