// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153

// #############################
//import { Inter } from '@next/font/google';
import { keyframes } from "tss-react";

const defaultFont = ""; // To be remove if no issues caused; // Inter({ subsets: ['latin'] });
const defaultLetterSpacing = "-.025em";

const hexToRgb = (input) => {
  input += "";
  input = input.replace("#", "");
  const hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    const first = input[0];
    const second = input[1];
    const last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  const first = input[0] + input[1];
  const second = input[2] + input[3];
  const last = input[4] + input[5];
  return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(
    last,
    16
  )}`;
};
const pulse = keyframes`
        to {
           background-color: rgba(60, 117, 129, 0.4);
        }
      `;
// ##############################
// // // Variables - Styles that are used on more than one component
// #############################
const HEADER_HEIGHT = 50;

const drawerWidth = 260;

const stravaColor = "#FC4C02";
// for ref, gives dark: B03501; light: FC6F34

const darkCustomColor = "#0C5362"; //'#0C5362'; // '#222E50'//'#0C5362'; // '#1A4B55';
const primaryColor = darkCustomColor; // "#136cdc"; // ; // "#84B082"; //darkCustomColor;
const secondaryColor = "#DC136C"; //; "#e90079"; // // stravaColor; //['#fafafa'];

const warningColor = [
  "#ff9800",
  "#ffa726",
  "#fb8c00",
  "#ffa21a",
  "#fcf8e3",
  "#faf2cc",
  "#ffe0b2",
  "#ffb74d",
];
const dangerColor = [
  "#f44336",
  "#ef5350",
  "#e53935",
  "#f55a4e",
  "#f2dede",
  "#ebcccc",
  "ef9a9a",
  "#ef5350",
];
const successColor = [
  "#4caf50",
  "#66bb6a",
  "#43a047",
  "#5cb860",
  "#dff0d8",
  "#d0e9c6",
  "#a5d6a7",
  "#66bb6a",
];
const infoColor = [
  "#00acc1",
  "#26c6da",
  "#00acc1",
  "#00d3ee",
  "#d9edf7",
  "#c4e3f3",
  "#b2ebf2",
  "#4dd0e1",
];
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#f8bbd0", "#f06292"];
const grayColor = [
  "#999",
  "#3C4858",
  "#eee",
  "#343434",
  "#585858",
  "#232323",
  "#ddd",
  "#6c757d",
  "#333",
  "#212121",
  "#777",
  "#D2D2D2",
  "#AAA",
  "#495057",
  "#e5e5e5",
  "#555",
  "#f9f9f9",
  "#ccc",
  "#444",
  "#f2f2f2",
  "#89229b",
  "#c0c1c2",
  "#9a9a9a",
  "#f5f5f5",
  "#505050",
  "#1f1f1f",
  "#F2F2F2",
];

const whiteColor = "#FFF";
const blackColor = "#000";
const twitterColor = "#55acee";
const facebookColor = "#3b5998";
const googleColor = "#dd4b39";
const linkedinColor = "#0976b4";
const pinterestColor = "#cc2127";
const youtubeColor = "#e52d27";
const tumblrColor = "#35465c";
const behanceColor = "#1769ff";
const dribbbleColor = "#ea4c89";
const redditColor = "#ff4500";
const instagramColor = "#125688";

const textDefaultColor = grayColor[1];
const textMutedColor = grayColor[7];
const defaultBorderRadius = 6;
const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const cardShadow = `0 2px 2px 0 rgba(${hexToRgb(
  blackColor
)}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(
  blackColor
)}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(blackColor)}, 0.12)`;

const transitionFast = { transition: "all 150ms ease-in-out" };

const containerFluid = {
  paddingRight: "16px",
  paddingLeft: "16px",
  marginRight: "auto",
  marginLeft: "auto",
  width: "100%",
};
const container = {
  ...containerFluid,
  "@media (min-width: 600px)": {
    maxWidth: "580px",
  },
  "@media (min-width: 676px)": {
    maxWidth: "640px",
  },
  "@media (min-width: 768px)": {
    maxWidth: "720px",
  },
  "@media (min-width: 860px)": {
    maxWidth: "820px",
  },
  "@media (min-width: 960px)": {
    maxWidth: "920px",
  },
  "@media (min-width: 1080px)": {
    maxWidth: "1040px",
  },
  "@media (min-width: 1200px)": {
    maxWidth: "1160px",
  },
};
const textCenter = {
  textAlign: "center!important",
};

const mlAuto = {
  marginLeft: "auto",
};

const mrAuto = {
  marginRight: "auto",
};

const bodyMinHeight = {
  minHeight: "78vh",
};
const centerWithMargin = {
  ...mlAuto,
  ...mrAuto,
};
const grayscale = { filter: "grayscale(1)" };

const pageContainer = {
  ...container,
  // borderTop: `111px solid ${darkCustomColor}`, // this to prevent the flash on page transition between border types

  marginTop: HEADER_HEIGHT, // allow for header
  paddingTop: "15px", // should be 90
  paddingBottom: "30px",
  zIndex: "2",
  //width: '100vw', // new
  //margin: '0px', // new
  // '@media (max-width: 600px)': {
  /* //   paddingTop: '182px' // should be 137px once blog only version goes
  // },
  '@media (min-width: 900px)': {
    //   paddingTop: '15px', // should be 137px once blog only version goes
    //   borderTop: `60px solid ${darkCustomColor}`, // this to prevent the flash on page transition between border types
  },
  '@media (min-width: 600px)': {
    //    paddingTop: '20px', // should be 137px once blog only version goes
    //   borderTop: `60px solid ${darkCustomColor}`, // this to prevent the flash on page transition between border types
  },
  '@media (max-width: 400px)': {
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '10px', // should be 137px once blog only version goes
    //   borderTop: `60px solid ${darkCustomColor}`, // this to prevent the flash on page transition between border types
  }, */
};
const fullScreenContainer = {
  marginTop: "-111px",
  "@media (min-width: 600px)": {
    marginTop: "-60px",
  },
};

const whiteHover = {
  "&:hover,&:focus": {
    color: whiteColor,
  },
};

const drawerPaper = {
  border: "none",
  // bottom: '0',
  transitionProperty: "top, bottom, width",
  transitionDuration: ".2s, .2s, .35s",
  transitionTimingFunction: "linear, linear, ease",
  position: "fixed",
  display: "block",
  borderTop: "none",
  textAlign: "left",
  paddingRight: 0,
  paddingLeft: 0,
};
const verticalMiddle = { verticalAlign: "middle" };
const verticalTop = { verticalAlign: "top" };
const topStrip = {
  // position: 'fixed',
  // top: '0px',
  zIndex: -2, // Changed from 0 to prevent showing at top of Error page, which has image of -1
  position: "relative",
  borderTop: `111px solid ${darkCustomColor}`, // this to prevent the flash on page transition between border types
  "@media (min-width: 600px)": {
    borderTop: `60px solid ${darkCustomColor}`, // this to prevent the flash on page transition between border types
  },
};
const hoverTransition = {
  /*   transition: `color 200ms ease-in-out`,
  '&:hover': {
    color: `${stravaColor} !important`,
  }, */
};
const topStripLimitedSite = {
  // position: 'fixed',
  // top: '0px',
  zIndex: "0",
  position: "relative",
  borderTop: `60px solid ${darkCustomColor}`, // this to prevent the flash on page transition between border types
};

const hoverShadow = {
  textShadow: "0 0 0.01px",
  transition: "all 150ms ease-in-out",
};

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: `0 1px 4px 0 rgba(${hexToRgb(blackColor)}, 0.14)`,
  borderRadius: defaultBorderRadius,
  color: `rgba(${hexToRgb(blackColor)}, 0.87)`,
  background: whiteColor,
};
const iconButtons = {
  position: "relative",
  padding: 10,
  height: "40px",
  width: "40px",
  zIndex: 4,
};

const secondaryPageTitleBlock = {
  padding: "50px 0",
};

const sectionTitleBlock = {
  padding: "0px 0",
  // marginBottom: '20px',
  textAlign: "center",
};

const postTags = {
  marginTop: "8px",
  "&: root": {
    paddingLeft: "0px",
  },
};

const displayNone = {
  display: "none !important",
};

const reviewInfoIcon = {
  "& svg": {
    fontSize: "15px",
    marginRight: "4px",
    marginBottom: "1px",
    verticalAlign: "middle",
  },
};
const reviewInfo = {
  fontSize: "14px",
  marginBottom: "0px",
};

const reviewInfoLight = {
  ...reviewInfo,
  color: `rgba(${hexToRgb(whiteColor)}, 0.6)`,
  "& p": {
    marginBottom: "0px",
  },
};
const pulseBackground = {
  backgroundColor: "rgba(12, 83, 98, 0.04)",
  animation: `${pulse} 1s infinite ease-in-out alternate-reverse`,
};
const tabbedSectionContainer = {
  minHeight: 600,
  width: "100%",
};

const zeroPadding = {
  padding: "0px",
};
const progress = {
  color: stravaColor,
  width: "4rem !important",
  height: "4rem !important",
  display: "inlineFlex",
  verticalAlign: "middle",

  // position: 'relative',
};
const progressContainer = {
  display: "flex",
  width: "100%",
  // textAlign: 'center',
  // position: 'relative',
  // zIndex: '9999',
  alignItems: "center",
  justifyContent: "center",
};
const anchorColor = "#006591";
/* const defaultFont = {
  fontFamily: defaultFont.style.fontFamily, //'"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: '400', // Was 300 but too light and have removed from bundle
  lineHeight: '1.5em',
}; */
const grayDisabled = {
  opacity: "20% !important",
  "& svg": { color: grayColor[0] },
  transition: "opacity 300ms ease-in-out 0s",
};
const selectFormControl = {
  //minHeight: '40px',
  padding: 0,
  margin: "0px, 0px, 8px, 0px", // used to be !important - switched off for sidebar
  /* 
    '& > div': {
      '&:before': {
        borderBottomWidth: '1px !important',
        borderBottomColor: grayColor[11] + ' !important',
      },
      '&:after': {
        borderBottomColor: stravaColor + '!important',
      },
    }, */
};
const boxShadow = {
  boxShadow: `0 10px 30px -12px rgba(${hexToRgb(
    blackColor
  )}, 0.42), 0 4px 15px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2)`,
};
const boxShadowMinimal = {
  boxShadow:
    "0 10px 30px -12px rgb(0 0 0 / 2%), 0 4px 4px 0px rgb(0 0 0 / 12%), 0 8px 10px -8px rgb(0 0 0 / 20%)",
};
const primaryBoxShadow = {
  boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
    primaryColor
  )}, 0.28), 0 4px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 7px 8px -5px rgba(${hexToRgb(primaryColor)}, 0.2)`,
};
const infoBoxShadow = {
  boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
    infoColor[0]
  )}, 0.28), 0 4px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 7px 8px -5px rgba(${hexToRgb(infoColor[0])}, 0.2)`,
};
const successBoxShadow = {
  boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
    successColor[0]
  )}, 0.28), 0 4px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 7px 8px -5px rgba(${hexToRgb(successColor[0])}, 0.2)`,
};
const warningBoxShadow = {
  boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
    warningColor[0]
  )}, 0.28), 0 4px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 7px 8px -5px rgba(${hexToRgb(warningColor[0])}, 0.2)`,
};
const dangerBoxShadow = {
  boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
    dangerColor[0]
  )}, 0.28), 0 4px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 7px 8px -5px rgba(${hexToRgb(dangerColor[0])}, 0.2)`,
};
const roseBoxShadow = {
  boxShadow: `0 4px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.14), 0 7px 10px -5px rgba(${hexToRgb(roseColor[0])}, 0.4)`,
};
const stravaBoxShadow = {
  boxShadow: `0 4px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.14), 0 7px 10px -5px rgba(${hexToRgb(stravaColor)}, 0.4)`,
};
const stravaCardHeader = {
  color: whiteColor,
  background: stravaColor,
  ...warningBoxShadow,
};

const warningCardHeader = {
  color: whiteColor,
  background: `linear-gradient(60deg, ${warningColor[1]}, ${warningColor[2]})`,
  ...warningBoxShadow,
};
const successCardHeader = {
  color: whiteColor,
  background: `linear-gradient(60deg, ${successColor[1]}, ${successColor[2]})`,
  ...successBoxShadow,
};
const dangerCardHeader = {
  color: whiteColor,
  background: `linear-gradient(60deg, ${dangerColor[1]}, ${dangerColor[2]})`,
  ...dangerBoxShadow,
};
const infoCardHeader = {
  color: whiteColor,
  background: `linear-gradient(60deg, ${infoColor[1]}, ${infoColor[2]})`,
  ...infoBoxShadow,
};
const primaryCardHeader = {
  color: whiteColor,
  background: `linear-gradient(60deg, ${primaryColor}, ${primaryColor})`,
  ...primaryBoxShadow,
};
const roseCardHeader = {
  color: whiteColor,
  background: `linear-gradient(60deg, ${roseColor[1]}, ${roseColor[2]})`,
  ...roseBoxShadow,
};
const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: `1px solid  ${grayColor[2]}`,
  height: "auto",
  // ...defaultFont,
};
const navLink = {
  color: "inherit",
  position: "relative",
  padding: "5px", // was 0.9375rem
  fontWeight: "400",
  fontSize: "18px",
  textTransform: "capitalize",
  lineHeight: "20px",
  textDecoration: "none",
  margin: "0px",
  width: "100%",
  display: "inline-flex",
  "& a": {
    color: whiteColor,
    transition: "all 150ms ease-in-out",

    "&:hover": {
      textShadow: "0 0 1px",

      color: `${whiteColor} !important`,
    },
  },
};
const cardHeader = {
  margin: "-30px 15px 0",
  borderRadius: defaultBorderRadius,
  padding: "15px",
  // alignItems: 'center' // TG added 030220
};
const wrapText = { whiteSpace: "pre-line" };

const fullWidthContainer = {
  paddingTop: 60,
};
const fullWidth = {
  width: "100%",
};
const fullHeight = {
  height: "100%",
};
const dropdownLink = {
  "&,&:hover,&:focus": {
    color: "inherit",
    textDecoration: "none",
    display: "flex",
    padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    transition: "none",
  },
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: defaultBorderRadius,
  boxShadow: `0 10px 20px -12px rgba(${hexToRgb(
    blackColor
  )}, 0.42), 0 3px 20px 0px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2)`,
  padding: "10px 0",
  transition: "all 150ms ease-in-out",
};

const titleFont = {
  // fontFamily: robotoSlab.style.fontFamily, //`"Roboto Slab", "Times New Roman", serif`,
  // color: grayColor[1],
  // textDecoration: 'none',
  //  fontWeight: '700',
};

const itemTitle = {
  marginBottom: "10px",
  "@media (max-width: 960px)": {
    fontSize: "1.75rem",
  },
  "@media (max-width: 576px)": {
    fontSize: "1.55rem",
  },
  "@media (max-width: 390px)": {
    fontSize: "1.45rem",
  },
};
const innerTitle = {
  marginTop: "20px",
  marginBottom: "20px",
};
const label = {
  color: whiteColor,
  "& a": {
    color: whiteColor,
    "&:hover,&:focus": {
      color: `${whiteColor} !important`,
      fontWeight: "500",
      // transition: 'all 0ms ease-in-out'
    },
  },
};

const raisedImg = {
  //   width: '200px', // was 100%
  //   minHeight: '150px',
  borderRadius: defaultBorderRadius,
  // pointerEvents: 'none',
  boxShadow: `0 5px 15px -8px rgba(${hexToRgb(
    blackColor
  )}, 0.48), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.4)`,
  // TODO: This should be using Theme.shadows[8] etc but not sure how to implement on this sheet outside of useTheme etc
};
const buttonHoverShadow = {
  transition:
    "all 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
  "&:hover,&:focus": {
    color: whiteColor,
    // backgroundColor: `${stravaColor} !important`,
    boxShadow: `0 14px 26px -12px rgba(${hexToRgb(
      grayColor[0]
    )}, 0.42), 0 4px 13px 0px rgba(${hexToRgb(
      blackColor
    )}, 0.22), 0 8px 10px -5px rgba(${hexToRgb(grayColor[0])}, 0.2)`,
    //    transform: 'scale(1.01)',
  },
};

const cardHeaderImage = {
  position: "relative",
  padding: "0",
  zIndex: "1",
  // maxWidth: '200px',
  marginLeft: "15px",
  marginRight: "15px",
  marginTop: "15px", // was 15px
  //justifyContent: 'center', // Makes image disappear! But should already be centered. Added to center images in productsList, remove and add elsewhere if issues

  "& img": {
    ...raisedImg,
  },
};

const cardHeaderImageButton = {
  "& img": {
    //'& a > span, & span > span
    ...buttonHoverShadow,
  },
};
const titleBadge = {
  position: "relative",
  fontSize: "22px",
  textTransform: "uppercase",
  fontWeight: "700",
  right: "-10px",
  padding: "10px 18px",
  top: "-30px",
  background: whiteColor,
  borderRadius: defaultBorderRadius,
  color: grayColor[18],
  lineHeight: "22px",
  boxShadow: "0 5px 5px -2px rgba(" + hexToRgb(grayColor[25]) + ",.4)",
};
const textOverlay = {
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 10,
  "& a": { fontSize: "20px", color: whiteColor },
};
const reviewScoreBadge = {
  color: whiteColor,
  fontSize: "16px",
  marginTop: "8px", //recently added for productPage produuctInfoSection
  // marginRight: '10px', // messes up comparison block layout, add an exception if needs reinstating
  // display: 'unset',
};

const pageTitle = {
  "@media (max-width: 960px)": {
    fontSize: "2rem",
  },
  "@media (max-width: 576px)": {
    fontSize: "1.75rem",
  },
  "@media (max-width: 390px)": {
    fontSize: "1.5rem",
  },
};

const cardTitle = {
  "&, & a": {
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto",
    "@media (max-width: 576px)": {
      fontSize: "1.25rem",
    },
    "@media (max-width: 390px)": {
      fontSize: "1rem",
    },
  },
};

const headerBoxShadow = `0 4px 18px 0px rgba(${hexToRgb(
  blackColor
)}, 0.12), 0 7px 10px -5px rgba(${hexToRgb(blackColor)}, 0.15)`;

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem",
  },
};

const cardSubtitle = {
  marginBottom: "0",
  marginTop: "-.375rem",
};

const disabledItem = {
  backgroundColor: grayColor[2],
  transition: `background-color 500ms ease-in-out`,
};
const noWrap = {
  whiteSpace: "noWrap",
};

const justifyContent = {
  justifyContent: "center!important",
};
/* const checkboxLabel = {
  //cursor: 'pointer',
  // pointerEvents: 'none',
  paddingLeft: '0',
  color: grayColor[12],
  fontSize: '14px',
  lineHeight: '1.428571429',
  fontWeight: '400',
  display: 'contents', // was inline-flex => but contents centers horizonatally when wrapping
  transition: '0.3s ease all',
  letterSpacing: 'unset',
  whiteSpace: 'normal',
}; */
const tooltipIcon = {
  fontSize: "18px",
  verticalAlign: "middle",
  opacity: "35%",
  color: grayColor[1],
  marginLeft: "4px",
  marginTop: "-2px", // experimental - works for comparison block
};
const textButton = {
  fontWeight: 500,
  alignItems: "stretch",
  overflowWrap: "normal ",
  whiteSpace: "normal",
  marginBottom: "unset", //'10px', // overrides 25px from ...title.
  color: darkCustomColor,
};

const pagination = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justify: "center",
  padding: 0,
};
const main = {
  background: whiteColor,
  position: "relative",
  zIndex: "3",
};

const mainRaised = {
  "@media (max-width: 576px)": {
    marginTop: "-30px",
  },
  "@media (max-width: 830px)": {
    marginLeft: "10px",
    marginRight: "10px",
  },
  margin: "-60px 30px 0px",
  borderRadius: defaultBorderRadius,
  boxShadow: `0 16px 24px 2px rgba(${hexToRgb(
    blackColor
  )}, 0.14), 0 6px 30px 5px rgba(${hexToRgb(
    blackColor
  )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2)`,
};

const section = {
  backgroundPosition: "50%",
  backgroundSize: "cover",
};

const sectionDark = {
  backgroundColor: grayColor[3],
  background: `radial-gradient(ellipse at center,${grayColor[4]} 0,${grayColor[5]} 100%)`,
};

const sectionDescription = {
  marginTop: "130px",
};

const description = {
  color: grayColor[1], // was grayColor[0] but too faint
  fontSize: "18px", // Was 18px but made smaller for Team Member description. Split these out if not desired elsewhere
};
const descriptionSmall = {
  color: grayColor[0],
  fontSize: "15px", // Was 18px but made smaller for Team Member description. Split these out if not desired elsewhere
};
const captionText = {
  fontSize: "0.9rem",
  color: grayColor[0],
  marginTop: "8px",
  marginBottom: "16px",
  marginLeft: "2px",
};

const btnLink = {
  backgroundColor: "transparent",
  //  boxShdow: 'none',
  marginTop: "5px",
  marginBottom: "5px",
};

const coloredShadow = {
  // some jss/css to make the cards look a bit better on Internet Explorer
  "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
    display: "none !important",
  },

  transform: "scale(0.94) translate3d(0,0,0)", //  causes issues with the blur on safari - added the below instead
  //

  // transform: 'translate3d(0,0,0)',

  top: "12px",
  filter: "blur(12px)",
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  zIndex: "-1",
  transition: "opacity .45s",
  opacity: "0",
};
const rotatingIcon = { transition: "all 150ms ease-in" };

const button = {
  /*   fontSize: '1rem',
  fontWeight: '400',
  textTransform: 'none',
  transition: 'background-color 150ms ease-in-out',
  '&:hover,&:focus': {
    color: whiteColor,
  }, */
  // borderRadius: '4px',
  //  color: whiteColor,
  //// minHeight: 'auto',
  //  minWidth: 'auto',
  // ...buttonHoverShadow, // Should be separate
  // backgroundColor: grayColor[0],
  /* boxShadow: `0 2px 2px 0 rgba(${hexToRgb(
    grayColor[0]
  )}, 0.14), 0 3px 1px -2px rgba(${hexToRgb(
    grayColor[0]
  )}, 0.2), 0 1px 5px 0 rgba(${hexToRgb(grayColor[0])}, 0.12)`, */
  //  border: 'none',
  // position: 'relative',
  //  padding: '12px 30px',
  // margin: '.3125rem 1px',
  // lineHeight: '1.42857143',
  //
  // was "capitalize",
  //  letterSpacing: '0',
  ///  willChange: 'box-shadow, transform',
  // ARE THESE NEEDED??
  // textAlign: 'center',
  // touchAction: 'manipulation',
  // cursor: 'pointer',
  //// whiteSpace: 'nowrap',
  //  verticalAlign: 'middle',
  /*  '& .fab,& .fas,& .far,& .fal,& .material-icons': {
    position: 'relative',
    display: 'inline-block',
    top: '0',
    marginTop: '-1em',
    marginBottom: '-1em',
    fontSize: '1.1rem',
    marginRight: '4px',
    verticalAlign: 'middle',
  },

  '&$justIcon': {
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      marginTop: '0px',
      marginRight: '0px',

      position: 'absolute',
      width: '100%',
      transform: 'none',
      left: '0px',
      top: '0px',
      height: '100%',
      lineHeight: '41px',
      fontSize: '20px',
    },
  }, */
};

const buttonSvg = {
  "& svg": {
    position: "relative",
    // display: "inline-block", // This is causing circularProgress in buttons to wobble. Disabled and looking for side effects
    top: "0",
    width: "21px", // was 18px
    height: "21px",
    marginRight: "4px",
    verticalAlign: "middle",
  },
};

const focusHighlightShadow = {
  transition:
    "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
  "&:hover,&:focus": {
    backgroundColor: whiteColor,
    boxShadow: `0 14px 26px -12px rgba(${hexToRgb(
      blackColor
    )}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(
      blackColor
    )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(stravaColor)}, 0.2)`,
  },
};

/*  '&:hover,&:focus': {
      backgroundColor: stravaColor,
      boxShadow: `0 14px 26px -12px rgba(${hexToRgb(
        stravaColor
      )}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(
        blackColor
      )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(stravaColor)}, 0.2)`,
    }, */

const genderFont = {
  "& span": {
    fontWeight: "500",
  },
};
const disabledColor = grayColor[10];

const inlineFlexContainer = {
  display: "inline-flex",
  alignItems: "flex-start",
  width: "100%",
};
const textMuted = {
  color: textMutedColor,
};
const imgFluid = {
  maxWidth: "100%",
  height: "auto",
};
const dashboardLabel = {
  marginTop: 0,
  marginBottom: "6px", //Specifically 6 to line up with settings
  fontSize: "1rem",
  lineHeight: 1,
  "& h5": { margin: "unset", lineHeight: "unset", fontSize: "unset" },
  width: "fit-content",
  ...textMuted,
};
const selectMenuItem = {
  fontSize: "14px",
  borderRadius: defaultBorderRadius,
  transition: "all 150ms linear",
  // display: 'block', // Removed to prevent needing to override for gridMenuItem, should add conditionally if not working elsewhere
  clear: "both",
  //  fontWeight: '400',
  lineHeight: "2",
  whiteSpace: "nowrap",
  color: grayColor[8],
  border: `1px solid ${whiteColor}`,

  // NB custom styles for Hover have a "sticky hover state" issue: https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
  // Because it does not add much to the design, I have disabled to reduce complexity
  /*  '&:hover': {
    border: `1px solid ${stravaColor} `,
    backgroundColor: `${whiteColor}`,
    color: `${stravaColor}`,
  }, */
};

const rotate180 = { transform: "rotate(180deg)" };
const rotate360 = { transform: "rotate(360deg)" };
const select = {
  padding: "12px 0px 7px 10px",
  fontSize: ".9rem",
  fontWeight: "400",
  lineHeight: "1.42857",
  textDecoration: "none",
  //textTransform: "uppercase",
  color: grayColor[1],
  letterSpacing: "0",
  '& ul[role="listbox"]': {
    paddingTop: "0px",
  },
  "&:focus": {
    backgroundColor: "transparent",
  },
  "&[aria-owns] + input + svg": {
    ...rotate180,
  },
  "& + input + svg": {
    transition: "all 300ms linear",
  },
};

const selectMenu = {
  zIndex: "1600 !important",
  "& > div > ul": {
    border: "0",
    //  padding: '5px 0', // was       padding: '5px 0', = > changed for sizes dropdown
    padding: "5px",
    margin: "0",
    boxShadow: "none",
    minWidth: "100%",
    borderRadius: defaultBorderRadius,
    boxSizing: "border-box",
    display: "block",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: whiteColor,
    backgroundClip: "padding-box",
  },
  /*  '& $selectPaper $selectMenuItemSelectedMultiple': {
      backgroundColor: 'inherit',
    }, */
};
const selectMenuItemSelected = {
  border: `1px solid ${stravaColor} !important`,
  borderRadius: defaultBorderRadius,
  backgroundColor: `#FFF1ED !important`,
  color: `${stravaColor} !important`,
  "&:hover": {
    backgroundColor: `${whiteColor} !important`,
  },
};

const colourButton = {
  color: primaryColor,

  transition: "opacity 0.5s, padding 0.5s, border 0.5s, box-shadow 0.5s",
  minWidth: "unset",
  textTransform: "unset",
};

const colourButtonSquare = {
  padding: "4px",
  margin: "5px",
  backgroundClip: "content-box",
};

const colourButtonCompact = {
  width: "40px",
  height: "40px",
  fontSize: "12px",
};
const helperTextColor = grayColor[0];

const helperText = {
  fontSize: "0.75rem",
  lineHeight: 1,
  color: helperTextColor,
  marginBottom: "8px",
};
const setSizeButton = {
  textDecoration: "underline",
  cursor: "pointer",
};
const labelBold = {
  fontWeight: 500,
};
const displayFlex = { display: "flex", alignItems: "center" };
export {
  colourButton,
  colourButtonCompact,
  colourButtonSquare,
  selectMenuItem,
  selectMenuItemSelected,
  select,
  selectMenu,
  drawerWidth,
  titleBadge,
  inlineFlexContainer,
  transition,
  transitionFast,
  container,
  pageContainer,
  textMuted,
  displayFlex,
  containerFluid,
  boxShadow,
  topStrip,
  topStripLimitedSite,
  fullScreenContainer,
  fullWidth,
  zeroPadding,
  card,
  postTags,
  reviewScoreBadge,
  defaultFont,
  imgFluid,
  wrapText,
  primaryColor,
  secondaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  reviewInfo,
  reviewInfoLight,
  roseColor,
  grayColor,
  darkCustomColor,
  boxShadowMinimal,
  whiteColor,
  blackColor,
  twitterColor,
  facebookColor,
  setSizeButton,
  tabbedSectionContainer,
  googleColor,
  tooltipIcon,
  secondaryPageTitleBlock,
  sectionTitleBlock,
  helperTextColor,
  helperText,
  hoverTransition,
  defaultBorderRadius,
  pagination,
  iconButtons,
  linkedinColor,
  pinterestColor,
  captionText,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  itemTitle,
  focusHighlightShadow,
  instagramColor,
  stravaColor,
  stravaBoxShadow,
  dropdownLink,
  primaryBoxShadow,
  displayNone,
  infoBoxShadow,
  successBoxShadow,
  verticalMiddle,
  verticalTop,
  anchorColor,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  warningCardHeader,
  stravaCardHeader,
  titleFont,
  buttonSvg,
  successCardHeader,
  hoverShadow,
  dangerCardHeader,
  infoCardHeader,
  headerBoxShadow,
  dashboardLabel,
  rotatingIcon,
  primaryCardHeader,
  roseCardHeader,
  cardActions,
  descriptionSmall,
  grayDisabled,
  disabledColor,
  fullWidthContainer,
  cardHeader,
  defaultBoxShadow,
  selectFormControl,
  HEADER_HEIGHT,
  whiteHover,
  cardHeaderImage,
  innerTitle,
  pageTitle,
  navLink,
  cardTitle,
  centerWithMargin,
  description,
  cardLink,
  label,
  textButton,
  disabledItem,
  cardSubtitle,
  fullHeight,
  progress,
  main,
  mainRaised,
  raisedImg,
  rotate180,pulseBackground,
  rotate360,
  button,
  section,
  reviewInfoIcon,
  sectionDark,
  sectionDescription,
  mlAuto,
  textCenter,
  justifyContent,
  progressContainer,
  drawerPaper,
  mrAuto,
  btnLink,
  cardShadow,
  coloredShadow,
  cardHeaderImageButton,
  noWrap,
  genderFont,
  labelBold,
  buttonHoverShadow,
  hexToRgb,
  textOverlay,
  textDefaultColor,
  textMutedColor,
  grayscale,
  defaultLetterSpacing,
  bodyMinHeight,
};
