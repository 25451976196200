import cookie from "react-cookies";

type LocaleDetails = {
  lang?: string;
  ipCountry?: string;
  isFallbackCountry?: boolean;
  error?: string;
};

async function getRegionFromIP(): Promise<LocaleDetails> {
  try {
    const COOKIE_KEY = "ipCountry";

    const ipCountryFromCookie = cookie.load(COOKIE_KEY);
    if (ipCountryFromCookie) {
      return { ipCountry: ipCountryFromCookie };
    }
    const response = await fetch("/api/getGeoLocation");
    if (response.ok) {
      const data = await response.json();

      if (data.ipCountry) {
        cookie.save(COOKIE_KEY, data.ipCountry, {
          path: "/",
          maxAge: 60 * 60 * 24 * 14, // 2 week
        });
      }

      return data || null;
    } else {
      console.error(
        "Non-OK response retrieving region from IP:",
        response.statusText
      );
      return { error: response.statusText };
    }
  } catch (error) {
    console.error("Error retrieving region from IP:", error);
    return { error: "unknown error" };
  }
}

export { getRegionFromIP };
