import React from "react";
import Router from "next/router";

import PropTypes from "prop-types";
import I18nProvider from "next-translate/I18nProvider";
import { useScreenSizes } from "components/Providers/ScreenSizesProvider";
import StravaLogin from "components/Login/StravaLogin.js";
import UserNav from "components/Login/UserNav.js";
import Box from "@mui/material/Box";

import ClientOnly from "components/ClientOnly/ClientOnly.js";

import { makeStyles } from "tss-react/mui";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import Drawer from "components/Drawer/Drawer.js";

import { useAuth } from "auth/useUser.js";
import checkIfIsExpandingHeader from "modules/checkIfIsExpandingHeader";
import { useRenders } from "modules/utils";

import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";

import LogoButton from "components/CustomButtons/LogoButton.js";

import {
  i18Internals,
  collapseMenuWidthThreshold,
  SHOW_STRAVA_LOGIN,
} from "settings/global-website-config";
import setNamespaces from "modules/setNamespaces";
import { hexToRgb } from "@mui/material";

import SearchBar, { SearchIconButton } from "../SearchBar/SearchBar";
import HeaderLinks from "./HeaderLinks";
import {
  container,
  grayColor,
  HEADER_HEIGHT,
} from "../../assets/jss/nextjs-material-kit-pro";
import { shouldShowPageChange } from "../PageChange/usePageChange";

const useStyles = makeStyles()((theme) => {
  return {
    flexGrow: { flexGrow: 7 },
    openSearchIcon: { color: theme.palette.common.white },
    appBar: {
      color: theme.palette.common.white,
      //  position: 'fixed',
    },
    isExpandingHeader: { transition: "padding-top 150ms ease-in-out" },
    container: {
      ...container,
      // marginRight: '270px', //allows for account bar
      // minHeight: 'unset',
      width: "100%",
      justifyContent: "space-between",
      display: "flex",
      // order: 2,
      // flexWrap: 'noWrap',
      [theme.breakpoints.down("md")]: {
        //   flexWrap: 'wrap',
        justifyContent: "space-between",
      },
    },
    appResponsive: {
      margin: "20px 10px",
      marginTop: "0px",
      "& svg": {
        color: `${theme.palette.common.black} !important`,
      },
    },
    toolbarSection: {
      display: "flex",
      alignItems: "center",
    },
    closeButtonDrawer: {
      position: "absolute",
      right: "8px",
      top: "9px",
      zIndex: "1",
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    transparent: {
      backgroundColor: "transparent !important",
      boxShadow: "none",
      //   paddingTop: '25px',
      [theme.breakpoints.up("md")]: {
        paddingTop: 25,
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: 15,
      },
      "&toolbar": {
        paddingTop: 25,
      },
    },
    // eslint-disable-next-line tss-unused-classes/unused-classes
    solid: {
      maxHeight: HEADER_HEIGHT, //
      paddingTop: `0 !important`,
      //  color: whiteColor,
      backgroundColor: `${theme.palette.primary.main} !important`,
      boxShadow: `0 4px 20px 0px rgba(${hexToRgb(
        theme.palette.common.black
      )}, 0.14), 0 7px 12px -5px rgba(${hexToRgb(grayColor[9])}, 0.46)`,
    },
  };
});

export default function Header(props) {
  const { lang, fullScreen, isLandingPage, hide } = props;

  useRenders("header");

  const supportedNamespaces = ["nav"];
  const namespaces = setNamespaces(lang, supportedNamespaces);
  const { isMdScreen, isSmScreen, isXsScreen } = useScreenSizes();

  const [user, { loggingOut, loading }] = useAuth();
  const [showSearchBar, setShowSearchBar] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [isExpandingHeader, setIsExpandingHeader] = React.useState(fullScreen);
  const [headerColor, setheaderColor] = React.useState(
    isExpandingHeader ? "transparent" : "solid"
  );

  React.useEffect(() => {
    if (showSearchBar) {
      setShowSearchBar(false);
    } else if (!showSearchBar && !isMdScreen) {
      //   setShowSearchBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMdScreen, isSmScreen]);

  const height = 100;

  const isCollapsedMenu = isMdScreen; // was isSmScreen? // SHOW_STRAVA_LOGIN ? isSmScreen : isMdScreen;

  // NB this is not the same as isXsScreen which is
  const { classes, cx } = useStyles();

  React.useEffect(() => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMdScreen]);

  React.useEffect(() => {
    if (fullScreen && !isExpandingHeader) {
      setIsExpandingHeader(true);
    } else if (!fullScreen && isExpandingHeader) {
      setIsExpandingHeader(false);
    }
  }, [fullScreen, isExpandingHeader, setIsExpandingHeader]);

  const handlePageChange = (url) => {
    const isExpanding = checkIfIsExpandingHeader(url);
    if (parseInt(process.env.NEXT_PUBLIC_SHOW_HEADER_CHANGES)) {
      console.log(
        "[Header] | Route change Started | New page is Expanding! ",
        isExpanding,
        "| New URL:",
        url,
        "| Previous isExpandingHeader:",
        isExpandingHeader
      );
    }

    if (isExpanding !== isExpandingHeader) {
      setIsExpandingHeader(isExpanding);
      if (!isExpanding) {
        if (headerColor !== "solid") {
          setheaderColor("solid");
          window.removeEventListener("scroll", handleScrollChange);
        }
      } else {
        if (headerColor !== "transparent") {
          setheaderColor("transparent");
        }
      }
    }
  };

  // When rendering fallback pages etc, we show a loader page so want to change the header immediately; for other pages we only want to update the header when the page is ready to change
  Router.events.on("routeChangeStart", (url) => {
    if (shouldShowPageChange(url, Router)) {
      handlePageChange(url);
    }
  });

  Router.events.on("routeChangeComplete", (url) => {
    if (!shouldShowPageChange(url, Router)) {
      handlePageChange(url);
    }
  });

  const handleScrollChange = React.useCallback(() => {
    if (!isExpandingHeader) {
      return;
    }
    const windowsScrollTop = window.pageYOffset;

    const hasScrolled = windowsScrollTop > height;

    // when the user is scrolled down:
    if (hasScrolled) {
      setheaderColor("solid");
      if (parseInt(process.env.NEXT_PUBLIC_SHOW_HEADER_CHANGES)) {
        console.log(
          "[Header] | Handling Scroll change | Setting Color to Solid"
        );
      }
      // when they are at the top of the page:
    } else {
      if (parseInt(process.env.NEXT_PUBLIC_SHOW_HEADER_CHANGES)) {
        console.log(
          "[Header] | Handling Scroll change | Setting Color to Transparent"
        );
      }
      setheaderColor("transparent");
    }
  }, [isExpandingHeader]);

  const handleDrawerToggle = () =>
    isCollapsedMenu && setMobileMenuOpen(!mobileMenuOpen);

  React.useEffect(() => {
    if (isExpandingHeader) {
      if (parseInt(process.env.NEXT_PUBLIC_SHOW_HEADER_CHANGES)) {
        console.log("[Header] | ADDING SCROLL LISTENER");
      }
      setheaderColor("transparent");
      window.addEventListener("scroll", handleScrollChange);
    } else {
      if (parseInt(process.env.NEXT_PUBLIC_SHOW_HEADER_CHANGES)) {
        console.log("[Header] | REMOVING SCROLL LISTENER");
      }
      setheaderColor("solid");
      window.removeEventListener("scroll", handleScrollChange);
    }
    return function cleanup() {
      if (isExpandingHeader) {
        window.removeEventListener("scroll", handleScrollChange);
      }
    };
    // Eslint wants us to add classes to the deps array, but this would
  }, [fullScreen, handleScrollChange, isExpandingHeader]);

  if (parseInt(process.env.NEXT_PUBLIC_SHOW_HEADER_CHANGES)) {
    console.log(
      "[Header] | Is expanding header:",
      isExpandingHeader,
      "| HeaderColor:",
      headerColor,
      "fullScreen",
      fullScreen
    );
  }

  if (hide) {
    return <div />;
  }

  // NB was previously clientOnly
  const links = <HeaderLinks handleDrawerToggle={handleDrawerToggle} />;

  const appBarClasses = cx({
    [classes.appBar]: true,
    [classes[headerColor]]: headerColor,
    [classes.isExpandingHeader]: isExpandingHeader,
  });

  const nextBreakpointUp = collapseMenuWidthThreshold === "sm" ? "md" : "lg";
  const unCollapsedLinksProps = {
    sx: { display: { xxs: "none", [nextBreakpointUp]: "block" } },
  };

  const collapsedLinksProps = {
    sx: { display: { [collapseMenuWidthThreshold]: "none", xxs: "block" } },
  };

  const SearchBarProps = {
    id: "main-search-bar",
    location: "header",
    setValue: setShowSearchBar,
  };

  return (
    <div id="header">
      <I18nProvider
        lang={lang}
        namespaces={namespaces}
        internals={i18Internals}
      >
        <AppBar className={appBarClasses}>
          <Toolbar
            id="toolbar"
            className={classes.container}
            disableGutters
            variant="dense"
          >
            <Box {...collapsedLinksProps} className={classes.toolbarSection}>
              <IconButton
                sx={{ mr: 2 }}
                id="mobileMenu"
                color="inherit"
                aria-label="menu"
                label="open drawer"
                onClick={handleDrawerToggle}
                size="large"
              >
                <Menu />
              </IconButton>
              <Drawer
                anchor="left"
                drawerVersion="header"
                open={mobileMenuOpen}
                onClose={handleDrawerToggle}
              >
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  label="close drawer"
                  onClick={handleDrawerToggle}
                  className={classes.closeButtonDrawer}
                  size="large"
                >
                  <Close />
                </IconButton>
                <div className={classes.appResponsive}>{links}</div>
              </Drawer>
            </Box>
            <LogoButton logoHeight={40} titleFontSize="24px" centreOnMobile />
            <Box
              sx={{ display: { xxs: "none", sm: "block" } }}
              className={classes.flexGrow}
            >
              <SearchBar {...SearchBarProps} value={isSmScreen} />
            </Box>
            <Box {...unCollapsedLinksProps}>{links}</Box>
            <div className={classes.toolbarSection}>
              {SHOW_STRAVA_LOGIN && (
                <Box
                  sx={{
                    minWidth: { lg: "140px", sm: "125px" },
                  }}
                >
                  <ClientOnly>
                    {(!user || user.provider == "wordpress") &&
                    !loading &&
                    !loggingOut ? (
                      (!isLandingPage ||
                        !parseInt(
                          process.env.NEXT_PUBLIC_REDIRECT_STRAVA_LOGIN
                        )) && <StravaLogin location="header" />
                    ) : (
                      <UserNav
                        user={user}
                        isMdScreen={isMdScreen}
                        loading={loading}
                      />
                    )}
                  </ClientOnly>
                </Box>
              )}
              <SearchIconButton
                sx={{ display: { sm: "none", xs: "block" } }}
                customIconClasses={classes.openSearchIcon}
                onClick={() => {
                  setShowSearchBar(!showSearchBar);
                }}
              />
              <Drawer
                drawerVersion="searchBar"
                open={showSearchBar}
                anchor="top"
                onClose={() => setShowSearchBar(false)}
              >
                <SearchBar {...SearchBarProps} value={isXsScreen} />
              </Drawer>
            </div>
          </Toolbar>
          {/*    </Container> */}
        </AppBar>
      </I18nProvider>
    </div>
  );
}

Header.defaultProps = { color: "white" };
Header.propTypes = {
  fullScreen: PropTypes.any,
  hide: PropTypes.any,
  lang: PropTypes.any,
  pageTransition: PropTypes.any,
};
