import useSSR from "use-ssr";
import React from "react";
//import { animateScroll as scroll } from 'react-scroll';

/* const scroll = dynamic(() => import('react-scroll')).then(
  (mod) => mod.animateScroll
); */
import dayjs from "dayjs";


import {
  RANDOM_REVALIDATE_MULTIPLIER,
  MAX_RECENT_PRODUCTS,
} from "settings/global-website-config";
import { sleep } from "@geerly/shared";

import { errorLoggerUrl, ROOT_URL } from "settings/global-urls";
import { logException } from "components/TrackingProvider/TrackingProvider";
import purgeCdn from "apollo/purgeCdn";
import { SerializableError } from "./customIsSerializableProps";

export const deleteQueryFromAsPath = (asPath) => asPath.split("?")[0];

export const getSupportsShoes = (category) =>
  category?.categoryDetails?.supportedChoiceTypes?.includes("shoe-size");

export const addPossibleQueryParams = (url, query) => {
  if (query && Object.keys(query).length) {
    return [url, new URLSearchParams(query).toString()].join("?");
  }
  return url;
};
export const getStringBeforeLastSlash = (input) => {
  const lastSlashIndex = input.lastIndexOf("/");
  return lastSlashIndex !== -1 ? input.substring(0, lastSlashIndex) : input;
};
export const dedupeArray = (arr) => {
  return Array.from(new Set(arr));
};
export const validateEmail = (email) => {
  // Not 100% conclusive but will catch most obviously wrong emails
  // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const getTaxFromTaxArray = ({ tax, taxArray }) =>
  taxArray?.find((t) => t.taxonomy === tax)?.terms;

// Checks the taxArray for a sport and if found returns it - but only if there's only one sport
export const getSport = (taxArray) => {
  const terms = getTaxFromTaxArray({ tax: "sport", taxArray });
  if (terms?.length === 1) return terms[0];
  return null; // no sport or more than one
};

export const getSportFromCategory = (category) =>
  category.sports.nodes.length === 1 ? category.sports.nodes[0] : null;

export const createTaxArrayEntry = (taxonomy, terms) => {
  terms = Array.isArray(terms) ? terms : [terms];

  return { taxonomy, terms };
};

export const addPageNumberToTitle = (title, page) =>
  page > 1 ? `${title} (page ${page})` : title;
export const getSportBreadcrumbEntry = (sport, type) =>
  `${sport.node.name} ${type}`;
export const camelToSlug = (str) =>
  str.charAt(0).toLowerCase() +
  str.slice(1).replace(/[A-Z]/g, (m) => "-" + m.toLowerCase());

export const depluralizer = (w) => w.substring(0, w.length - 1);

export const createTaxArrayFromScratch = (category, taxesToInclude) => {
  let taxArray = [{ taxonomy: "category", terms: [category.slug] }];

  const sport = getSportFromCategory(category);

  if (sport) taxArray.push(sport);
  if (taxesToInclude) {
    taxArray = taxArray.concat(taxesToInclude);
  }
  return taxArray;
};

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const { isBrowser } = useSSR();

//export const isLocal = () => true; //isBrowser && window?.location.hostname === 'localhost';

export const isDev = process.env.NEXT_PUBLIC_ENVIRONMENT !== "prod"; //&&
// process.env.NEXT_PUBLIC_ENVIRONMENT !== 'preview'; // TODO: disable once tested & working

export const forceTrack = () =>
  Boolean(parseInt(process.env.NEXT_PUBLIC_FORCE_TRACKING));

export const shouldTrack = (!isDev || forceTrack()) && isBrowser;

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getStrideLength = (speed, cadence, noUnit) => {
  const value = Math.round((speed / ((cadence * 2) / 60)) * 100);
  //const value = Math.round((((speed * 60) / 0.5) * cadence) / 1000);

  if (noUnit) {
    return value;
  }
  return [value, "cm"].join(" ");
};

const highResUnits = { km: "m", mi: "ft" };
const loResUnits = { km: "km", mi: "miles" };

export const getConvertedDistance = (
  distance,
  measurementPreference,
  includeUnit = true,
  highResolution = false,
  notRounded = false
) => {
  let converted = highResolution
    ? measurementPreference === "km"
      ? Math.round(distance)
      : Math.round(distance * 3.28)
    : measurementPreference === "km"
    ? distance / 1000
    : (distance / 1000) * 0.621;

  if (!highResolution) {
    converted = !notRounded ? Math.round(converted) : converted;
  }

  if (!includeUnit) {
    return converted;
  }
  const measurementPreferenceToUse = highResolution
    ? highResUnits[measurementPreference]
    : loResUnits[measurementPreference];
  return [(converted || 0).toLocaleString(), measurementPreferenceToUse].join(
    " "
  );
};

export const getDistanceAlertParams = (measurementPreference) => ({
  min: measurementPreference === "km" ? 300 : 200,
  max: measurementPreference === "km" ? 1200 : 800,
  step: 50,
});

export const getConvertedDistanceAlertThreshold = (
  threshold,
  measurementPreference,
  reverse = false
) => {
  if (measurementPreference === "mi") {
    threshold = reverse ? threshold / 0.621371 : threshold * 0.621371;
  }

  const { step, min, max } = getDistanceAlertParams(measurementPreference);

  const calculated = Math.round(threshold / step) * step;
  if (!reverse) {
    if (calculated < min) {
      return min;
    } else if (calculated > max) {
      return max;
    }
  }
  return calculated;
};

export const getPaceUnit = (measurementPreference) =>
  "min/" + measurementPreference;

export const getPaceFromSpeed = (speed, measurementPreference, addUnits) => {
  const duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);
  if (!speed) {
    return;
  }
  const paceSeconds = (measurementPreference === "km" ? 1000 : 1620) / speed;

  let formatted = dayjs.duration(paceSeconds * 1000).format("mm:ss");

  if (addUnits) {
    formatted = [formatted, getPaceUnit(measurementPreference)].join(" ");
  }
  return formatted;
};

export const logError = (path, from, code) => {
  console.log("[Error logger] | Log error to path", path, "| From", from);
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "prod") {
    fetch(`${errorLoggerUrl}${path}&from=${from}&code=${code}`, {
      method: "POST",
    });

    logException({ path, from }, true);
  } else {
    console.log(
      "[Error logger] | Did not send as in local development environment"
    );
  }
};

export const clearSessionStorage = (options) => {
  /*  sessionStorage.removeItem(TRANSITION_PROPS_CACHE_NAME);
  if (!options || options.ignoreModelUrl) {
    sessionStorage.removeItem(PRODUCTS_URL_CACHE_NAME);
  } */
};

/* export const isWebKit154 =
  typeof navigator !== 'undefined' &&
  /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) &&
  /(os |version\/)15(.|_)[4-9]/i.test(navigator.userAgent); */

// Technically can be wrong when using dev tools etc to toggle btw device types, but prefer to save the result and only calc once until this becomes a problem.
/* export const isTouchDevice =
  isBrowser &&
  ('ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0); */

/* export const isIOS =
  isBrowser &&
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export const isSafari =
  isBrowser &&
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1;
*/

export const tryParseJson = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (typeof o === "object") {
      return o;
    }
    return jsonString; // send back the original if it wasn't a JSON
  } catch (e) {
    // if it returns an error, it wasn't a JSON, so just return the original value;
    return jsonString;
  }
};

const REMOVE_TYPENAME_FROM_PROPS = true;
export const removeTypeNameFromProps = (result) => {
  if (REMOVE_TYPENAME_FROM_PROPS) {
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty("sizeCharts")) {
      return JSON.parse(
        JSON.stringify(result, (key, value) => {
          if (key === "__typename") return;
          return value;
        })
      );
    }
  }
  return result;
};
/* 
function removeTypeNameFromProps(obj) {
  for (const key in obj) {
    if (key === 'sizeCharts') {
      if (Array.isArray(obj[key])) {
        obj[key].forEach(removeTypeNameFromProps);
      } else if (typeof obj[key] === 'object') {
        removeTypeNameFromProps(obj[key]);
      }
    } else if (key === '__typename') {
      delete obj[key];
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach(removeTypeNameFromProps);
    } else if (typeof obj[key] === 'object') {
      removeTypeNameFromProps(obj[key]);
    }
  }
} */
export const getAuthorisedImage = (athlete) =>
  athlete.node.integration.accountAuthorised
    ? athlete.node.athleteDetails.profileImages.profile
    : athlete.node.featuredImage?.node.thumbnail;

export const getRandomRevalidateFromInterval = (interval, from) => {
  // Adds some variation into revalidate times so that every page doesn't need revalidating at exactly the same time - to distribute load to server;
  // Will revalidate +/- the specified interval. Eg. if period is 1 hour, could happen between 45 mins and 1hr 15;
  if (parseInt(process.env.NO_REVALIDATE)) {
    console.log("NO REVALIDATION FOR PAGE");
    return false;
  }
  if (parseInt(process.env.RANDOMISE_REVALIDATE_TIME)) {
    interval = parseInt(interval);

    const randomisedInterval =
      Math.random() * RANDOM_REVALIDATE_MULTIPLIER * interval;

    const randomisedNumber =
      interval +
      randomisedInterval -
      (interval * RANDOM_REVALIDATE_MULTIPLIER) / 2;
    const rounded = Math.round(randomisedNumber);

    console.log(
      `[Build] | ${from} | Revalidation Interval ${Math.round(
        rounded / 1000 / 60
      )} minutes`
    );
    return rounded;
  }
  // Just return the interval if randomising not set
  return interval;
};

const types = {
  en: [
    { single: "product", plural: "models", schema: "product" },
    { single: "family", plural: "families", schema: "family" },
  ],
};
export const getPluralType = (type, lang) => {
  if (!lang) {
    //return type;
    throw new Error("getPluralType | no Lang");
  }
  if (!types[lang]) {
    throw new Error(
      `getPluralType | No types found for lang ${lang} | type: ${type}`
    );
  }
  return types[lang].find((t) => t.schema === type).plural;
};
//t === 'product' ? 'models' : 'families';

export const getSingleType = (type, lang) => {
  if (!lang) {
    //return type;
    throw new Error("getSingleType | no Lang");
  }
  if (!types[lang]) {
    throw new Error(
      `getSingleType |No types found for lang ${lang} | type: ${type}`
    );
  }
  return types[lang].find((t) => t.plural === type).schema;
};

// NB we stick with Product here as this is only used for querying grapqhl where models are still called products
//type === 'models' ? 'product' : 'family';

export const preventNegative = (int) => Math.max(0, int); // Returns 0 if passed a -ve number

export const getQueryType = (type) => type === "custom";

export const getRecentItems = (limit) => {
  let recentItems = localStorage?.getItem("recentItems");
  if (recentItems) {
    recentItems = JSON.parse(recentItems);
    if (limit && recentItems.length > MAX_RECENT_PRODUCTS) {
      recentItems.length = MAX_RECENT_PRODUCTS;
    }
    return recentItems;
  }
  return null;
};

export const checkIsValidResponseAndInvalidate = async (
  error,
  uri,
  type,
  queryName
) => {
  if (!error) {
    console.log(
      "[Build] | No error object found | Assume response was OK and attempt to purge cache"
    );
    return purgeCdn({ uri, type, queryName });
  } else if (error instanceof SerializableError) {
    console.log("[Build] | Serializable error found | Purge CDN and try again");
    return purgeCdn({ uri, type, queryName });
  } else if (error.networkError?.statusCode === 200) {
    console.log("[Build] | Error had 200 status code | Check JSON validity");
    if (error.networkError.bodyText) {
      console.log("[Build] | Body text found | Check JSON validity");

      if (!isJsonString(error.networkError.bodyText)) {
        console.log(
          "[Build] | Invalid JSON found in 200 status response | Invalidating cache before retrying"
        );
        return purgeCdn({ uri, type, queryName });
        // Handle invalidate mutation here using SLUG etc
      } else {
        console.log(
          "[Build] | StatusCode === 2000 && Body text looks OK | Not purging"
        );
      }
    } else {
      console.log(
        "[Build] | No body text found but Status 200 returned | Invalidating cache before retrying"
      );
      console.log(JSON.stringify(error, null, 2));
      return purgeCdn({ uri, type, queryName });
    }
  } else {
    console.log(
      "[Build] | Non-200 status code found | Must be genuine error | No point in purging cache | Code:",
      error.networkError?.statusCode,
      "| Error:",
      JSON.stringify(error, null, 2)
    );
    if (parseInt(process.env.PURGE_NON_200_ERRORS)) {
      // TODO: Some non-200 errors have been solved by purging, so this logic should be simplified
      console.log(
        "[Build] | Going to purge anyway as PURGE_NON_200_ERRORS set"
      );

      return purgeCdn({ uri, type, queryName });
    }

    return;
  }
};

export const checkForObject = (obj, checkForValues = false) => {
  // Rejects empty objects ie {} === false
  if (!obj) {
    return false;
  }
  const keys = Object.keys(obj);
  if (keys.length === 0) {
    return false;
  }
  if (checkForValues) {
    for (const key of keys) {
      if (obj[key]) {
        return true;
      }
    }
    return false;
  }

  return true;
};

export const getUrlPostType = (postType) =>
  postType === "product" ? "model" : postType;
export const getUserFromReq = (req, res, noLogoutOnNoUser = false) => {
  const user = req.session?.passport?.user;
  if (!user && !noLogoutOnNoUser) {
    if (res.finished || res.writableEnded || res.headersSent) {
      console.log(
        "[getUserFromReq] | Headers already sent so can't add notLoggedIn code"
      );
    } else {
      res
        .status(401)
        .json({ message: "You are not logged in", notLoggedIn: true });
    }
  }
  return user;
};

export const checkScope = (user, scope) =>
  user?.scope && user.scope.includes(scope);

export const getSizeLocaleOptions = (
  sizeCharts,
  categoryDetails,
  allowUnsupported
) => {
  if (
    (!allowUnsupported &&
      !categoryDetails.supportedChoiceTypes?.includes("shoe-size")) ||
    !sizeCharts
  ) {
    return null;
  }

  const keys = Object.keys(sizeCharts);

  return Object.keys(sizeCharts[keys[0]][0]).filter((k) => k !== "value");
};

export const getAthleteUri = (slug, absolute = false) => {
  const main = `/athlete/${slug}`;
  if (!absolute) {
    return main;
  }
  return [ROOT_URL, main].join("/");
};
export const useRenders = (from, displayName) => {
  if (parseInt(process.env.NEXT_PUBLIC_TRACK_RENDERS)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      console.log("[Track renders] | +++MOUNTING", from);
      return () => {
        console.log("[Track renders] | ---UNMOUNTING", from);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let string = "[Track renders] | Rendering " + from;

    if (displayName) {
      string = string + " | " + displayName;
    }
    console.log(string);
  }
};
export const sortByViewCount = (a, b) =>
  parseInt(b.reviewDetails.videoReview.viewCount) -
  parseInt(a.reviewDetails.videoReview.viewCount);
// In maintenance mode we don't want any fallback pages

const fallbackModeForEmptyStaticPaths = !(
  parseInt(process.env.NEXT_PUBLIC_MAINTENANCE_MODE) &&
  parseInt(process.env.NEXT_PUBLIC_CONNECT_ONLY)
);
export const emptyStaticPathsForBypass = {
  paths: [],
  fallback: fallbackModeForEmptyStaticPaths,
};

export const noPrerender = !!(
  parseInt(process.env.NEXT_PUBLIC_MAINTENANCE_MODE) ||
  parseInt(process.env.NEXT_PUBLIC_SKIP_PRERENDERING_PAGES) ||
  parseInt(process.env.NEXT_PUBLIC_CONNECT_ONLY)
);

export const isMaintenanceMode = Boolean(
  parseInt(process.env.NEXT_PUBLIC_MAINTENANCE_MODE) ||
    parseInt(process.env.NEXT_PUBLIC_CONNECT_ONLY)
);

export const checkIfMaintenanceModeProps = (uri = null) => {
  // Usually, URI will be empty
  if (isMaintenanceMode && uri !== "maintenance") {
    console.log(
      "[Build] | As maintenance mode is enabled, returning empty props"
    );
    return { props: {} };
  }
};

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
};

export const scrollToTop = () => {
  import("react-scroll").then((scroll) =>
    scroll.animateScroll.scrollToTop({
      smooth: true,
      animation: "easeInOutQuint",
    })
  );
};

export const logTime = () => {
  if (parseInt(process.env.LOG_TIME)) {
    const time = new Date().toString();
    console.log("[Log time] | Time now: ", time);
  }
};

export const getApprovedContent = (obj, key = "content") => {
  if (!obj) {
    return null;
  }
  const { approvalStatus } = obj;

  if (approvalStatus === "publish") {
    return obj[key];
  }
  return null;
};
