// import { createHttpLink } from 'apollo-link-http'; // just 'HttpLink in the pkg
//import fs from 'fs';
//import fetch from 'node-fetch';
//const fetch = require('node-fetch');

const { ApolloLink, createHttpLink } = require("@apollo/client");

const { RetryLink } = require("@apollo/client/link/retry");
const { onError } = require("@apollo/client/link/error");

const { TokenRefreshLink } = require("apollo-link-token-refresh");

const { updateAccessToken } = require("./updateAccessToken.js");

const isTokenValid = require("./isTokenValid.js");
const { GRAPHQL_URL } = require("../settings/global-urls.js");

const roundToDecimals = (num, decimals = 0) => Number(num.toFixed(decimals));

export const apolloLink = function link(
  accessToken,
  refreshToken,
  expiresAt,
  useBasicAuth
) {
  if (useBasicAuth && !process.env.WP_ENCODED_USERNAME_FRONTEND_WORKER) {
    throw new Error("useBasicAuth is set but no encoded username found");
  }
  const httpLink = createHttpLink({
    //   fetch, // Switches between unfetch & node-fetch for client & server.
    uri: GRAPHQL_URL,
    // credentials: 'include'
  });

  if (parseInt(process.env.NEXT_PUBLIC_BYPASS_CDN)) {
    console.log("[Apollo client] | CDN bypassed | URL:", GRAPHQL_URL);
  }

  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: new Date() });
    return forward(operation);
  });

  const errorLink = onError(
    ({ operation, response, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`,
            "| useBasicAuth:",
            useBasicAuth,
            "| Operation:",
            operation
          )
        );
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }

      if (response && parseInt(process.env.SHOW_GQL_RESPONSE_IN_ERROR)) {
        console.log("[Response]", response);
      }
    }
  );
  let count = 0;

  // retries if the request fails e.g. due to network issues:
  const retryLink = new RetryLink({
    delay: {
      jitter: true,
      initial: 1000,
    },
    attempts: {
      max: 3,
      retryIf: async (error, operation) => {
        console.log(
          "[RetryLink] | Should retry? Error:",
          error,
          "Operation",
          operation,
          "count:",
          count++
        );
        const shouldRetry = !!error;

        if (shouldRetry) {
          // Can we purge the CDN?
          const { operationName, variables } = operation;

          if (variables) {
            const { uri, slug } = variables;
            if (uri || slug) {
              if (typeof window === "undefined") {
                const { default: purgeCdn } = await import("./purgeCdn.js", {
                  ssr: true,
                });

                console.log("purgeCdn imported | PURGING ", purgeCdn);
                await purgeCdn({ uri, type: operationName, slug });
              }
            }
          }
        }

        return shouldRetry;
      },
    },
  }); /* .split(
// Source: https://graphcdn.io/docs/how-to/retry-failed-requests

    // This allows us to switch to the standard CMS URL if the CDN is down. Not sure how it works with the retryLink above though...

    // If the operation is ok…
    (operation) => operation.getContext().response?.ok !== false,
    // …send requests through the GraphCDN service…
    new HttpLink({ uri: GRAPHQL_URL }),
    // …otherwise retry requests to the origin directly
    new HttpLink({ uri: CMS_URL })
  ); */

  // checks the access token is still valid, and if not, requests a new one. Not currently needed?:

  let updatedAccessToken = accessToken;

  const refreshLink = new TokenRefreshLink({
    accessTokenField: "access_token",
    isTokenValidOrUndefined: () => {
      return new Promise((resolve) => {
        const isIt =
          !refreshToken ||
          !expiresAt ||
          isTokenValid(accessToken, expiresAt) ||
          typeof accessToken !== "string";
        resolve(isIt);
      });
    },
    async fetchAccessToken() {
      return new Promise((resolve) => {
        resolve(updateAccessToken(refreshToken));
      });
    },

    handleResponse: (_operation, _accessTokenField) => (response) => {
      return response;
    },
    handleFetch: (uAccessToken) => {
      updatedAccessToken = uAccessToken;

      // TODO: update session with new token
    },
    handleError: async (err) => {
      // full control over handling token fetch Error
      if (err) {
        console.log("Error renewing token");
        console.error(err);
      }
    },
  });

  // return the headers to the context so httpLink can read them

  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      const customHeaders = { ...headers };

      if (useBasicAuth || accessToken) {
        customHeaders.authorization =
          (accessToken && `Bearer ${updatedAccessToken}`) ||
          `Basic ${process.env.WP_ENCODED_USERNAME_FRONTEND_WORKER}`;

        /*  console.log(
          '[useBasicAuth] | customHeaders.authorization being added:',
          customHeaders.authorization
        ); */
      }

      return {
        headers: customHeaders,
      };
    });
    return forward(operation);
  });

  const responseLogger = new ApolloLink((operation, forward) =>
    forward(operation).map((result) => {
      const { headers } = operation.getContext().response;

      if (parseInt(process.env.SHOW_RAW_GQL_QUERY)) {
        console.log(
          "[Apollo] | Raw query | Remove SHOW_RAW_GQL_QUERY to hide",
          operation.query
        );
      }
      const time = Math.round(
        (new Date() - operation.getContext().start) / 1000
      );

      if (parseInt(process.env.SHOW_GRAPHQL_CACHE_DETAILS)) {
        let contentLength;

        if (parseInt(process.env.SHOW_GRAPHQL_RESPONSE_LENGTH)) {
          contentLength = new TextEncoder().encode(result.data).length;
        }

        const cacheControl = headers.get("Cache-control");
        const maxAge = cacheControl?.split("maxage=")[1];
        const age = headers.get("age");
        const percentOfMaxAge =
          maxAge && (age || age === 0)
            ? roundToDecimals((age / maxAge) * 100, 4)
            : "n/a";
        console.log(
          "[apolloLink] | Operation:",
          operation.operationName,
          `| Took ${time} seconds | x-cache:`,
          headers.get("x-cache"),
          "| Age:",
          age,
          "| Cache-control:",
          headers.get("Cache-control"),
          "| % of life used:",
          percentOfMaxAge,
          "| Response size (bytes):",
          headers.get("Content-Length"),
          "| Use basic auth?",
          !!useBasicAuth,
          (contentLength && `| Response size (bytes): ${contentLength}`) || ""
        );
      }
      if (parseInt(process.env.SHOW_GRAPHQL_RESPONSE_HEADERS)) {
        console.log(
          "[Apollo client] | Showing grapqhl full headers in dev mode:",
          headers
        );
      }
      return result;
    })
  );
  if (process.env.CONFIRM_APOLLO_LINK_CREATION) {
    console.log(
      "[Apollo] | Creating apolloLink | AccessToken:",
      accessToken,
      "useBasicAuth?",
      useBasicAuth,
      "process.env.WP_ENCODED_USERNAME_FRONTEND_WORKER",
      process.env.WP_ENCODED_USERNAME_FRONTEND_WORKER
    );
  }
  // concatenate the links and return the object for the Client to use:
  return ApolloLink.from([
    responseLogger,
    errorLink,
    retryLink,
    refreshLink,
    authLink,
    timeStartLink,
    httpLink,
  ]);
};
