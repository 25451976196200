const mergeUniqueNodes = (existing = {}, incoming, { readField }) => {
  const existingNodes = existing?.nodes || [];
  const incomingNodes = incoming?.nodes || [];

  const nodeMap = new Map();

  // Use readField to access the ID for more reliable uniqueness handling
  existingNodes.forEach((node) => {
    const nodeId = readField("id", node);
    nodeMap.set(nodeId, node);
  });
  incomingNodes.forEach((node) => {
    const nodeId = readField("id", node);
    nodeMap.set(nodeId, node);
  });

  return {
    ...existing, // Merge other properties
    ...incoming,
    nodes: Array.from(nodeMap.values()), // Convert map back to array
  };
};

const mergeContentNodes = (existing = {}, incoming, { readField }) => {
  const mergedData = mergeUniqueNodes(existing, incoming, {
    readField,
  });

  return {
    ...mergedData,
    pageInfo: incoming.pageInfo || existing.pageInfo,
  };
};

const mergeCommunicationPreferences = (existing = {}, incoming) => {
  // Merge top-level fields
  const mergedResult = {
    ...existing,
    ...incoming,
  };

  // Check for nested objects and merge them separately
  if (existing.shoeSizing || incoming.shoeSizing) {
    mergedResult.shoeSizing = {
      ...(existing.shoeSizing || {}),
      ...(incoming.shoeSizing || {}),
      sizeLocale: {
        ...(existing.shoeSizing?.sizeLocale || {}),
        ...(incoming.shoeSizing?.sizeLocale || {}),
      },
      sex: {
        ...(existing.shoeSizing?.sex || {}),
        ...(incoming.shoeSizing?.sex || {}),
      },
    };
  }

  return mergedResult;
};
const mergeTopicContent = (existing = {}, incoming) => {
  return {
    ...existing,
    ...incoming,
    pageTitle:
      incoming.pageTitle !== undefined
        ? incoming.pageTitle
        : existing.pageTitle,
    pageDescription:
      incoming.pageDescription !== undefined
        ? incoming.pageDescription
        : existing.pageDescription,
    image: incoming.image !== undefined ? incoming.image : existing.image,
    imageLoadingColor:
      incoming.imageLoadingColor !== undefined
        ? incoming.imageLoadingColor
        : existing.imageLoadingColor,
  };
};
const typePolicies = {
  RootQuery: {
    fields: {
      contentNodes: { merge: mergeContentNodes },
    },
  },
  Product: {
    fields: {
      category: { merge: true },
    },
  },
  Category: {
    fields: {
      categoryDetails: {
        merge(existing = {}, incoming) {
          return {
            ...existing,
            ...incoming,
            // Assume each subfield needs specific handling; example for an array field
            supportedChoiceTypes: [
              ...(existing.supportedChoiceTypes || []),
              ...(incoming.supportedChoiceTypes || []).filter(
                (item) => !existing.supportedChoiceTypes?.includes(item)
              ),
            ],
            // If there are other complex fields, handle them similarly
          };
        },
      },
    },
  },
  Athlete: {
    fields: {
      athleteDetails: { merge: true },
      communicationPreferences: mergeCommunicationPreferences,
      integration: { merge: true },
    },
  },

  Topic: {
    fields: {
      topicContent: {
        merge: mergeTopicContent,
      },
    },
  },
  ProductNode: {
    fields: {
      productNodeContent: { merge: true },
      productReviews: { merge: true },
      latestModelDetails: { merge: true },
      category: { merge: true },
    },
  },
  ProductToCategoryOneToOneConnectionEdge: {
    fields: {
      productReviews: { merge: true },
    },
  },
  Review: {
    fields: {
      reviewDetails: { merge: true },
    },
  },
  TermContentNode: {
    fields: {
      termContent: { merge: true },
    },
  },
};

export default typePolicies;
