import React from "react";

import PropTypes from "prop-types";

import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles()({
  grid: {
    // marginRight: "-15px",
    // marginLeft: "-15px",

    width: "auto", // TG REMOVED 03 FEB 2020 - was impacting griditem in section product content // KEEP THIS IN - ITS IMPORTANT!
  },
  fullWidth: {
    marginLeft: "0 !important",
    width: "100% !important", // TG RE
  },
});

export default function GridContainer(props) {
  const { children, className, fullWidth, disable, ...rest } = props;
  const { classes, cx } = useStyles();
  return (
    <Grid
      container={!disable}
      columnSpacing={{ xxs: 1, sm: 2 }}
      {...rest}
      className={cx({
        [className]: className,
        [classes.grid]: true,
        [classes.fullWidth]: fullWidth,
      })}
    >
      {children}
    </Grid>
  );
}

GridContainer.defaultProps = {
  className: "",
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
